import { Component } from "react";
import { Linking } from "react-native";
import { connect } from "react-redux";
import {
  SHr,
  SLoad, SMapView, SNavigation, SPage,
  SText, STheme,
  SView
} from "servisofts-component";
import Botoncito from "../Components/Botoncito";
import FotoPerfilUsuario from "../Components/FotoPerfilUsuario";
import Model from "../Model";

class ubicacion_gps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {
        latitude: -17.7833276,
        longitude: -63.1821408,
      }
    };
    this.key_usuario = SNavigation.getParam("key_usuario", "fad45bc1-015a-48e9-9e7a-1162daaad707");
    this.key_rol = SNavigation.getParam("key_rol");

    if (!this.key_rol) {
      this.key_rol = "d16d800e-5b8d-48ae-8fcb-99392abdf61f";
    }
  }




  valido_NombreCompleto(nombre, apellidos) {
    return <SText style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{"Nombre Completo: " + (nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }
  valido_CI(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 7 ? STheme.color.text : "cyan"} >{"CI: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText backgroundColor='red' color="white" bold > error: el CI tiene menos de 7 dígitos </SText> : ""}
    </SView>
  }
  valido_Telefono(telefono) {
    return <SView col={"xs-12"} row >
      <SText color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}   </SText>
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText backgroundColor='red' color="white" bold > error: campo Telefono </SText> : ""}
    </SView>
  }
  valido_Correo(correo) {
    return <SText style={{ color: (correo ?? "").length < 12 ? "red" : "cyan" }}>{"Correo: " + (correo ?? "")} </SText>;
  }
  valido_Cumpleaños(Cumpleaños) {
    return <SView col={"xs-12"} row >
      <SText color={STheme.color.text} >{"F.Nacimiento: " + (Cumpleaños ?? "")}   </SText>
      {(Cumpleaños ?? "").length < 9 ? <SText backgroundColor='red' color="white" bold > error: campo Fecha Nacimiento </SText> : ""}
    </SView>
  }
  valido_RazonSocial(nombre) {
    return <SView col={"xs-12"} row >
      <SText color={(nombre ?? "").length < 5 ? STheme.color.text : "cyan"} >{"Razón Social: " + (nombre ?? "")}   </SText>
      {(nombre ?? "").length < 5 ? <SText backgroundColor='red' color="white" bold > error: La razón social tiene poco caracteres </SText> : ""}
    </SView>
  }
  valido_Nit(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 7 ? STheme.color.text : "cyan"} >{"Nit: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText backgroundColor='red' color="white" bold > error: el CI/NIT tiene menos de 7 dígitos </SText> : ""}
    </SView>
  }
  getPerfil() {
    this.data = Model.usuario.Action.getByKey(this.key_usuario);
    if (!this.data) return <SLoad />
    return <SView center col={"xs-11 md-11 lg-7"} style={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 8 }} row border>
      <SView col={"xs-12"} row height center  >
        <SHr height={24} />

        <SView col={"xs-12 md-4"} border={"transparent"} center >
          <SView border="transparent" style={{ width: 180, height: 180, }} center>
            <FotoPerfilUsuario usuario={this.data} />
          </SView>
        </SView>
        <SView col={"xs-12 md-8"} border={"transparent"} center>
          <SView col={"xs-12"}>
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos de usuario</SText>
            <SHr height={4} />
            {this.valido_NombreCompleto(this.data?.Nombres, this.data?.Apellidos)}
            {this.valido_CI(this.data?.CI)}
            {this.valido_Telefono(this.data?.Telefono)}
            {this.valido_Cumpleaños(this.data["Fecha nacimiento"])}
            {this.valido_Correo(this.data?.Correo)}
            <SHr />
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos para la facturación</SText>
            <SHr height={4} />
            {this.valido_Nit(this.data?.Nit)}
            <SHr height={4} />
            {this.valido_RazonSocial(this.data?.RazonSocial)}
          </SView>
        </SView>
        <SHr height={18} />
      </SView>
    </SView >
  }

  getLista() {


    var usr = {};
    if (this.key_usuario) {
      usr = Model.usuario.Action.getByKey(this.key_usuario);
      if (!usr) return <SLoad />;
    }
    return <>
      <SView col={"xs-11 md-11 lg-7"} border="transparent" >
      <SHr height={18} />

        <SText fontSize={16} color={STheme.color.secondary} bold >UBICACIÓN GPS</SText>
        <SHr height={10}></SHr>

        <SView col={"xs-12"} height={200} border="transparent" style={{ borderRadius: 10, maxHeight:250 }}>
          <SMapView
            initialRegion={{
              latitude: parseFloat(usr.Latitude) ? parseFloat(usr.Latitude) : this.state.region.latitude,
              longitude: parseFloat(usr.Longitude) ? parseFloat(usr.Longitude) : this.state.region.longitude,
              latitudeDelta: 0.0922,
              longitudeDelta: 0.0421,
            }}
            options={{
              fullscreenControl: false,
              zoomControl: false,
              gestureHandling: "none",
              scrollwheel: false,
            }} preventCenter>
            <></>
            <SMapView.SMarker latitude={parseFloat(usr.Latitude) ? parseFloat(usr.Latitude) : ""} longitude={parseFloat(usr.Longitude) ? parseFloat(usr.Longitude) : ""}>
            </SMapView.SMarker>
          </SMapView>
          <SView col={"xs-12"} height style={{ position: "absolute" }} withoutFeedback></SView>
        </SView>

        <SHr height={20}></SHr>

        <SView col={"xs-12"} row center>
          <Botoncito backgroundColor="#0C0C0C" style={{ borderColor: "black" }} center col={"xs-5.8"} onPress={() => { Linking.openURL(`http://maps.google.com/maps?q=${parseFloat(usr.Latitude) ?? this.state.region.latitude}+${parseFloat(usr.Longitude) ?? this.state.region.longitude}`); }} >IR A GOOGLE MAPS</Botoncito>
          <SView flex></SView>
          <Botoncito backgroundColor="#D70000" style={{ borderColor: "#BF0707" }} center col={"xs-5.8"} onPress={() => { SNavigation.navigate("Mapa", { key: this.key_usuario }) }} >EDITAR UBICACIÓN</Botoncito>
        </SView>
        <SHr height={20}></SHr>
      </SView>

    </>
  }





  render() {
    return (
      <SPage title={'Registrar ubicacion_gps'} disableScroll center>
        <SView col={"xs-9"} center row border="transparent">
          {this.getPerfil()}
          {/* <SHr height={32} /> */}
          {this.getLista()}
        </SView>
        <SLoad type='window' hidden={!this.state.loading} />
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ubicacion_gps);
