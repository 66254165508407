import { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SLoad, SText, STheme, SThread, SView } from 'servisofts-component';
import dispositivo from '../../dispositivo';
class SincronizarUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getContent() {
    if (this.state.loading) return <SLoad />
    return <SText center col={"xs-12"} fontSize={18}>Guardar huella de usuario  <SIcon center name={"Reload"} fill={STheme.color.text} style={{ height: 16, width: 16 }} />
    </SText>

  }
  render() {
    if (!this.props.key_usuario) return null;
    return (
      <SView onPress={() => {
        if (this.state.loading) return;
        dispositivo.Actions.sincronizarAll(this.props);
        new SThread(3000, "load", true).start(() => {
          this.setState({ loading: false })
        })
        this.setState({ loading: true })

        // SSocket.send({
        //     servicio: "zkteco",
        //     component: "zkteco",
        //     type: "sincronizarUsuario",
        //     estado: "cargando",
        //     key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
        //     data: {
        //         key_usuario: this.props.key_usuario,
        //         // key_sucursal: this.key_sucursal,
        //     }
        // })
      }} center style={{
        borderWidth: 1,
        borderRadius: 8,
        height: 60,
        width: "100%",
        borderColor: "#ddd",
        backgroundColor: STheme.color.card
      }}>
        {this.getContent()}
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(SincronizarUsuario);