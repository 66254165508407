import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SHr, SIcon, SLoad, SNavigation, SPage, SPopup, SScrollView2, SText, STheme, SThread, SView } from 'servisofts-component';
import Recibo from '../../Component/Recibo';
import SSocket from 'servisofts-socket'
import Html2Canvas from 'html2canvas';
import jspdf from 'jspdf';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';

class EsperandoVenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.key = SNavigation.getParam("key");
    this.key_paquete_venta_usuario = SNavigation.getParam("key_paquete_venta_usuario");
  }

  imprimirRecibo() {
    const input = document.getElementById('recibo');
    Html2Canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jspdf('p', 'mm', [canvas.width, canvas.height]);
        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const heightLeft = imgHeight;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('recibo.pdf');
      });
  }

  imprimirFacturaCarta() {
    if (!this.codigo_factura) return;
    SSocket.sendPromise({
      component: "facturacion",
      type: "getFacturaPdfV2",
      codigo_factura: this.codigo_factura + ""
    }).then(e => {
      const byteCharacters = atob(e.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
      console.log(e);
    }).catch(e => {
      console.error(e);
    })
  }

  imprimirFacturaRollo() {
    if (!this.codigo_factura) return;
    SSocket.sendPromise({
      component: "facturacion",
      type: "getFacturaRollo",
      codigo_factura: this.codigo_factura + ""
    }).then(e => {
      const byteCharacters = atob(e.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
      console.log(e);
    }).catch(e => {
      console.error(e);
    })
  }

  validate = () => {
    var reducer = this.props.state.paqueteVentaReducer;
    if (reducer.estado == "exito" && reducer.type == "registro") {
      reducer.estado = "";
      var data = reducer.lastRegister;
      this.setState({
        data: data
      });
    }
    if (reducer.estado == "error" && reducer.type == "registro") {
      reducer.estado = "";
      this.setState({
        error: reducer.error
      });
    }
  }

  getButtom({ title, onPress, icon }) {
    return <SButtom type={"default"} style={{ height: 70, borderRadius: 8, backgroundColor: STheme.color.card }} onPress={onPress}>
      <SIcon name={icon} width={40} height={40} />
      <SHr height={4} />
      <SText secondary bold center >{title}</SText>
    </SButtom>
  }

  getRenderData() {
    let validaProrroga = SSRolesPermisosValidate({ page: "EsperandoVenta", permiso: "prorroga" });
    let validaTraspaso = SSRolesPermisosValidate({ page: "EsperandoVenta", permiso: "traspaso_sucursal" });
    if (this.state.error) {
      if (this.state.error == "existe_venta") {
        return <SView col={"xs-12"} center>
          <SHr />
          <SHr />
          <SText fontSize={22} bold>Ya existe una venta este día para este cliente.</SText>
          <SHr />
          <SHr />
          <SButtom props={{ type: "danger" }} onPress={() => { SNavigation.replace("inicio"); }}>Ir a inicio</SButtom>
        </SView>
      }
      return <SText>{this.state.error}</SText>
    }
    if (SNavigation.getParam("loading")) {
      if (!this.state.data) return this.isLoad();
    }

    var reducer = this.props.state.paqueteVentaReducer;
    this.codigo_factura = reducer?.recibo[this.key]?.paquete_venta?.codigo_factura;

    // var key = this.key;
    // console.log("traer reducer  ", reducer.recibo)
    // console.log("traer codigo factura  ", reducer.recibo[this.key].paquete_venta?.codigo_factura)

    return <SView center col={"xs-12"} flex backgroundColor='transparent'>
      <SHr height={36} />
      <SView col={"xs-12 md-8 lg-5"} center row border="transparent">
        {this.key_paquete_venta_usuario && validaTraspaso ?
          <SView flex center style={{ overflow: "hidden" }} >
            {this.getButtom({
              title: "TRASPASO SUCURSAL", icon: "TraspasoSucursal", onPress: () => {
                SNavigation.navigate("/sucursal_paquete_venta_usuario", { key_paquete_venta_usuario: this.key_paquete_venta_usuario })
              }
            })
            }
          </SView> : null}
        {this.key_paquete_venta_usuario && validaProrroga ?
          <SView flex center style={{ overflow: 'hidden' }} >
            {this.getButtom({
              title: "PRORROGA", icon: "Profanity", onPress: () => {
                SNavigation.navigate("prorroga/registro", { key_paquete_venta_usuario: this.key_paquete_venta_usuario })
              }
            })
            }
          </SView>
          : null}
        <SView flex center> {this.getButtom({ title: "IMPRIMIR RECIBO", icon: "Ajustes", onPress: () => { this.imprimirRecibo() } })}</SView>
        <SView flex center> {this.getButtom({ title: "FACTURA CARTA", icon: "Ajustes", onPress: () => { this.imprimirFacturaCarta() } })} </SView>
        <SView flex center> {this.getButtom({ title: "FACTURA ROLLO", icon: "Ajustes", onPress: () => { this.imprimirFacturaRollo() } })} </SView>
        <SView flex center>
          {this.getButtom({
            title: "SALIR", icon: "Salir", onPress: () => {
              SPopup.confirm({ title: "¿Está seguro que desea ir caja?", onPress: () => { SNavigation.replace("CajaPage"); } })
            }
          })}
        </SView>
      </SView>
      <SHr height={16} />
      <Recibo key_paquete_venta={this.key} />
      <SHr height={32} />
    </SView>
  }

  hilo() {
    new SThread(10000, "venta", false).start(() => {
      if (!this.state.data && !this.state.error) {
        this.setState({ data: {} })
      }
    });
  }
  isLoad() {
    return <SView center>
      <SHr height={64} />
      <SText fontSize={20}>Estamos verificando la venta.</SText>
      <SHr height={64} />
      <SLoad />
      <SHr height={64} />
      <SText>Por favor aguardé sin recargar la ventana.</SText>
      <SHr />
    </SView>
  }
  render() {
    this.hilo();
    this.validate();
    var isLoad = SNavigation.getParam("loading", false);
    return (
      <SPage hidden={isLoad ? true : false} disableScroll >
        <SView col={"xs-12"} flex>
          <SScrollView2 disableHorizontal>
            <SView col={"xs-12"}>
              {this.getRenderData()}
            </SView>
          </SScrollView2>
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(EsperandoVenta);