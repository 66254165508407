import { Component } from "react";
import { connect } from "react-redux";
import {
  SHr,
  SInput,
  SLoad,
  SPage,
  STable2,
  SText,
  SView
} from "servisofts-component";
import Model from "../Model";
import Sucursal from "./Sucursal";

// let arr = [];
// const accumulator = {};
// const stockSum = {};

let arr_dias_semana = [
  { key: "0", content: "--" },
  { key: "1", content: "Lunes" },
  { key: "2", content: "Martes" },
  { key: "3", content: "Miercoles" },
  { key: "4", content: "jueves" },
  { key: "5", content: "Viernes" },
  { key: "6", content: "Sabado" },
  { key: "7", content: "Domingo" },
];

const horas_dia = [
  // { key: " ", content: "--" },
  { key: "06:00", content: "06:00" },
  { key: "07:00", content: "07:00" },
  { key: "08:00", content: "08:00" },
  { key: "09:00", content: "09:00" },
  { key: "10:00", content: "10:00" },
  { key: "11:00", content: "11:00" },
  { key: "12:00", content: "12:00" },
  { key: "13:00", content: "13:00" },
  { key: "14:00", content: "14:00" },
  { key: "15:00", content: "15:00" },
  { key: "16:00", content: "16:00" },
  { key: "17:00", content: "17:00" },
  { key: "18:00", content: "18:00" },
  { key: "19:00", content: "19:00" },
  { key: "20:00", content: "20:00" },
  // { key: "21:00", content: "21:00" },
];

class testAlvaroHorario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _dia: {},
      _lunes: {},
      _martes: {},
      _miercoles: {},
      _jueves: {},
      _viernes: {},
      _sabado: {},
      _domingo: {},
      _sucursal: {},
      _entrenador: "",
      _precio: {},
      _totalHoras: 0,
      _totalPagar: 0
    };
    this.key_rol = "b5b4a616-dd16-4443-b859-39245f50c8df";
  }

  entrenador_seleciona: SInput;
  dia_seleciona: SInput;

  plantilla() {
    var objEntrenador = Model.usuarioRol.Action.getAllByKeyRol(this.key_rol);
    if (!objEntrenador) return <SLoad />;

    var objUsuario = Model.usuario.Action.getAll();
    if (!objUsuario) return <SLoad />;

    const data_entrenador = Object.values(objEntrenador).map((data) => data.key_usuario);

    const arr_entrenadores = Object.values(data_entrenador).map((keys) => ({ key: keys, content: objUsuario[keys]?.Nombres + " " + objUsuario[keys]?.Apellidos }));

    if (!arr_entrenadores) return <SLoad />;

    return (
      <>
        <SInput
          label={"Entrenador"}
          defaultValue={""}
          type={"select"}
          options={[{ key: "0", content: "--" }, ...arr_entrenadores]}
          ref={(ref) => (this.entrenador_seleciona = ref)}
          onChangeText={(val) => {
            this.setState({ _entrenador: val });
          }}
        />

        {/* <SInput
          label={"Dia"}
          defaultValue={""}
          type={"select"}
          options={arr_dias_semana}
          ref={(ref) => (this.dia_seleciona = ref)}
          onChangeText={(val) => {
            if (this.state._dia !== val) {

              // this.state._dia = val;
              // this.state._sucursal = {};
              // this.state._precio = {};
              // this.setState({ ...this.state })

              this.setState({
                _dia: val,
                _sucursal: {},
                _precio: {} // Puedes restablecer el valor predeterminado de _precio aquí si es necesario
              });

              console.log("pansado todo")
            }
          }}
          required
        /> */}
      </>
    );
  }

  tabla() {

    var objUsuario = Model.usuario.Action.getAll();
    if (!objUsuario) return <SLoad />;


    var objSucursal = Sucursal.Actions.getAll(this.props);
    if (!objSucursal) return <SLoad />


    const data_sucursales = Object.values(objSucursal).map((obj) => ({
      // { key: "0", content: "--" },
      key: obj.key,
      content: obj.descripcion
    }));





    return (
      <>
        <STable2
          header={[


            {
              key: "-Suc", label: "Sucursal", width: 90,
              component: (aux) => {
                return <SInput type={"select"} value={this.state._sucursal[aux.key] || "0"} options={[{ key: "0", content: "--" }, ...data_sucursales]}
                  required={true}
                  onChangeText={(val) => {
                    this.state._sucursal[aux.key] = val;
                    this.setState({ ...this.state })
                  }}
                />
              }
            },

            { key: "content", label: "Hora", width: 50, center: true },

            {
              key: "-lun", label: "Lun", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._lunes[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._lunes[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },
            {
              key: "-mar", label: "Mar", width: 45, sumar: true, center: true,
              renderTotal: (a) => 100,
              component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._martes[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._martes[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },
            {
              key: "-mie", label: "Mie", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._miercoles[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._miercoles[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },
            {
              key: "-jue", label: "Jue", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._jueves[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._jueves[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },


            {
              key: "-vier", label: "Vie", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"}
                    value={this.state._viernes[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._viernes[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              },
            },

            {
              key: "-sab", label: "Sab", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._sabado[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._sabado[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },

            {
              key: "-dom", label: "Dom", width: 45, center: true, component: (obj) => {
                return <SView flex height center >
                  <SInput type={"checkBox"} value={this.state._domingo[obj.key]}
                    onChangeText={(e) => {
                      if (e) {
                        this.state._totalHoras++;
                      } else {
                        this.state._totalHoras--;
                      }
                      this.state._domingo[obj.key] = e;
                      this.setState({ ...this.state })
                    }}
                  />
                </SView>
              }
            },




            {
              key: "-ssas", label: "Precio", width: 70, center: true,
              component: (aux2) => {
                return <SInput type={"number"} value={this.state._precio[aux2.key] || ""} onChangeText={(val) => {
                  console.log("calorrr ", val)
                  console.log("auxiliar ", aux2)

                  this.state._precio[aux2.key] = val;
                  this.setState({ ...this.state })
                }}
                  required={true}
                />;
              }
            },
            {
              key: "key", label: "confirmar", center: true, width: 90, component: (keys) => {
                return (
                  <SView width={50} height={30} card center onPress={() => {

                    if (!this.diasSeleccionado?.getValue() || !this.state._sucursal[keys] || !this.state._precio[keys]) {
                      alert("campos vacios")
                    }


                    var data_capturado = {
                      hora: keys,
                      entrenado: this.entrenador_seleciona?.getValue(),
                      Sucursal: this.state._sucursal[keys],
                      precio: this.state._precio[keys] ?? 0,
                      diasSeleccionado: [
                        this.state._lunes[keys] ? "lun" : '',
                        this.state._martes[keys] ? "mar" : '',
                        this.state._miercoles[keys] ? "mie" : '',
                        this.state._jueves[keys] ? "jue" : '',
                        this.state._viernes[keys] ? "vie" : '',
                        this.state._sabado[keys] ? "sab" : '',
                        this.state._domingo[keys] ? "dom" : '',
                      ].filter(day => day !== '')


                    }
                    this.state._totalPagar = this.state._totalPagar + (data_capturado?.precio * data_capturado.diasSeleccionado?.length);
                    this.setState({ ...this.state })
                    console.log("enviar al servidor ", data_capturado)
                  }}>
                    <SText>Guardar</SText>
                  </SView >
                );
              }
            }
          ]}
          data={horas_dia}
        />
      </>
    );
  }

  render() {
    return (
      <SPage title={"horarios"} disableScroll center>
        <SView col={"xs-12 md-5"} padding={8} border={"red"} >
          {this.plantilla()}
          <SText color={"blue"}> Total horas {this.state._totalHoras} - pago {this.state._totalPagar} </SText>
        </SView>
        <SHr />
        <SView flex col={"xs-12 md-8"} card border={"blue"}>
          {this.tabla()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(testAlvaroHorario);
