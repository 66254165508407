import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SHr, SLoad, SMapView, SMath, SNavigation, SPage, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from 'servisofts-socket';
import Model from "../Model";



class testAlvaro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {
        latitude: -17.7833276,
        longitude: -63.1821408,
      },
      func: "_asistencias_por_cliente",
      parametros: {
        "key_usuario": ""
      },
      ...this.state,
    };
    this.isRun = false;
  }

  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      // params: [" "],
      params: ["'" + this.state.parametros.key_usuario + "'",],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }



  showLista() {
    if (!this.state.data) return <SLoad />;
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />

    return <STable2
      limit={18}
      data={this.state.data}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}

      header={[
        { key: "index", label: "#" },
        { key: "sucursal", label: "Sucursal", width: 70 },
        {
          key: "tipo_asistencia", label: "Asistencia", width: 80, render: key => key == 'automatico' ? "Molinete" : "Manual",
          component: (e) => <SView ><SText color={e == 'Molinete' ? "yellow" : "green"}   >{`${e}`}</SText> </ SView>
        },
        // { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
        {
          key: "key_usuario", label: "Usuario", width: 230, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },
        { key: "fecha_hora_registro", label: "F.Registro", order: "desc", width: 120 },
        { key: "horario", label: "Horario", width: 80 },
        { key: "paquete", label: "Paquete", width: 180 },
        {
          key: "key_entrenamiento", label: "Entranmiento", width: 80,
          component: (e) => <SView center style={{
            borderWidth: 1,
            borderColor: "yellow",
            borderRadius: 4,
            padding: 4
          }} onPress={() => { window.open("/entrenamiento_perfil?key_entrenamiento=" + e + "&key_usuario=" + this.state.parametros.key_usuario) }}>
            <SText center color={"yellow"} >ver</SText>  </ SView>
        }, {
          key: "atc_key_usuario", label: "Registro por ATC", width: 280, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },
      ]}
    />


  }

  render() {

    this.state.parametros.key_usuario = SNavigation.getParam("key", "fad45bc1-015a-48e9-9e7a-1162daaad707");
    // this.state.parametros.key_usuario = SNavigation.getParam("nombre", "fad45bc1-015a-48e9-9e7a-1162daaad707");
    // 
    // var usuarios = Model.usuario.Action.getAll();
    // if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />


    // let usuarioooo = this.state.parametros.key_usuario;
    return (
      // <SPage title={"mapa"} disableScroll center>
      <SPage title={`Asistencia usuario`} disableScroll center>
        {/* <SPage title={`Asistencia usuario (${usuarios_data[usuarioooo]?.Nombres + " " + usuarios_data[usuarioooo]?.Apellidos}) `} disableScroll center> */}

        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(testAlvaro);
