import { Component } from "react";
// import { SectionList, Text } from "react-native";

import { SectionList } from 'react-native';

import { connect } from "react-redux";
import { SPage, SText, SView } from "servisofts-component";


let aaaaaaa = [
  {
    title: 'Frutas',
    data: ['Maçã', 'Banana', 'Laranja', 'Manga'],
  },
  {
    title: 'Legumes',
    data: ['Cenoura', 'Batata', 'Abobrinha'],
  },
  {
    title: 'Bebidas',
    data: ['Água', 'Suco', 'Refrigerante'],
  },
];
class Test extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_romeo2",
      parametros: {
        inicio: "2015-01-01",
        fin: "2028-02-01",
      },
      ...this.state,
    };
  }



  MySectionList() {
    return (<SView>
      <SectionList
        sections={aaaaaaa} // Dados da lista
        keyExtractor={(item, index) => item + index} // Chave única para cada item
        renderItem={({ item }) => <SText  >{item}</SText>} // Renderiza cada item da seção
        renderSectionHeader={({ section: { title } }) => ( // Renderiza o cabeçalho da seção
          <SText color="red">{title}</SText>
        )}
      />
    </SView>
    );
  };

  render() {
    return (
      <SPage title={"REPORTE 44"} disableScroll center>
        {this.MySectionList()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(Test);
