import { SPageListProps } from "servisofts-component";
import Actions from "./Actions";

import ClienteHistorial from "./Page/ClienteHistorial";
import ClientePerfilPage from "./Page/ClientePerfilPage_old";
import ClientesComparacion from "./Page/ClientesComparacion";
import ClientesCumpleaño from "./Page/ClientesCumpleaño";
import ClientesPage from "./Page/ClientesPage";
import EntrenadorPage from "./Page/EntrenadorPage";
import entrenador_horarios from "./Page/EntrenadorPage/horarios";
import lista_horarios2 from "./Page/EntrenadorPage/sin_lista_horarios";
import registro_horarios from "./Page/EntrenadorPage/sin_registro_horarios";
import Login from "./Page/Login";
import Perfil from "./Page/Perfil";
import RegistroNuevo from "./Page/RegistroNuevo";
import UsuarioPage from "./Page/UsuarioPage";
import VendedorPage from "./Page/VendedorPage";
import Huellas from "./Page/Huellas";
import Mapa from "./Page/Mapa";
import AsistenciasSucursal from "./Page/ClientesPage/AsistenciasSucursal";
import {
  default as VentasPage,
  default as VentasSelect,
} from "./Page/VentasPage";
const Pages: SPageListProps = {
  login: Login,
  // registro:RegistroCliente,
  registro: RegistroNuevo,
  perfil: Perfil,
  UsuarioPage,
  ClientesPage,
  VentasPage,
  VentasSelect,
  ClientePerfilPage,
  VendedorPage,
  EntrenadorPage,
  "EntrenadorPage/horarios": entrenador_horarios,
  lista_horarios2,
  registro_horarios,
  "usuarios/cumpleaños": ClientesCumpleaño,
  "clientes/historial": ClienteHistorial,
  "clientes/comparacion": ClientesComparacion,
  Huellas,
  Mapa,
  AsistenciasSucursal,
};

import usuarioReducer from "./Reducer/usuarioReducer";
const Reducers = {
  usuarioReducer,
};

export default {
  Pages,
  Actions,
  Reducers,
};
