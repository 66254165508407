import { SPage } from "servisofts-component";

import root from "./root";
import ventas from "./ventas";
import usuarios from "./usuarios";
export const Parent = {
    title: "Migrador",
    name: "migrador",
    path: "/migrador"
}
export default SPage.combinePages(Parent.name,
    {
        "": root,
        ventas,
        usuarios
    }
)