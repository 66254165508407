import { SPage } from "servisofts-component";

import root from "./root";
export const Parent = {
  title: "Grupo ventas",
  name: "grupo_ventas",
  path: "/grupo_ventas",
};
export default SPage.combinePages(Parent.name, {
  "": root,
  // ventitdas: ventitdas,

});
