import { Component } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SImage, SLoad, SNavigation, SOrdenador, SPage, SScrollView2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Usuario from '../..';
import BarraSuperior from '../../../../Components/BarraSuperior';
import Buscador from '../../../../Components/Buscador';
import FloatButtom from '../../../../Components/FloatButtom';
import FloatButtomSyncUsuario from '../../../../Components/FloatButtomSyncUsuario';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';

var objFinal = {};
let tamaño_texto = 9;

class UsuarioPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pagination: { curPage: 1 }
    };
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
    const isMobile = window.innerWidth <= 768;
    tamaño_texto = isMobile ? 6.5 : 9;
  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  }
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />
    }
    if (data.estado != 0) {
      return <View />
    }
    return <SButtom
      style={{ height: 50, width: 55, borderRadius: 4, backgroundColor: "#4c2882", position: "absolute", right: 4 }}
      onPress={() => {
        Usuario.Actions.editar({
          ...data,
          estado: 1,
        }, this.props)
      }} ><SText center fontSize={9.3}>RECUPERAR</SText></SButtom>
  }

  getExportExcel() {
    // valido permiso exportar_excel
    if (!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "exportar_excel" })) {
      return null;
    }

    return <SView col={"xs-12"} center row>
      <SText style={{ color: STheme.color.lightGray, fontSize: 12 }}>(Exportar)</SText>
      <ExportExcel
        header={[
          { key: "indice", label: "Nro", width: 40 },
          { key: "ci", label: "CI", width: 60 },
          { key: "nombres", label: "Nombre completo", width: 200 },
          { key: "telefono", label: "Teléfono", width: 95 },
          { key: "correo", label: "Correo", width: 170 },
          { key: "cumpleaños", label: "Fecha nacimiento", width: 75 },
          { key: "zona", label: "Zona", width: 70 },
          { key: "direccion", label: "Dirección", width: 170 },
          { key: "empresa", label: "Empresa", width: 100 },
          { key: "cargo", label: "Cargo", width: 70 },
          { key: "referencia", label: "Referencia", width: 100 },
          { key: "key_usuario", label: "key_usuario", width: 220 },
        ]}
        getDataProcesada={() => {
          var daFinal = {};
          Object.values(objFinal).map((obj, i) => {
            if (obj.estado == 0) return;
            var toInsert = {
              indice: i + 1,
              key_usuario: obj?.key,
              nombres: obj?.Nombres + " " + obj?.Apellidos,
              ci: obj?.CI,
              telefono: obj?.Telefono,
              correo: obj?.Correo,
              cumpleaños: obj["Fecha nacimiento"],
              zona: obj?.Zona,
              direccion: obj?.Direccion,
              empresa: obj?.Empresa,
              cargo: obj?.Cargo,
              referencia: obj?.Referencia,
            }
            daFinal[i] = toInsert
          })
          return daFinal
        }}
      />
    </SView>
  }

  valido_NombreCompleto(nombre, apellidos) {
    return <SText fontSize={tamaño_texto + 6} style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{(nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_CI(nit) {
    return <SText fontSize={tamaño_texto + 2} color={(nit ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"CI: " + (nit ?? "")}  {(nit ?? "").length < 5 ? <SText fontSize={tamaño_texto + 1} backgroundColor='red' color="white" bold  > error </SText> : ""}</SText >
  }

  valido_Cumpleaños(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"F.Nac: " + (Cumpleaños ?? "")}  {(Cumpleaños ?? "").length < 9 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}   </SText >
  }
  valido_sexo(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Género: " + (Cumpleaños ?? "")} {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}</SText >
  }

  valido_Festivo(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Festivo: " + (Cumpleaños ?? "")} {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold> error </SText> : ""}</SText>
  }

  valido_Telefono(telefono) {
    // if (!telefono) return null;
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}  </SText>
  }

  valido_Correo(correo) {
    return <SText fontSize={tamaño_texto + 2} style={{ color: (correo ?? "").length < 12 ? "red" : STheme.color.text }}> {"Correo: " + (correo ?? "")} </SText >;
  }

  valido_Nit(nit) {
    return <SText fontSize={tamaño_texto} color={(nit ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"Nit: " + (nit ?? "")}  {(nit ?? "").length < 7 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}  </SText>
  }

  valido_RazonSocial(nombre) {
    return <>
      <SText fontSize={tamaño_texto} color={(nombre ?? "").length < 3 ? STheme.color.text : STheme.color.text} >{"Razón Social: " + (nombre ?? "")}
        {(nombre ?? "").length < 5 ? <SText fontSize={tamaño_texto} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}</SText>
    </>
  }

  render() {

    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      if (!data) return <>
        <SText>Cargando server usuarios</SText>
        <SLoad />
      </>

      if (!this.state.buscador) {
        return <View />
      }

      Object.keys(data).map((key) => {
        objFinal[key] = data[key];
      });


      // valido permiso usuarios_eliminado
      var isRecuperar = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "recuperar_eliminado" });
      return this.pagination(
        new SOrdenador([
          { key: "Peso", order: "desc", peso: 4 },
          { key: "Nombres", order: "asc", peso: 2 },
          { key: "Apellidos", order: "asc", peso: 1 },
        ]).ordernarObject(
          this.state.buscador.buscar(objFinal)
        )
      ).map((key) => {
        var obj = data[key];
        return <TouchableOpacity
          key={key}
          style={{
            width: "90%",
            maxWidth: 600,
            height: 65,
            margin: 4,
            borderRadius: 10,
            backgroundColor: STheme.color.card
          }} onPress={() => {
            SNavigation.navigate("registro", { key: key })
          }}>

          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={{
              flexDirection: "row",
              height: "100%",
              width: "100%",
              alignItems: "center"
            }}>

              {/* foto */}
              <SView border={"blue"}
                // <SView border={STheme.color.card}
                style={{ width: 40, height: 40, marginRight: 8, marginLeft: 8, justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 100, overflow: "hidden" }}
              >
                <SImage src={SSocket.api.root + "usuario/" + key + `?date=${new Date().getTime() / 500}`}
                />
              </SView>

              {/* texto */}
              <View style={{ flex: 1, justifyContent: "center" }}>
                <SView col={"xs-12"} row height  >
                  <SView col={"xs-8"}  >
                    {this.valido_NombreCompleto(obj?.Nombres, obj?.Apellidos)}
                    <SView col={"xs-12"} row >
                      <SView col={"xs-2.3"}>
                        {this.valido_CI(obj?.CI)}
                      </SView>
                      <SView col={"xs-3.4"}  >
                        {this.valido_Cumpleaños(obj?.["Fecha nacimiento"])}
                      </SView>
                      <SView col={"xs-3.2"}    >
                        {this.valido_sexo(obj?.Sexo)}
                      </SView>
                      <SView col={"xs-3"}  >
                        {this.valido_Festivo(obj?.Festivo)}
                      </SView>
                    </SView>
                    <SView col={"xs-12"} row >
                      <SView col={"xs-5"}   >
                        {this.valido_Telefono(obj?.Telefono)}
                      </SView>
                      <SView>
                        {this.valido_Correo(obj?.Correo)}
                      </SView>
                    </SView>
                  </SView>
                  <SView col={"xs-4"}  >
                    <SText fontSize={tamaño_texto + 3} color={"cyan"}>DATOS FACTURACIÓN</SText>
                    {this.valido_Nit(obj?.Nit)}
                    {this.valido_RazonSocial(obj?.RazonSocial)}
                  </SView>
                </SView>
              </View>

              {this.getRecuperar(obj, isRecuperar)}
            </View>
          </View>
        </TouchableOpacity >
      })
    }

    return (
      <SPage hidden disableScroll>
        <BarraSuperior title={"USUARIOS"} navigation={this.props.navigation} goBack={() => {
          SNavigation.goBack();
        }} />
        <Buscador placeholder={"Buscar usuario por CI, nombres, apellidos, teléfono."} ref={(ref) => {
          if (!this.state.buscador) this.setState({ buscador: ref });
        }} repaint={() => { this.setState({ ...this.state }) }}
          eliminados={SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "ver_eliminados" })}
        />
        <View style={{ flex: 1, width: "100%" }} >

          <SScrollView2
            disableHorizontal
            onScroll={(evt) => {
              var evn = evt.nativeEvent;
              var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
              var heigth = evn.contentSize.height;
              if (heigth - posy <= 0) {
                this.state.pagination.curPage += 1;
                this.setState({ ...this.state })
              }
            }}
          >
            {this.getExportExcel()}

            <SView col={"xs-12"} center>
              {getLista()}
            </SView>

          </SScrollView2>
          <FloatButtomSyncUsuario style={{ bottom: 90 }} onPress={() => {
            Model.usuario.Action.CLEAR_STORAGE()
          }}
          />
          <FloatButtom esconder={!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "crear" })} onPress={() => {
            SNavigation.navigate("registro")
          }} />
        </View>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(UsuarioPage);