import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SLoad, SMath, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../Model';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      func: "_get_fidelizacion_nuevos3",
      parametros: {
        "inicio": new SDate().setDay(1).toString("yyyy-MM-dd"),
        "fin": new SDate().addMonth(1).toString("yyyy-MM-dd"),
      },
      ...this.state,
    };
  }

  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: ["'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }



  showLista() {
    if (!this.state.data) return <SLoad />;
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />
    console.log(["'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'"])
    return <STable2
      limit={20}
      data={this.state.data}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        { key: "sucursal", label: "Sucursal", width: 100 },
        // { key: "-usuarios", label: "key_usuario", width: 270, render: (obj) => { return obj.key_usuario }, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "key_usuario", label: "Cliente", width: 220, render: (key) => { return `${usuarios[key]?.Nombres} ${usuarios[key]?.Apellidos}`; } },
        { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
        { key: "-telefono", label: "Telefono", width: 100, render: (obj) => { return `${usuarios[obj.key_usuario]?.Telefono}`; } },
        { key: "-correo", label: "Correo", width: 200, render: (obj) => { return `${usuarios[obj.key_usuario]?.Correo}`; } },
        {
          key: "-nacimiento", label: "F.Nac", width: 80, render: (obj) => {
            const validacion = usuarios[obj.key_usuario]?.["Fecha nacimiento"];
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-edad", label: "Edad", center: true, width: 34, render: (obj) => {
            const validacion = new SDate().toString("yyyy") - new SDate(usuarios[obj.key_usuario]?.["Fecha nacimiento"]).toString("yyyy");
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-usuario_key", label: "Historial", width: 60,
          render: (obj) => { return obj.key_usuario },
          component: (e) => <SView center style={{
            borderWidth: 1,
            borderColor: "yellow",
            borderRadius: 4,
            padding: 4
          }} onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>
            <SText center color={"yellow"} >ver</SText>  </ SView>
        },
        { key: "veces", label: "Cant.", width: 30, center: true, },
        { key: "fecha_primera_inscripcion", label: "F.Inscrip.🆕", center: true, filter_h: new SDate().toString('yyyy-MM-'), width: 80 },
        { key: "paquete", label: "Paquete", width: 250 },
        { key: "precio_paquete", label: "Precio", width: 70, center: true, render: (obj) => { return SMath.formatMoney(obj ?? 0, 2, ".") } },
        {
          key: "key_cajero", label: "ATC👤🏧", width: 210, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={"yellow"} >{`${e}`}</SText> </ SView>
        },

      ]}
    />
  }

  render() {
    this.state.parametros.inicio = '2021-01-01';
    this.state.parametros.fin = '2030-01-01';
    return (
      <SPage title={`Reporte de nuevos inscriptos (${this.state.parametros.inicio} / ${this.state.parametros.fin}) 📆`} disableScroll center>
        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);