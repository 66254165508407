import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SHr, SIcon, SImage, STheme } from 'servisofts-component';
import { SLoad, SPopup, SText, SView } from 'servisofts-component';
// import SeleccionarUsuario from './SeleccionarUsuario';
import SSocket from 'servisofts-socket'
import SeleccionarUsuario from './Entrenamiento/Pages/EntrenamientoPage/Entrenamiento/Participantes/SeleccionarUsuario';
import Asistencia from './Asistencia';
import Model from '../Model';
 
class wellcome_molinete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sucursal: '5486c004-a0d7-4c4b-911c-8c183f229810',
    };
  }


  getItem(content) {
    return <SView width={120} height={120} colSquare style={{ padding: 4 }} row >
      {content}
    </SView>

  }
  getAdd() {
    return <SView col={"xs-12"} center height={60}>
      <SView col={"xs-11 md-8 xl-6"} height={50} card center onPress={() => {
        SPopup.open({
          key: "selectUser",
          content: <SeleccionarUsuario select={(usr) => {
            Asistencia.Actions.registro({
              descripcion: "",
              key_usuario: usr.key,
              key_sucursal: this.state.sucursal,
              // key_entrenamiento: this.props.entrenamiento.key
            }, this.props)
            SPopup.close("selectUser");
          }} />
        })
      }}>
        <SText fontSize={16}>Asistencia</SText>
      </SView>
    </SView>
  }
  sincronizar() {
    var key_sucursal = this.state.sucursal;
    return <SView col={"xs-12"} center height={60}>
      <SView col={"xs-11 md-8 xl-6"} height={50} card center onPress={() => {
        SSocket.sendPromise({
          component: "zkteco",
          type: "sincronizarLog",
          estado: "cargando",
          key_sucursal: key_sucursal,
        }, 10000)
      }}>
        <SText fontSize={16}>Sync Molinetes</SText>
      </SView>
    </SView>
  }
  getUsuario(data) {

    var usuarios = Model.usuario.Action.getByKey(data.key_usuario);
    if (!usuarios) {
      return <SLoad />
    }
    return (<>
      <SView card border={data.descripcion == 'automatico' ? "yellow" : "blue"} height col={"xs-12"} center style={{
        overflow: "hidden",
      }}>

        <SText key={data.key_usuario} capitalize center fontSize={12}>{data.descripcion == 'automatico' ? "molinete" : "manual"}</SText>
        <SHr />

        <SView width={40} height={40}>
          <SImage src={SSocket.api.root + "usuario/" + data.key_usuario} />
        </SView>
        <SHr />

        <SText center fontSize={10}>{`${usuarios.Nombres} ${usuarios.Apellidos}`}</SText>
      </SView>

      {/* </SView> */}
    </>
      // </SView>
    )
  }
  getListaUsuariosMolinete = () => {
    if (!this.state.sucursal) return <SView />
    var data = Asistencia.Actions.getByKeySucursal({ key_sucursal: this.state.sucursal }, this.props);
    if (!data) return <SLoad />
    return Object.keys(data).map((key) => {
      var obj = data[key];
      if (obj.estado != 1) return null;
      // si es molinete no mostrar
      if (obj?.descripcion == "") return null;
      return this.getItem(this.getUsuario(obj));
    })
  }
  getListaUsuariosManual = () => {
    if (!this.state.sucursal) return <SView />
    var data = Asistencia.Actions.getByKeySucursal({ key_sucursal: this.state.sucursal }, this.props);
    if (!data) return <SLoad />
    return Object.keys(data).map((key) => {
      var obj = data[key];
      if (obj.estado != 1) return null;
      // si es molinete no mostrar
      if (!obj?.descripcion == "") return null;
      return this.getItem(this.getUsuario(obj))
    })

  }
  getAsistencia_manual = (obj) => {
    var asistencia = Asistencia.Actions.getByKeyEntrenamiento({
      key_entrenamiento: this.key_entrenamiento,
    }, this.props);

    if (!asistencia) return <SLoad />

    return <SView col={"xs-12"} center>
      <SView col={"xs-12"} center row>
        {Object.keys(asistencia).map((key) => {
          var usuario = Model.usuario.Action.getByKey(asistencia[key].key_usuario);
          if (!usuario) return <SLoad />

          if (!asistencia[key]?.descripcion == "") return null;

          return <SView width={120} height={120} colSquare style={{
            padding: 4,
          }} onPress={() => {
            SNavigation.navigate("ClientePerfilPage", { key: asistencia[key].key_usuario })
          }}>
            <SView card border={asistencia[key].key_usuario == this.key_usuario ? "cyan" : STheme.color.card} height col={"xs-12"} center style={{
              overflow: "hidden",
            }}>
              <SText key={key} capitalize center fontSize={12}>Manual</SText>
              <SHr />
              <SView width={40} height={40}>
                <SImage src={SSocket.api.root + "usuario/" + usuario.key} />
              </SView>
              <SHr />
              <SText key={key} capitalize center fontSize={12}>{`${usuario.Nombres} ${usuario.Apellidos}`}</SText>
            </SView>

          </SView>
        })}
      </SView>
    </SView>
  }

  render() {
    return (
      <SView col={"xs-12"} center>
        <SView height={8} />

        <SView col={"xs-12"} center  >


          <SText>{"ASISTENCIA MOLINETE"}</SText>
          <SHr height={40} />
          {this.getListaUsuariosMolinete()}

          <SText>{"ASISTENCIA MANUAL"}</SText>
          <SHr height={40} />
          <SView col={"xs-11.3"} center row backgroundColor='transparent' >
            {this.getListaUsuariosManual()}
          </SView>
        </SView>

      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(wellcome_molinete);