import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SIcon, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SView } from 'servisofts-component';
import FloatButtom from '../../../../../Components/FloatButtom';
import Model from '../../../../../Model';

class lista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Reporte Name",
    };
    this.data_prestamos_pagos;

  }
 
  
  componentDidMount() {
    
    this.getLista();
    Model.usuario.Action.getAll({force:true});
      }

  getCantPagos(key) {
    var _data001 = Model.prestamo_pago.Action.getAll();
    if (!_data001) return <SLoad />;
    var _cantidad = 0;
    Object.values(_data001).map((obj) => {
      if (obj.key_prestamo == key) {
        return _cantidad++;
      }
    });
    console.log("funcion getCantPagos: ", _cantidad)
    return _cantidad;
  }

  getMontoaPagar(key, monto) {
    var cant = this.getCantPagos(key)
    var _monto = cant * monto;
    return (cant * monto);
  }

  // getMontoPagado(item) {
  //   var _data002 = Model.prestamo_pago.Action.getAll();
  //   if (!_data002) return <SLoad />;
  //   var total = 0;
  //   Object.values(_data002).map((obj) => {
  //     if (obj.key_prestamo == item)
  //       return total = total + obj.interes_pagado;
  //   });
  //   return total;
  // }

  // getSaldo(key, monto) {
  //   var data_rrestamo_pago = Model.prestamo_pago.Action.getAll();
  //   if (!data_rrestamo_pago) return <SLoad />;
  //   var cantidades = 0;
  //   var totales = 0;
  //   Object.values(data_rrestamo_pago).map((obj) => {
  //     if (obj.key_prestamo == key) {
  //       totales = totales + obj.interes_pagado;
  //       return cantidades++;
  //     }
  //   });
  //   return (cantidades * monto) - totales;
  // }

  getLista() {
    var data = Model.prestamo.Action.getAll();
    var data_rrestamo_pago = Model.prestamo_pago.Action.getAll();
    var usuarios = Model.usuario.Action.getAll();
    if (!data) return <SLoad />
    if (!data_rrestamo_pago) return <SLoad />
    if (!usuarios) return <SLoad />

    console.log("prestamos ", data);
    console.log("pagos ", data_rrestamo_pago);
    console.log("usuario ", usuarios);

    return <STable2
      header={[
        { key: "index", label: "#", width: 50, },
        { key: "key_prestamista", label: "Prestamista", width: 160 },
        { key: "inicio_prestamo", label: "Inicio prestamo", width: 90, center: true, render: (item) => { return new SDate(item).toString("yyyy-MM-dd") } },
        { key: "fin_prestamo", label: "Fin prestamo", width: 80, type: "date", center: true, render: (item) => { return new SDate(item).toString("yyyy-MM-dd") } },
        { key: "periocidad", label: "periocidad", width: 70, render: (item) => { return (item ? item : 0) + " (dias)" } },
        { key: "monto_prestado", label: "Monto", width: 90, center: true, render: (item) => { return SMath.formatMoney(item ? item : 0) + " Bs" } },
        { key: "porcentaje", label: "Porcentaje", width: 70, center: true, render: (item) => { return (item ? item : 0) + " %" }, },
        {
          key: "estado_prestamo", label: "Estado", width: 60, render: (item) => {
            if (item == 1) {
              return "activo";
            } else if (item == 2) {
              return "pagando";
            } else if (item = 3) return "saldado";
          }
        },
        {
          key: "key-cantidad", label: "Pagos", width: 60, render: (item) => {
            var info = this.getCantPagos(item);
            return info;
          }
        },
        // {
        //   key: "-chau", label: "Monto a Pagar", width: 60, render: (obj) => {
        //     var info = this.getMontoaPagar(obj.key, (obj?.monto_prestado * obj?.porcentaje / 100));
        //     return info;
        //   }
        // },
        // {
        //   key: "key-pagos", label: "Pagados", width: 60, render: (item) => {
        //     var info = this.getMontoPagado(item);
        //     return info;
        //   }
        // },
        // {
        //   key: "-chaval", label: "Saldo", width: 60, render: (obj) => {
        //     var info = this.getSaldo(obj.key, (obj.monto_prestado * obj.porcentaje / 100));
        //     return info;
        //   }
        // },
        {
          key: "fecha_ultimo_pago", label: "Ultimo pago", order: "asc", width: 80, type: "date", center: true, render: (item) => {
            if (!item) return;
            return new SDate(item).toString("yyyy-MM-dd") || null;
          }
        },
        {
          key: "key_usuario", label: "Pagante", width: 120, render: (item) => {
            var _cliente = `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
            return _cliente;
          }
        },
        {
          key: "key-editar", label: "Editar", width: 50, center: true,
          component: (item) => {
            return <SView onPress={() => { SNavigation.navigate("prestamos/registro", { key: item }) }}>
              <SIcon name={"Edit"} width={35} />
            </SView>
          }
        },
        {
          key: "key-eliminar", label: "Anular", width: 80, center: true,
          component: (key) => {
            return <SView width={35} height={35} onPress={() => {
              SPopup.confirm({
                title: "Anular", message: "¿Esta seguro de eliminar?", onPress: () => {
                  Model.prestamo.Action.editar({
                    data: { key: key, estado: 0 }
                  }).then((resp) => {
                    console.log(resp)
                  }).catch((e) => {
                    console.error(e)
                  })
                }
              })
            }}>
              <SIcon name={'Delete'} />
            </SView>
          }
        },
        {
          key: "key-pago", label: "Pagos", width: 80, center: true,
          component: (key) => {
            return <SView width={35} height={35} onPress={() => {
              SNavigation.navigate("prestamos/listaPago", { key: key })
            }}>
              <SIcon name={'Pagos01'} />
            </SView>
          }
        },
      ]}
      filter={(data) => {
        if (data.estado != 1) return false;
        return true;
      }}
      data={data}
    />
  }






  template() {
    return <>
      <SButtom Card
        onPress={() => { SNavigation.navigate("prestamos/dashboard") }}
        style={{ position: "absolute", right: 10, bottom: 90, width: 50, height: 50, }}>
        <SIcon name={"DBPrestamo"} style={{ width: 50, height: 50, }} />
      </SButtom>
    </>
  }


  render() {
    return (<><SPage title={'lista prestamos'} onRefresh={(end) => {
      Model.prestamo.Action.CLEAR();
      end()
    }} disableScroll>

      {this.getLista()}

      <FloatButtom onPress={() => {
        Model.prestamo.Action._getReducer(this.props).estado = "";
        SNavigation.navigate("prestamos/registro");
      }} />

    </SPage >
      {this.template()}
    </>
    )
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(lista);
