import { Component } from 'react';
import { SDate, SHr, SLoad, SNavigation, SPage, STable2, SText } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Botoncito from '../../../Components/Botoncito';
import Model from '../../../Model';
import Paquete from '../../Paquete';
import { connect } from 'react-redux';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Reporte Name",
      func: "reparador_de_paquetes_no_registrados",
      params: [`'${SNavigation.getParam("fecha" || null)}'`],
    };
    this.select_fecha = SNavigation.getParam("fecha" || null);
  }

  componentDidMount() {
    this.getData();
  }

  getFechaPaquete = (f) => {
    if (!f) return new SDate().toString("yyyy-MM-dd")
    const date = new SDate(f, "yyyy-MM-dd");
    const cur = new SDate();
    if (date.equalDay(cur)) {
      date.addDay(1);
    } else if (date.isBefore(cur)) {
      return cur.toString("yyyy-MM-dd")
    }
    date.addDay(1);
    return date.toString("yyyy-MM-dd")
  }
  getFecha_fin = (f, dias) => {
    const date = new SDate(f, "yyyy-MM-dd");
    date.addDay(dias)
    return date.toString("yyyy-MM-dd")
  }
  handleReparar = (obj, paquete) => {
    const fecha_inicio = this.getFechaPaquete(obj?.fecha_fin)
    const fecha_fin = this.getFecha_fin(fecha_inicio, paquete.dias)
    const paquete_venta_usuario = {
      key: obj.key_paquete_venta_usuario,
      key_paquete_venta: obj.key_paquete_venta,
      key_usuario: obj.key_usuario,
      key_paquete: obj.key_paquete,
      estado: 1,
      descripcion: "",
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin,
      key_caja: obj.key_caja,

    }
    SSocket.sendPromise({
      component: "paqueteVentaUsuario",
      type: "reparar",
      data: paquete_venta_usuario
    }).then(e => {
      this.getData();
      console.log(e)
    }).catch((e => {
      console.error(e);
    }))
    console.log(paquete_venta_usuario);
  }
  getLista() {
    const usuarios = Model.usuario.Action.getAll();
    const paquetes = Paquete.Actions.getAll(this.props)
    if (!this.state.data) return <SLoad type='skeleton' col={"xs-12"} height />
    if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />
    if (!paquetes) return <SLoad type='skeleton' col={"xs-12"} height />
    return <>
      <SHr height={80} />
      <SText color='red'>

        {this?.select_fecha}
      </SText>

      <STable2
        limit={30}
        data={this.state.data}
        cellStyle={{
          fontSize: 10,
          height: 30,
        }}
        header={[
          { key: "index", label: "#" },
          // { key: "key_caja", label: "key_caja", width: 150 },
          { key: "key_paquete", label: "key_paquete", width: 150, },
          { key: "key_paquete-paquete", label: "key_paquete", width: 150, render: k => paquetes[k]?.descripcion },
          { key: "key_paquete-dias", label: "dias", width: 50, render: k => paquetes[k]?.dias },
          { key: "key_paquete_venta", label: "key_paquete_venta", width: 150 },
          { key: "key_paquete_venta_usuario", label: "key_paquete_venta_usuario", width: 150 },
          { key: "key_usuario", label: "key_usuario", width: 150, order: "desc" },
          { key: "key_usuario-nombre", label: "usuario", width: 150, render: ku => `${usuarios[ku]?.Nombres} ${usuarios[ku]?.Apellidos}` },
          { key: "monto", label: "monto", width: 100 },
          { key: "observacion", label: "observacion", width: 150 },
          {
            key: "fecha_fin-fi", label: "F inicio", width: 150, render: f => this.getFechaPaquete(f)
          },
          { key: "fecha_fin-new", label: "F fin", width: 150 },
          // { key: "pagos/3/Banco", label: "Banco", width: 150 },
          // { key: "monto", label: "monto", width: 150 },
          // { key: "monto", label: "monto", width: 150 },
          // { key: "monto", label: "Monto", width: 100, },
          // { key: "key", label: "Key", width: 100, cellStyle: { fontSize: 8 } },
          {
            key: "-vercaja", label: "caja", width: 100, component: obj => {
              return <Botoncito height={20} onPress={() => {
                SNavigation.navigate("DetalleCaja", { key: obj.key_caja })
              }} >{"CAJA"}</Botoncito>
            }
          },
          {
            key: "-vercliente", label: "ver", width: 100, component: obj => {
              return <Botoncito height={20} onPress={() => {
                SNavigation.navigate("ClientePerfilPage", { key: obj.key_usuario })
              }} >{"CLIENTE"}</Botoncito>
            }
          },
          {
            key: "-onpress", label: "Reparar", width: 100, component: obj => {
              return <Botoncito height={20} onPress={this.handleReparar.bind(this, obj, paquetes[obj?.key_paquete])} >{"REPARAR"}</Botoncito>
            }
          }
        ]}
      />
    </>
  }
  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      //params: this.select_fecha,
      params: this.state.params,
    }).then(resp => {
      this.setState({ loading: false, data: resp.data });
    }).catch(e => {
      this.setState({ loading: false, error: e });
    })
  }
  render() {
    return <SPage title={this.state.title} center disableScroll>
      {this.getLista()}

    </SPage>
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);