//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SDate, SHr, SInput, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import Container from '../../Components/Container';
import SSocket from "servisofts-socket"
import { FacturaType } from './type';
import { crear_excel } from './reporte_excel_functions';
// create a component
export default class index extends Component {
    state = {
        desde: new SDate().toString("yyyy-MM-dd"),
        hasta: new SDate().toString("yyyy-MM-dd"),
        sucursales: {},
        usuarios: ""
    }

    componentDidMount() {
        SSocket.sendPromise({
            component: "facturacion",
            type: "getParametricas"
        }).then(e => {
            this.setState({ parametricas: e.data.empresa })
        }).catch(e => {
            console.error(e)
        })
    }


    onPress = () => {
        this.setState({ loading: true });
        SSocket.sendPromise({
            component: "facturacion",
            type: "getFacturas",
            fecha_inicio: this.state.desde,
            fecha_fin: this.state.hasta,
            CODIGOSUCURSAL: this.state?.sucursales?.codigo_sucursal_factura,
            CODIGO_AMBIENTE: 1,
        }).then(e => {
            this.setState({ loading: false });
            let arr = e.data.data;
            crear_excel(arr, this.state.parametricas);
            console.log(e);
        }).catch(e => {
            this.setState({ loading: false });
            console.error(e)
        })
    }
    render() {
        return <SPage title={"Reporte de facturas"} >
            <Container loading={!this.state.parametricas}>
                <SView row style={{
                    justifyContent: "space-between"
                }}>
                    <SInput label={"Desde"} col={"xs-5.5"} type='date' value={this.state.desde} onChangeText={(e) => {
                        this.setState({ desde: e })
                    }} />
                    <SInput label={"Hasta"} col={"xs-5.5"} type='date' value={this.state.hasta} onChangeText={(e) => {
                        this.setState({ hasta: e })
                    }} />
                    <SInput label={"Sucursales"} placeholder={"Todas las sucursales"} value={this.state?.sucursales?.descripcion} editable={false} onPress={() => {
                        SNavigation.navigate("SucursalPage", {
                            onSelect: (e) => {
                                this.setState({ sucursales: e })
                            }
                        })
                    }} />
                    <SInput label={"Usuarios"} placeholder={"Todos los usuarios"} value={this.state.usuarios} editable={false} onPress={() => {

                    }} />
                </SView>
                <SHr />
                <SHr />
                <SView col={"xs-12"}>
                    <SText>Formatos seleccionados:</SText>
                    <SHr />
                    <SView row col={"xs-12"}>
                        <SView card padding={8} width={80} height={50} center >
                            <SText >PDF</SText>
                        </SView>
                        <SView width={8} />
                        <SView card padding={8} width={80} height={50} center border={STheme.color.text}>
                            <SText >XLS</SText>
                        </SView>
                    </SView>
                </SView>
                <SHr />
                <SView card padding={8} width={120} height={50} center style={{
                    backgroundColor: STheme.color.danger
                }} onPress={this.onPress.bind(this)}>
                    {this.state.loading ? <SLoad /> : <SText >DESCARGAR</SText>}
                </SView>
            </Container>
        </SPage>
    }
}


