import { SPageListProps } from "servisofts-component";
import Actions from "./Actions";

import Lista from "./Pages/Lista";
// import Human from './Pages/Human'
import EntrenamientoPage from "./Pages/EntrenamientoPage";
import Historial from "./Pages/Historial/index";
import Historial2 from "./Pages/Historial/Lista2";
import Perfil from "./Pages/Perfil";
const Pages: SPageListProps = {
  entrenamientos: Lista,
  entrenamientos_historial: Historial,
  "dashboard_asistencia": Historial2,
  "reporte_asistencias": reporte_asistencias,
  "reporte_asistencias_detalle": reporte_asistencias_detalle,
  "reporte_asistencias_global": reporte_asistencias_global,
  "MailApp": mailApp,
  entrenamiento_perfil: Perfil,
  // "entrenamiento/human":Human,
  EntrenamientoPage,
};

import entrenamientoReducer from "./Reducer/entrenamientoReducer";
import mailApp from "../mailApp";
import reporte_asistencias from "./Pages/Historial/reporte_asistencias";
import reporte_asistencias_detalle from "./Pages/Historial/reporte_asistencias_detalle";
import reporte_asistencias_global from "./Pages/Historial/reporte_asistencias_global";
const Reducers = {
  entrenamientoReducer,
};

export default {
  Pages,
  Actions,
  Reducers,
};
