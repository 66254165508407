import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
  info: {
    component: "entrenador_horario",
  },
  Columns: {
    key: { type: "text", pk: true },
    key_usuario: { type: "text", fk: "usuario" },
    fecha_on: { type: "timestamp", label: "Fecha de creacion" },
    estado: { type: "integer" },

    key_entrenador: { type: "text", fk: "entrenador" },
    key_sucursal: { type: "text", fk: "restaurante" },
    dia: { type: "integer", label: "Día" },
    hora_inicio: { type: "text", editable: true, label: "Desde" },
    hora_fin: { type: "text", editable: true, label: "Hasta" },
  },
  Action,
  Reducer,
});
