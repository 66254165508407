import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SIcon, SInput, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../../../Model';

class registro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        "key_prestamista": {
          label: "Nombre prestamista",
          value: null,
          type: "text",
          col: "xs-11.8",
          isRequired: true,
          placeholder: "Ingresar nombre completo"
        },
        "sucursal": {
          label: "Sucursal",
          value: null,
          type: "text",
          col: "xs-11.8",
          isRequired: true,
          placeholder: "Ingresar sucursal"
        },
        "monto_prestado": {
          label: "Monto prestamo",
          value: null,
          type: "money",
          isRequired: true,
          placeholder: "Ingresar"
        },
        "porcentaje": {
          label: "Porcentaje interes",
          value: null,
          type: "money", icon: <SText>%</SText>,
          isRequired: true,
          placeholder: "Ingresar porcentaje interes",
        },
        "inicio_prestamo": {
          label: "Fecha de inicio",
          value: null,
          type: "date",
          isRequired: true,
          placeholder: "Ingresar fecha",
          iconR: < SIcon name={"Evento"} width={20} fill={STheme.color.card} stroke={STheme.color.secondary} style={{ paddingRight: 10 }
          } />,
        },
        "fin_prestamo": {
          label: "Fecha de fin",
          value: null,
          type: "date",
          isRequired: true,
          placeholder: "Ingresar fecha",
          iconR: <SIcon name={"Evento"} width={20} fill={STheme.color.card} stroke={STheme.color.secondary} style={{ paddingRight: 10 }
          } />,

        },
        "periocidad": {
          label: "Periocidad (cada cuanto/dias pagar)",
          value: null,
          type: "number",
          isRequired: true,
          col: "xs-11.8",
          placeholder: "Ingresar periocidad"
        },
      }
    };
    this.key = SNavigation.getParam("key");
    this._ref = {};

  }

  componentDidMount() {
 
    Model.usuario.Action.getAll({force:true});
      }

  calcular(key) {
    var inputs = this.state.inputs;
    if (key == "inicio_prestamo") {
      if (inputs["inicio_prestamo"].value) {
        var fff = new SDate(inputs["inicio_prestamo"].value, "yyyy-MM-dd");
        // inputs["fin_prestamo"].value = fff.addMonth(1).toString("yyyy-MM-dd");
        inputs["fin_prestamo"].value = fff.addMonth(12).toString("yyyy-MM-dd");
        inputs["periocidad"].value = 90;
      }
    }

    if (key == "fin_prestamo") {
      if (inputs["fin_prestamo"].value < inputs["inicio_prestamo"].value) {
        inputs["fin_prestamo"].value = "";
      }
    }

    var _aux = new SDate(inputs["inicio_prestamo"].value).diff(new SDate(inputs["fin_prestamo"].value));

    var diascapturado = parseInt(_aux);
    this.setState({ inputs: { ...this.state.inputs } });
  }


  getInputs() {
    if (this?.key) {
      this.data = Model.prestamo.Action.getByKey(this.key);
      if (!this.data) return <SLoad />
      if (!this.state.isLoad) {
        this.state.inputs.key_prestamista.value = this.data?.key_prestamista || null;
        this.state.inputs.monto_prestado.value = parseFloat(this.data?.monto_prestado || 0).toFixed(2) || null;
        this.state.inputs.porcentaje.value = this.data?.porcentaje || null;
        this.state.inputs.inicio_prestamo.value = new SDate(this.data?.inicio_prestamo).toString("yyyy-MM-dd") || null;
        this.state.inputs.fin_prestamo.value = new SDate(this.data?.fin_prestamo).toString("yyyy-MM-dd") || null;
        this.state.inputs.periocidad.value = this.data?.periocidad || null;
        this.state.inputs.sucursal.value = this.data?.sucursal || null;
        // this.state.inputs.estado_prestamo.value = this.data?.estado_prestamo || null;
        // this.state.inputs.cantidad_dias.value = 2;
        this.setState({ isLoad: true });
      }
    }
    return Object.keys(this.state.inputs).map((key) => {
      var obj = this.state.inputs[key];
      return <SView col={obj.col ?? "xs-6"} center>
        <SInput ref={(r) => this._ref[key] = r} customStyle={"calistenia"} col={"xs-11"} {...obj} onChangeText={(val) => {
          this.state.inputs[key].value = val;
          this.setState({ inputs: { ...this.state.inputs } });
          this.calcular(key);
        }} />
      </SView>
    })
  }

  render() {

    return (
      <SPage title={'lista'} center>
        <SText>Registrar</SText>
        <SHr height={24} color={"transparent"}></SHr>
        <SView col={"xs-12 sm-10 md-8 lg-6 xl-4"} center row >
          {this.getInputs()}
          <SView col={"xs-12"} height={24} />
          <SButtom type='danger' onPress={() => {

            var values = {};
            var isExito = true;
            Object.keys(this._ref).map((key) => {
              if (!this._ref[key].verify()) {
                isExito = false;
              }


              let finicio, ffin;
              finicio = this.state.inputs.inicio_prestamo.value;
              ffin = this.state.inputs.fin_prestamo.value;
              // var _aux = new SDate((finicio).diff(ffin)).toString("yyyy-MM-dd");

              var _aux = new SDate(finicio).diff(new SDate(ffin));
              var diascapturado = parseInt(_aux);



              // if (diascapturado < this.state.inputs.periocidad.value) {
              // alert("error hay dia por demas");
              // this.state.inputs.periocidad.value = "";
              // isExito = false;
              // }

              values[key] = this._ref[key].getValue();
            })


            if (isExito) {

              if (this.key) {



                Model.prestamo.Action.editar({
                  data: {
                    key: this.key,
                    key_prestamista: this.state.inputs.key_prestamista.value,
                    monto_prestado: this.state.inputs.monto_prestado.value,
                    porcentaje: this.state.inputs.porcentaje.value,
                    inicio_prestamo: this.state.inputs.inicio_prestamo.value,
                    fin_prestamo: this.state.inputs.fin_prestamo.value,
                    periocidad: this.state.inputs.periocidad.value,
                    sucursal: this.state.inputs.sucursal.value,
                    estado_prestamo: 1,
                    estado: 1,
                  }
                }).then((resp) => {
                  Model.prestamo.Action.CLEAR();
                  SNavigation.goBack();
                }).catch((e) => {
                  console.error(e)
                })
              } else {
                Model.prestamo.Action.registro({
                  data: {
                    key_usuario: Model.usuario.Action.getKey(),
                    key_prestamista: this.state.inputs.key_prestamista.value,
                    monto_prestado: this.state.inputs.monto_prestado.value,
                    porcentaje: this.state.inputs.porcentaje.value,
                    inicio_prestamo: this.state.inputs.inicio_prestamo.value,
                    fin_prestamo: this.state.inputs.fin_prestamo.value,
                    periocidad: this.state.inputs.periocidad.value,
                    sucursal: this.state.inputs.sucursal.value,
                    estado_prestamo: 1,
                    // estado_prestamo: this.state.inputs.estado_prestamo.value,
                    estado: 1,
                    // ...data
                  }
                }).then((resp) => {
                  console.log(resp)
                  // Model.prestamo.Action.CLEAR();
                  SNavigation.goBack();

                }).catch((e) => {
                  console.error(e)
                })

              }


            }

          }} >{this.key ? "Editar" : "registrar"}</SButtom>

        </SView>

        {/* <SHr height={50} color={"transparent"}></SHr> */}
      </SPage >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(registro);
