import { Component } from "react";
import { connect } from "react-redux";
import { SLoad, SPage, STable2 } from "servisofts-component";
import SSocket from 'servisofts-socket';
import Model from "../Model";
import sucursal_usuario from "./sucursal_usuario";

class baseDatosAsistencias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_ricky_entrenamientos",
      parametros: {
        "inicio": "2023-07-06",
        "fin": "2026-02-01",
      },
      ...this.state,
    };
  }

 
  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
}

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: ["'" + this.state.parametros.inicio + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type='skeleton' col={"xs-12"} height />

    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return <SLoad />

    return <STable2
      limit={15}
      data={dase_datos}
      cellStyle={{
        fontSize: 12,
        // height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        // { key: "-d", label: "cliente_key", width: 80, render: (item) => { return item.cliente_key } },
        // { key: "key_paquete", label: "Paquete_key", width: 80 },
        // { key: "sucursal_key", label: "Sucursal_key", width: 80 },
        // { key: "-e", label: "Cajero_key", width: 80, render: (item) => { return item.cajero_key } },

        // { key: "sucursal_descripcion", label: "Sucursal", width: 70 },
        // { key: "cliente_key", label: "Cliente", width: 240, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}` } },
        // { key: "fecha_on", label: "F.compra", order: "asc", width: 90, center: true, render: (item) => { return new SDate(item).toString("yyyy-MM-dd") } },
        // { key: "-fecha_on1", label: "Hora", width: 60, center: true, render: (obj) => { return new SDate(obj.fecha_on).toString("hh:mm") } },
        // { key: "-fecha_on2", label: "Año", width: 60, order: "asc", center: true, render: (obj) => { return new SDate(obj.fecha_on).toString("yyyy") } },
        // { key: "paquete", label: "Paquete", width: 150 },
        // // { key: "mes", label: "Cantidad Mes", width: 150 },
        // { key: "fi_inscripcion", label: "F.inicio", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy") } },
        // { key: "ff_inscripcion", label: "F.fin", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy") } },
        // { key: "pagado", label: "Pagado", center: true, sumar: true, width: 80 },
        // { key: "debio_pagar", label: "Precio", width: 60 },
        // { key: "cajero_key", label: "Cajero", width: 210, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}` } },
      ]}
      filter={(item) => {
        return sucursal_usuario.Actions.isActive(item.sucursal_key, this.props)
      }}
    />
  }
  render() {
    return (
      <SPage title={"Base datos - Registro de asistencias 📋📅"} disableScroll center>
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(baseDatosAsistencias);