import { Component } from 'react';
import { SDate, SIcon, SNavigation, SPage, SPopup } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
export default class root extends Component {


  getItemFecha({ title, icon, url, onPress, permiso }) {
    return this.getItem2({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          SNavigation.navigate(url, evt);
        });
      }
    })
  }


  getItem2({ title, icon, url, onPress, permiso }) {
    return <MenuButtom label={title} url={url} onPress={onPress} icon={<SIcon name={icon} />} />
  }



  render() {
    let fi = SNavigation.getParam("fecha_inicio", new SDate().setDay(1).toString("yyyy-MM-dd"));
    let ff = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));

    return <SPage title={"Facturacion"}>
      <MenuPages path={"/facturacion/"} permiso={"page"}
      // filter={(obj) => !!obj.is_page}
      >
        {/* <MenuButtom label={"Notificaciones"} url={"/notificacion"} icon={""} /> */}
        <MenuButtom label={"Parametricas"} url={"/facturacion/parametricas"} icon={<SIcon name='Ajustes' />} />
        {/* <MenuButtom label={"Facturas"} url={"/facturacion/facturas"} icon={<SIcon name='Ajustes' />} /> */}
        <MenuButtom label={"Reporte Excel"} url={"/facturacion/reporte_excel"} icon={<SIcon name='Excel' />} />
        <MenuButtom label={"DasBoard"} url={"/facturacion/dashboard"} icon={<SIcon name='Alert' />} />

        {/* <MenuButtom label={"Facturas consolidados"} url={"/facturacion/consolidados"} params={{ fechaInicio: fi, fechaFin: ff, }} icon={<SIcon name='DataBase' />} />
        <MenuButtom label={"Facturas anulados"} url={"/facturacion/anulados"} params={{ fechaInicio: fi, fechaFin: ff, }} icon={<SIcon name='DataBase' />} />
        <MenuButtom label={"Facturas Historicos"} url={"/facturacion/facturas"} params={{ fecha_inicio: fi, fecha_fin: ff, }} icon={<SIcon name='DataBase' />} /> */}

        {/* si no tiene parametrofecha que te envie a url={"/facturacion"} */}



        {this.getItemFecha({ title: "Facturas Historicos", icon: "Pruebas", url: "/facturacion/facturas", permiso: "PaquetesVendidos" })}
        {this.getItemFecha({ title: "Facturas consolidados", icon: "DataBase", url: "/facturacion/consolidados", permiso: "PaquetesVendidos" })}
        {this.getItemFecha({ title: "Facturas anulados", icon: "DataBase", url: "/facturacion/anulados", permiso: "PaquetesVendidos" })}

      </MenuPages>

    </SPage>
  }



}