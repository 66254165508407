import { Component } from 'react';
import { ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SDate, SHr, SList2, SLoad, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import * as SPDF from 'servisofts-rn-spdf';
import BarraSuperior from '../../../../Components/BarraSuperior';
import FloatButtom from '../../../../Components/FloatButtom';
import FotoPerfilUsuario from '../../../../Components/FotoPerfilUsuario';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
import Sucursal from '../../../Sucursal';

// import BarraSuperior from '../../../../Components/BarraSuperior';
// import FotoPerfilUsuario from '../../../../Components/FotoPerfilUsuario';
// import Model from '../../../../Model';
// import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
// import PaquetesDeUsuario from './PaquetesDeUsuario';

var finalData = {};

let arr_dias_semana = [
  { key: "0", content: "--" },
  { key: "1", content: "Lunes" },
  { key: "2", content: "Martes" },
  { key: "3", content: "Miercoles" },
  { key: "4", content: "jueves" },
  { key: "5", content: "Viernes" },
  { key: "6", content: "Sabado" },
  { key: "7", content: "Domingo" },
];

const horas_dia = [
  // { key: " ", content: "--" },
  { key: "06:00", content: "06:00" },
  { key: "07:00", content: "07:00" },
  { key: "08:00", content: "08:00" },
  { key: "09:00", content: "09:00" },
  { key: "10:00", content: "10:00" },
  { key: "11:00", content: "11:00" },
  { key: "12:00", content: "12:00" },
  { key: "13:00", content: "13:00" },
  { key: "14:00", content: "14:00" },
  { key: "15:00", content: "15:00" },
  { key: "16:00", content: "16:00" },
  { key: "17:00", content: "17:00" },
  { key: "18:00", content: "18:00" },
  { key: "19:00", content: "19:00" },
  { key: "20:00", content: "20:00" },
  { key: "21:00", content: "21:00" },
];

class lista_horarios extends Component {
  static navigationOptions = ({ navigation }) => {
    return { headerShown: false, }
  }
  constructor(props) {
    super(props);
    this.key_entrenador = SNavigation.getParam("key", false);
    this.bandera = false;
    this.state = {
      activa: true,
      _dia: "",
      _sucursal: {},
      _entrenador: "",
      _precio: {}
    };
    // this.key_rol = "b5b4a616-dd16-4443-b859-39245f50c8df";
  }
  componentDidMount() {
    // if (this.key) {
    //   this.getData();
    // }
  }

  entrenador_seleciona: SInput;
  dia_seleciona: SInput;

  EditarPerfil() {
    return <SButtom style={{ width: 60, height: 30, position: "absolute", right: 0, top: 0, backgroundColor: "green" }}
      // if (this.bandera) return <SButtom style={{ width: 60, height: 30, position: "absolute", right: 0, top: 0, backgroundColor: "green" }}
      onPress={() => { SNavigation.navigate("registro", { key: this.key }) }}>
      {/* <SIcon name={"Edit"} style={{ width: 30, height: 30 }} /> */}
      <Text style={{ fontSize: 16, color: STheme.color.text }}>EDITAR</Text>
    </SButtom>
  }





  pdfBody() {
    console.log("jajajaj ", finalData);

    return Object.values(finalData).map((obj, i) => {

      return <SPDF.View >
        <SPDF.Text style={{ width: "100%", }}>Nro. {i + 1}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.sucursal?.descripcion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{new SDate(obj.data?.fecha_on).toString("yyyy-MM-dd")}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>cajero {obj.usuario?.Nombres}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.paquete?.descripcion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.observacion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.monto ?? "0"}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.fecha_inicio}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>fin {obj.data?.fecha_fin}</SPDF.Text>;
      </SPDF.View>

    })
  }




  getPerfil() {
    this.data = Model.usuario.Action.getByKey(this.key_entrenador);
    if (!this.data) return <SLoad />
    return <SView center style={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card }}>
      <SView center border={(this.bandera ? "green" : "transparent")} style={{
        width: "100%",
        maxWidth: 400,
        alignItems: "center"
      }}>

        <SHr />
        <SHr />


        <SView style={{
          width: 180,
          height: 180,
        }} center>
          <FotoPerfilUsuario usuario={this.data} />
        </SView>


        <Text style={{
          color: STheme.color.text,
          fontSize: 18,
          textTransform: "capitalize",
          fontWeight: "bold"
        }}>{this.data["Nombres"] + " " + this.data["Apellidos"]}</Text>

        <Text style={{ color: STheme.color.text, fontSize: 18, textTransform: "capitalize", fontWeight: "bold" }}>{this.data?.CI}</Text>
        <Text style={{ color: STheme.color.text, fontSize: 18, textTransform: "capitalize", fontWeight: "bold" }}>{this.data?.Telefono}</Text>
        <Text style={{ color: STheme.color.text, fontSize: 18, textTransform: "capitalize", fontWeight: "bold" }}>{this.data?.Correo}</Text>
        <Text style={{ color: STheme.color.text, fontSize: 18, textTransform: "capitalize", fontWeight: "bold" }}>{this.data?.["Fecha nacimiento"]}</Text>




        <SHr />
        <SHr />

        {this.EditarPerfil()}


      </SView>
      <SHr />
      {/* <HuellasDeUsuario key_usuario={this.data?.key} /> */}
      <SHr />
      {/* <SincronizarUsuario key_usuario={this.data?.key} /> */}
      <SHr />
      <SHr />
    </SView >
  }





  persona() {
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />

    let datos = this.state?.data_usuario;
    if (!datos) return <SLoad />


    return Object.keys(datos).map(key => {
      let obj = datos[key];
      let fullname = usuarios[obj?.key_usuario]?.Nombres + " " + usuarios[obj?.key_usuario]?.Apellidos;
      return (
        <>
          <SText fontSize={13}>tipo: {obj?.tipo}</SText>
          {!obj?.key_usuario ? null : <SText fontSize={13}>{fullname}</SText>}
          <SText fontSize={13}>{new SDate(obj?.fecha_on).toString('yyyy-MM-dd hh:mm')}</SText>
          <SHr height={8} />
        </>
      );
    });
  }

  exportar_excel() {

    if (!SSRolesPermisosValidate({ page: "ClientesPage", permiso: "exportar_excel" })) {
      return;
    }

    return <ExportExcel
      header={[
        { key: "indice", label: "Indice", width: 30 },
        // { key: "cliente", label: "cliente", width: 40 },
        { key: "sucursal", label: "sucursal", width: 50 },
        { key: "fecha_on", label: "fecha compra", width: 80 },
        { key: "cajera", label: "Cajera", width: 100 },
        { key: "paquete", label: "paquete", width: 140 },
        { key: "motivo", label: "motivo", width: 100 },
        { key: "precio", label: "precio", width: 40 },
        { key: "fecha_inicio", label: "paquete inicio", width: 80 },
        { key: "fecha_fin", label: "paquete fin", width: 80 },
      ]}
      getDataProcesada={() => {
        var daFinal = {};
        Object.values(finalData).map((obj, i) => {
          // if (!obj.data.estado || obj.data.estado <= 0) return;
          var toInsert = {
            indice: i + 1,
            sucursal: obj.sucursal?.descripcion,
            fecha_on: new SDate(obj.data?.fecha_on).toString("yyyy-MM-dd"),
            cajera: obj.usuario?.Nombres,
            paquete: obj.paquete?.descripcion,
            motivo: obj.data?.observacion,
            precio: obj.data?.monto ?? "0",
            fecha_inicio: obj.data?.fecha_inicio,
            fecha_fin: obj.data?.fecha_fin,
          }
          daFinal[i] = toInsert
        })
        return daFinal
      }}
    />
  }



  getFB() {
    if (!this.state.activa) return <SView />
    return <FloatButtom label={"+"} onPress={() => {
      // if (!this.state.activa.key) {
      //   return null;
      // }
      this.props.navigation.navigate("registro_horarios", { key_entrenador: this.key_entrenador });
    }} />
  }


  getItem2(obj) {
    if (obj.key_entrenador != this.key_entrenador) return null;
    if (obj.estado === 0) return null;


    const sucursales = Sucursal.Actions.getAll(this.props);
    if (!sucursales) return <SLoad />

    let dias_semana = [
      { key: "", content: "--" },
      { key: "1", content: "Lunes" },
      { key: "2", content: "Martes" },
      { key: "3", content: "Miercoles" },
      { key: "4", content: "jueves" },
      { key: "5", content: "Viernes" },
      { key: "6", content: "Sabado" },
      { key: "7", content: "Domingo" },
    ]

    return <SView width={90} height={90} border={"red"} onPress={() => { SNavigation.navigate("registro_horarios", { key_horario: obj.key }) }} >
      <SText fontSize={13}>{dias_semana[obj?.dia]?.content}</SText>
      <SText fontSize={13}>{sucursales[obj?.key_sucursal].descripcion}</SText>
      <SText fontSize={13}>{obj?.hora_inicio + " " + obj?.hora_fin}</SText>


      <SView width={40} height={40} backgroundColor={"yellow"} onPress={() => {
        SPopup.confirm({
          title: "Eliminar", message: "¿Esta seguro de eliminar?", onPress: () => {
            // Parent.Actions.eliminar(data[key], this.props)

            Model.entrenador_horario.Action.editar({

              key_usuario: Model.usuario.Action.getUsuarioLog().key,

              data: {
                ...obj,
                estado: 0
              },


            });

          }
        })
      }} />
    </SView>
  }

  getItem(obj) {

    if (obj.key_entrenador != this.key_entrenador) return null;

    // if (obj.key === "788ef65e-12a6-4fb5-b626-bf9b6dc1010d" ||
    //   obj.key === "1d897377-6ddb-46f8-895b-0ef369da64fe" ||
    //   obj.key === "489fdb5f-65aa-44c0-ac79-f5a084680325" ||
    //   obj.key === "2b9cb90e-ea14-4ea7-9a1e-db103f7203cb") {
    //   return null;
    // }

    return (<>

      <SView
        col={"xs-12 md-6 lg-4 xl-3"}

        height
        height={400}
        style={{ padding: 8 }}
        center
      >
        <SView col={"xs-12"}
          height
          card
          style={{ borderWidth: 1.5, borderRadius: 4, padding: 4, borderColor: STheme.color.card }}
        >
          <SView col={"xs-12"}
            height={60} center border={"transparent"}
            style={{ borderWidth: 1.5, borderRadius: 4 }}
          >
            {/* <SText center fontSize={14} bold>{obj?.descripcion}</SText> */}
          </SView>



          <SHr height={8} />

        </SView>

      </SView>

    </>

    );
  }


  getHorarios() {


    // let dias_semana = [
    //   { key: "", content: "--" },
    //   { key: "1", content: "Lunes" },
    //   { key: "2", content: "Martes" },
    //   { key: "3", content: "Miercoles" },
    //   { key: "4", content: "jueves" },
    //   { key: "5", content: "Viernes" },
    //   { key: "6", content: "Sabado" },
    //   { key: "7", content: "Domingo" },
    // ]


    this.data11 = Model.entrenador_horario.Action.getAll();
    if (!this.data11) return <SLoad />

    console.log("aa ", this.data11)


    return (
      <SList2
        data={this.data11}
        horizontal
        space={0}
        render={(obj) =>
          // this.Item(obj_fondo)
          // aux = indice++;
          this.getItem2(obj)
        }
      />
    );

    //   return <SList2
    //     data={this.data11}
    //     horizontal
    //     space={0}
    //     render={(obj) =>

    //       console.log("aa ", obj)
    //       // if (obj.key_entrenador != this.key_entrenador) return null;
    //       // this.Item(obj_fondo)
    //       // aux = indice++;
    //       // this.Item(obj)
    //       this.getItem(obj)
    // }
    //   />
  }


  render() {
    // this.cargarDatos();
    return (<>

      <SPage hidden >
        <BarraSuperior duration={500} title={"Perfil de cliente"} navigation={this.props.navigation} goBack={() => {
          SNavigation.goBack()
        }} {...this.props} />
        <ScrollView>
          <SView col={"xs-12"} center>


            {this.getPerfil()}





            <SView col={"xs-11 md-8 xl-6"} center>
              {this.getHorarios()}

            </SView>
          </SView>
        </ScrollView>

      </SPage >
      {this.getFB()}


    </>);
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(lista_horarios);