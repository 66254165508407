import { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { SImage, SLoad, SText, STheme } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import * as SImageImput from '../SImageImput';
import Model from '../../Model';
class FotoPerfilUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  validoRol_colorear(key) {
    var dataRoles = Model.usuarioRol.Action.getAllByKeyUsuario(key)
    const rolcolor = [
      { key: "01726154-c439-4d63-99a1-0615d9e15f15", color: "blue", titulo: "Admin" },
      { key: "b5b4a616-dd16-4443-b859-39245f50c8df", color: "green", titulo: "Entrenador" },
      { key: "ececdfbf-c82c-4cf9-8f1b-989bbdee5087", color: "red", titulo: "Vendedor" },
      { key: "d16d800e-5b8d-48ae-8fcb-99392abdf61f", color: "yellow", titulo: "Cliente" },
    ]
    if (!dataRoles) {
      return <SLoad />
    }

    const arrroles = Object.values(dataRoles)
    let select_color = { key: "default", color: "pink" , titulo: "sin rol"};

    for (const rcolor of rolcolor) {
      if (arrroles.find(o => o.key_rol == rcolor.key)) {
        select_color = rcolor
        break;
      }
    }
    return select_color;
    // console.log("seleccionado ", select_color);
    // for (const obj of Object.values(dataRoles)) {
    //   if (obj.key_rol == "d16d800e-5b8d-48ae-8fcb-99392abdf61f") return ["yellow", "Cliente"];
    //   if (obj.key_rol == "b5b4a616-dd16-4443-b859-39245f50c8df") return ["green", "Entrenador"];
    //   if (obj.key_rol == "ececdfbf-c82c-4cf9-8f1b-989bbdee5087") return ["red", "Vendedor"];
    //   if (obj.key_rol == "01726154-c439-4d63-99a1-0615d9e15f15") return ["blue", "Admin"];
    // }
    // return ["pink", "sin rol"];
  }


  render() {
    var usuario = this.props.usuario;

     
    let color = this.validoRol_colorear(usuario.key).color;
    let titulo = this.validoRol_colorear(usuario.key).titulo;
 

    console.log("role ", color, " titulo ", titulo)
    return <>
      <TouchableOpacity style={{
        width: "90%",
        height: "90%",
        backgroundColor: STheme.color.card,
        // borderRadius: 8,
        borderColor: color,
        // borderColor: "red",
        borderWidth: 4,
        borderRadius: "50%",
        overflow: "hidden",
      }} onPress={() => {
        if (this.props.disable) {
          return;
        }
        SImageImput.choseFile({
          servicio: "root",
          service: "usuario",
          component: "usuario",
          type: "subirFoto",
          estado: "cargando",
          key: usuario.key,
          key_usuario: usuario.key,
        }, (resp) => {
          this.props.dispatch({
            component: "image",
            type: "cambio",
            url: SSocket.api.root + "usuario_" + usuario.key,
          })
          // this.state.repaint = new Date().getTime()
          // this.setState({ ...this.state });
        });
      }}>

        {/* {"foto"} */}
        <SImage src={SSocket.api.root + "usuario/" + usuario.key} />

      </TouchableOpacity>
      <SText col={"xs-12"} row bold center fontSize={16} style={{
        // position: "absolute",
        // bottom: -5,
        // justifyContent: "center",
        // alignItems: "center",
        // right: "32%"
        // bottom: 80
        // borderRadius: 8,
        // padding: 5,
        // fontSize: 2,
      }}  >{titulo}</SText>
    </>

  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(FotoPerfilUsuario);
