import { Component } from 'react';
import { ActivityIndicator, Linking, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SDate, SForm, SHr, SIcon, SImage, SInput, SLoad, SMath, SNavigation, SOrdenador, SPage, SPopup, SPopupClose, SPopupOpen, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../../../../Model';
import { SSRolesPermisosValidate } from '../../../../../SSRolesPermisos';
import Sucursal from '../../../../Sucursal';
import sucursal_usuario from '../../../../sucursal_usuario';
import Paquete_Item from './Paquete_Item';

// PaquetesDeUsuarioV2 te permite ver todos los paquete, de otras sucrusales
let coloreando = "transparent";
// let coloreando = "yellow";

let fecha_mes_anterior = new SDate().addMonth(-1).toString("yyyy-MM-dd");

class PaquetesDeUsuarioV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parametros: {
        "inicio": new SDate(),
        "fin": new SDate(),
        "dias": 0,
      },
      ...this.state,
    };


  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
    this.getCaja();
    this.getDataTraspasoSucursal()
  }


  getDataTraspasoSucursal() {
    this.setState({ loading: "cargando", _data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_get_traspaso_sucursales",
      params: ["'" + this.props.state.usuarioReducer.usuarioLog.key + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, _data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getCaja() {
    var obj = {
      component: "caja",
      type: "getActiva",
      estado: "cargando",
      key_usuario: this.props.state.usuarioReducer.usuarioLog.key
    }
    SSocket.send(obj, true);
  }

  getPaquete(key) {
    let reducer = this.props.state.paqueteReducer;
    let data = reducer.data;
    if (!data) {
      if (reducer.estado == "cargando") return false;
      if (reducer.estado == "error") return false;
      var object = {
        component: "paquete",
        type: "getAll",
        estado: "cargando",
        key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
      }
      SSocket.send(object, true);
      return false;
    }
    return data[key];
  }

  getSucursal(key_sucursal) {
    var data = Sucursal.Actions.getAll(this.props);
    if (!data) return <View />
    var obj = data[key_sucursal]
    if (!obj) return <View />
    return <SView>
      <SText fontSize={13} color={STheme.color.text} style={{ textTransform: "uppercase" }} >SUCURSAL: {obj.descripcion}</SText>
    </SView>
  }

  getUsuario_ATC(key_usuario) {
    var data = Model.usuario.Action.getAll();
    let key_vendedor = "186104a6-98e2-49c6-af46-d6539dabfb1a";
    if (!data) return <View />
    var obj = data[key_usuario]
    if (!obj) return <View />
    const textoPago = key_vendedor === obj.key ? "Pago en Aplicación" : "";
    return <SView>
      <SText fontSize={13} color={obj.key == '1dae3da5-ee8a-445c-ad34-d33fefb4f005' || obj.key == '186104a6-98e2-49c6-af46-d6539dabfb1a' ? "pink" : "cyan"}>ATC: {obj.Nombres} {obj.Apellidos}</SText>
    </SView>
  }


  verRecibo(obj) {
    if (!obj) return;
    return <SView border="yellow" center style={{ borderRadius: 2, width: 100, padding: 2 }}
      onPress={() => {
        Linking.openURL(`EsperandoVenta?key=${obj.key}&key_paquete_venta_usuario=${obj.key_paquete_venta_usuario}&t=1`)
      }}
    >
      <SText fontSize={13} color={"yellow"}>VER RECIBO</SText>
    </SView>
  }

  verFactura(codigo_factura) {
    if (!codigo_factura) return;
    // if (!codigo_factura) return;



    return <>
      {/* <SHr></SHr> */}
      <SView col={"col-xs-12"} border="cyan" center
        style={{ borderRadius: 2, width: 100, padding: 2 }}
        onPress={() => {
          SSocket.sendPromise({
            component: "facturacion",
            type: "getFacturaPdfV2",
            codigo_factura: codigo_factura + ""
          }).then(e => {
            const byteCharacters = atob(e.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            console.log(e);
          }).catch(e => {
            console.error(e);
          })
        }}>
        <SText fontSize={13} color={"cyan"}>VER FACTURA</SText>
      </SView>
    </>
  }

  getVerPermisosSucursal(key) {
    var data_permisos = this.state._data;
    if (!data_permisos) return null;
    const permisos = Object.values(data_permisos).filter(obj => obj.key_paquete_venta_usuario === key);
    return permisos.map((obj, index) => (
      <SText key={index} fontSize={13} color={"yellow"}>
        Suc: {obj.descripcion}
      </SText>
    ));
  }

  getUsuarioModificador(obj) {
    /**
     * @param {object} options.permiso  Comprueba si el usuario tiene permisos  anular_venta puede ver esta función.
    */
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "anular_venta" })) return <View />
    if (!obj.key_usuario_modificador) return;
    var data = Model.usuario.Action.getAll();
    if (!data) return <View />
    var data_usuario = data[obj?.key_usuario_modificador]
    if (!data_usuario) return <View />
    return <>
      <SView border={"cyan"} style={{ position: "absolute", top: 0, right: -210, borderRadius: 2, width: 200, padding: 2 }} >
        <SText bold color={"cyan"}>Autorizado</SText>
        <SText fontSize={12} >Usr: {data_usuario?.Nombres + " " + data_usuario?.Apellidos}</SText>
        <SText fontSize={12} color='yellow' >tipo: {obj?.motivo_tipo}</SText>
        <SText fontSize={12} color='yellow' >fecha: {new SDate(obj?.motivo_fecha).toString('dd-MM-yyyy hh:mm')}</SText>
        <SText fontSize={12} >Motivo</SText>
        <SText fontSize={12} >{obj?.motivo_modificador}</SText>
      </SView>
    </>
  }

  calculadorAlvaro(inicio, dias) {
    this.state.parametros.inicio = inicio;
    this.state.parametros.dias = dias;
    this.state.parametros.fin = new SDate(inicio, "yyyy-MM-dd").addDay(parseInt(dias)).toString("yyyy-MM-dd");
    this.setState({ ...this.state })
  }


  handlePermisosSucursal(obj) {

    if (new SDate(obj.fecha_fin) <= new SDate()) {
      return <View />
    }
    return <SButtom style={{ width: 24, height: 24, paddingEnd: 27 }} onPress={() => {
      SNavigation.navigate("/sucursal_paquete_venta_usuario", { key_paquete_venta_usuario: obj.key_paquete_venta_usuario })
    }}>  <SIcon name={"TraspasoSucursal"} style={{ width: 30, height: 30 }} />
    </SButtom>
  }
  handleTraspasoPaqueteUsuario(obj) {

    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "traspaso_paquete" })) return null;

    var reducer = this.props.state.paqueteVentaReducer;
    if (reducer.estado == "error") {
      reducer.estado = "";
      SPopup.alert(reducer.error);
    }

    return <SButtom style={{ width: 24, height: 24, paddingEnd: 20 }}
      onPress={() => {
        SPopup.open({ content: this.displayTraspasoPaqueteUsuario(obj), key: "CodigoSeguridad" });
      }}>
      <SIcon name={"Card"} style={{ width: 30, height: 30 }} />
    </SButtom>
  }
  handlePaqueteModificarFechaInicio(obj) {
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "editar_venta" })) {
      if (!this.caja) {
        return <View />
      }
      if (this.caja.key != obj.key_caja) {
        return <View />
      }
      return <View /> // aqui sigo si no tiene permiso no mostrar nada
    }
    var reducer = this.props.state.paqueteVentaReducer;
    if (reducer.estado == "error") {
      reducer.estado = "";
      SPopup.alert(reducer.error);
    }
    return <SButtom
      style={{ width: 24, height: 24, paddingEnd: 10 }}
      onPress={() => { SPopup.open({ content: this.displayPaqueteModificarFechaInicio(obj), key: "CodigoSeguridad" }); }}>
      <SIcon name={"Edit"} style={{ width: 30, height: 30 }} />
    </SButtom>
  }
  handleAnularPaquete(obj) {
    // Comprobar si el usuario tiene permisos para anular venta en la página PaqueteVentaPage
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "anular_venta" })) {
      if (!this.caja) {// Verificar si no hay una caja abierta
        return <View />
      }
      if (this.caja.key != obj.key_caja) {// Verificar si la fecha_on de la caja actual es diferente a la fecha_on registrada de hoy
        return <View />
      }
      return <View />// No se muestra nada si la no tiene permiso
    }
    var reducer = this.props.state.paqueteVentaReducer;
    if (reducer.estado == "error") {
      reducer.estado = "";
      SPopup.alert(reducer.error);
    }
    return <SButtom style={{ width: 24, height: 24 }} onPress={() => { SPopup.open({ content: this.displayAnularPaquete(obj), key: "CodigoSeguridad" }); }}>
      <SIcon name={"Delete"} style={{ width: 30, height: 30 }} />
    </SButtom>
  }
  handleFacturaPaquete(obj) {
    // el registrar factura solo mostrara cuando el anterior mes
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "anular_venta" })) return null; //valido si tiene permiso
    if (this.state.cargado) return <SLoad />;
    // if (obj.is_factura) return; //aqui valido si tiene codigo

    // obj.is_factura
    // if (obj.codigo_factura && obj.key_usuario_factura) return; //aqui valido si tiene codigo
    // if (obj.codigo_factura && obj.key_usuario_factura_anulada) return; //aqui valido si tiene codigo
    // if (obj.codigo_factura) return; //aqui valido si tiene codigo
    if (obj.monto == 0) return; //aqui valido si es becado

    let fecha_ahora = new SDate(obj.fecha_on, "yyyy-MM-dd").toString("yyyy-MM-dd");
    if (fecha_ahora <= fecha_mes_anterior) return;

    return <>
      <SView col={"col-xs-12"} center >
        <SHr height={4}></SHr>
        <SView center backgroundColor='#06560D' style={{ borderRadius: 4, width: 100, padding: 4 }}
          onPress={() => { SPopup.open({ content: this.displayFacturaPaquete(obj), key: "CodigoSeguridad" }); }}>
          <SText fontSize={13} color={"white"}>REG. FACTURA</SText>
        </SView>
      </SView>
    </>
  }
  cancelFacturaPaquete(obj) {

    // el anular factura solo mostrara cuando sea dentro del mes

    // if (!obj.codigo_factura) return;
    // if (obj.is_factura && !obj.key_usuario_factura_anulada) return; //aqui valido si tiene codigo
    // if (!obj.key_usuario_factura_anulada) return; //aqui valido si tiene codigo
    // if (obj.key_usuario_factura_anulada) return; //aqui valido si tiene codigo

    // if (!obj.is_factura && !obj.key_usuario_factura_anulada) return;
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "anular_venta" })) {
      return <View />
    }

    // solo se podra anular dentro del mes que uno se encuentra
    let fecha_capturada = new SDate(obj.fecha_on, "yyyy-MM-dd").toString("yyyy-MM-dd");
    let fecha_ahora = new SDate().setDay(1).toString("yyyy-MM-dd");
    console.log("vida ", fecha_ahora, " vida capturada ", fecha_capturada)
    if (fecha_capturada <= fecha_ahora) return;
    // alert()
    return <>
      <SView col={"col-xs-12"} center >
        <SHr height={4}></SHr>
        <SView backgroundColor="red" center style={{ borderRadius: 4, width: 100, padding: 4 }}
          onPress={() => {
            SPopup.confirm({
              "title": <>
                <SText col={"col-xs-12"} fontSize={18} color={STheme.color.text}>ANULAR FACTURA #{obj.codigo_factura}</SText>
                <SHr></SHr>
                <SIcon name={"Pruebas"} style={{ width: 64, height: 64 }} />
              </>,
              message: "¿Esta seguro de anular esta factura?",


              onPress: () => {
                SSocket.sendPromise({
                  component: "facturacion",
                  type: "anularFactura",
                  codigo_factura: obj.codigo_factura + "",
                }).then(e => {
                  alert("La factura ha sido anulada con éxito. ¡Operación completada satisfactoriamente!");
                  SSocket.sendPromise({
                    component: "paqueteVenta",
                    type: "anularFactura",
                    codigo_factura: obj.codigo_factura + "",
                    key_usuario_factura_anulada: this.props.state.usuarioReducer.usuarioLog.key + "",
                  }).then(e => {
                    alert("Campos borrados código de factura, NIT y razón social!");
                    console.log(e);
                  }).catch(e => {
                    console.error(e);
                    alert(e.error);
                    SPopup.alert("paqueteVenta - Error anular al factura. ", e.error);
                  })

                }).catch(e => {
                  console.error(e);
                  SPopup.alert("facturacion - Error anular al factura. ", e);
                })
              }
            })
          }}
        >
          <SText fontSize={13} color={STheme.color.text}>ANULAR FAT.</SText>
        </SView>

      </SView>
    </>

  }

  displayTraspasoPaqueteUsuario(obj) {
    var reducer = this.props.state.paqueteVentaReducer;
    if (reducer.estado == "error") {
      reducer.estado = "";
      SPopup.alert(reducer.error);
    }
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}  >
        <SHr height={20} />
        <SText col={"xs-9"} color={"yellow"} center>key venta paquete usuario: {obj.key_paquete_venta_usuario}</SText>
        <SInput ref={ref => this._key_cliente_traspaso = ref} placeholder={"Ingresar la key del cliente"} col={"xs-10"} type={"text"} customStyle={"calistenia"} />
        <SText col={"xs-9"} color={"yellow"} center>¿Está seguro de que desea hacer el traspaso del paquete?</SText>
        <SInput ref={ref => this._motivo_modificador2 = ref} col={"xs-10"} type={"textArea"} height={64} customStyle={"calistenia"} placeholder={"Escribir motivo.."} style={{ borderColor: "orange", borderRadius: 4, }} />
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={60} ></SView>
        <SButtom type="success" onPress={() => {
          this.setState({ loading: "cargando", data: null });

          let usuario = Model.usuario.Action.getByKey(this.props.key_usuario);

          if (!usuario) return;

          SSocket.sendPromise({
            component: "reporte",
            type: "execute_function",
            func: "_actualizar_trasferencia_paquete",
            params: ["'" + obj.key_paquete_venta_usuario + "','" + this._key_cliente_traspaso.getValue() + "'"],
          })
            .then((resp) => {
              this.setState({ loading: false, data: resp.data });
              console.log("todo un exito ", resp);

              // SNavigation.navigate("ClientePerfilPage", {
              //   key: this._key_cliente_traspaso.getValue()
              // })

            })
            .catch((e) => {
              console.error("error ", e);
              this.setState({ loading: false, error: e });
            });


          obj.motivo_tipo = "Traspaso paquete";
          obj.motivo_fecha = new SDate().toString('yyyy-MM-dd hh:mm:ss');
          // obj.motivo_modificador = "Paquete Origen usuario CI: " + usuario?.CI + " " + usuario?.Nombres + " " + usuario?.Apellidos;
          obj.motivo_modificador = this._motivo_modificador2.getValue();

          var objSen = {
            component: "paqueteVenta",
            type: "editar",
            estado: "cargando",
            key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
            data: obj
          }
          SSocket.send(objSen, true);


          SPopup.close("CodigoSeguridad");
          // SNavigation.navigate("ClientePerfilPage", { key: this._key_cliente_traspaso.getValue() })
          SNavigation.navigate("ClientePerfilPage", {
            key: this._key_cliente_traspaso.getValue()
          })

          // return;
          // /ClientePerfilPage?key=b1f96079-e783-43d7-bb89-02595441b9a9
        }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }
  displayPaqueteModificarFechaInicio(obj) {
    let usuario = Model.usuario.Action.getByKey(obj.key_usuario);
    var mes_inicio = new SDate(obj.fecha_inicio);
    var mes_fin = new SDate(obj.fecha_fin);
    var cantodad_dias = new SDate(obj.fecha_inicio).diff(new SDate(obj.fecha_fin));
    this.calculadorAlvaro(obj.fecha_inicio, cantodad_dias);
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}  >
        <SHr height={20} />
        <SInput ref={ref => this._fechaInicio = ref} col={"xs-10"} type={"date"} defaultValue={this.state.parametros.inicio} customStyle={"calistenia"}
          onChangeText={(val) => {
            if (this.state.parametros.inicio != val) {
              this.state.parametros.inicio = val;
              this.setState({ ...this.state })
              this.calculadorAlvaro(val, this.state.parametros.dias);
            }
          }}
        />

        <SInput ref={ref => this._diasObtenidos = ref} placeholder={"Ingresar cantidad dias"} col={"xs-3"} defaultValue={cantodad_dias} customStyle={"calistenia"}
          onChangeText={(val) => {
            if (val == false) return;
            if (this.state.parametros.dias != val) {
              this.state.parametros.dias = val;
              this.setState({ ...this.state })
              this.calculadorAlvaro(this.state.parametros.inicio, val);
            }
          }}
        />

        <SInput placeholder={"Ingresar fecha fin"} col={"xs-6"} type={"date"} defaultValue={this.state.parametros.fin} customStyle={"calistenia"} disabled />
        <SText col={"xs-9"} color={STheme.color.text + 66} center>¿Está seguro de que desea cambiar la fecha del paquete?{this.state.parametros.inicio}</SText>

        <SInput ref={ref => this._motivo_modificador = ref} col={"xs-10"} type={"textArea"} height={64} customStyle={"calistenia"} placeholder={"Escribir motivo.."} style={{ borderColor: "orange", borderRadius: 4, }} />

        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelaraaaa</SButtom>
        <SView width={30} ></SView>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={() => {
          var aux = this._diasObtenidos.getValue();
          var fecha_inicio_modificada = this._fechaInicio.getValue();
          var fecha_fin_modificada = new SDate(fecha_inicio_modificada, "yyyy-MM-dd").addDay(parseInt(aux)).toString("yyyy-MM-dd");
          let reducer = this.props.state.paqueteVentaReducer;
          let data = reducer.data;
          if (!data) {
            if (reducer.estado == "cargando") return false;
            if (reducer.estado == "error") return false;

            var objSen = {
              component: "paqueteVentaUsuario",
              type: "editar",
              estado: "cargando",
              key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
              data: {
                key: obj.key_paquete_venta_usuario,
                fecha_inicio: fecha_inicio_modificada,
                fecha_fin: fecha_fin_modificada,
                key_usuario_modificador: this.props.state.usuarioReducer.usuarioLog.key,
              }
            }


            SSocket.sendPromise(objSen).then((resp) => {
              if (resp.estado == "exito") {
                obj.motivo_modificador = this._motivo_modificador.getValue();
                obj.motivo_tipo = "Fecha modificada";
                obj.motivo_fecha = new SDate().toString('yyyy-MM-dd hh:mm:ss');
                var objSen = {
                  component: "paqueteVenta",
                  type: "editar",
                  estado: "cargando",
                  key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
                  data: obj
                }
                SSocket.send(objSen, true);
                this.setState({ ...this.state })
                SPopup.close("CodigoSeguridad");
                return;
              }
            })
          }
        }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }
  displayAnularPaquete(obj) {
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}  >
        <SHr height={20} />
        <SText col={"xs-9"} color={"yellow"} center>Especificar el motivo de anulación?</SText>
        <SInput ref={ref => this._motivo_anulacion = ref} col={"xs-10"} type={"text"} customStyle={"calistenia"} />
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={30} ></SView>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={() => {


          let motivo = this._motivo_anulacion.getValue() ?? "";
          obj.estado = 0;
          obj.motivo_anulacion = motivo;

          // metodo anula venta
          var objSen = {
            component: "paqueteVenta",
            type: "eliminar",
            estado: "cargando",
            key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
            data: obj
          }
          SSocket.send(objSen, true);
          // console.log("alvarito ", objSen)
          // metodo anula factura
          SSocket.sendPromise({
            component: "facturacion",
            type: "anularFactura",
            codigo_factura: obj?.codigo_factura + "",
          }).then(e => {
            alert("La factura ha sido anulada con éxito. ¡Operación completada satisfactoriamente!");
          }).catch(e => {
            console.error(e);
          })

          SPopup.close("CodigoSeguridad");
          return;
        }}>Confirmar</SButtom>
        {/* <SHr height={20} /> */}
      </SView >
    </>
  }
  displayFacturaPaquete(obj) {
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "anular_venta" })) return null; //valido si tiene permiso
    if (this.state.cargado) return <SLoad />;
    // if (obj.codigo_factura) return; //aqui valido si tiene codigo
    // if (obj.monto == 0) return; //aqui valido si es becado

    return <>
      <SView width={350} height={280} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}  >
        <SHr height={20} />
        <SText fontSize={18} >DATOS PARA LA FACTURA</SText>
        <SForm
          ref={ref => this.form_factura = ref}
          props={{ col: "xs-11", dir: "row" }}
          // inputProps={{ col: "xs-12", separation: 4 }}
          style={{ justifyContent: "space-between" }}
          inputs={{
            "Nit": { type: 'number', label: "NIT", defaultValue: this.props?.usuario?.Nit ?? "0", isRequired: true, style: { borderColor: "cyan", borderRadius: 8, } },
            "RazonSocial": { focus: true, label: "RAZÓN SOCIAL", defaultValue: this.props?.usuario?.RazonSocial ?? "SN", isRequired: true, style: { borderColor: "cyan", borderRadius: 8, } },
          }}
        ></SForm>
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={() => {
          // console.clear()
          let dataFactura = this.form_factura?.getValues()
          // el problema fue que no puedo usar el sus atributos si esque no lo defino eantes
          // porque, la utilizar AnimatedLines, me saldra indefinido
          // console.log(dataFactura.Nit)
          SPopup.close("CodigoSeguridad");
          this.setState({ cargado: true })
          SSocket.sendPromise({
            component: "facturacion",
            type: "facturarPaquete",
            force: true,
            key_paquete_venta: obj?.key,
            razon_social: dataFactura.RazonSocial,
            nit: dataFactura.Nit,
            key_usuario_factura: this.props.state.usuarioReducer.usuarioLog.key,
          }, 1000 * 60).then(e => {
            this.props.state.paqueteVentaReducer.usuario = {}
            this.setState({ cargado: false })
            alert("La venta ha sido facturada con éxito")
            console.log(e);
          }).catch(e => {
            this.setState({ cargado: false })
            alert(e?.error)
            console.log("Error: Al vovler a facturar")
          })
        }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }

  getLista() {
    var reducer = this.props.state.paqueteVentaReducer;
    var data = reducer.usuario[this.props.key_usuario];


    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );

    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);


    if (!data) {
      if (reducer.estado == "cargando") return <ActivityIndicator color={STheme.color.text} />
      if (reducer.estado == "error") return <Text>ERROR</Text>
      var object = {
        component: "paqueteVenta",
        // aqui tengo que traer la fecha de la factura
        type: "getAllByUsuario",
        estado: "cargando",
        key_usuario: this.props.key_usuario
      }
      SSocket.send(object, true);
      return <View />
    }
    let contador = 0;
    return new SOrdenador([
      { key: "fecha_inicio", order: "desc", peso: 1 },
    ]).ordernarObject(
      data
    ).map((key) => {
      contador++;
      var obj = data[key];


      // Verificar permisos para la sucursal correspondiente
      if (!arr_sucursal_key.includes(obj.key_sucursal)) return null;
      var paquete = this.getPaquete(obj.key_paquete);
      var urlImagePaquete = SSocket.api.root + "paquete_" + obj.key_paquete + `?date=${new Date().getTime()}`;
      var fecha_registrado = new SDate(obj.fecha_on, "yyyy-MM-dd hh:mm");

      var fecha_facturado = '';
      if (obj.fecha_factura) {
        fecha_facturado = new SDate(obj.fecha_factura, "yyyy-MM-dd hh:mm");

      } else {
        fecha_facturado = "";
      }



      if (!paquete) {
        return <SLoad />
      }

      console.log("tamaño ", obj)


      var bd_usuario = Model.usuario.Action.getAll();

      if (!bd_usuario) {
        return <SLoad />
      }

      var obj_usuario = bd_usuario[obj.key_usuario_factura]
      var aux = "";
      if (obj_usuario) {
        // aux = obj_usuario.Nombres;
        aux = obj_usuario.Nombres + " " + obj_usuario.Apellidos;
      } else {
        aux = "";
      }

      console.log("miradas ", obj)

      return <SView style={{
        width: "100%",
        backgroundColor: STheme.color.card,
        marginBottom: 8,
        borderRadius: 8,
        alignItems: "center",
        justifyContent: "center",
        borderColor: (contador <= this.state?.veces ? "cyan" : "transparent"),
        borderWidth: 1.5,
      }}
      >

        {this.getUsuarioModificador(obj)}
        <SView style={{ flex: 1, width: "100%", flexDirection: "row", padding: 4 }}>


          <SView col={"xs-3"} border={coloreando}>
            {this.getSucursal(obj.key_sucursal)}




            <SHr height={10}></SHr>


            <SView col={"xs-12"} height={40} border={coloreando} row>


              <SView border={STheme.color.card} center style={{ width: 45, height: 45, justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                <SImage enablePreview src={SSocket.api.root + "paquete/" + obj.key_paquete} style={{ resizeMode: 'cover' }} />
              </SView>

              <SView flex border={coloreando} style={{ paddingLeft: 8 }}>
                <Text style={{ fontSize: 13, color: STheme.color.text }}>{paquete.descripcion}</Text>
                <Text style={{ fontSize: 13, color: STheme.color.text }}>Persona {paquete.participantes}</Text>
                <Text style={{ fontSize: 13, color: STheme.color.text }}>Dias {paquete.dias}</Text>
              </SView>
            </SView>
            <SHr height={12}></SHr>

            {obj.observacion ? <SView border={coloreando} col={"xs-11.5"}>
              <Text style={{ fontSize: 13, color: "cyan" }}>Motivo</Text>
              <Text col={"xs-9"} style={{ fontSize: 13, color: STheme.color.text }}>{obj.observacion}</Text>
            </SView> : <SView height={15}></SView>}
          </SView>

          <SView col={"xs-3"} border={coloreando}>
            <SText fontSize={13} color={STheme.color.text}>VENTA REGISTRADA</SText>
            {this.getUsuario_ATC(obj.key_usuario)}
            <SHr height={4}></SHr>

            {obj.monto ? <SView row><SIcon name='SBilletera' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>Bs. {SMath.formatMoney(obj.monto)}</SText></SView> : <SView height={15}></SView>}
            {fecha_registrado ? <SView row> <SIcon name='SCalendario' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>{fecha_registrado.toString("dd/MM/yyyy")}</SText></SView> : <SView height={15}></SView>}
            {fecha_registrado ? <SView row> <SIcon name='SReloj' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>Hrs {fecha_registrado.toString("hh:mm")}</SText></SView> : <SView height={15}></SView>}
            <SHr height={10}></SHr>
            {this.verRecibo(obj)}
          </SView>

          {(obj.is_factura) ?
            <SView col={"xs-2.5"} flex border={coloreando}>
              {obj.codigo_factura ? <SText fontSize={13} color={STheme.color.text}>FACTURA REGISTRADA</SText> : <SView height={15}></SView>}
              {aux ? <SView><SText fontSize={13} color={"cyan"}>ADM: {aux}</SText> </SView> : <SView height={15}></SView>}
              <SHr height={4}></SHr>
              {obj.codigo_factura ? <SView row> <SIcon name='SFactura' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>Código: {obj.codigo_factura}</SText></SView> : <SView height={15}></SView>}
              {fecha_facturado ? <SView row><SIcon name='SCalendario' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>{fecha_facturado.toString("dd/MM/yyyy")}</SText></SView> : <SView height={15}></SView>}
              {fecha_facturado ? <SView row> <SIcon name='SReloj' width={13} style={{ paddingRight: 4 }} /><SText fontSize={13} color={STheme.color.text}>Hrs {fecha_facturado.toString("hh:mm")}</SText></SView> : <SView height={15}></SView>}
              <SHr height={10}></SHr>

              {this.verFactura(obj.codigo_factura)}
            </SView>
            : < SView col={"xs-2.5"} flex border={coloreando} />}


          <SView col={"xs-2"} border={coloreando}>
            <SView col={"xs-12"} row center  >
              {this.handlePermisosSucursal(obj)}
              {this.handleTraspasoPaqueteUsuario(obj)}
              {this.handlePaqueteModificarFechaInicio(obj)}
              {this.handleAnularPaquete(obj)}
              <SHr height={8} />
              {(!obj.codigo_factura && !obj.key_usuario_factura_anulada) ? this.handleFacturaPaquete(obj) : this.cancelFacturaPaquete(obj)}
            </SView>
          </SView>
        </SView >
        <Paquete_Item data={obj} paquete={paquete} />
      </SView >



    })
  }

  getBotonNuevoPaquete = () => {
    return <TouchableOpacity style={{
      width: "96%",
      backgroundColor: STheme.color.card,
      borderColor: "yellow",
      borderWidth: 2,
      height: 50,
      marginBottom: 8,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      padding: 4,
    }} onPress={() => {
      if (!this.caja) {
        SPopupOpen({
          key: "errorPaquete",
          content: (
            <SView col={"xs-11 md-8"} backgroundColor={STheme.color.background} center style={{ height: 200, borderRadius: 8, }}>
              {SPage.backgroundComponent}

              <SText>no HAY Paquete</SText>
              <SView style={{
                width: "100%",
                height: "100%",
              }} center>
                <SText style={{ fontSize: 16, }}>NO TIENE CAJA ABIERTA.</SText>

                <SText style={{ fontSize: 16, }}>Nota</SText>
                <SText style={{ fontSize: 16, }}>Es necesario abrir caja para registrar la sucursal y asignarla a la caja correspondiente.</SText>
                {/* <SText style={{ fontSize: 12, }}>Dirijase a caja y abra una caja para continuar.</SText> */}
                <SButtom props={{ type: "outline" }} onPress={() => {
                  this.props.navigation.navigate("CajaPage")
                  SPopupClose("errorPaquete");
                }}>Ir a caja</SButtom>
              </SView>
            </SView>
          )
        })
        return;
      }

      this.props.navigation.navigate("PaquetePage", {
        type: "select",
        onSelect: (obj) => {
          SNavigation.goBack();
          this.props.navigation.navigate("ClientePaqueteRegistroPage", {
            key_usuario: this.props.key_usuario,
            key_paquete: obj.key,
          });
        }
      });
    }}>
      <Text style={{
        color: "yellow",
      }}>Nuevo paquete</Text>
    </TouchableOpacity>
  }
  getBtnAdd2 = () => {
    return <TouchableOpacity style={{
      width: "96%",
      borderColor: "yellow",
      borderWidth: 2,
      height: 50,
      marginBottom: 8,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      padding: 4,
    }} onPress={() => {
      if (!this.caja) {
        SPopupOpen({
          key: "errorPaquete",
          content: (
            <SView col={"xs-11 md-8"} backgroundColor={STheme.color.background} center style={{ height: 200, borderRadius: 8, }}>
              {SPage.backgroundComponent}
              <SView style={{
                width: "100%",
                height: "100%",
              }} center>
                <SText style={{ fontSize: 16, }}>No tiene una caja abierta.</SText>
                <SText style={{ fontSize: 12, }}>Dirijase a caja y abra una caja para continuar.</SText>
                <SButtom props={{ type: "outline" }} onPress={() => {
                  this.props.navigation.navigate("CajaPage")
                  SPopupClose("errorPaquete");
                }}>Ir a caja</SButtom>
              </SView>
            </SView>
          )
        })
        return;
      }

      this.props.navigation.navigate("PaquetePage", {
        type: "select",
        onSelect: (obj) => {
          SNavigation.goBack();
          this.props.navigation.navigate("ClientePaqueteRegistroPage2", {
            key_usuario: this.props.key_usuario,
            key_paquete: obj.key,
          });
        }
      });
    }}>
      <Text style={{
        color: "yellow",
        color: STheme.color.text,
        textDecorationLine: "underline",
      }}>Nuevo paquete v2</Text>
    </TouchableOpacity>
  }



  render() {
    // VERIFICO SI CAJA ESTA APERTURADA PARA PROCEDER A HACER CAMBIOS
    this.caja = this.props.state.cajaReducer.usuario[this.props.state.usuarioReducer.usuarioLog.key];
    return (
      <View style={{ width: "100%", alignItems: "center", marginTop: 16 }}>
        {this.getBotonNuevoPaquete()}
        {this.getLista()}
      </View>
    );
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(PaquetesDeUsuarioV2);