import { Component } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SDate, SHr, SImage, SLoad, SNavigation, SOrdenador, SPage, SScrollView2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Usuario from '../..';
import Buscador from '../../../../Components/Buscador';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
import Sucursal from '../../../Sucursal';
import sucursal_usuario from '../../../sucursal_usuario';
import Paquete_Item from './Paquete_Item';
import SucursalSelect from './SucursalSelect';
let tamaño_texto = 10;

class ClientesPage extends Component {

  constructor(props) {
    super(props);
    var fecha_inicio = new SDate();

    this.state = {
      key_sucursal: SNavigation.getParam("key_sucursal", ""),
      soloBecados: SNavigation.getParam("becados", false),
      soloNoBecados: SNavigation.getParam("nobecados", false),
      pagination: {
        curPage: 1,
      },
      fecha: {
        fecha_inicio: fecha_inicio.toString("yyyy-MM-dd"),
        fecha_fin: new SDate().toString("yyyy-MM-dd"),
      }
    };


  }
  componentDidMount() {
    // var object = {
    //   service: "usuario",
    //   component: "usuario",
    //   version: "2.0",
    //   type: "getAll",
    //   estado: "cargando",
    //   cabecera: "registro_administrador",
    //   key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
    // }
    // SSocket.send(object);

  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 20
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  }
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />
    }
    if (data.estado != 0) {
      return <View />
    }
    return <SButtom
      props={{
        type: "danger",
        variant: "confirm"
      }}
      onPress={() => {
        Usuario.Actions.editar({
          ...data,
          estado: 1,
        }, this.props)
      }} >Recuperar</SButtom>
  }
  getSucursal(key_sucursal) {
    var data = Sucursal.Actions.getByKey(key_sucursal, this.props);
    if (!data) return <View />
    return <SText fontSize={tamaño_texto}>Sucursal: {data.descripcion}</SText>
  }
  getUsuario(key_usuario) {
    var data = Model.usuario.Action.getByKey(key_usuario);
    if (!data) return <View />
    return <SText fontSize={tamaño_texto}>ATC: {data.Nombres}</SText>
  }




  getMotivo(motivo) {
    if (!motivo) return <SHr />;
    return <SText fontSize={tamaño_texto} col={"xs-12"} color={"yellow"}>Motivo: {motivo}</SText>
  }

  exportar_excel() {
    if (!SSRolesPermisosValidate({ page: "ClientesPage", permiso: "exportar_excel" })) {
      return;
    }
    return <ExportExcel
      header={[
        { key: "indice", label: "Indice", width: 30 },
        { key: "key_usuario", label: "key_usuario", width: 130 },
        { key: "paquete", label: "Paquete", width: 130 },
        { key: "paquete_precio", label: "Precio", width: 30 },
        { key: "motivo", label: "Motivo", width: 150 },
        { key: "fecha_inicio", label: "Fecha inicio", width: 70 },
        { key: "fecha_fin", label: "Fecha fin", width: 70 },

        { key: "paquete_dias", label: "Dias", width: 70 },


        { key: "ci", label: "CI", width: 70 },
        { key: "nombres", label: "Cliente", width: 200 },
        { key: "telefono", label: "Telefono", width: 90 },
        { key: "correo", label: "Correo", width: 200 },
        { key: "cumpleaños", label: "F.Nacimiento", width: 70 },
        { key: "nit", label: "Nit", width: 100 },
        { key: "razon_social", label: "Razon social", width: 180 },
        { key: "direccion", label: "Dirección", width: 70 },
        { key: "zona", label: "Zona", width: 70 },
        { key: "estadocivil", label: "Estado civil", width: 70 },
        { key: "sexo", label: "Género", width: 70 },
        { key: "empresa", label: "Empresa", width: 70 },
        { key: "cargo", label: "Cargo", width: 70 },
        { key: "referencia", label: "Ref. Empresa", width: 70 },
        { key: "festivo", label: "Festivo", width: 70 },
        { key: "sucursal", label: "Sucursal", width: 70 },
      ]}
      getDataProcesada={() => {
        var daFinal = {};

        Object.values(this.finalData).map((obj, i) => {
          // var usr = this.usuarios[obj.key];
          // this.getSucursal(vijencia["caja"].key_sucursal)
          // { this.getUsuario(vijencia["caja"].key_usuario) }

          if (!obj?.estado || obj?.estado <= 0) return;
          var toInsert = {
            indice: i + 1,
            paquete: obj?.vijencia?.paquete?.descripcion,
            paquete_precio: obj?.vijencia?.paquete?.precio,

            // var cantodad_dias = new SDate(obj.fecha_inicio).diff(new SDate(obj.fecha_fin));

            paquete_dias: new SDate(obj.fecha_inicio).diff(new SDate(obj.fecha_fin)),

            motivo: obj?.vijencia?.motivo,
            fecha_inicio: obj?.vijencia?.fecha_inicio,
            fecha_fin: obj?.vijencia?.fecha_fin,
            key_usuario: obj.keyusuario,
            ci: obj?.CI,
            nombres: obj?.Nombres + " " + obj?.Apellidos,
            telefono: obj?.Telefono,
            correo: obj?.Correo,
            cumpleaños: obj["Fecha nacimiento"],
            direccion: obj?.Direccion,
            zona: obj?.Zona,
            estadocivil: obj["Estado civil"],
            sexo: obj?.Sexo,
            empresa: obj?.Empresa,
            cargo: obj?.Cargo,
            referencia: obj?.Referencia,
            sucursal: obj.descripcion_sucursal,
            nit: obj.Nit,
            razon_social: obj.RazonSocial,
            festivo: obj?.Festivo,

            // fecha_inicio: obj?.vijencia?.["caja"].key_sucursal
            // ["caja"].key_sucursal,
            //  --{ this.getSucursal(vijencia["caja"].key_sucursal) }
          }
          daFinal[i] = toInsert
        })
        return daFinal
      }}
    />
  }

  valido_CI(nit) {
    return <>
      <SText fontSize={tamaño_texto} color={(nit ?? "").length < 7 ? STheme.color.text : "cyan"} >{"CI: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }
  valido_NombreCompleto(nombre, apellidos) {
    return <SText fontSize={tamaño_texto + 2} style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{(nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_Cumpleaños(Cumpleaños) {
    return <>
      <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"F.Nac: " + (Cumpleaños ?? "")}</SText>
      {(Cumpleaños ?? "").length < 9 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Telefono(telefono) {
    return <>
      <SText fontSize={tamaño_texto} color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}   </SText>
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText fontSize={tamaño_texto} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Correo(correo) {
    return <SText fontSize={tamaño_texto} style={{ color: (correo ?? "").length < 12 ? "red" : "cyan" }}>{"Correo: " + (correo ?? "")}</SText >;
  }



  valido_RazonSocial(nombre) {
    return <>
      <SText fontSize={tamaño_texto} color={(nombre ?? "").length < 5 ? STheme.color.text : "cyan"} >{"Raz.Soc: " + (nombre ?? "")}   </SText>
      {(nombre ?? "").length < 5 ? <SText fontSize={tamaño_texto} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Nit(nit) {
    return <>
      <SText fontSize={tamaño_texto} color={(nit ?? "").length < 7 ? STheme.color.text : "cyan"} >{"Nit: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>

    // <SView col={"xs-12"} row >
    // </SView>
  }


  render() {

    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      // var data = Usuario.Actions.getAll(this.props);
      var ClientesActivos = Usuario.Actions.getAllClientesActivos(this.props);
      var data_sucursal = Sucursal.Actions.getAll(this.props);
      if (!data) return <SLoad />
      if (!data_sucursal) return <View />
      if (!ClientesActivos) return <SLoad />
      if (!this.state.buscador) {
        return <View />
      }
      this.usuarios = data;

      var objFinal = {};
      Object.keys(ClientesActivos).map((key) => {
        if (this.state.key_sucursal) {
          if (this.state.key_sucursal != ClientesActivos[key]["caja"].key_sucursal) {
            return null
          }
        }
        if (ClientesActivos[key]["paquete"].precio == 0 && this.state.soloNoBecados) {
          return;
        }
        if (ClientesActivos[key]["paquete"].precio > 0 && this.state.soloBecados) {
          return;
        }

        var ca = ClientesActivos[key];
        var now = new SDate();
        if (!new SDate(ca.fecha_fin, "yyyy-MM-dd").isCurDate(now)) {
          if (!(new SDate(ca.fecha_inicio, "yyyy-MM-dd").isBefore(now) && new SDate(ca.fecha_fin, "yyyy-MM-dd").isAfter(now))) {
            return;
          }
        }


        if (!sucursal_usuario.Actions.isActive(ClientesActivos[key]["caja"].key_sucursal, this.props)) return null;
        objFinal[key] = {
          ...data[ClientesActivos[key]?.key_usuario],
          keyusuario: ClientesActivos[key]?.key_usuario,
          vijencia: ClientesActivos[key],
          fecha_inicio: ClientesActivos[key].fecha_on,
          fecha_fin: ClientesActivos[key].fecha_fin,
          descripcion_sucursal: data_sucursal[ClientesActivos[key]["caja"].key_sucursal].descripcion,
          key: key,
        };
      });

      // var isRecuperar = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "recuperar_eliminado" });

      this.finalData = objFinal;
      return this.pagination(
        new SOrdenador([
          { key: "Peso", order: "desc", peso: 4 },
          // { key: "fecha_fin", order: "asc", peso: 3 },
        ]).ordernarObject(
          this.state.buscador.buscar(objFinal)
        )
      ).map((key) => {
        var obj = data[ClientesActivos[key]?.key_usuario];
        if (!obj) return null;
        var vijencia = objFinal[key]["vijencia"];
        // if (!vijencia) return null;

        // console.log("chaval ", vijencia?.paquete?.observacion)
        return <TouchableOpacity style={{
          // width: "90%",
          maxWidth: 650,
          width: "90%",

          padding: 4,
          height: 100,
          margin: 4,
          borderRadius: 10,
          backgroundColor: STheme.color.card,
        }} onPress={() => {
          SNavigation.navigate("ClientePerfilPage", {
            key: obj.key
          })
        }}>
          <View style={{
            flex: 1,
            justifyContent: "center", backgroundColor: "transparent"
          }}>
            <View style={{
              flexDirection: "row",
              height: "100%",
              width: "100%",
              alignItems: "center"
            }}>

              <SView row col="xs-12" border={"transparent"}>
                <View style={{ width: 40, height: 40, marginRight: 8, justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 100, overflow: "hidden" }}>
                  <SImage src={SSocket.api.root + "usuario/" + obj.key} />
                </View>

                <SView style={{ flex: 1, justifyContent: "center" }}>
                  <SView col={"xs-12"} row height border={"transparent"} >
                    <SView col={"xs-5"} border={"transparent"} >
                      <SView col={"xs-12"} border={"transparent"} row> {this.valido_CI(obj?.CI)}</SView>
                      <SView col={"xs-12"} border={"transparent"} row> {this.valido_NombreCompleto(obj?.Nombres, obj?.Apellidos)} </SView>
                      <SView col={"xs-12"} border={"transparent"} row >{this.valido_Telefono(obj?.Telefono)} {this.valido_Cumpleaños(obj?.["Fecha nacimiento"])} </SView>
                      <SView col={"xs-12"} border={"transparent"} row > {this.valido_Correo(obj?.Correo)}</SView>
                    </SView>
                    <SView col={"xs-4"} border={"transparent"} >
                      <SText fontSize={tamaño_texto} color={STheme.color.text}>DATOS FACTURACIÓNaa</SText>
                      <SText fontSize={tamaño_texto} color={STheme.color.text}>{this.valido_Nit(obj?.Nit)}</SText>
                      <SText fontSize={tamaño_texto} color={STheme.color.text}>{this.valido_RazonSocial(obj?.RazonSocial)}</SText>
                    </SView>
                    <SView col={"xs-3"} border={"transparent"} >
                      {this.getSucursal(vijencia["caja"].key_sucursal)}
                      {this.getUsuario(vijencia["caja"].key_usuario)}
                      <SText fontSize={tamaño_texto} >{vijencia?.paquete.descripcion}   Bs {vijencia.paquete.monto ?? 0}</SText>
                      <SText fontSize={tamaño_texto} color={"yellow"}>Fecha {new SDate(vijencia.fecha_on, "yyyy-MM-dd").toString("dd/MM/yyyy")}</SText>
                      {this.getMotivo(vijencia?.motivo)}
                    </SView>
                  </SView>
                </SView>
                {/* <SView style={{ flex: 1, justifyContent: "center" }}> */}


                {/* <SText col="xs-12" style={{
                    fontSize: 13, fontWeight: "bold", color: STheme.color.text, textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  }}>{obj["Nombres"] + " " + obj["Apellidos"]}</SText>
                  <Text style={{
                    fontSize: 13, color: STheme.color.text, textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  }}>Ci: {obj["CI"]}</Text>
                  <Text style={{
                    fontSize: 13, color: STheme.color.text, textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  }}>Telf:{obj["Telefono"]}</Text>
                  <Text style={{
                    fontSize: 13, color: STheme.color.text, textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  }}>nit:{obj["Nit"]}</Text>
                  <Text style={{
                    fontSize: 13, color: STheme.color.text, textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  }}>razon:{obj["RazonSocial"]}</Text> */}


                {/* {this.getSucursal(vijencia["caja"].key_sucursal)}
                {this.getUsuario(vijencia["caja"].key_usuario)} */}

                {/* {this.getMotivo(vijencia?.motivo)} */}
                {/* <SHr></SHr> */}

                {/* <Text style={{ fontSize: 10, color: STheme.color.text, }}>{vijencia.paquete.nombre}</Text> */}
                {/* </SView> */}
              </SView>



              {/* <SView col="xs-3" border={"transparent"}>
                {this.getUsuario(vijencia["caja"].key_usuario)}
                {this.getSucursal(vijencia["caja"].key_sucursal)}
                {this.getMotivo(vijencia?.motivo)}
              </SView> */}

              {/* <SView col="xs-4" border={"transparent"}>


                <Text col="xs-12" style={{ fontSize: 13, color: STheme.color.text }}>Pack: {vijencia.paquete.descripcion}</Text>
                <Text col="xs-12" style={{ fontSize: 13, color: STheme.color.text, textTransform: "lowercase" }}>Precio: Bs {vijencia.paquete.monto ?? 0}</Text>
                <SText col="xs-12" fontSize={13} color={"yellow"}>Fecha {new SDate(vijencia.fecha_on, "yyyy-MM-dd").toString("dd/MM/yyyy")}</SText>


              </SView> */}

            </View>

          </View>
          <Paquete_Item data={vijencia} paquete={vijencia.paquete} />
        </TouchableOpacity>
      })
    }
    // tarea1
    // todo aqui tengo que mejorar Alvaro
    // la idea esque exporte todos los clientes
    return (
      <SPage disableScroll title={"Clientes activos"}>
        <Buscador placeholder={"Buscar por CI, Nombres, Apellidos, Correo, Telefono o Motivo."} ref={(ref) => {
          if (!this.state.buscador) this.setState({ buscador: ref });
        }} repaint={() => { this.setState({ ...this.state }) }}
        />


        {/* <FechasBetween onChange={(fecha_inicio, fecha_fin) => {
          this.state.fecha.fecha_inicio = fecha_inicio
          this.state.fecha.fecha_fin = fecha_fin
          this.setState({ ...this.state })
        }} /> */}



        <SView col={"xs-12"} center border={"transparent"}>


          {this.exportar_excel()}

        </SView>

        <View style={{
          flex: 1,
          width: "100%",
        }}>
          <SScrollView2
            disableHorizontal
            onScroll={(evt) => {
              var evn = evt.nativeEvent;
              var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
              var heigth = evn.contentSize.height;
              if (heigth - posy <= 0) {
                this.state.pagination.curPage += 1;
                this.setState({ ...this.state })
              }
            }}
          >
            <SView col={"xs-12"} center>
              <SucursalSelect key_sucursal={this.state.key_sucursal}
                sucursal={this.state.sucursal} setSucursal={(suc) => {
                  // SStorage.setItem("sucursal", suc.key)
                  this.setState({ sucursal: suc, key_sucursal: suc.key });
                }} />
              {getLista()}
            </SView>
          </SScrollView2>
          {/* <FloatButtom esconder={!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "crear" })} onPress={() => {
            SNavigation.navigate("registro")
          }} /> */}
        </View>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ClientesPage);