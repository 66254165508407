import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SIcon, SInput, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../../../Model';

class registroPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        "key_prestamista": {
          label: "Nombre",
          value: null,
          type: "text",
          isRequired: true,
          placeholder: "Nombre"
        },
        "monto_prestado": {
          label: "Monto prestado",
          value: null,
          type: "money",
          isRequired: true,
          placeholder: "Monto prestado"
        },
        "fecha_ultimo_pago": {
          label: "fecha ultimo pago",
          value: null,
          type: "date",
          isRequired: true,
          placeholder: "fecha_ultimo_pago"
        },
        "porcentaje": {
          label: "Porcentaje interes",
          value: null,
          type: "money",
          isRequired: true,
          placeholder: "Ingresar porcentaje interes",
        },
        "periocidad": {
          label: "Porcentaje interes",
          value: null,
          type: "number",
          isRequired: true,
          col: "xs-11.8",
          placeholder: "Ingresar porcentaje interes",
        },
        "interes_porperiocidad": {
          label: "Interes por periocidad",
          value: null,
          type: "number",
          isRequired: true,
          placeholder: "Ingresar porcentaje interes",
        },
        "interes_pordia": {
          label: "Interes por dia",
          value: null,
          type: "number",
          isRequired: true,
          placeholder: "Ingresar porcentaje interes",
        },
        "fecha_porpagar": {
          label: "Fecha por pagar",
          value: null,
          type: "date",
          isRequired: true,
          placeholder: "Fecha por pagar",
          iconR: <SIcon name={"Evento"} width={20} fill={STheme.color.card} stroke={STheme.color.secondary} style={{ paddingRight: 10 }
          } />,

        },
        "dias_pasado": {
          label: "dias pasado desde ultimo pago",
          value: null,
          type: "number",
          isRequired: true,
          placeholder: "dias pasado desde ultimo pago",
          // defaultValue: 5,
        },
        "interes_pagado": {
          label: "Pagar",
          value: null,
          type: "money",
          isRequired: true,
          col: "xs-11.8",
          placeholder: "Ingresar porcentaje interes",
        }
      }
    };
    this.key = SNavigation.getParam("keyPrestamo");
    this._ref = {};
    this.key_prestamista;
  }

  componentDidMount() {
 
    Model.usuario.Action.getAll({force:true});
      }

  calcular(key) {
    var inputs = this.state.inputs;
    if (key == "fecha_porpagar") {
      if (inputs["fecha_ultimo_pago"].value > inputs["fecha_porpagar"].value) {
        inputs["fecha_porpagar"].value = "";
      }
    }
    var ___interes_pordia = inputs["interes_pordia"].value;
    if (key == "dias_pasado") {
      var fecha_inicio = new SDate(inputs["fecha_ultimo_pago"].value);
      if (inputs["dias_pasado"].value > 0) {
        fecha_inicio.addDay(parseInt(inputs["dias_pasado"].value));
        inputs["fecha_porpagar"].value = fecha_inicio.addDay(1).toString("yyyy-MM-dd");
      } else {
        inputs["fecha_porpagar"].value = fecha_inicio.addDay(1).toString("yyyy-MM-dd");
      }
      inputs["interes_pagado"].value = parseFloat(inputs["dias_pasado"].value * ___interes_pordia).toFixed(2);
    } else {
      if (inputs["fecha_porpagar"].value) {
        var cantodad_meses = new SDate(inputs["fecha_ultimo_pago"].value).diff(new SDate(inputs["fecha_porpagar"].value));
        inputs["dias_pasado"].value = parseFloat(cantodad_meses);
        inputs["interes_pagado"].value = parseFloat(cantodad_meses * ___interes_pordia).toFixed(2);
      }
    }
    this.setState({ inputs: { ...this.state.inputs } });
  }

  getInputs() {
    if (this?.key) {
      this.dataPrestamo = Model.prestamo.Action.getByKey(this.key);
      if (!this.dataPrestamo) return <SLoad />
      var _aux2 = this.dataPrestamo;

      this.key_prestamista = this.dataPrestamo?.key_prestamista;

      if (_aux2.fecha_ultimo_pago != null) {
        this.data = Model.prestamo_pago.Action.getAll();
        if (!this.data) return <SLoad />

        var _aux = Object.values(this.data).filter(o => o.key_prestamo === this.key);
        _aux.sort((a, b) => new Date(b.fecha_ultimo_pago, "yyyy-MM-dd") - new Date(a.fecha_ultimo_pago, "yyyy-MM-dd"));

        // var dataPrestamoPago = _aux[0];


        // var dataPrestamoPago = _aux.pop();
        var dataPrestamoPago = _aux[_aux.length - 1];

        // console.log("ultima fecha ", dataPrestamoPago)

        var _monto_prestado = parseFloat(dataPrestamoPago?.monto_prestado || 0).toFixed(2);
        var _fecha_ultimo_pago = new SDate(this.dataPrestamo?.fecha_ultimo_pago, "yyyy-MM-dd").toString("yyyy-MM-dd");
        var _porcentaje = parseFloat(dataPrestamoPago?.porcentaje);
        var _interes_porperiocidad = parseFloat(_monto_prestado * (_porcentaje / 100)).toFixed(2);

      } else {

        var _monto_prestado = parseFloat(this.dataPrestamo?.monto_prestado || 0).toFixed(2);
        var _fecha_ultimo_pago = new SDate(this.dataPrestamo?.inicio_prestamo, "yyyy-MM-dd").toString("yyyy-MM-dd");
        var _porcentaje = parseFloat(this.dataPrestamo?.porcentaje);
        var _interes_porperiocidad = parseFloat(_monto_prestado * (_porcentaje / 100)).toFixed(2);
      }
      this.state.inputs.key_prestamista.value = this.dataPrestamo?.key_prestamista;
      this.state.inputs.monto_prestado.value = _monto_prestado;
      this.state.inputs.fecha_ultimo_pago.value = _fecha_ultimo_pago;
      this.state.inputs.porcentaje.value = _porcentaje;

      this.state.inputs.periocidad.value = this.dataPrestamo?.periocidad + " dias";

      this.state.inputs.interes_porperiocidad.value = _interes_porperiocidad;
      this.state.inputs.interes_pordia.value = parseFloat(_interes_porperiocidad / this.dataPrestamo?.periocidad).toFixed(2);
      this.state.inputs.dias_pasado.value = this.state.inputs.dias_pasado?.value;
      this.state.inputs.interes_pagado.value = parseFloat(this.state.inputs.interes_pagado?.value || 0).toFixed(0);
      // this.state.inputs.fecha_porpagar.value = new SDate(_fecha_ultimo_pago, "yyyy-MM-dd").addMonth(3).toString("yyyy-MM-dd");
      // this.state.inputs.dias_pasado.value = 90;
      // this.state.inputs.interes_pagado.value = this.state.inputs.interes_pordia.value * 90;

    }
    return Object.keys(this.state.inputs).map((key) => {
      var obj = this.state.inputs[key];
      return <SView col={obj.col ?? "xs-6"} center>
        <SInput ref={(r) => this._ref[key] = r} customStyle={"calistenia"} col={"xs-11"} {...obj} onChangeText={(val) => {
          this.state.inputs[key].value = val;
          this.setState({ inputs: { ...this.state.inputs } });
          this.calcular(key);
        }} />
      </SView>
    })
  }
  

  setcolor(texto) {

    return <SText color={"red"} bold>{texto} dias</SText>

  }


  render() {
    // var key_usuario = Model.usuario.Action.getKey();

    return (
      <SPage title={'lista'} center>
        <SText>Registrar Pago periocidad de {this.setcolor(this.dataPrestamo?.periocidad)}</SText>
        <SHr height={24} color={"transparent"}></SHr>
        <SView col={"xs-12 sm-10 md-8 lg-6 xl-4"} center row >
          {this.getInputs()}
          <SView col={"xs-12"} height={24} />
          <SButtom type='danger' onPress={() => {


            var values = {};
            var isExito = true;
            Object.keys(this._ref).map((key) => {
              if (!this._ref[key].verify()) {
                isExito = false;
              }
              values[key] = this._ref[key].getValue();
            })
            if (isExito) {

              Model.prestamo_pago.Action.registro({
                data: {
                  key_prestamo: this.key,
                  key_usuario: Model.usuario.Action.getKey(),
                  monto_prestado: this.state.inputs.monto_prestado.value,
                  fecha_ultimo_pago: this.state.inputs.fecha_porpagar.value,
                  porcentaje: this.state.inputs.porcentaje.value,
                  interes_porperiocidad: this.state.inputs.interes_porperiocidad.value,
                  interes_pordia: this.state.inputs.interes_pordia.value,
                  interes_pagado: this.state.inputs.interes_pagado.value,
                  estado_pago: 1,
                  estado: 1,
                }
              }).then((resp) => {
                // console.log(resp)


                Model.prestamo.Action.editar({
                  data: {
                    key: this.key, fecha_ultimo_pago: this.state.inputs.fecha_porpagar.value
                  }
                }).then((resp) => {
                  // console.log(resp)
                  // Model.prestamo_pago.Action.CLEAR();
                  Model.prestamo.Action.CLEAR();
                  // Model.prestamo.Action._getReducer(this.props).estado = "";

                  SNavigation.goBack();

                }).catch((e) => {
                  console.error(e)
                });



              }).catch((e) => {
                console.error(e)
              });


            }
          }} >{"registrar"}</SButtom>

        </SView>
      </SPage >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(registroPago);


// CREATE TABLE IF NOT EXISTS public.prestamo
//   (
//     key character varying COLLATE pg_catalog."default" NOT NULL,
//     key_usuario character varying COLLATE pg_catalog."default",
//     fecha_on timestamp without time zone,
//     estado integer,
//     key_prestamista character varying COLLATE pg_catalog."default",
//     monto_prestado double precision,
//     porcentaje double precision,
//     inicio_prestamo timestamp without time zone,
//     fin_prestamo timestamp without time zone,
//     periocidad integer,
//     estado_prestamo integer,
//     fecha_ultimo_pago timestamp without time zone,
//     sucursal character varying COLLATE pg_catalog."default",
//     CONSTRAINT prestamo_pkey PRIMARY KEY(key)
//   )

// public.prestamo_pago
//   (
//     key character varying COLLATE pg_catalog."default" NOT NULL,
//     fecha_on timestamp without time zone,
//     estado integer,
//     key_prestamo character varying COLLATE pg_catalog."default",
//     porcentaje integer,
//     interes_porperiocidad double precision,
//     interes_pordia double precision,
//     interes_pagado double precision,
//     estado_pago integer,
//     fecha_ultimo_pago timestamp without time zone,
//     monto_prestado double precision,