import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SInput, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../../Model';
import Paquete from '../../../Paquete';
import Header from './Header';
import Container from '../../../../Components/Container';
import Table from './Table';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: null
        };

        this.key_paquete = SNavigation.getParam("key_paquete", false);
        this.key_caja = SNavigation.getParam("key_caja", false);
    }




    render() {
        const paquete = Paquete.Actions.getByKey(this.key_paquete, this.props);
        if (!paquete) return <SLoad />
        if (!this.state.usuarios) {
            this.state.usuarios = Array.from({ length: paquete.participantes }, (_, i) => {
                return {
                    usuario: {},
                    fecha_inicio: new SDate().toString("yyyy-MM-dd"),
                    fecha_fin: new SDate().addDay(paquete.dias).toString("yyyy-MM-dd")
                }
            });
        }

        return (
            <SPage title={'Ventas'} disableScroll center >
                {/* <Container> */}
                <SView col={"xs-12 md-8 lg-10"} flex border="transparent"   >
                    <Header paquete={paquete} />
                    <SHr h={50} />
                    <Table usuarios={this.state.usuarios} paquete={paquete} key_caja={this.key_caja}  />
                </SView>
                {/* </Container> */}
                <SHr h={50} />
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);