import { SPage } from "servisofts-component";

import root from "./root";

export const Parent = {
  title: "activos",
  name: "activos",
  path: "/activos",
};
export default SPage.combinePages(Parent.name, {
  "": root,
});
