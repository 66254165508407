import entrenador_horario from "./entrenador_horario";
import paqueteServicio from "./paqueteServicio";
import prestamo from "./prestamo";
import prestamo_pago from "./prestamo_pago";
import sucursal_paquete_venta_usuario from "./sucursal_paquete_venta_usuario";
import tipo_asiento from "./tipo_asiento";
import servicio from "./servicio";
export default {
  tipo_asiento,
  paqueteServicio,
  prestamo,
  prestamo_pago,
  sucursal_paquete_venta_usuario,
  entrenador_horario,
  servicio
};
