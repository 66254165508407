import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SIcon, SInput, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import { SSRolesPermisosValidate } from '../../../SSRolesPermisos';

class FinanzaPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getItemFecha({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }
    return this.getItem({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          // alert(JSON.stringify(evt));
          SNavigation.navigate(url, evt);
        });
      }
    })
  }

  popupFecha({ callback }) {

    let fechaso = new SDate().toString('yyyy-MM-dd');

    return <>
      <SView width={362} height={325} center row style={{ borderWidth: 3, borderRadius: 4, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}>
        <SHr height={20} />
        <SInput ref={ref => this._fechaInicio = ref} defaultValue={fechaso} col={"xs-10"} type={"date"} customStyle={"calistenia"} />


        <SButtom props={{ type: "secondary", variant: "default" }} onPress={() => {
          if (callback) {
            callback(this._fechaInicio.getValue());
          }
          // SNavigation.navigate("reparador", { fecha: this._fechaInicio })
          // SNavigation.navigate("reparador", { fecha: new SDate(this._fechaInicio).toString('dd/MM/yyyy') })
        }}>confirm</SButtom>

      </SView >
    </>
  }

  popupFechaPagosQr({ callback }) {

    let aux = new SDate().toString('yyyy-MM-dd');

    //  console.log()
    return <>
      <SView width={362} height={325} center row style={{ borderWidth: 3, borderRadius: 4, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}>
        <SHr height={20} />
        <SInput ref={ref => this._fechaInicio = ref} defaultValue={aux} col={"xs-10"} type={"date"} customStyle={"calistenia"} />


        <SButtom props={{ type: "secondary", variant: "default" }} onPress={() => {

          console.log("aqui ", this._fechaInicio.getValue())

          if (callback) {
            callback(this._fechaInicio.getValue());
          }
          // SNavigation.navigate("reparador", { fecha: this._fechaInicio })
          // SNavigation.navigate("reparador", { fecha: new SDate(this._fechaInicio).toString('dd/MM/yyyy') })
        }}>confirm</SButtom>

      </SView >
    </>
  }

  getItemFecha2({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }
    return this.getItem({
      title: title, icon: icon, onPress: () => {

        SPopup.open({
          content: this.popupFechaPagosQr({
            callback: (fecha) => {
              let fechaFormateada = new SDate(fecha).toString('dd-MM-yyyy');
              SNavigation.navigate(url, { fecha: fechaFormateada })
            }
          }), key: "CodigoSeguridad"
        });

      }
    })
  }
  getItemFechaPagosQr({ title, icon, url, onPress, permiso }) {

    // console.log("huekka ",this.props.state.usuarioReducer.usuarioLog.key)
    // let key_uiser =
    if (permiso != this.props.state.usuarioReducer.usuarioLog.key) {
      // if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
      return null;
      // }
    }

    return this.getItem({
      title: title, icon: icon, onPress: () => {

        SPopup.open({
          content: this.popupFechaPagosQr({
            callback: (fecha) => {
              let fechaFormateada = new SDate(fecha, "yyyy-MM-dd").toString('dd-MM-yyyy');

              SNavigation.navigate(url, { date: (fechaFormateada + "").replace(/\-/g, "") })
            }
          }), key: "CodigoSeguridad"
        });

      }
    })
  }

  getItemFechaSucursal({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }
    return this.getItem({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          // alert(JSON.stringify(evt));
          SNavigation.navigate(url, evt);
        });
      }
    })
  }


  getItem({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }

    return <SView col={"xs-3 sm-2.5 md-2 lg-1.5 xl-1.3"} colSquare style={{
      padding: 4,
    }}
    >
      <SView col={"xs-12"} height center>
        <SView col={"xs-7"} colSquare onPress={() => {
          if (onPress) {
            onPress();
            return;
          }
          SNavigation.navigate(url);
        }} >
          <SIcon name={icon} />
        </SView>
        <SHr />
        <SView center height={16}>
          <SText center fontSize={12}>{title}</SText>
        </SView>
      </SView>
    </SView>
  }

  getLista() {
    return <>
      {this.getItem({ title: "Cajas activas", icon: "Caja", url: "CajasAbiertas", permiso: "CajasAbiertas" })}
      {/* {this.getItem({ title: "Cajas historico", icon: "Caja", url: "CajasPage" })} */}
      {this.getItem({ title: "Cajas historico", icon: "Caja", url: "CajasPage", permiso: "CajasPage" })}
      {/* {this.getItem({ title: "Usuarios", icon: "Usuarios_all", url: "UsuariosTabla", permiso: "UsuariosTabla" })} */}
      {this.getItem({
        title: "Reporte Asistencias", icon: "Entrenamiento", permiso: "ReporteAsistencia", onPress: () => {
          SPopup.dateBetween("Reporte de asistencia", (evt) => {
            // alert(JSON.stringify(evt));
            SNavigation.navigate("ReporteAsistencia", evt);
          });
        }
      })}
      {this.getItemFecha({ title: "Paquetes vendidos", icon: "Paquete", url: "PaquetesVendidos", permiso: "PaquetesVendidos" })}
      {this.getItemFecha({ title: "Ingresos & egresos manuales", icon: "Traspaso", url: "IngresosEgresos", permiso: "IngresosEgresos" })}
      {this.getItemFecha({ title: "Reporte de bancos", icon: "Card", url: "ReporteBancos", permiso: "ReporteBancos" })}
      {this.getItemFechaSucursal({ title: "Estado financiero", icon: "Money", url: "EstadoFinancieroSelect", permiso: "EstadoFinancieroSelect" })}
      {this.getItemFecha({ title: "Prorroga", icon: "Ajustes", url: "ReporteProrroga", permiso: "ReporteProrroga" })}
      {/* {this.getItem({ title: "Montos actuales en cajas", icon: "Ajustes", url: "reporte_montos_actuales_todas_las_cajas" })} */}
      {/* {this.getItem({ title: "Anulados", icon: "Ajustes", url: "reporte_paquetes_anulados_todas_las_cajas" })} */}
      {/* {this.getItem({ title: "Montos actuales en cajas", icon: "Ajustes", url: "reporte_montos_actuales_todas_las_cajas" })} */}
      {/* {this.getItemFecha({ title: "Reporte movimientos banco", icon: "Ajustes", url: "reporte_movimientos_banco" })} */}

      {/* ------------------------------------------ */}
      {/* {this.getItemFecha({ title: "Reporte movimientos caja", icon: "Ajustes", url: "reporte_movimientos_caja" })} */}
      {/* {this.getItem({ title: "Reporte QR banco ganadero", icon: "LogoQR", url: "reporte_qr_banco_ganadero" })} */}
      {/* {this.getItem({ title: "PAGO QR", icon: "LogoQR", url: "ReporteQR" })} */}
      {/* ------------------------------------------ */}

      {/* {this.getItemFecha({ title: "Ingresos", icon: "Ingreso", url: "Ingreso" })} */}
      {/* {this.getItemFecha2({ title: "ricky", icon: "Ingreso", url: "reparador" })} */}
      {/* {this.getItem({ title: "Base datos Entrenamiento", icon: "DataBase", url: "bd_asistencias" })} */}
      {this.getItem({ title: "Base datos Regt.Ventas", icon: "DataBase", url: "bd_ventas" })}
      {this.getItem({ title: "Registro Vtas. Eliminadas", icon: "DataBase", url: "historial_ventas_elimanadas" })}
      {this.getItem({ title: "Registro Vtas. Modificadas", icon: "DataBase", url: "historial_ventas_modificadas" })}
      {/* {this.getItem({ title: "Pagos QR", icon: "DataBase", url: "qr" })} */}
      {/* {this.getItemFechaSucursal({ title: "Pagos QR2", icon: "Money", url: "qr", permiso: "EstadoFinancieroSelect" })} */}
      {/* {this.getItemFechaPagosQr({ title: "Pagos QR", icon: "DataBase", url: "qr", permiso: "fad45bc1-015a-48e9-9e7a-1162daaad707" })} */}


    </>
  }
  render() {
    return (
      <SPage title={"Finanzas"}>
        <SView col={"xs-12"} row>
          {this.getLista()}
        </SView>
      </SPage>
    );
  }

}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(FinanzaPage);