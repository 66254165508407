import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SHr, SIcon, SList2, SNavigation, SPage, SText, STheme, SView } from "servisofts-component";
import SSocket from 'servisofts-socket';
import Dia from '../../Components/Dia';

let totalesss = 0;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nroPublicaciones: 0,
      curDay: new SDate(),
      captura_fi: new SDate().setDay(1).setHours(0, 0, 0, 0),
      captura_ff: new SDate().addMonth(1).setDay(1).setHours(23, 59, 59, 0).addDay(-1).toString('yyyy-MM-dd hh:mm:ss'),
      // captura_ff: new SDate().addMonth(1).setDay(1).setHours(23, 23, 23, 23).addDay(-1).toString('yyyy-MM-dd'),
      ...this.state,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let aux_fi = new SDate().setDay(1).toString("yyyy-MM-dd");
    let aux_ff = new SDate().addMonth(5).setDay(1).addDay(-1).toString("yyyy-MM-dd");
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_get_fidelizacion_calendario",
      params: ["'" + aux_fi + "'", "'" + aux_ff + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }
  getTotal() {
    if (!this.state.data) {
      console.log("No hay datos disponibles");
      return;
    }

    let inicio = this.state.captura_fi;
    // let fin = new SDate(this.state.captura_ff).toString('yyyy-MM-dd hh:mm')
    // let fin = new SDate(this.state.captura_ff).addHour(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss');
    let fin = this.state.captura_ff.toString('yyyy-MM-dd hh:mm:ss');

    const totalSum = this.state.data.reduce((accumulator, item) => {
      const itemDate = new SDate(item?.fecha, "yyyy-MM-dd");

      if (itemDate < inicio || itemDate > fin) {
        return accumulator;
      }
      const total = item?.total || 0;
      return accumulator + total;
    }, 0);

    let aux = this.state.captura_ff.toString("yyyy-MM-dd hh:mm:ss");

    console.log("fecha ini:", inicio.toString("yyyy-MM-dd hh:mm:ss"));
    console.log("fecha fin:", fin);
    console.log("fecha fin real:", aux);
    console.log(" total  ", totalSum);

    return totalSum;
  }

  renderDias(data, i) {


    // let hoy = this.state.curDay.toString('yyyy-MM-dd')
    // let para_hoy = data.paquete.fecha

    // if (!data) return;
    let hoy = this.state.curDay.toString('dd')
    // let hoy = this.state.curDay.toString('dd/MM/yyyy')
    let isSelect = false

    let color = hoy == data.diaMes ? "red" : STheme.color.text
    // console.log("info ", data)
    let auxx = 0;
    return <>
      <SView col={"xs-1.7"} height={90} style={{
        borderWidth: 1, borderColor: hoy == data.diaMes ? "blue" : STheme.color.gray,
        backgroundColor: STheme.color.card
      }} center >

        <SView width={24} height={24} style={{
          position: "absolute", top: 5, left: 2, borderRadius: 20,
          backgroundColor: hoy == data.diaMes ? "blue" : "transparent"
        }} center  >
          <SText center font={"Roboto"} fontSize={12} color={STheme.color.text}   > {data.diaMes || ""}</SText>
        </SView>


        <SView col={"xs-12"} row center>

          {data?.dataAsis ? data.dataAsis.map((k) => {
            totalesss = totalesss + k.total;
            return (<>
              <SHr />
              <SView style={{
                borderWidth: 1,
                borderRadius: 5,
                borderColor: "white",
                // padding: 4

              }} width={40} height={40} center
                onPress={() => {
                  SNavigation.navigate("/fidelizacion/calendarioDetalle", { total: k.total, fecha: k.fecha, obj: k.detalle })
                }}
              >
                <SText center font={"Roboto"} fontSize={18} color={"red"}>{k?.total || ""}</SText>
              </SView>
            </>
            )
          }) : null}
        </SView>
        <SHr />

      </SView>
    </>
  }



  getCalendario(mes, ano) {



    let primerDiaSemana = new Date(ano, mes, 1).getDay();
    let fechaFin = new Date(ano, mes + 1, 1);
    fechaFin = fechaFin.setDate(fechaFin.getDate() - 1);
    let ultimoDiaSemana = new Date(fechaFin).getDay();
    let ultimoDiaMes = new Date(fechaFin).getDate()

    let format = new Date(fechaFin);
    let formateado = new SDate(format).setHours(23, 59, 59, 1).toString('yyyy-MM-dd hh:mm:ss')

    var dataAsistencia = this.state.data;
    if (!dataAsistencia) return null;

    let calendario = [];
    var index = 0;
    var diaMes = 0;
    var asisti = false;
    var data;
    var dato;
    var dataMostrar = [];



    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        index++;
        if (i == 0 && j < primerDiaSemana) {
          calendario.push({ index, dia_semana: j, semana: i, fecha: null })
        } else {
          if (diaMes < ultimoDiaMes) {
            diaMes++;
            let fechaActual = new Date(ano, mes, diaMes)
            let asistido;
            let sdActual = new SDate(fechaActual);
            Object.values(dataAsistencia).map((obj) => {
              if (new SDate(obj.fecha, "yyyy-MM-dd").equalDay(sdActual)) {
                asisti = true
                data = obj;
                dataMostrar.push(obj)
              }
            })

            let isPaquete = false;
            calendario.push({ isPaquete: isPaquete, diaMes, index, dia_semana: j, semana: i, fecha: "", asistiendo: asisti, dataAsis: dataMostrar, paquete: dataMostrar[0] })
            asisti = false
            data = null;
            dataMostrar = []
          } else {
            asisti = false
            data = null;
            dataMostrar = []
            calendario.push({ index, dia_semana: j, semana: i, fecha: null })
          }
        }
      }
    }


    if (this.state.captura_ff != formateado) {
      this.setState({ captura_ff: formateado })

    }

    return <>

      <SView col={"xs-12"} center  >

        <SView col={"xs-12"} row center backgroundColor={"#D70000"}
          style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }} >
          <SView col={"xs-2"} onPress={() => {
            this.setState({ curDay: this.state.curDay.addMonth(-1), })
            this.setState({ captura_fi: this.state.captura_fi.addMonth(-1) })
          }}>
            <SIcon name='Back' height={20} fill={STheme.color.secondary} />
          </SView>
          <SView col={"xs-8"} row center height={55}>
            <SView flex border={"transparent"}   >
              <SText fontSize={18} bold style={{ color: STheme.color.white }}>Total, {this.getTotal()} Usuarios</SText>
            </SView>
            <SView flex border={"transparent"} center>
              <SText fontSize={18} bold style={{ color: STheme.color.white }}>{this.state.curDay.toString("MONTH, yyyy")}</SText>
            </SView>
            <SView flex border={"transparent"} center>
            </SView>
          </SView>
          <SView col={"xs-2"} onPress={() => {
            this.setState({ curDay: this.state.curDay.addMonth(1) })
            this.setState({ captura_fi: this.state.captura_fi.addMonth(1) })
          }}>
            <SIcon name='Back' style={{ transform: [{ rotate: "180deg" }] }} height={20} fill={STheme.color.secondary} />
          </SView>
        </SView>

        <SView col={"xs-12"} row >
          <Dia dia="DOM" />
          <Dia dia="LUN" />
          <Dia dia="MAR" />
          <Dia dia="MIE" />
          <Dia dia="JUE" />
          <Dia dia="VIE" />
          <Dia dia="SAB" />
        </SView>

        <SView col={"xs-12"} row    >
          <SList2
            horizontal
            space={0}
            data={calendario}
            render={
              this.renderDias.bind(this)
            }
          />
        </SView>
      </SView>
    </>
  }
  getBody() {


    return <>
      <SView col={"xs-12"}   center  >
        {/* <SText bold fontSize={22}>Total, {this.getTotal()} usuarios inactivos</SText> */}
        {this.getCalendario(this.state.curDay.getMonth() - 1, this.state.curDay.getYear())}
      </SView>
    </>
  }

  render() {

    // let sonrie = this.state.captura_ff.toString('yyyy-MM-dd hh:mm:ss');

    // let eeeeeeeee = this.state.captura_ff.toString('yyyy-MM-dd');
    // let eeeeeeeee = new SDate(this.state.captura_ff).toString('yyyy-MM-dd');
    // console.log("sonrie ", eeeeeeeee)

    return (
      <SPage title={"CALENDARIO USUARIOS INACTIVOS "} center >
        {/* <SPage title={"USUARIOS PRÓXIMAS A VENCER PAQUETE inicio " + this.state.captura_fi.toString("yyyy-MM-dd") + " fin " + this.state.captura_ff.toString("yyyy-MM-dd")} center > */}
        {/* <SHr height={20}></SHr> */}
        <SView col={"xs-12"} flex center>
          {/* <SHr height={30}></SHr> */}
          <SView col={"xs-11 md-10 lg-8"} row center flex>
            {this.getBody()}
          </SView>
          {/* <SHr height={30}></SHr> */}

          <SButtom style={{ backgroundColor: "transparent", width: 40, position: "absolute", bottom: 20, right: 2 }} onPress={() => {
            SNavigation.navigate("/fidelizacion/calendarioDetalleGlobal", { fecha_inicio: this.state.captura_fi.toString("yyyy-MM-dd"), fecha_fin: this.state.captura_ff.toString("yyyy-MM-dd") })
          }}>
            <SIcon name={"Excel"} width={32} />
          </SButtom>

        </SView>

        {/* <SHr height={100}></SHr> */}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(index);
