import { Component } from 'react'
import { Linking } from 'react-native'
import { SDate, SLoad, SMath, SNavigation, SPage, SPopup, STable2, STheme, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import Botoncito from '../../Components/Botoncito'
export default class root extends Component {
  state = {
  }
  componentDidMount() {

    this.fecha_inicio = SNavigation.getParam("fecha_inicio", new SDate().toString("yyyy-MM-dd"));
    this.fecha_fin = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));

    SSocket.sendPromise({
      component: "facturacion",
      type: "getFacturas",
      fecha_inicio: this.fecha_inicio,
      fecha_fin: this.fecha_fin,
      // type: "getFacturas",
      // fecha_inicio: "2024-04-01",
      // fecha_fin: "2024-04-21",
      // CODIGO_AMBIENTE: 1,
    }).then(e => {
      this.setState({ data: e?.data?.data })
    }).catch(e => {
      console.error(e)
    })

    SSocket.sendPromise({
      component: "sucursal",
      type: "getAll",
    }).then(e => {
      this.setState({ sucursal: e?.data })
    }).catch(e => {
      console.error(e)
    })
  }



  render() {
    if (!this.state.data) return <SLoad />
    if (!this.state.sucursal) return <SLoad />
    return <SPage title={"Historial de facturas"} disableScroll>
      <STable2
        rowHeight={30}
        header={[
          { key: "index", width: 50 },
          {
            // key: "CABECERA/CODIGO_AMBIENTE", label: "Facturación", width: 70, render: obj => {
            key: "CABECERA/CODIGO_AMBIENTE", label: "Facturación", width: 70, filter_h: "pro", render: obj => {
              if (obj == 2) return "Prueba";
              if (obj == 1) return "Producción";
            }
          },
          { key: "CABECERA/FECHAEMISION", label: "Fec. Emisión📅", width: 120, render: obj => new SDate(obj).toString("yyyy-MM-dd hh:mm") },

          // { key: "-usr", label: "Usuario", width: 150, },
          // {
          //   key: "CABECERA/CODIGOSUCURSAL-suc", label: "Sucursal", width: 90, render: (codigo_sucursal) => {
          //     let suc = Object.values(this.state.sucursal).find((a) => a.codigo_sucursal_factura == codigo_sucursal);
          //     return suc.descripcion
          //   }
          // },
          // { key: "-atc", label: "ATC", width: 150, },





          { key: "CABECERA/ID", label: "Fact. Cód⚡", width: 90, order: "asc" },
          { key: "CABECERA/NUMEROFACTURA", label: "Fact. Nro⚡", width: 90, order: "asc" },
          { key: "CABECERA/NUMERODOCUMENTO", label: "NIT/CI/CEX", width: 90 },
          { key: "CABECERA/NOMBRERAZONSOCIAL", label: "Razón Social", width: 180 },
          { key: "CABECERA/MONTOTOTAL", label: "Monto✅", width: 70, render: obj => SMath.formatMoney(obj) },


          {
            key: "CABECERA/ESTADO-a", label: "EC", width: 50, component: (e) => {
              if (e == "enviado") {
                return <SView backgroundColor={STheme.color.success} width={40} height={20}></SView>
              } else if (e == "procesando") {
                return <SView backgroundColor={STheme.color.gray} width={40} height={20}></SView>
              } else if (e == "emitida") {
                return <SView backgroundColor={STheme.color.warning} width={40} height={20}></SView>
              } else if (e == "anulada") {
                return <SView backgroundColor={STheme.color.danger} width={40} height={20}></SView>
              }
            }
          },
          { key: "CABECERA/ESTADO", label: "Estado", width: 70, order: "asc" },
          // {
          //   key: "-onpressImpuestoSiat", renderExcel: (a) => "", label: "Impuestos Siat", width: 90, component: obj => {
          //     if (obj?.CABECERA?.ESTADO != 'procesando') return;
          //     return <Botoncito height={20}
          //       style={{ backgroundColor: "#058DFF" }}
          //       onPress={() => {
          //         console.log(obj)
          //         SSocket.sendPromise({
          //           component: "facturacion",
          //           type: "reenviar",
          //           codigo_factura: obj?.CABECERA?.ID + "",
          //         }, 1000 * 60).then(e => {
          //           alert("La factura reenviada!");
          //           console.log(e);
          //         }).catch(e => {
          //           console.error(e);
          //         })
          //       }} >{"Reenviar"}</Botoncito>
          //   }
          // },
          {
            key: "-onpress", label: "FACTURA", width: 100,
            renderExcel: (a) => "-",
            component: obj => {
              if (obj?.CABECERA?.ESTADO == 'anulada') return;
              if (obj?.CABECERA?.ID + "") {
                return <Botoncito
                  height={20}
                  // style={{ backgroundColor: "green" }}
                  style={{ backgroundColor: "#E30613" }}
                  onPress={() => {
                    SSocket.sendPromise({
                      component: "facturacion",
                      type: "anularFactura",
                      codigo_factura: obj?.CABECERA?.ID + "",
                      // force: true

                    }).then(e => {
                      alert("La factura ha sido anulada con éxito. ¡Operación completada satisfactoriamente!");
                      console.log(e);
                      SSocket.sendPromise({
                        component: "paqueteVenta",
                        type: "anularFactura",
                        codigo_factura: obj?.CABECERA?.ID + "",
                      }).then(e => {
                        alert("Campos borrados código de factura, NIT y razón social!");
                        console.log(e);
                      }).catch(e => {
                        console.error(e);
                        alert(e.error);
                        SPopup.alert("Error anular al factura.");
                      })
                    }).catch(e => {
                      console.error(e);
                      SPopup.alert("Error anular al factura.");
                    })
                  }}
                >Anular</Botoncito>
              }
              return <Botoncito height={20} onPress={() => {
                if (obj?.CABECERA?.ID + "") {
                  SPopup.alert("Ya fue facturado");
                  return;
                }
                SSocket.sendPromise({
                  component: "facturacion",
                  type: "facturarPaquete",
                  key_paquete_venta: obj?.CABECERA?.ID + "",
                  nit: obj?.CABECERA?.NUMERODOCUMENTO + "",
                  razon_social: obj?.CABECERA?.NOMBRERAZONSOCIAL + "",
                  key_usuario_factura: this.props.state.usuarioReducer.usuarioLog.key,
                }).then(e => {
                  console.log(e);
                }).catch(e => {
                  console.error(e);

                  if (e?.error) {
                    if (e?.error.indexOf("fac_codigo_producto") > -1) {
                      SPopup.alert("Error: El paquete no tiene configurado el codigo de producto ni la unidad de medida.")
                      return;
                    }
                    SPopup.alert(e.error);
                    return;
                  }
                  SPopup.alert("Error desconocido al generar factura.");

                })
              }} >{"FACT."}</Botoncito>
            }
          },

          {
            key: "-onpress98", renderExcel: (a) => "", label: "Descargar carta", width: 100, component: obj => {
              return <Botoncito height={20} onPress={() => {
                console.log(obj)

                SSocket.sendPromise({
                  component: "facturacion",
                  type: "getFacturaPdfV2",
                  codigo_factura: obj?.CABECERA?.ID + ""
                }).then(e => {
                  const byteCharacters = atob(e.data);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });

                  // Create an object URL for the blob and open it
                  const blobUrl = URL.createObjectURL(blob);
                  window.open(blobUrl, '_blank');
                  console.log(e);
                }).catch(e => {
                  console.error(e);
                })
              }} >{"Carta"}</Botoncito>
            }
          },
          {
            key: "-onpress2", label: "Descargar", width: 100,
            renderExcel: (a) => "", component: obj => {
              return <Botoncito height={20} onPress={() => {
                console.log(obj)
                SSocket.sendPromise({
                  component: "facturacion",
                  type: "getFacturaRollo",
                  codigo_factura: obj?.CABECERA?.ID + ""
                }).then(e => {
                  const byteCharacters = atob(e.data);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });

                  // Create an object URL for the blob and open it
                  const blobUrl = URL.createObjectURL(blob);
                  window.open(blobUrl, '_blank');
                  console.log(e);
                }).catch(e => {
                  console.error(e);
                })
              }} >{"Rollo"}</Botoncito>
            }
          },
          {
            key: "-onpress4", label: "Impuestos", width: 150,
            renderExcel: (a) => "", component: obj => {
              return <Botoncito height={20} onPress={() => {
                console.log(obj)
                Linking.openURL(`https://siat.impuestos.gob.bo/consulta/QR?nit=${obj.CABECERA.NITEMISOR}&cuf=${obj.CABECERA.CUF}&numero=${obj.CABECERA.NUMEROFACTURA}&t=1`)

              }} >{"Ver en SIAT"}</Botoncito>
            }
          }
        ]}
        data={this.state?.data}
      />
    </SPage>
  }
}