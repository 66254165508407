import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SIcon, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import FloatButtom from '../../../../../Components/FloatButtom';
import Model from '../../../../../Model';


class listaPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Reporte Name",
      func: "_actualizar_prestamo_ultimo_pago",
      params: this.key,
    };
    this.key = SNavigation.getParam("key");
    this.fecha_ultimo_pago;


  }

  componentDidMount() {
 
    Model.usuario.Action.getAll({force:true});
      }

  getData() {
    console.log("entro ");

    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_actualizar_prestamo_ultimo_pago",
      params: ["'" + this.key + "'"],
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
        console.log("todo un exito ", resp);
      })
      .catch((e) => {
        console.error("error ", e);
        this.setState({ loading: false, error: e });
      });
  }
  mostrar_boton() {
    var dataPrestamo = Model.prestamo.Action.getByKey(this.key);
    var data = Model.prestamo_pago.Action.getAll();
    if (!dataPrestamo || !data) {
      return <SLoad />;
    }


    var _fecha1 = new SDate(dataPrestamo.fin_prestamo);
    var _fecha2 = new SDate(dataPrestamo.fecha_ultimo_pago);

    if (_fecha1 > _fecha2) {
      return <FloatButtom onPress={() => {
        Model.prestamo_pago.Action._getReducer(this.props).estado = "";
        SNavigation.navigate("prestamos/registroPago", { keyPrestamo: this.key });
      }} />
    }
  }

  getLista() {
    var data = Model.prestamo_pago.Action.getAll();
    if (!data) return <SLoad type='skeleton' col={"xs-12"} height />

    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />

    var arr_active = Object.values(data).filter(i => i.key_prestamo === this.key && i.estado !== 0);

    const INSTANCE = this;
    return <STable2
      header={[
        { key: "index", label: "#", width: 50 },
        {
          key: "key_usuario", label: "Pagante", width: 120, render: (item) => {
            return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`
          },
          // component: (c) => {
          //   return <SText underLine fontSize={12} color={STheme.color.link}  >{c}</SText>
          // }
          component: (c) => {
            return <SText fontSize={12} color={"red"}  >{c}</SText>
          }
        },
        { key: "fecha_ultimo_pago", label: "Fecha pago", order: "asc", width: 80, center: true, render: (item) => { return new SDate(item, "yyyy-MM-dd").toString("yyyy-MM-dd") } },
        { key: "monto_prestado", label: "Prestamo", width: 90, center: true, render: (item) => { return SMath.formatMoney(item ? item : 0) + " Bs" } },
        { key: "porcentaje", label: "Interes", width: 50, center: true, render: (item) => { return (item ? item : 0) + " %" }, },
        { key: "interes_porperiocidad", label: "Monto periocidad", width: 100, center: true, render: (item) => { return SMath.formatMoney(item ? item : 0) + " Bs" } },
        { key: "interes_pagado", label: "Monto Pagado", width: 90, render: (item) => { return SMath.formatMoney(item ? item : 0) + " Bs" }, },
        {
          key: "estado_pago", label: "Estado", width: 50, render: (item) => {
            if (item == 0) {
              return "anulado";
            } else if (item == 1) {
              return "pagado";
            } else if (item == 2) {
              return "otro";
            } else if (item = 3) return "consolidado";
          }
        },
        {
          key: "key-eliminar", label: "Anular", width: 80, center: true,
          component: (item) => {
            return <SView width={35} height={35} onPress={() => {
              SPopup.confirm({
                title: "Anular", message: "¿Esta seguro de anular pago?", onPress: () => {
                  Model.prestamo_pago.Action.editar({
                    data: { key: item, estado: 0 }
                  }).then((resp) => {
                    INSTANCE.getData();
                    Model.prestamo.Action.CLEAR();
                    SNavigation.goBack();
                  }).catch((e) => {
                    console.error(e)
                  })
                }
              })
            }}>
              <SIcon name={'Delete'} />
            </SView>
          }
        },
      ]}

      data={arr_active}
    />
  }
  render() {
    return (

      <SPage title={'lista prestamos'} onRefresh={(end) => {
        Model.prestamo_pago.Action.CLEAR();
        end()
      }} disableScroll>
        {this.getLista()}
        {this.mostrar_boton()}
        {/* <FloatButtom onPress={() => {
          Model.prestamo_pago.Action._getReducer(this.props).estado = "";
          SNavigation.navigate("prestamos/registroPago", { keyPrestamo: this.key });
        }} /> */}
      </SPage >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(listaPago);
