import { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SText, STheme, SView } from 'servisofts-component';
import Sucursal_usuario from '../sucursal_usuario';

class MisSucursales extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    return (
      <SView col={"xs-12"} center>
        <SHr />
        <SText style={{
          fontSize: 14,
          color: STheme.color.secondary,
          width: "100%",
          textAlign: "center"
        }}>SUCURSALES ACTIVAS</SText>
        <Sucursal_usuario.Components.Select key_usuario={key_usuario} preventEdit={true} />
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(MisSucursales);