import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SLoad, SPage, STable2, SText, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Model from "../Model";
import sucursal_usuario from "./sucursal_usuario";

class baseDatosVentasModificadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_historial_ventas_modificadas",
      parametros: {
        inicio: "2015-01-01",
        fin: "2028-02-01",
      },
      ...this.state,
    };
  }


  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }
  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: [
      ],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  setColor(item) {
    if (item == '1dae3da5-ee8a-445c-ad34-d33fefb4f005')
      return "pink";
  }



  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type="skeleton" col={"xs-12"} height />;

    console.log("papi ", dase_datos)
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return <SLoad />;

    // let bandera = false;
    return (
      <STable2
        limit={15}
        data={dase_datos}
        cellStyle={{
          fontSize: 12,
          // height: 30,
        }}
        header={[
          { key: "index", label: "#", width: 20 },
          { key: "sucursal_descripcion", label: "Sucursal", width: 70 },
          {
            key: "cliente_key", label: "Cliente", width: 200, render: (item) => {
              return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
            },
            component: (e) => <SText center color={"yellow"} >{e}</SText>
          },
          { key: "-telefonocliente", label: "Telefono", width: 100, render: (obj) => { return `${usuarios[obj.cliente_key]?.Telefono}`; } },

          {
            key: "-usuarisas", label: "Historial", width: 70,
            render: (obj) => { return obj.cliente_key },
            component: (e) => <SView center style={{
              borderWidth: 1,
              borderColor: "yellow",
              borderRadius: 4,
              padding: 4
            }} onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>
              <SText center color={"yellow"} >ver</SText></ SView>
          },

          {
            key: "key_usuario_vendedor", label: "⚡ATC Vendedor", width: 180,
            render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; },
          },
          { key: "paquete_venta_fecha_on", label: "⚡F.Venta📆", width: 110, center: true, render: (item) => { return new SDate(item).toString("dd/MM/yyyy hh:mm"); } },
          { key: "paquete", label: "⚡Paquete_descripcion", width: 150 },
          // { key: "participantes", label: "⚡persona", center: true, width: 60 },
          // { key: "paquete_precio", label: "⚡Paquete precio", center: true, width: 90 },

          {
            key: "key_usuario_modificador", label: "🛠️ATC Modificador", width: 180,
            render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}` ? `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}` : item; },
            component: (e) => <SText center color={"yellow"} >{e}</SText>
          },

          {
            key: "fecha_modificada", label: "📆Fecha", width: 90, center: true,
            render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); },
          },

          {
            key: "tipo_modificacion", label: "📝🛠️Tipo", width: 100, center: true,
            component: (e) => <SText center color={e == "Traspaso paquete" ? "cyan" : "green"} >{e}</SText>
          },


          { key: "observacion", label: "Observación🔄", center: true, sumar: true, width: 200 },
          // { key: "-horas", label: "Hora Mod.🔄", width: 80, center: true, render: (obj) => { return new SDate(obj.fecha_modificada).toString("hh:mm"); } },
          // { key: "-años", label: "Año Mod.🔄", width: 80, center: true, render: (obj) => { return new SDate(obj.fecha_modificada).toString("yyyy"); } },

          // { key: "-horas", label: "Hora", width: 60, center: true, render: (obj) => { return new SDate(obj.paquete_venta_fecha_on).toString("hh:mm"); } },
          // { key: "-años", label: "Año", width: 60, center: true, render: (obj) => { return new SDate(obj.paquete_venta_fecha_on).toString("yyyy"); } },
          // { key: "paquete_dias", label: "Paquete_dias", center: true, width: 60 },
          // { key: "fi_inscripcion", label: "F.inicio", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
          // { key: "ff_inscripcion", label: "F.fin", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
          // { key: "pagado", label: "⚡Monto Pagado", center: true, width: 90 },
          // { key: "tipos_pago", label: "⚡Tipo_pago", width: 90 },


        ]}
        filter={(item) => {
          // bandera = item.cliente_key === 'fad45bc1-015a-48e9-9e7a-1162daaad707';
          return sucursal_usuario.Actions.isActive(item.sucursal_key, this.props);
        }}
      />
    );
  }
  render() {
    return (
      <SPage title={"Registro Vtas. Modificadas🛠️🔄"} disableScroll center>
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(baseDatosVentasModificadas);
