import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import {
  ExportExcel,
  SButtom,
  SIcon,
  SImage,
  SLoad,
  SNavigation,
  SOrdenador,
  SPage,
  SScrollView2,
  SText,
  STheme,
  SView,
} from "servisofts-component";
import SSocket from "servisofts-socket";
import Usuario from "../..";
import BarraSuperior from "../../../../Components/BarraSuperior";
import Buscador from "../../../../Components/Buscador";
import FloatButtomMolinete from "../../../../Components/FloatButtomMolinete";
import Model from "../../../../Model";
import { SSRolesPermisosValidate } from "../../../../SSRolesPermisos";
import Sucursal from "../../../Sucursal";
import sucursal_usuario from "../../../sucursal_usuario";
var objFinal = {};

class EntrenadorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        curPage: 1,
      },
    };
    this.key_rol = "b5b4a616-dd16-4443-b859-39245f50c8df";
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }

  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50;
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  };
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />;
    }
    if (data.estado != 0) {
      return <View />;
    }
    return (
      <SButtom
        props={{
          type: "danger",
          variant: "confirm",
        }}
        onPress={() => {
          Usuario.Actions.editar(
            {
              ...data,
              estado: 1,
            },
            this.props
          );
        }}
      >
        Recuperar
      </SButtom>
    );
  }

  getExportar() {
    if (
      !SSRolesPermisosValidate({
        page: "EntrenadorPage",
        permiso: "exportar_excel",
      })
    ) {
      return;
    }
    return (
      <SView col={"xs-12"} center>
        <ExportExcel
          header={[
            { key: "indice", label: "n", width: 40 },
            { key: "key_usuario", label: "key", width: 250 },
            { key: "nombres", label: "nombres", width: 200 },
          ]}
          getDataProcesada={() => {
            var daFinal = {};
            Object.values(objFinal).map((obj, i) => {
              var toInsert = {
                indice: i + 1,
                key_usuario: obj?.key,
                nombres: obj?.Nombres + " " + obj?.Apellidos,
              };
              daFinal[i] = toInsert;
            });
            return daFinal;
          }}
        />
      </SView>
    );
  }

  getBotonMolinete() {
    if (
      !SSRolesPermisosValidate({ page: "EntrenadorPage", permiso: "crear" })
    ) {
      return;
    }
    return (
      <FloatButtomMolinete
        label={"+"}
        onPress={() => {
          this.props.navigation.navigate("dispositivo");
        }}
      />
    );
  }

  sucursal_descripcion(_keys) {
    const arr = Sucursal.Actions.getAll(this.props);
    const arr_sucursal_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(
      _keys,
      this.props
    );

    if (!arr || !arr_sucursal_usuario) return <SLoad />;

    const sucursalesActivas = Object.values(arr).filter((obj) => {
      const objFind = arr_sucursal_usuario.find(
        (o) => o.key_sucursal === obj.key && o.estado !== 0
      );
      return objFind !== undefined;
    });

    return sucursalesActivas.map((obj) => (
      <>
        <SView
          border={STheme.color.gray}
          row
          center
          style={{
            borderRadius: 2,
            marginBottom: 0,
            padding: 2,
            paddingTop: 1,
            paddingBottom: 1,
            // backgroundColor: "#090"
          }}
        >
          <SText fontSize={10} font={"Roboto"} color={STheme.color.gray}>
            {obj.descripcion}
          </SText>
          {/* <SText fontSize={10} font={"Roboto"} style={{ padding: 1 }}>{obj.descripcion}</SText> */}
        </SView>
        <SView width={6} height />
      </>
    ));
  }

  veces(_keys) {
    const arr = Sucursal.Actions.getAll(this.props);
    const arr_sucursal_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(
      _keys,
      this.props
    );
    if (!arr || !arr_sucursal_usuario) return <SLoad />;
    const sucursalesActivas = Object.values(arr).filter((obj) => {
      const objFind = arr_sucursal_usuario.find(
        (o) => o.key_sucursal === obj.key && o.estado !== 0
      );
      return objFind !== undefined;
    });
    return (
      <>
        <SView
          col={"xs-2"}
          height
          border={"transparent"}
          row
          center
          style={{
            top: 0,
            right: 8,
            position: "absolute",
          }}
        >
          {/* <SView col={"xs-5"} row center> */}
          <Text
            style={{
              color: sucursalesActivas.length < 1 ? "red" : STheme.color.text,
            }}
          >
            {"veces(" + sucursalesActivas.length + ")"}
          </Text>
        </SView>
        {/* <SView col={"xs-7"} height onPress={() => { SNavigation.navigate("lista_horarios", { key: _keys }) }} border={"#014385"} row center >
          <Text fontSize={10} style={{ color: STheme.color.text }}>Horarios </Text>
          <SIcon width={18} height={18} name={"Reloj"} fill={"white"} />
        </SView> */}
        {/* </SView> */}
      </>
    );
  }
  getButtonHorarios(_keys) {
    const arr = Sucursal.Actions.getAll(this.props);
    const arr_sucursal_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(
      _keys,
      this.props
    );
    if (!arr || !arr_sucursal_usuario) return <SLoad />;
    const sucursalesActivas = Object.values(arr).filter((obj) => {
      const objFind = arr_sucursal_usuario.find(
        (o) => o.key_sucursal === obj.key && o.estado !== 0
      );
      return objFind !== undefined;
    });
    return (
      <>
        <SView
          col={"xs-12"}
          height
          row
          center
          style={{
            borderRadius: 4,
            // backgroundColor: "#D70000"
            backgroundColor: "#2D2D2D",
          }}
          onPress={() => {
            SNavigation.navigate("EntrenadorPage/horarios", { key: _keys });
          }}
        >
          <SIcon center width={18} height={18} name={"Reloj"} fill={"white"} />
          <Text fontSize={10} style={{ color: STheme.color.text }}>
            {" "}
            Horarios
          </Text>
        </SView>
        {/* </SView > */}
      </>
    );
  }
  render() {
    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      // var dataRU = SSRolesPermisos.Events.getUsuarioRol(this.key_rol, this.props)
      var dataRU = Model.usuarioRol.Action.getAllByKeyRol(this.key_rol);
      var arr_sc_us = sucursal_usuario.Actions.getAll(this.props);
      if (!data) return <SLoad />;
      if (!dataRU) return <SLoad />;
      if (!arr_sc_us) return <SLoad />;
      if (!this.state.buscador) {
        return <View />;
      }
      var isAll = SSRolesPermisosValidate({
        page: "SucursalPage",
        permiso: "admin_all",
      });

      Object.keys(dataRU).map((key) => {
        var rol_user = dataRU[key];
        if (!data[rol_user.key_usuario]) {
          return;
        }
        if (!isAll) {
          var suc_por_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(
            key,
            this.props
          );
          var isActive = false;

          suc_por_usuario.map((suc) => {
            if (!isActive) {
              isActive = sucursal_usuario.Actions.isActive(
                suc.key_sucursal,
                this.props
              );
            }
          });
          if (!isActive) return null;
        }

        objFinal[rol_user.key_usuario] = data[rol_user.key_usuario];
      });

      var isRecuperar = SSRolesPermisosValidate({
        page: "UsuarioPage",
        permiso: "recuperar_eliminado",
      });

      return this.pagination(
        new SOrdenador([
          // { key: "Peso", order: "desc", peso: 4 },
          { key: "Nombres", order: "asc", peso: 2 },
          { key: "Apellidos", order: "asc", peso: 1 },
        ]).ordernarObject(this.state.buscador.buscar(objFinal))
      ).map((key) => {
        var obj = data[key];
        console.log("mira ", obj);
        return (
          <TouchableOpacity
            style={{
              width: "90%",
              maxWidth: 600,
              height: 50,
              margin: 4,
              borderRadius: 10,
              // backgroundColor: "yellow"
              backgroundColor: STheme.color.card,
            }}
            onPress={() => {
              SNavigation.navigate("registro", {
                key: key,
              });
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    marginLeft: 8,
                    marginRight: 8,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: STheme.color.card,
                    borderRadius: 100,
                    overflow: "hidden",
                  }}
                >
                  <SImage
                    src={
                      SSocket.api.root +
                      "usuario/" +
                      key +
                      `?date=${new Date().getTime()}`
                    }
                  />
                </View>

                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    // backgroundColor: "blue"
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: STheme.color.text,
                      textDecorationLine:
                        obj.estado == 0 ? "line-through" : "none",
                    }}
                  >
                    {obj["Nombres"] + " " + obj["Apellidos"]}
                    {this.veces(obj.key)}
                  </Text>
                  <SView flex row color={"#D70000"}>
                    <Text style={{ fontSize: 12, color: STheme.color.gray }}>
                      Permisos:{" "}
                    </Text>{" "}
                    {this.sucursal_descripcion(obj.key)}
                  </SView>
                </View>

                <View
                  style={{
                    width: 90,
                    height: "80%",
                    marginLeft: 8,
                    marginRight: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.getButtonHorarios(obj.key)}
                </View>

                {this.getRecuperar(obj, isRecuperar)}
              </View>
            </View>
          </TouchableOpacity>
        );
      });
    };
    return (
      <>
        <SPage hidden disableScroll>
          <BarraSuperior
            title={"Entrenadores"}
            navigation={this.props.navigation}
            goBack={() => {
              SNavigation.goBack();
            }}
          />
          <Buscador
            placeholder={
              "Buscar por CI, Nombres, Apellidos, Correo o Telefono."
            }
            ref={(ref) => {
              if (!this.state.buscador) this.setState({ buscador: ref });
            }}
            repaint={() => {
              this.setState({ ...this.state });
            }}
          />
          {this.getExportar()}

          <SView
            flex
            style={{
              width: "100%",
            }}
          >
            <SScrollView2
              disableHorizontal
              onScroll={(evt) => {
                var evn = evt.nativeEvent;
                var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
                var heigth = evn.contentSize.height;
                if (heigth - posy <= 0) {
                  this.state.pagination.curPage += 1;
                  this.setState({ ...this.state });
                }
              }}
            >
              <SView col={"xs-12"} center>
                {getLista()}
              </SView>
            </SScrollView2>
          </SView>
          {this.getBotonMolinete()}
        </SPage>
      </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(EntrenadorPage);
