import { STheme } from "servisofts-component";
import { ButtomType } from "..";

const index: ButtomType = {
    viewProps: {
        height: 40,
        center: true,
        borderRadius: 8,
        style: {
            backgroundColor: STheme.color.secondary
        }
    },
    textProps: {
    
        color: STheme.color.primary,
        fontSize: 18,
        bold: true
    }
}
export default index;