import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SText, STextProps, SView, SViewProps } from 'servisofts-component'
import alvaro from "./type/alvaro"
import ricky from "./type/ricky"
import _default from "./type/default"

export type ButtomType = {
    viewProps: SViewProps,
    textProps: STextProps
}

const types = {
    alvaro,
    default: _default,
    ricky
}

type BotoncitoPropsType = {
    onPress: () => any,
    children?: any,
    type: keyof typeof types,

} & SViewProps

export default class Botoncito extends Component<BotoncitoPropsType> {

    render() {
        const { children, onPress, type, style } = this.props
        const typeSelect: ButtomType = types[type ?? "default"]
        let CONTENIDO = null
        if (typeof children == "string" || typeof children == "number") {
            CONTENIDO = <SText {...typeSelect.textProps}>{children}</SText>
        } else if (Array.isArray(children)) {
            CONTENIDO = children.map(itm => {
                if (typeof itm == "string" || typeof itm == "number") {
                    return <SText {...typeSelect.textProps}>{itm}</SText>
                } else {
                    return itm
                }
            })
        } else {
            CONTENIDO = children;
        }

        // @ts-ignore
        return <SView  {...typeSelect.viewProps} {...this.props} style={{ ...typeSelect.viewProps?.style ?? {}, ...style ?? {} }} >
            {CONTENIDO}
        </SView >
    }
}