import { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { SImage, SLoad, SText, STheme } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import * as SImageImput from '../SImageImput';
import Model from '../../Model';
class FotoDocumentoUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

 
  render() {
    var usuario = this.props.usuario;
    return <>
      <TouchableOpacity style={{
        width: "90%",
        height: "90%",
        backgroundColor: STheme.color.card,
        // borderRadius: 8,
        borderColor: "cyan",
        // borderColor: "red",
        borderWidth: 3,
        borderRadius: "10%",
        overflow: "hidden",
      }} onPress={() => {
        if (this.props.disable) {
          return;
        }
        SImageImput.choseFile({
          servicio: "root",
          service: "usuario",
          component: "usuario",
          type: "subirDocumento",
          estado: "cargando",
          key: usuario.key,
          key_usuario: usuario.key,
        }, (resp) => {
          this.props.dispatch({
            component: "image",
            type: "cambio",
            url: SSocket.api.root + "usuario/documento" + usuario.key,
          })
          // this.state.repaint = new Date().getTime()
          // this.setState({ ...this.state });
        });
      }}>

        {/* {"foto"} */}
        <SImage src={SSocket.api.root + "usuario/documento/" + usuario.key} />

      </TouchableOpacity>
      <SText col={"xs-12"} row bold center fontSize={16} style={{
        position: "absolute",
        bottom: -5,
      }}  >{"DOCUMENT"}</SText>
    </>

  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(FotoDocumentoUsuario);
