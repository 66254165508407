import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SImage, STheme } from 'servisofts-component';
import { SLoad, SPopup, SText, SView } from 'servisofts-component';
import SeleccionarUsuario from './SeleccionarUsuario';
import SSocket from 'servisofts-socket'
import Asistencia from '../../../../../Asistencia';
import Usuario from '../../../../../Usuario';
import Model from '../../../../../../Model';
class Participantes extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    getItem(content) {
        return <SView width={120} height={120} colSquare style={{ padding: 4 }} row >
            {content}
        </SView>

    }
    getAdd() {
        return <SView col={"xs-12"} center height={60}>
            <SView col={"xs-11 md-8 xl-6"} height={50} card center onPress={() => {
                SPopup.open({
                    key: "selectUser",
                    content: <SeleccionarUsuario select={(usr) => {
                        Asistencia.Actions.registro({
                            descripcion: "",
                            key_usuario: usr.key,
                            key_sucursal: this.props.sucursal?.key,
                            // key_entrenamiento: this.props.entrenamiento.key
                        }, this.props)
                        SPopup.close("selectUser");
                    }} />
                })
            }}>
                <SText fontSize={16}>Asistencia</SText>
            </SView>
        </SView>
    }
    sincronizar() {
        var key_sucursal = this.props?.sucursal?.key;
        return <SView col={"xs-12"} center height={60}>
            <SView col={"xs-11 md-8 xl-6"} height={50} card center onPress={() => {
                SSocket.sendPromise({
                    component: "zkteco",
                    type: "sincronizarLog",
                    estado: "cargando",
                    key_sucursal: key_sucursal,
                }, 10000)
            }}>
                <SText fontSize={16}>Sync Molinetes</SText>
            </SView>
        </SView>
    }
    getUsuario(data) {

        var usuarios = Model.usuario.Action.getByKey(data.key_usuario);
        if (!usuarios) {
            return <SLoad />
        }
        // console.log("puuta ", data);
        return (<>

            <SView card border={"green"} height backgroundColor='yellow' col={"xs-12"} center style={{
                overflow: "hidden",
            }}>

                <SText key={data.key_usuario} capitalize center fontSize={12}>Manual</SText>
                <SHr />

                <SView width={40} height={40}>
                    <SImage src={SSocket.api.root + "usuario/" + data.key_usuario} />
                </SView>
                <SHr />

                <SText center fontSize={10}>{`${usuarios.Nombres} ${usuarios.Apellidos}`}</SText>
                <SText center fontSize={10} color={"cyan"} >Hr.Reg. {new SDate(data.fecha_on).toString('hh:mm')}</SText>
            </SView>

            <SView style={{
                position: "absolute",
                width: 20,
                height: 20,
                top: -4,
                right: 4,
            }} onPress={() => {
                SPopup.confirm({
                    "title": "Eliminar asistencia",
                    message: "¿Esta seguro de eliminar esta asistencia?",
                    onPress: () => {

                        Asistencia.Actions.eliminar(data, this.props.sucursal.key, this.props)
                    }
                })

            }}>
                <SIcon name={"Delete"} />
            </SView>
            {/* </SView> */}
        </>
            // </SView>
        )
    }
    getListaUsuariosMolinete = () => {
        if (!this.props.sucursal) return <SView />
        var data = Asistencia.Actions.getByKeySucursal({ key_sucursal: this.props.sucursal.key }, this.props);
        if (!data) return <SLoad />
        return Object.keys(data).map((key) => {
            var obj = data[key];
            if (obj.estado != 1) return null;
            // si es molinete no mostrar
            if (!obj?.descripcion != "") return null;
            return this.getItem(this.getUsuario(obj));
        })
    }
    getListaUsuariosManual = () => {
        if (!this.props.sucursal) return <SView />
        var data = Asistencia.Actions.getByKeySucursal({ key_sucursal: this.props.sucursal.key }, this.props);
        if (!data) return <SLoad />
        return Object.keys(data).map((key) => {
            var obj = data[key];
            if (obj.estado != 1) return null;
            // si es molinete no mostrar
            if (!obj?.descripcion == "") return null;
            return this.getItem(this.getUsuario(obj))
        })
        // .sort((a, b) => data[a].fecha_on - data[b].fecha_on);

    }
    render() {
        return (
            <SView col={"xs-12"} center>
                {/* <SText>Participantes</SText> */}
                <SView height={8} />
                <SText color={"#999"}>Ingrese el participante</SText>
                <SView height={4} />
                <SView col={"xs-12"} center  >
                    {this.getAdd()}
                    <SHr />
                    {this.sincronizar()}
                    <SHr />

                    {/* <SText>{"ASISTENCIA MOLINETE"}</SText>
                    <SHr height={40} />

                    {this.getListaUsuariosMolinete()} */}

                    <SText>{"ASISTENCIA MANUALss"}</SText>
                    <SHr height={40} />
                    <SView col={"xs-11.3"} center row backgroundColor='transparent' >
                        {this.getListaUsuariosManual()}
                    </SView>
                </SView>

            </SView>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Participantes);