import React, { Component } from 'react'
import { SDate, SHr, SIcon, SInput, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
export default class Table extends Component {

    renderTableData = () => {
        const styleInput = {
            height: 40,
            backgroundColor: STheme.color.card,
            textAlign: "center"
        }

        // let test_json = {
        //     "component": "paqueteVenta",
        //     "type": "registro",
        //     "estado": "cargando",
        //     "key_usuario": "c4310023-4413-42dd-9676-e9ed1bd862dc",
        //     "data": {
        //         "descripcion": "",
        //         "key_paquete": "4cfca082-7d36-4157-931d-f67e9141b85a",
        //         "nombre_paquete": "PROMO RELÁMPAGO",
        //         "monto": 199.00
        //     },
        //     "clientes": [
        //         {
        //             "key": "c4310023-4413-42dd-9676-e9ed1bd862dc",
        //             "fecha_inicio": "2023-08-28",
        //             "fecha_fin": "2023-09-26",
        //             "data": {
        //                 "1": {
        //                     "monto": "109.00",
        //                     "Número de serie": "EFECTIVO"
        //                 },
        //                 "2": {
        //                     "monto": "20.00",
        //                     "Código": "TERJETA",
        //                     "Fecha de vencimiento": "2023-08-28",
        //                     "Nombre": "RPD",
        //                     "Banco": "GANADERO"
        //                 },
        //                 "3": {
        //                     "monto": "30.00",
        //                     "Banco": "MSC",
        //                     "Código": "TRANSFERENCIA"
        //                 },
        //                 "4": {
        //                     "monto": "40.00",
        //                     "Banco": "BMSC",
        //                     "Código": "CHEQUE",
        //                     "Fecha de emisión": "2023-08-28",
        //                     "Beneficiario": "BENEFICIARIO",
        //                     "Orden": "1",
        //                     "Librador": "LIBRADOR"
        //                 }
        //             }
        //         }
        //     ]
        // };

        const { paquete, caja } = this.props;

        const { key, descripcion, observacion, precio, participantes, dias, requiere_motivo } = paquete;

        return this.props.usuarios.map((o, i) => {
            return <SView col={"xs-12"} row backgroundColor={i % 2 != 0 ? "" : STheme.color.card} key={"IndexUser" + i}>

                <SView width={14} padding={4} center border="blue" ><SText>{i + 1}</SText></SView>

                <SView width={170} padding={4} border="yellow">
                    <SView onPress={(e) => {
                        SNavigation.navigate("VentasPage", {
                            onSelect: e => {
                                SNavigation.goBack();
                                o.usuario = e;
                                this.setState({ ...this.state })
                            }
                        })
                    }} style={styleInput} center row>
                        <SText col={"xs-12"}>{o.usuario?.Nombres} {o.usuario?.Apellidos}</SText>
                    </SView>
                </SView>

                <SView width={90} padding={4} border="blue">
                    <SInput type='date' defaultValue={o.fecha_inicio} customStyle={"clean"} style={styleInput} onChangeText={e => {
                        o.fecha_inicio = e;
                    }} />
                </SView>
                <SView width={90} padding={4}>
                    <SInput type='date' defaultValue={o.fecha_inicio} customStyle={"clean"} style={styleInput} onChangeText={e => {
                        o.fecha_fin = e;
                    }} />
                </SView>

                <SView width={100} padding={4}>
                    <SInput type='number' defaultValue={o?.tipo1} customStyle={"clean"} style={styleInput} onChangeText={e => { o.tipo1 = e; }} />
                </SView>

                <SView width={100} padding={4}>
                    <SInput type='number' defaultValue={o?.tipo2} customStyle={"clean"} style={styleInput} onChangeText={e => { o.tipo2 = e; }} />
                </SView>

                <SView width={100} padding={4}>
                    <SInput type='number' defaultValue={o.tipo3} customStyle={"clean"} style={styleInput} onChangeText={e => { o.tipo3 = e; }} />
                </SView>

                <SView width={100} padding={4}>
                    <SInput type='number' defaultValue={o.tipo4} customStyle={"clean"} style={styleInput} onChangeText={e => { o.tipo4 = e; }} />
                </SView>
{/* 
                <SView width={100} padding={4}>
                    <SInput type='number' defaultValue={o.tipo5} customStyle={"clean"} style={styleInput} onChangeText={e => { o.tipo5 = e; }} />
                </SView> */}

                <SView width={50} padding={4}>
                    <SView style={styleInput} center onPress={() => {


                        let arraaay = [
                            {
                                // "key_paquete": key,
                                "key_paquete": this.props.paquete.key,
                                "key_caja": this.props.key_caja,

                                "key": o.usuario.key,
                                "fecha_inicio": o.fecha_inicio,
                                "fecha_fin": o.fecha_fin,

                                "data": {

                                    "1": {
                                        "monto": o.tipo1,
                                        "Número de serie": "EFECTIVO"
                                    },
                                    "2": {
                                        "monto": o.tipo2,
                                        "Código": "TERJETA",
                                        "Fecha de vencimiento": "2023-08-28",
                                        "Nombre": "RPD",
                                        "Banco": "GANADERO"
                                    },
                                    "3": {
                                        "monto": o.tipo3,
                                        "Banco": "MSC",
                                        "Código": "TRANSFERENCIA"
                                    },
                                    "4": {
                                        "monto": o.tipo4,
                                        "Banco": "BMSC",
                                        "Código": "CHEQUE",
                                        "Fecha de emisión": "2023-08-28",
                                        "Beneficiario": "BENEFICIARIO",
                                        "Orden": "1",
                                        "Librador": "LIBRADOR"
                                    },
                                    "5": {
                                        "monto": o.tipo5,
                                        "Banco": "BMSC",
                                        "Código": "CHEQUE",
                                        "Fecha de emisión": "2023-08-28",
                                        "Beneficiario": "BENEFICIARIO",
                                        "Orden": "1",
                                        "Librador": "LIBRADOR"
                                    }
                                }
                            }
                        ]

                        console.log("huevo ", arraaay)
                        // alert("exito", test_json)
                    }}>
                        <SIcon width={25} height={25} name={"Arrow"} fill={STheme.color.text} />
                    </SView >
                </SView>

            </SView>
        })
    }
    render = () => {
        return <SView col={"xs-12"} row center >
            <SView col={"xs-12"} row height={28}  >
                <SView width={14} padding={4}><SText>{"#"}</SText></SView>
                <SView width={170} padding={4}><SText>{"Usuario"}</SText></SView>
                <SView width={90} padding={4}><SText>{"fecha_inicio"}</SText></SView>
                <SView width={90} padding={4}><SText>{"fecha_fin"}</SText></SView>

                <SView width={100} padding={4}><SText>{"Efectivo"}</SText></SView>
                <SView width={100} padding={4}><SText>{"Tarjeta"}</SText></SView>
                <SView width={100} padding={4}><SText>{"Transferencia"}</SText></SView>
                <SView width={100} padding={4}><SText>{"Cheque"}</SText></SView>
                {/* <SView width={100} padding={4}><SText>{"Pago APP"}</SText></SView> */}

                <SView width={50} padding={4}><SText center>{"save"}</SText></SView>
            </SView>
            {this.renderTableData()}
        </SView>
    }
}