import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SLoad, SNavigation, SPage } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
import { Parent } from ".";
import Model from '../../Model';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    let empresa = Model.empresa.Action.getSelect();
    let gestion = Model.gestion.Action.getSelect();
    if (!empresa) return <SLoad />

    // let fecha = new SDate();
    // let fecha_inicio = fecha.toString("yyyy-MM-dd");
    // fecha.addMonth(1).addDay(-1);
    // let fecha_fin = fecha.toString("yyyy-MM-dd");


    let uno = SNavigation.getParam("fecha_inicio", new SDate().setDay(1).toString("yyyy-MM-dd"));
    let dos = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));

    return (
      <SPage title={Parent.title} onRefresh={(end) => {
        Model.usuarioPage.Action.CLEAR();
        end()
      }}>
        <SHr height={32} />
        <MenuPages path={Parent.path + "/"}
          blackList={["/fidelizacion/renovacion"]}
          // blackList={["/fidelizacion/resumen_anual"]}
          params={{
            fecha_inicio: uno,
            fecha_fin: dos,
          }}
          onPress={(e) => {
            e.preventDefault();
            console.log(e);
          }}
        >

          <MenuButtom label={"Usuarios"} url={"UsuarioPage"} icon={<SIcon name={"DataBase"} />} />
          <MenuButtom label={"Activos"} url={"/activos"} icon={<SIcon name={"DataBase"} />} />
          <MenuButtom label={"ventas"} url={"VentasPage"} icon={<SIcon name={"DataBase"} />} />
        </MenuPages>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);