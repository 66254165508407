import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SLoad, SPage, STable2, SText, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Model from "../Model";
import sucursal_usuario from "./sucursal_usuario";

class baseDatosVentas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_romeo",
      parametros: {
        inicio: "2015-01-01",
        fin: "2028-02-01",
      },
      ...this.state,
    };
  }


  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }
  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: [
      ],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type="skeleton" col={"xs-12"} height />;

    console.log("papi ", dase_datos)
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return <SLoad />;

    return (
      <STable2
        limit={15}
        data={dase_datos}
        cellStyle={{
          fontSize: 12,
          // height: 30,
        }}
        header={[
          { key: "index", label: "#" },
          {
            key: "cliente_key", label: "Cliente", width: 240, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; },
            component: (e) => <SView ><SText color={"yellow"} >{`${e}`}</SText> </ SView>
          },
          { key: "-carnet", label: "CI 🆔", width: 60, render: (obj) => { return `${usuarios[obj.cliente_key]?.CI}`; } },
          { key: "-telefonocliente", label: "Telefono☎️", width: 100, render: (obj) => { return `${usuarios[obj.cliente_key]?.Telefono}`; } },
          { key: "-correocliente", label: "Correo📧", width: 200, render: (obj) => { return `${usuarios[obj.cliente_key]?.Correo}`; } },
          { key: "-nacimiento", label: "F.Nac 🎂📅", width: 80, center: true, render: (obj) => { return new SDate(`${usuarios[obj.cliente_key]?.["Fecha nacimiento"]}`).toString("dd/MM"); }, component: (e) => <SView ><SText color={"cyan"} >{`${e}`}</SText> </ SView> },
          {
            key: "-edad", label: "Edad", width: 30, render: (obj) => {
              let edad = new SDate().toString("yyyy") - new SDate(`${usuarios[obj.cliente_key]?.["Fecha nacimiento"]}`).toString("yyyy");
              return edad;
            },
          },
          // { key: "ORDERSTATE", width: 50, filter_h: "2" },
          // { key: "TRANSACTIONID", width: 100, filter_notin: "^0" },
          {
            key: "-usuario_k1ey", label: "Perfil", width: 60,
            render: (obj) => { return obj.cliente_key },
            component: (e) => <SView center style={{
              borderWidth: 1,
              borderColor: "yellow",
              borderRadius: 4,
              padding: 4
            }} onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>
              <SText center color={"yellow"} >ver</SText>  </ SView>
          },
          {
            key: "sucursal_descripcion", label: "Sucursal🏢", width: 70,
            component: (e) => <SView ><SText color={"yellow"} >{`${e}`}</SText> </ SView>
          },
          { key: "sucursal_direccion", label: "Direccion📍", width: 70 },
          { key: "paquete_venta_fecha_on", label: "F.compra🛒📅", order: "asc", width: 90, center: true, render: (item) => { return new SDate(item).toString("yyyy-MM-dd"); }, component: (e) => <SView ><SText color={"cyan"} >{`${e}`}</SText> </ SView> },
          { key: "-horas", label: "Hora", width: 60, center: true, render: (obj) => { return new SDate(obj.paquete_venta_fecha_on).toString("hh:mm"); } },
          { key: "-años", label: "Año", width: 60, order: "asc", center: true, render: (obj) => { return new SDate(obj.paquete_venta_fecha_on).toString("yyyy"); } },
          { key: "paquete", label: "Paquete_descripcion📦", width: 150, component: (e) => <SView ><SText color={"green"} >{`${e}`}</SText> </ SView> },
          { key: "paquete_dias", label: "Pqt_dias", center: true, width: 60 },
          { key: "paquete_precio", label: "Pqt_precio💰", center: true, width: 90 },
          { key: "participantes", label: "Pqt_part👫", center: true, width: 60 },
          { key: "fi_inscripcion", label: "F.inicio📅", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
          { key: "ff_inscripcion", label: "F.fin📅", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
          { key: "pagado", label: "Pagado✅", center: true, sumar: true, width: 80 },
          {
            key: "cajero_key", label: "ATC👤🏧", width: 200, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; },
            component: (e) => <SView ><SText color={"yellow"} >{`${e}`}</SText> </ SView>
          },
          { key: "tipos_pago", label: "Tipo_pago💵", width: 80, component: (e) => <SView ><SText color={"cyan"} >{`${e}`}</SText> </ SView> },
          { key: "sucursal_key", label: "Sucursal_key", width: 80 },
          { key: "paquete_key", label: "Paquete_key", width: 80 },
          { key: "paquete_venta_key", label: "Paquete_venta_key", width: 80 },
          { key: "-cliente", label: "Cliente_key", width: 80, render: (item) => { return item.cliente_key; } },
          { key: "caja_key", label: "caja_key", width: 80 },
          { key: "-cajero", label: "Cajero_key", width: 80, render: (item) => { return item.cajero_key; } },

        ]}
        filter={(item) => {
          return sucursal_usuario.Actions.isActive(
            item.sucursal_key,
            this.props
          );
        }}
        
      />
    );
  }
  render() {
    return (
      <SPage title={"Base datos - Registro de ventas 📊"} disableScroll center>
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(baseDatosVentas);
