import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SIcon, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket'
import Model from '../../../Model';
import Sucursal from '../../Sucursal';

class index extends Component {
    constructor(props) {
        super(props);

        this.params = SNavigation.getAllParams();
        this.state = {
            title: "Reporte de qr",
            // params: [`'${this.params.fecha_inicio}'`, `'${this.params.fecha_fin}'`],
        };
    }
    componentDidMount() {
        Model.usuario.Action.getAll({ force: true });
        this.getData();
    }

    getLista() {
        var usuarios = Model.usuario.Action.getAll();
        if (!this.state.data) return <SLoad type='skeleton' col={"xs-12"} height />
        return <STable2
            limit={100}
            data={this.state.data}
            cellStyle={{
                fontSize: 12,
                height: 30,
            }}
            rowHeight={30}
            header={[
                { key: "index", label: "#" },
                { key: "qrid", width: 150, order: "desc" },
                { key: "bg/tipo", width: 140, },
                {
                    key: "key_usuario", width: 200,

                    // render: (e) => `${usuarios[e]?.Nombres} ${usuarios[e]?.Apellidos}`,
                    component: (e) => <SView onPress={() => {
                        // SNavigation.navigate("ClientePerfilPage", { key: e })
                        window.open("/ClientePerfilPage?key=" + e)
                    }}>
                        <SText>{`${usuarios[e]?.Nombres} ${usuarios[e]?.Apellidos}`}</SText>
                    </ SView>
                },

                // {key: "transactionid", width: 100, },
                // {key: "accountreference", width: 150, },
                { key: "monto", width: 80, },
                // {key: "currency", width: 60, },
                // {key: "tipo", width: 120, },
                // {key: "expirationdate", width: 100, },
                { key: "fecha_pago", width: 170, },

                { key: "fecha_on", width: 170, order: "desc" },
                { key: "fecha_inicio", width: 170, },
                { key: "fecha_vencimiento", width: 170, },
                // {key: "fecha_pago", width: 100, render: (a) => !a ? "" : new SDate(a).toString("yyyy-MM-dd hh:mm") },
                // {key: "gloss", width: 200, },
                // {key: "message_", width: 500, },
                // {key: "descripcion", label: "Descripcion", width: 100, },
                // {key: "reference", width: 100, },
                // {key: "estado", width: 100, },

                // {key: "key_paquete", width: 100, },
                { key: "key_sucursal", width: 100, },
                // {key: "key_bg_profile", width: 100, },
                { key: "key", label: "Key", width: 100, },
                {
                    key: "-", width: 200,
                    // render: (e) => `${usuarios[e]?.Nombres} ${usuarios[e]?.Apellidos}`,
                    component: (e) => <SView onPress={() => console.log(e)}>
                        <SText>{`ENVIAR`}</SText>
                    </ SView>
                },

            ]}
        />
    }
    getData() {
        this.setState({ loading: "cargando", data: null });
        SSocket.sendPromise({
            component: "solicitud_compra_paquete",
            type: "getAll",
        }).then(resp => {
            SSocket.sendPromise({
                service: "banco_ganadero",
                component: "bg_payment_order",
                type: "getAllSimple",
                key_bg_profile: "8f686d25-1d0a-4cff-9b5b-ae030efa0e99",
            }).then(resp2 => {
                this.setState({ loading: false, data: resp.data });
                // const arr = Object.values(resp2.data);
                // Object.values(resp.data).map((a) => {
                //     const of = arr.find((b) => b.qrid == a.qrid)
                //     if (of) {
                //         a.bg = of;
                //     }
                // })
            }).catch(e => {
                this.setState({ loading: false, error: e });
            })
        }).catch(e => {
            this.setState({ loading: false, error: e });
        })


    }
    render() {
        return <SPage title={this.state.title} center disableScroll>
            {this.getLista()}
        </SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);