import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SForm, SHr, SIcon, SInput, SList2, SLoad, SNavigation, SPage, SScrollView2, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from "servisofts-socket";

class reporte_asistencias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fecha_capturada = SNavigation.getParam("fecha", new SDate().toString("yyyy-MM-dd"));
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_ricky_entrenamientos",
      params: ["'" + this.fecha_capturada + "'"],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }
  showLista() {
    if (!this.state.data) return <SLoad />;
    const data = this.state.data;
    const dataHorarios = Object.values(data).flatMap(item => item.lista_horarios.map(horario => horario));
    const sucursalesExcluidas = new Set([
      "788ef65e-12a6-4fb5-b626-bf9b6dc1010d",
      "1d897377-6ddb-46f8-895b-0ef369da64fe",
      "489fdb5f-65aa-44c0-ac79-f5a084680325",
      "7164d756-ebe5-48be-b471-5f413ea72b20",
      "77a2f26f-7a65-40d5-8e2c-f8292b6def43",
      "7bd13b8b-5811-4096-ab32-17129bd66423",
      "24e3e37e-195f-431f-ae46-04d4842cb93b",
      "a3d7655f-5e2d-4a72-9a5d-82a70aebb143",
      "a4629322-fda0-4c00-8431-5267491c2432",
      "df9b2831-b508-41bc-b5b6-c1a69d18818e",
      "0a9b2cdd-6173-4e8e-b776-05a0f642090d",
      "ffd2d5ce-a61d-46ad-97c4-b7a1947ed180",
      "2b9cb90e-ea14-4ea7-9a1e-db103f7203cb"
    ]);
    const sucursalesFiltradas = dataHorarios.filter(horario => !sucursalesExcluidas.has(horario.key_sucursal));
    console.log("mira bobo ", sucursalesFiltradas)
    return <STable2
      limit={18}
      data={sucursalesFiltradas}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        { key: "descripcion", label: "Sucursal", width: 80 },
        { key: "horario", label: "Horario", center: true, width: 60 },
        { key: "manual", label: "Asis. Manual", center: true, width: 100, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "molinete", label: "Asis. Molinete", center: true, width: 100 },
        { key: "asistencia", label: "Total Asistencia", width: 120, center: true, component: (e) => <SText color={"orange"} >{`${e}`}</SText> },
        // {
        //   key: "-key_usuario", label: "Grado", center: true, width: 80, render: (obj) => {
        //     if (obj.molinete > 20) {
        //       // return "green";
        //       return "Mayor 20";
        //     } else if (obj.molinete > 10) {
        //       // return "#cc8f00";
        //       return "mayor 10";
        //     } else if (obj.molinete > 0) {
        //       return "mayor A 1";
        //       // return "#7f0000";
        //     } else {
        //       // return "transparent";
        //       return "";
        //     }
        //   }
        // },
        // todo si no hay manualk, no mostrar boton
        {
          key: "key_entrenamiento", label: "Detalle Entren.", width: 150,
          // render: (obj) => {
          //   if (!obj == null) return;
          // },
          component: (e) => <SView center style={{ borderWidth: 1, borderColor: "yellow", borderRadius: 4, padding: 4 }} onPress={() => {

            // SNavigation.navigate("entrenamiento_perfil", { key_entrenamiento: obj?.key_entrenamiento }) 
            // window.open("/entrenamiento_perfil?key_entrenamiento=" + e)
            window.open("/reporte_asistencias_detalle?key_entrenamiento=" + e)
          }}>
            <SText center color={"yellow"} >ver</SText></ SView>
        },
        {
          key: "-index", label: "Fecha", width: 90, center: true, render: (obj) => {
            const validacion = new SDate(this.fecha_capturada).toString("dd/MM/yyyy");
            return validacion;
          }
        }
      ]}
    />
  }
  render() {
    return (<>
      <SPage title={"Reporte Entrenamientos " + this.fecha_capturada}>
        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(reporte_asistencias);

