import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SIcon, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket'
import Model from '../../../Model';
import Sucursal from '../../Sucursal';

class index extends Component {
    constructor(props) {
        super(props);

        this.params = SNavigation.getAllParams();
        this.state = {
            title: "Reporte de qr",
            // params: [`'${this.params.fecha_inicio}'`, `'${this.params.fecha_fin}'`],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getLista() {
        // var usuarios = Model.usuario.Action.getAll();
        if (!this.state.data) return <SLoad type='skeleton' col={"xs-12"} height />
        return <STable2
            limit={30}
            data={this.state.data}
            cellStyle={{
                fontSize: 12,
                height: 30,
            }}
            header={[
                { key: "index", label: "#" },
                { key: "qrid", label: "qrid", width: 200 },
                { key: "tipo", label: "tipo", width: 200 },
                { key: "amount",  width: 100 },
                { key: "fecha_on", width: 200, order: "desc" },
                { key: "bg/key_usuario", width: 200 },
                { key: "bg/key_paquete", width: 200 },
                { key: "bg/key_sucursal", width: 200 },
                { key: "bg/monto", width: 200 },

            ]}
        />
    }
    getData() {
        this.setState({ loading: "cargando", data: null });
        SSocket.sendPromise({
            component: "solicitud_compra_paquete",
            type: "getAll",
        }).then(resp => {
            SSocket.sendPromise({
                service: "banco_ganadero",
                component: "bg_payment_order",
                type: "getAllSimple",
                key_bg_profile: "8f686d25-1d0a-4cff-9b5b-ae030efa0e99",
            }).then(resp2 => {
                this.setState({ loading: false, data: resp2.data });
                const arr = Object.values(resp.data);
                Object.values(resp2.data).map((a) => {
                    const of = arr.find((b) => b.qrid == a.qrid)
                    if (of) {
                        a.bg = of;
                    }
                })
            }).catch(e => {
                this.setState({ loading: false, error: e });
            })
        }).catch(e => {
            this.setState({ loading: false, error: e });
        })


    }
    render() {
        return <SPage title={this.state.title} center disableScroll>
            {this.getLista()}
        </SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);