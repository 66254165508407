import { Component } from "react";
import { connect } from "react-redux";
import {
    SButtom,
    SDate,
    SForm,
    SHr,
    SLoad,
    SMapView,
    SNavigation,
    SPage,
    SText,
    SView
} from "servisofts-component";
import FotoPerfilUsuario from "../../../Components/FotoPerfilUsuario";
import Model from "../../../Model";
import Botoncito from "../../../Components/Botoncito";

class Mapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region: {
                latitude: -17.7833276,
                longitude: -63.1821408,
            }
        };
        this.key = SNavigation.getParam("key");
        this.key_rol = SNavigation.getParam("key_rol");
        if (!this.key_rol) {
            this.key_rol = "d16d800e-5b8d-48ae-8fcb-99392abdf61f";
        }
    }

    componentDidMount() {
        if (this.key) {
            // this.getData();
        }
    }

    // getData() {
    //     // console.log("'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'");
    //     // retur
    //     this.setState({ loading: "cargando", data_usuario: null });
    //     SSocket.sendPromise({
    //         version: "2.0",
    //         component: "usuario",
    //         type: "get_last_history_by_key_usuario",
    //         service: "usuario",
    //         key_usuario: this.key,
    //     })
    //         .then((resp) => {
    //             this.setState({ loading: false, data_usuario: resp.data });
    //         })
    //         .catch((e) => {
    //             this.setState({ loading: false, error: e });
    //         });
    // }

    render_foto() {
        this.data = Model.usuario.Action.getByKey(this.key);
        if (!this.data) return <SLoad />
        return <SView style={{ width: 140, height: 140 }} center>
            <FotoPerfilUsuario usuario={this.data} />
        </SView>

    }

    render_form() {
        var usr = {};
        if (this.key) {
            usr = Model.usuario.Action.getByKey(this.key);
            if (!usr) return <SLoad />;


        }

        // this.state.region.latitude = parseFloat(usr.Latitude)??this.state.region.latitude;
        // this.state.region.longitude = parseFloat(usr.Longitude)??this.state.region.longitude;
        // this.setState({
        //   region: {
        //     latitude:parseFloat(usr.Latitude),
        //     longitude: parseFloat(usr.Longitude),
        //   }
        // });

        let coordenadas = { latitude: this.state.region.latitude ?? parseFloat(usr.Latitude), longitude: this.state.region.longitude ?? parseFloat(usr.Longitude) };
        // this.setState({ region: coordenadas })

        console.log("muestro region ", coordenadas)
        // this.setState({ ...this.state });

        return (
            <SForm
                ref={(ref) => (this.form = ref)}
                row
                style={{
                    justifyContent: "space-between",
                }}
                inputProps={{
                    col: "xs-12",
                    customStyle: "calistenia",
                }}
                inputs={{
                    // CI: {
                    //     label: "CI",
                    //     isRequired: true,
                    //     defaultValue: usr.CI,
                    //     col: "xs-12",
                    // },
                    Nombres: {
                        label: "Nombres",
                        isRequired: true,
                        defaultValue: usr.Nombres,
                        col: "xs-5.9",
                    },
                    Apellidos: {
                        label: "Apellidos",
                        isRequired: true,
                        defaultValue: usr.Apellidos,
                        col: "xs-5.9",
                    },

                    // Sexo: {
                    //     label: "Género",
                    //     type: "select",
                    //     options: [
                    //         { key: "Maculino", content: "Maculino" },
                    //         { key: "Femenimo", content: "Femenimo" },
                    //     ],
                    //     isRequired: true,
                    //     defaultValue: usr.Sexo,
                    //     col: "xs-2.8",
                    // },
                    // "Fecha nacimiento": {
                    //     label: "Fecha de nacimiento",
                    //     type: "date",
                    //     isRequired: true,
                    //     defaultValue: usr["Fecha nacimiento"],
                    //     col: "xs-3.8",
                    // },

                    // Telefono: {
                    //     label: "Teléfono",
                    //     type: "phone",
                    //     isRequired: true,
                    //     defaultValue: usr.Telefono,
                    //     col: "xs-5",
                    // }
                }}
                onSubmit={(values) => {
                    // this.setState({ loading: true });
                    // console.log("printer ", values)
                    // if (this.key) {
                    //     Model.usuario.Action.editar({
                    //         key_usuario: Model.usuario.Action.getKey(),
                    //         data: {
                    //             ...usr,
                    //             ...values,
                    //             Latitude: this.state.region.latitude,
                    //             Longitude: this.state.region.longitude,
                    //         },
                    //     })
                    //         .then((resp) => {
                    //             this.setState({ loading: false });
                    //             console.log("editado ", values)
                    //             SNavigation.goBack();
                    //         })
                    //         .catch((e) => {
                    //             this.setState({ loading: false });
                    //             SPopup.alert("Error en al editar el dato " + e.error_dato);
                    //         });
                    // } else {
                    //     Model.usuario.Action.registro({
                    //         key_usuario: Model.usuario.Action.getKey(),
                    //         data: values,
                    //         key_rol: this.key_rol,
                    //     })
                    //         .then((resp) => {
                    //             this.setState({ loading: false });
                    //             console.log("regiistrado ", values)
                    //             SNavigation.goBack();
                    //             // SNavigation.navigate("/registro")
                    //         })
                    //         .catch((e) => {
                    //             this.setState({ loading: false });
                    //             SPopup.alert("Error en el registro, " + e.error_dato);
                    //         });
                    // }
                }}
            />
        );
    }
    render_mapa() {
        var usr = {};
        if (this.key) {
            usr = Model.usuario.Action.getByKey(this.key);
            if (!usr) return <SLoad />;


        }

        if (!this.state.ready) {
            this.setState({
                region: {
                    latitude: parseFloat(usr.Latitude) ? parseFloat(usr.Latitude) : this.state.region.latitude,
                    longitude: parseFloat(usr.Longitude) ? parseFloat(usr.Longitude) : this.state.region.longitude,
                },
                ready: true,
            })
            return <SLoad />
        }

        // this.state.region.latitude = parseFloat(usr.Latitude)??this.state.region.latitude;
        // this.state.region.longitude = parseFloat(usr.Longitude)??this.state.region.longitude;
        // this.setState({
        //   region: {
        //     latitude:parseFloat(usr.Latitude),
        //     longitude: parseFloat(usr.Longitude),
        //   }
        // });

        // let coordenadas = { latitude: this.state.region.latitude??parseFloat(usr.Latitude), longitude: this.state.region.longitude??parseFloat(usr.Longitude) };

        // console.log("muestro virgen ", coordenadas)

        return <>
            <SView col={"xs-12"} flex border="black" >
                <SMapView
                    initialRegion={{
                        latitude: this.state.region.latitude,
                        longitude: this.state.region.longitude,
                        latitudeDelta: 0.0222,
                        longitudeDelta: 0.0221,
                    }}
                    options={{
                        fullscreenControl: false,
                        zoomControl: false,
                        gestureHandling: "none",
                        scrollwheel: false,
                    }}
                    preventCenter
                    onPress={(evt) => {

                        // console.log("coñasoooooooooooooo ", evt)
                        this.setState({ region: evt.coordinate })
                    }}
                >
                    <></>
                    <SMapView.SMarker latitude={this.state.region.latitude} longitude={this.state.region.longitude}  >
                    </SMapView.SMarker>
                </SMapView>
            </SView>
            <SHr></SHr>
        </>
    }







    render() {
        return (
            <>
                <SPage title={"Mapa"}>
                    <SView col={"xs-12"} center height>
                        <SView col={"xs-12"} center height>
                            {/* <SView col={"xs-11 sm-10 md-8  "} center height> */}
                            {/* {this.render_foto()} */}
                            {/* <SHr height={20}></SHr> */}
                            {/* {this.render_form()} */}
                            {this.render_mapa()}
                            <SHr height={20}></SHr>


                            {/* <Botoncito  onPress={() => { SNavigation.navigate("Mapa", { key: this.key }) }} >EDITAR UBICACIÓN</Botoncito> */}


                            <Botoncito backgroundColor="#D70000" style={{ borderColor: "#BF0707" }} center col={"xs-5.8"}  loading={this.state.loading} onPress={() => {

                                console.log("pinta ", this.state.region)
                                Model.usuario.Action.editar({
                                    key_usuario: Model.usuario.Action.getKey(),
                                    data: {

                                        key: this.key,
                                        Latitude: this.state.region.latitude,
                                        Longitude: this.state.region.longitude,
                                        estado: 1
                                    },
                                })

                                SNavigation.goBack();
                             }}> ELEGIR ESTA UBICACIÓN GPS </Botoncito>
                            <SHr height={20}></SHr>

                        </SView>
                    </SView>
                </SPage>

            </>
        );
    }
}
const initStates = (state) => {
    return { state };
};
export default connect(initStates)(Mapa);
