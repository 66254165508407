import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SNavigation, SPage, SPopup, SText, SView } from 'servisofts-component';
import { SSRolesPermisosValidate } from '../../SSRolesPermisos';
// import { SSRolesPermisosValidate } from '../../../SSRolesPermisos';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  getItemFecha({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }
    return this.getItem({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          // alert(JSON.stringify(evt));
          SNavigation.navigate(url, evt);
        });
      }
    })
  }
  getItemFechaSucursal({ title, icon, url, onPress, permiso }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "FinanzaPage", permiso: permiso })) {
        return null;
      }
    }
    return this.getItem({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          // alert(JSON.stringify(evt));
          SNavigation.navigate(url, evt);
        });
      }
    })
  }
  getItem({ title, icon, url, onPress, permiso, params }) {
    if (permiso) {
      if (!SSRolesPermisosValidate({ page: "activos", permiso: permiso })) {
        return null;
      }
    }

    return <SView col={"xs-3 sm-2.5 md-2 lg-1.5 xl-1.3"} colSquare style={{
      padding: 4,
    }}
    >
      <SView col={"xs-12"} height center>
        <SView col={"xs-7"} colSquare onPress={() => {
          if (onPress) {
            onPress();
            return;
          }
          SNavigation.navigate(url, params);
        }} >
          <SIcon name={icon} />
        </SView>
        <SHr />
        <SView center height={16}>
          <SText center fontSize={12}>{title}</SText>
        </SView>
      </SView>
    </SView>
  }
  getLista() {
    return <>
      {/* SNavigation.navigate("asistencias_usuario", { key: this.key }) */}

      {this.getItem({ title: "Asistencias activos ", icon: "AsistenciaUsuarios", url: "AsistenciasSucursal", params: { fechaInicio: "2023", fechaFin: "2023" } })}
      {/* {this.getItem({ title: "Asistencias activos ", icon: "AsistenciaUsuarios", url: "ReporteAsistencia", params: { fecha_inicio: new SDate().toString('yyyy-MM-dd'), fecha_fin: new SDate().toString('yyyy-MM-dd') } })} */}
      {this.getItem({ title: "Clientes activos", icon: "ClienteActivo", url: "ClientesPage", params: { nobecados: true } })}
      {/* {this.getItem({ title: "Clientes", icon: "Ajustes", url: "ClientesPage?nobecados=true" })} */}
      {this.getItem({ title: "Becados activos", icon: "ClienteBecado", url: "ClientesPage", params: { becados: true } })}
      {/* {this.getItemFecha({ title: "Ingresos", icon: "Ingreso", url: "Ingreso" })} */}
    </>
  }
  render() {
    return (
      <SPage title={"Usuarios Activos"}>
        <SView col={"xs-12"} row center>
          {this.getLista()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);