import { Component } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { SImage, SLoad, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Paquete from '..';
type Props = {
  key_paquete: string,
  onPress: Function,
}
class PaqueteItem extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  validarMolinete(molinete) {
    return <View
      style={{
        width: 100,
        borderRadius: 4,
        borderWidth: 0.1,
        padding: 1,
        borderColor: molinete ? STheme.color.text : "red",
      }}>
      <SText fontSize={10} center color={molinete ? STheme.color.text : "red"}>  {molinete == true ? "CON MOLINETE " : "SIN MOLINETE"}</SText>
    </View>
  }

  validarMolinete(molinete) {
    return <View
      style={{
        width: 100,
        borderRadius: 4,
        borderWidth: 0.1,
        padding: 1,
        borderColor: molinete ? STheme.color.text : "red",
      }}>
      <SText fontSize={10} center color={molinete ? STheme.color.text : "red"}>  {molinete == true ? "CON MOLINETE " : "SIN MOLINETE"}</SText>
    </View>
  }

  validarMotivo(molinete) {
    if (!molinete) return;
    return <>
      <View style={{width: 10}}></View>
      <View
        style={{
          width: 100,
          borderRadius: 4,
          borderWidth: 0.1,
          padding: 1,
          borderColor: molinete ? "yellow" : "red",
        }}>
        <SText fontSize={10} center color={molinete ? "yellow" : "red"}>  {molinete == true ? "MOTIVO " : "SIN MOTIVO"}</SText>
      </View>
    </>
  }


  render() {
    if (!this.props.key_paquete) return <View />;
    var obj = Paquete.Actions.getByKey(this.props.key_paquete, this.props);
    if (!obj) return <SLoad />

    // console.log("alvaro ",obj)

    // if(obj.estado_app == 1) return null;

    return (
      <TouchableOpacity style={{
        width: "90%",
        maxWidth: 600,
        height: 50,
        margin: 4,
        borderRadius: 10,
        backgroundColor: STheme.color.card
      }} onPress={() => {
        if (this.props.onPress) this.props.onPress(obj);
        // if (this.onSelect) {
        //     this.onSelect(obj);
        //     this.props.navigation.goBack();
        //     return;
        // }
        // this.props.navigation.navigate("SucursalRegistroPage", {
        //     key: key
        // })
      }}>
        <View style={{
          flex: 1,
          justifyContent: "center"
        }}>
          <View style={{
            flexDirection: "row",
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}>
            <View style={{
              width: 40,
              height: 40,
              marginLeft: 4,
              marginRight: 8,
              justifyContent: "center",
              alignItems: "center",
              // padding: 1,
              // borderRadius: 200,
              backgroundColor: STheme.color.card,
              borderRadius: 4,
              overflow: "hidden"
            }}>
              <SImage src={SSocket.api.root + "paquete/" + this.props.key_paquete + "?date=" + new Date().getTime()} />
            </View>
            <View style={{
              flex: 1,
              justifyContent: "center"
            }}>
              <Text style={{
                fontSize: 14,
                fontWeight: "bold",
                color: STheme.color.text,
                textTransform: "capitalize",
                textDecorationLine: (obj.estado != 1 ? "line-through" : "none")
              }}>{obj["descripcion"] + " "}</Text>
              <SView row  >


                {this.validarMolinete(obj.is_molinete)}

                {this.validarMotivo(obj.requiere_motivo)}
              </SView>
            </View>
            <SView col={"xs-2"} >
              <SText fontSize={8}><SText fontSize={12}>{obj.participantes}</SText> personas</SText>
              <SText fontSize={8}><SText fontSize={12}>{obj.dias}</SText> dias</SText>
              {/* <SText fontSize={8}><SText fontSize={12}></SText> {obj.is_molinete == true ? "mnolinete " : "SIN MOLINETE"}</SText> */}
            </SView>

            {/* <SView col={"xs-1"} height={18} border={obj.estado_app == 1 ? "yellow" : STheme.color.card} center
                            style={{ borderRadius: 8, padding: 5, fontSize: 2 }}>
                            <SText color={obj.estado_app == 1 ? "yellow" : STheme.color.card} fontSize={12} >{obj.estado_app == 1 ? "APP" : "desactivo"}</SText>
                        </SView> */}



            <SView col={"xs-3"} center>
              <SText bold center>Bs. {(obj.precio ? obj.precio : 0).toLocaleString('en-IN')}</SText>
            </SView>
          </View>
        </View>
      </TouchableOpacity >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(PaqueteItem);