import React, { Component } from 'react';
import { TouchableOpacity, View, Text } from "react-native";
import { SIcon } from 'servisofts-component';
const FloatButtomSyncUsuario = (props) => {
    if (props.esconder) {
        return <View />
    }
    return (
        <TouchableOpacity onPress={() => { props.onPress(); }}
            style={{ position: "absolute", bottom: 0, right: 10, width: 50, ...props.style }}>
            <SIcon name={"SyncUsuario"} />
        </TouchableOpacity >
    )
}
export default FloatButtomSyncUsuario;