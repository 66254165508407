import { Component } from "react";
import { connect } from "react-redux";
import SSocket from 'servisofts-socket';
import Model from "../Model";
import { SButtom, SDate, SExcelReader, SHr, SIcon, SInput, SLoad, SNavigation, SPage, SPopup, STable2, SText, STheme, SUuid, SView } from "servisofts-component";
import Paquete from "./Paquete";

let pajarito = '0b29859d-1c59-40f8-a447-c4786af0cef9';

let captura_fecha_inicio = new SDate().setHours(0, 1, 1, 1).toString('yyyy-MM-dd hh:mm:ss');
let captura_fecha_fin = new SDate().addDay(2).setHours(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss');

class testAlvaro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: DataTest
    };
    }

  render() {
    return (
      <SPage title={'Migrador de ventas'} disableScroll center>
        <SText>proximam....</SText>
        <SLoad type='window' hidden={!this.state.loading} />
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(testAlvaro);
