import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SLoad, SNavigation, SText, SView } from 'servisofts-component';
import Model from '../../../../Model';
import Sucursal from '../../../Sucursal';
import sucursal_usuario from '../../../sucursal_usuario';
import Usuario from '../../../Usuario';

class ClientesActivos extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }
  getContent() {
    var clientesActivos = Usuario.Actions.getAllClientesActivos(this.props);

    var clientesRol = Model.usuarioRol.Action.getAllByKeyRol("d16d800e-5b8d-48ae-8fcb-99392abdf61f")
    var arr = sucursal_usuario.Actions.getActive(this.props);
    if (!clientesActivos) return <SLoad />
    if (!clientesRol) return <SLoad />
    if (!arr) return <SLoad />

    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return <SLoad />;
    // Filtrar las sucursales activas que tengan usuarios activos asociados
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);

    var now = new SDate();
    var total = Object.values(clientesActivos).filter(i =>
      (new SDate(i.fecha_inicio, "yyyy-MM-dd").isBefore(now) && new SDate(i.fecha_fin, "yyyy-MM-dd").isAfter(now)));
    var clientesActivosNoBecados = Object.values(clientesActivos).filter(i => (

      sucursal_usuario.Actions.isActive(i.caja.key_sucursal, this.props) &&
      parseFloat(i.paquete.precio) > 0) &&
      (new SDate(i.fecha_inicio, "yyyy-MM-dd").isBefore(now) && new SDate(i.fecha_fin, "yyyy-MM-dd").isAfter(now))
      && arr_sucursal_key.includes(i.caja.key_sucursal)
    );

    var becados = Object.values(clientesActivos).filter(o =>
      parseFloat(o.paquete.precio) == 0 &&
      (new SDate(o.fecha_inicio, "yyyy-MM-dd").isBefore(now) &&
        new SDate(o.fecha_fin, "yyyy-MM-dd").isAfter(now))
      && arr_sucursal_key.includes(o.caja.key_sucursal)
    )

    return <SView center height col={"xs-12"}>
      <SView col={"xs-12"} center >
        <SText fontSize={10}>{`Clientes registrados ( ${Object.keys(clientesRol).length} )`}</SText>
      </SView>
      <SHr height={4} />
      <SView col={"xs-12"} row>
        <SView col={"xs-3"} height={30} onPress={() => {
          SNavigation.navigate("ClientesPage", { nobecados: true });
        }}>
          <SIcon name={"Usuarios_cliente"} />
        </SView>
        <SView col={"xs-4"} center>
          <SText fontSize={12}>{`Activos`}</SText>
        </SView>
        <SView col={"xs-5"} center>
          <SText bold fontSize={12}>{` ${clientesActivosNoBecados.length} `}</SText>
        </SView>
      </SView>
      <SHr height={4} />
      <SView col={"xs-12"} row>
        <SView col={"xs-3"} height={30} onPress={() => {
          SNavigation.navigate("ClientesPage", { becados: true });
        }}>
          <SIcon name={"Usuarios_proveedor"} />
        </SView>
        <SView col={"xs-4"} center>
          <SText fontSize={12}>{`Becados`}</SText>
        </SView>
        <SView col={"xs-5"} center>
          <SText bold fontSize={12}>{` ${becados.length} `}</SText>
        </SView>
      </SView>
    </SView >
  }
  render() {
    return (
      <SView col={"xs-11 sm-6 md-4 xl-3"} height={100} style={{
        padding: 8,
      }}>
        <SView height card center>
          {this.getContent()}
        </SView>
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ClientesActivos);