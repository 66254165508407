import { Component } from 'react';
import { SImage, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getNoSelect() {
    if (!this.props.onSelect) return null;
    if (this.props.select) return null;
    return <SView col={"xs-12"} height backgroundColor={"#000000dd"} style={{
      position: "absolute",
    }} center onPress={() => {
      this.props.onSelect(this.props.obj);
    }}>
      <SText fontSize={18} bold>Activar</SText>
    </SView>
  }
  render() {
    return (
      <SView width={160} height={140} style={{
        borderRadius: 4,
        borderWidth: this.props.select ? 1 : 0.5,
        borderColor: this.props.select ? "green" : STheme.color.card,
        margin: 8,


      }} center backgroundColor={STheme.color.card} onPress={() => {
        this.props.deSelect(this.props.obj);
      }}>
        <SView col={"xs-12"} height={80}  > <SImage src={SSocket.api.root + "sucursal/" + this.props.obj.key} /></SView>
        <SView col={"xs-12"} flex center>
          <SText fontSize={16} bold>{this.props.obj.descripcion}</SText>
        </SView>
        {this.getNoSelect()}
      </SView >
    );
  }
}

export default (Item);