import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SExcelReader, SHr, SIcon, SLoad, SPage, STable2, SText, SView } from "servisofts-component";
import Model from "../../Model";

let arr = [];
const accumulator = {};
const stockSum = {};
var arr_usuarios_palmas = [
  {
    "CI": "12663739",
    "Nombres": "AARON FERNANDO",
    "Apellidos": "FERNANDO CALLE",
    "Fecha nacimiento": "2009-01-06",
    "Telefono": "72601278",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8945533",
    "Nombres": "ABRIL CAROLINA",
    "Apellidos": "CAROLINA MARTINEZ",
    "Fecha nacimiento": "2003-01-17",
    "Telefono": "60800593",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8235164",
    "Nombres": "ADILSON",
    "Apellidos": "SIERRA MONTAÑO",
    "Fecha nacimiento": "1994-12-24",
    "Telefono": "73988958",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5975645",
    "Nombres": "AGUSTIN FABIAN",
    "Apellidos": "FABIAN SALINAS",
    "Fecha nacimiento": "2018-04-21",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12726982",
    "Nombres": "AGUSTINA",
    "Apellidos": "ROJAS INCHAUSTI",
    "Fecha nacimiento": "2012-02-02",
    "Telefono": "75013850",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11301099",
    "Nombres": "ALAN HUMBERTO",
    "Apellidos": "HUMBERTO WAYAR",
    "Fecha nacimiento": "2005-05-12",
    "Telefono": "77046072",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3853956",
    "Nombres": "ALDO MANFREDO",
    "Apellidos": "MANFREDO WAYAR",
    "Fecha nacimiento": "1974-01-04",
    "Telefono": "78005760",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6310354",
    "Nombres": "ALEJANDRA",
    "Apellidos": "LAGUNA JUSTINIANO",
    "Fecha nacimiento": "1985-05-16",
    "Telefono": "76356623",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4655870",
    "Nombres": "ALEJANDRO",
    "Apellidos": "BORDA GANDARILLAS",
    "Fecha nacimiento": "1980-08-29",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9495034",
    "Nombres": "ALEJANDRO",
    "Apellidos": "CARDOZO OÑA",
    "Fecha nacimiento": "1993-07-25",
    "Telefono": "65334882",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4700915",
    "Nombres": "ALEJANDRO",
    "Apellidos": "ZEGARRA PINEDO",
    "Fecha nacimiento": "1995-04-07",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "0",
    "Nombres": "ALEJANDRO EMANUEL",
    "Apellidos": "EMANUEL ALDERETE",
    "Fecha nacimiento": "2006-05-02",
    "Telefono": "65025764",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4604120",
    "Nombres": "ALICIA",
    "Apellidos": "PEDRAZA JUSTINIANO",
    "Fecha nacimiento": "1977-01-13",
    "Telefono": "76348721",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12357610",
    "Nombres": "AMARA",
    "Apellidos": "WACHENFELD GUTIERREZ",
    "Fecha nacimiento": "2007-07-22",
    "Telefono": "77302972",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2461152",
    "Nombres": "AMINA DENISSE",
    "Apellidos": "DENISSE EID",
    "Fecha nacimiento": "1977-07-23",
    "Telefono": "78000052",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6308793",
    "Nombres": "ANA",
    "Apellidos": "ARAUZ",
    "Fecha nacimiento": "1993-09-23",
    "Telefono": "74177779",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12758365",
    "Nombres": "ANA",
    "Apellidos": "CAROLA MELGAREJO",
    "Fecha nacimiento": "2000-04-10",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6223425",
    "Nombres": "ANA",
    "Apellidos": "LAURA ROCA",
    "Fecha nacimiento": "1986-01-06",
    "Telefono": "70929709",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8981217",
    "Nombres": "ANA CECILIA",
    "Apellidos": "CECILIA LANGUIDEY",
    "Fecha nacimiento": "1993-03-16",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6301234",
    "Nombres": "ANA ISABEL",
    "Apellidos": "ISABEL WARNES",
    "Fecha nacimiento": "2002-04-24",
    "Telefono": "76636674",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9651881",
    "Nombres": "ANAHI ISABEL",
    "Apellidos": "ISABEL INTURIAS",
    "Fecha nacimiento": "1985-06-09",
    "Telefono": "77374606",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2856431",
    "Nombres": "ANAHY",
    "Apellidos": "AREVALO CASTRO",
    "Fecha nacimiento": "2000-06-27",
    "Telefono": "77654885",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11393274",
    "Nombres": "ANDRE",
    "Apellidos": "OILO GAIA",
    "Fecha nacimiento": "2008-03-12",
    "Telefono": "76003591",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4851495",
    "Nombres": "ANDREA",
    "Apellidos": "MUNOZ",
    "Fecha nacimiento": "1978-03-18",
    "Telefono": "75304840",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9641506",
    "Nombres": "ANDREA",
    "Apellidos": "SARMIENTO ARREDONDO",
    "Fecha nacimiento": "2005-01-08",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1034304",
    "Nombres": "ANDREA  VIVIANA",
    "Apellidos": "VIVIANA ANGULO",
    "Fecha nacimiento": "1994-06-28",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6257169",
    "Nombres": "ANDREA BELEN",
    "Apellidos": "BELEN NAVARRO",
    "Fecha nacimiento": "1988-07-06",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2625258",
    "Nombres": "ANDRES",
    "Apellidos": "BROCKMANN",
    "Fecha nacimiento": "1979-03-22",
    "Telefono": "69076996",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11384527",
    "Nombres": "ANDRES",
    "Apellidos": "PARADA PAZ",
    "Fecha nacimiento": "2009-01-29",
    "Telefono": "75396116",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11310306",
    "Nombres": "ANDY GIBRAN",
    "Apellidos": "GIBRAN TORRICO",
    "Fecha nacimiento": "2005-07-27",
    "Telefono": "79925841",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "16893524",
    "Nombres": "ANGEL ARIEL",
    "Apellidos": "ARIEL WARNES",
    "Fecha nacimiento": "2017-02-08",
    "Telefono": "70925340",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "854691",
    "Nombres": "ANGEL JOSE",
    "Apellidos": "JOSE VIBEROS",
    "Fecha nacimiento": "1985-02-08",
    "Telefono": "78003183",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14100378",
    "Nombres": "ANGIE ALEXANDRA",
    "Apellidos": "PARRA CADIMA",
    "Fecha nacimiento": "1999-01-03",
    "Telefono": "75340037",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13541761",
    "Nombres": "ANTONELLA",
    "Apellidos": "TORRELIO ROCA",
    "Fecha nacimiento": "2008-05-03",
    "Telefono": "62204646",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11335747",
    "Nombres": "ARACELY",
    "Apellidos": "MUÑOZ DURAN",
    "Fecha nacimiento": "2003-04-05",
    "Telefono": "67865344",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8966620",
    "Nombres": "ARACELY",
    "Apellidos": "VALVERDE FLORES",
    "Fecha nacimiento": "2005-05-28",
    "Telefono": "75683208",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9627828",
    "Nombres": "ARIANNE",
    "Apellidos": "MONTENEGRO",
    "Fecha nacimiento": "2006-07-12",
    "Telefono": "77608685",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3949911",
    "Nombres": "ARIANNE",
    "Apellidos": "SOLJANCIC NALLAR",
    "Fecha nacimiento": "1989-04-29",
    "Telefono": "76043403",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13400709",
    "Nombres": "ARTURO",
    "Apellidos": "PESSOA CARBALLO",
    "Fecha nacimiento": "2012-09-21",
    "Telefono": "72639422",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14971296",
    "Nombres": "AYLIN JESSICA",
    "Apellidos": "JESSICA GUAMAN",
    "Fecha nacimiento": "1999-09-20",
    "Telefono": "76097658",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2981818",
    "Nombres": "BEATRIZ",
    "Apellidos": "PAZ",
    "Fecha nacimiento": "1977-01-02",
    "Telefono": "69052828",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9315225",
    "Nombres": "BERNARDO JOSE",
    "Apellidos": "JOSE GARCÍA",
    "Fecha nacimiento": "2008-05-05",
    "Telefono": "77412298",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13486471",
    "Nombres": "BIANCA IVIS",
    "Apellidos": "IVIS ARGANDOÑA",
    "Fecha nacimiento": "2008-06-12",
    "Telefono": "78447027",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7822053",
    "Nombres": "BLANCA PAOLA",
    "Apellidos": "PAOLA ZURITA",
    "Fecha nacimiento": "1986-06-29",
    "Telefono": "79925841",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "848291",
    "Nombres": "BRAHIM BORIS",
    "Apellidos": "BORIS NALLAR",
    "Fecha nacimiento": "2007-12-05",
    "Telefono": "76221875",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14941312",
    "Nombres": "BRUNO",
    "Apellidos": "GARCIA",
    "Fecha nacimiento": "2011-04-06",
    "Telefono": "78100196",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5865717",
    "Nombres": "BRUNO ALEJANDRO",
    "Apellidos": "ALEJANDRO VARGAS",
    "Fecha nacimiento": "1987-09-23",
    "Telefono": "78460808",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9315227",
    "Nombres": "BRUNO ANTONIO",
    "Apellidos": "ANTONIO GALLLARDO",
    "Fecha nacimiento": "2008-05-05",
    "Telefono": "76039214",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4629110",
    "Nombres": "CANDYS",
    "Apellidos": "DORADO RODRIGUEZ",
    "Fecha nacimiento": "1978-10-04",
    "Telefono": "70039270",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8983965",
    "Nombres": "CARLA",
    "Apellidos": "MUÑOZ",
    "Fecha nacimiento": "1993-10-28",
    "Telefono": "76001632",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5314764",
    "Nombres": "CARLA",
    "Apellidos": "BELTRAN MADARIAGA",
    "Fecha nacimiento": "1985-01-05",
    "Telefono": "69005085",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8976886",
    "Nombres": "CARLA",
    "Apellidos": "JIMENA VELASQUEZ",
    "Fecha nacimiento": "1998-02-27",
    "Telefono": "60818562",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5399574",
    "Nombres": "CARLA ALEJANDRA",
    "Apellidos": "ALEJANDRA LAGUNA",
    "Fecha nacimiento": "1984-10-16",
    "Telefono": "70017667",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6296097",
    "Nombres": "CARLA ALEJANDRA",
    "Apellidos": "ALEJANDRA TERAN",
    "Fecha nacimiento": "1986-01-18",
    "Telefono": "70929938",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5705537",
    "Nombres": "CARLA BELEN",
    "Apellidos": "BELEN TORRICO",
    "Fecha nacimiento": "1995-11-29",
    "Telefono": "76904825",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7499379",
    "Nombres": "CARLA LORENA",
    "Apellidos": "LORENA LLANOS",
    "Fecha nacimiento": "1994-06-28",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4594274",
    "Nombres": "CARLOS",
    "Apellidos": "BENINGNO SAAVEDRA",
    "Fecha nacimiento": "1979-10-21",
    "Telefono": "76684407",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2399099",
    "Nombres": "CARLOS EDUARDO",
    "Apellidos": "EDUARDO BROCKMANN",
    "Fecha nacimiento": "1966-01-15",
    "Telefono": "78290732",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7692020",
    "Nombres": "CARLOS GABRIEL",
    "Apellidos": "GABRIEL VAQUERO",
    "Fecha nacimiento": "1987-11-04",
    "Telefono": "79931293",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9975761",
    "Nombres": "CARLOS RODRIGO",
    "Apellidos": "RODRIGO MEJIA",
    "Fecha nacimiento": "2001-06-16",
    "Telefono": "73729628",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7708124",
    "Nombres": "CARMEN VALENTINA",
    "Apellidos": "VALENTINA PIANESI",
    "Fecha nacimiento": "2002-07-23",
    "Telefono": "75571110",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3937953",
    "Nombres": "CAROLINA",
    "Apellidos": "URGEL ROMAN",
    "Fecha nacimiento": "1979-04-23",
    "Telefono": "70009010",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7700844",
    "Nombres": "CAROLINA",
    "Apellidos": "ROMERO CRUZ",
    "Fecha nacimiento": "1991-06-04",
    "Telefono": "77000313",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "565879",
    "Nombres": "CATALINA",
    "Apellidos": "PEROV",
    "Fecha nacimiento": "2014-04-05",
    "Telefono": "77128280",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14971297",
    "Nombres": "CELESTE CAMILA",
    "Apellidos": "CAMILA GUAMAN",
    "Fecha nacimiento": "2004-03-30",
    "Telefono": "70059922",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7685251",
    "Nombres": "CHRISTOPHER",
    "Apellidos": "COCIANNI",
    "Fecha nacimiento": "1990-12-17",
    "Telefono": "70833723",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13305804",
    "Nombres": "CINDY MAYTHE",
    "Apellidos": "MAYTHE VELASCO",
    "Fecha nacimiento": "1999-07-25",
    "Telefono": "75596374",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "252648",
    "Nombres": "CLARA",
    "Apellidos": "FERNANDEZ",
    "Fecha nacimiento": "1989-10-12",
    "Telefono": "77128280",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3933005",
    "Nombres": "CLAUDIA",
    "Apellidos": "RAMIREZ BEDIA",
    "Fecha nacimiento": "1979-12-18",
    "Telefono": "76025934",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6653530",
    "Nombres": "CLAUDIA",
    "Apellidos": "RAMIREZ CUELLAR",
    "Fecha nacimiento": "2001-05-08",
    "Telefono": "73865151",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7846597",
    "Nombres": "CLAUDIA PATRICIA",
    "Apellidos": "PATRICIA GOMEZ",
    "Fecha nacimiento": "1988-09-10",
    "Telefono": "60000797",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13365562",
    "Nombres": "CRISTIAN",
    "Apellidos": "HURTADO RUIZ",
    "Fecha nacimiento": "2001-07-15",
    "Telefono": "62216549",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6374694",
    "Nombres": "DANELLY",
    "Apellidos": "PEREIRA TORREZ",
    "Fecha nacimiento": "1998-09-01",
    "Telefono": "79900911",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8995377",
    "Nombres": "DANESA",
    "Apellidos": "CESPEDES CHAVEZ",
    "Fecha nacimiento": "1990-04-29",
    "Telefono": "77684858",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4686194",
    "Nombres": "DANIA",
    "Apellidos": "ROMERO",
    "Fecha nacimiento": "1986-01-07",
    "Telefono": "76650418",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8129338",
    "Nombres": "DANIEL",
    "Apellidos": "ARANDIA ORTIZ",
    "Fecha nacimiento": "2000-02-04",
    "Telefono": "76620009",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13985425",
    "Nombres": "DANIEL",
    "Apellidos": "RIBERA RIVERA",
    "Fecha nacimiento": "2013-09-06",
    "Telefono": "76676479",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8254000",
    "Nombres": "DANIELA",
    "Apellidos": "RICO",
    "Fecha nacimiento": "1992-07-22",
    "Telefono": "77004216",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6305825",
    "Nombres": "DANIELA",
    "Apellidos": "REJAS LEON",
    "Fecha nacimiento": "1998-10-28",
    "Telefono": "77335231",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11387322",
    "Nombres": "DANIELA",
    "Apellidos": "RICO VITA",
    "Fecha nacimiento": "2002-03-03",
    "Telefono": "76653783",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "46482823",
    "Nombres": "DANIELA ALESSANDRA",
    "Apellidos": "ALESSANDRA VALVIDIA",
    "Fecha nacimiento": "2000-04-07",
    "Telefono": "67704203",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4648283",
    "Nombres": "DANIELA KARINA",
    "Apellidos": "KARINA ZEBALLOS",
    "Fecha nacimiento": "1985-08-20",
    "Telefono": "67704203",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11310301",
    "Nombres": "DANNA VIVIANA",
    "Apellidos": "VIVIANA TORRICO",
    "Fecha nacimiento": "2009-11-22",
    "Telefono": "79925841",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10269214",
    "Nombres": "DANNY",
    "Apellidos": "HERNIRY RUIZ",
    "Fecha nacimiento": "1988-08-07",
    "Telefono": "74041425",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9815615",
    "Nombres": "DAVID",
    "Apellidos": "SAUCEDO PACHECO",
    "Fecha nacimiento": "1990-01-16",
    "Telefono": "78002755",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13485587",
    "Nombres": "DAVINIA",
    "Apellidos": "GONZALES GUTIERREZ",
    "Fecha nacimiento": "2008-05-10",
    "Telefono": "78079319",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9590049",
    "Nombres": "DAYRA ALEJANDRA",
    "Apellidos": "ALEJANDRA VEDIA",
    "Fecha nacimiento": "2002-07-25",
    "Telefono": "78410534",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5336072",
    "Nombres": "DENIS FERNANDO",
    "Apellidos": "FERNANDO BRUNO",
    "Fecha nacimiento": "1988-07-01",
    "Telefono": "69002898",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7749515",
    "Nombres": "DIEGO",
    "Apellidos": "VACA JORDAN",
    "Fecha nacimiento": "1996-06-17",
    "Telefono": "72615803",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7713168",
    "Nombres": "DIEGO",
    "Apellidos": "VILLAGRAN GOMEZ",
    "Fecha nacimiento": "2001-01-17",
    "Telefono": "75522888",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7823352",
    "Nombres": "DIEGO ILICH",
    "Apellidos": "ILICH CHOQUE",
    "Fecha nacimiento": "1988-03-16",
    "Telefono": "65857293",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9812013",
    "Nombres": "DITER JHOEL",
    "Apellidos": "JHOEL PEÑA",
    "Fecha nacimiento": "2003-08-22",
    "Telefono": "78503831",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4851495019",
    "Nombres": "DOMINIQUE",
    "Apellidos": "ARRUE",
    "Fecha nacimiento": "2009-05-17",
    "Telefono": "75304840",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1989238",
    "Nombres": "DORA",
    "Apellidos": "SUAREZ BLACUTT",
    "Fecha nacimiento": "1955-03-05",
    "Telefono": "77379000",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3180993",
    "Nombres": "DORYS VARGAS",
    "Apellidos": "VARGAS DE",
    "Fecha nacimiento": "1966-04-22",
    "Telefono": "73141173",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6162727",
    "Nombres": "DRINA PAMELA",
    "Apellidos": "EGGY ECHAZU SUAZNABAR",
    "Fecha nacimiento": "1983-06-10",
    "Telefono": "76913348",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5300963",
    "Nombres": "EDDY RODRIGO",
    "Apellidos": "RODRIGO BALDERRAMA",
    "Fecha nacimiento": "1996-11-21",
    "Telefono": "79026286",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "16621026",
    "Nombres": "EDMUNDO JOSE",
    "Apellidos": "JOSE BONILLA",
    "Fecha nacimiento": "2016-07-19",
    "Telefono": "79008016",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3288213",
    "Nombres": "EDSON",
    "Apellidos": "VALVERDE PARDO",
    "Fecha nacimiento": "1971-03-09",
    "Telefono": "77017109",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13211185",
    "Nombres": "EDUARDO",
    "Apellidos": "BARBERY SANTISTEVAN",
    "Fecha nacimiento": "2013-04-24",
    "Telefono": "71639110",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "54218112",
    "Nombres": "EDUARDO",
    "Apellidos": "GARCIA SALAZAR",
    "Fecha nacimiento": "1988-01-29",
    "Telefono": "70059094",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6338671",
    "Nombres": "ELIANA",
    "Apellidos": "PEREIRA QUIROZ",
    "Fecha nacimiento": "1988-08-03",
    "Telefono": "79867480",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6246981",
    "Nombres": "ELIAS",
    "Apellidos": "CUELLAR CABALLERO",
    "Fecha nacimiento": "1990-01-21",
    "Telefono": "78457811",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4408426",
    "Nombres": "ELMER",
    "Apellidos": "GUAMAN ANDIA",
    "Fecha nacimiento": "1979-05-04",
    "Telefono": "74656060",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "15807763",
    "Nombres": "EMILIANO",
    "Apellidos": "VACA ARDAYA",
    "Fecha nacimiento": "2017-07-25",
    "Telefono": "72680043",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8862821",
    "Nombres": "ERICKA YANINNE",
    "Apellidos": "YANINNE RÍOS",
    "Fecha nacimiento": "1989-11-24",
    "Telefono": "78402160",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8794561",
    "Nombres": "ERIKA",
    "Apellidos": "JORDAN ECHEVERRIA",
    "Fecha nacimiento": "1985-12-12",
    "Telefono": "78945612",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4286498",
    "Nombres": "ESEDIN SADDAM",
    "Apellidos": "SADDAM MUSTAFA",
    "Fecha nacimiento": "1990-03-31",
    "Telefono": "78777994",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14504232",
    "Nombres": "FABIANA",
    "Apellidos": "ROJAS MERCADO",
    "Fecha nacimiento": "2008-10-29",
    "Telefono": "71626345",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9633950",
    "Nombres": "FABIANA ANAHI",
    "Apellidos": "FUENTES WARNES",
    "Fecha nacimiento": "1995-12-15",
    "Telefono": "71624000",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8181502",
    "Nombres": "FABIOLA",
    "Apellidos": "CABRAL VALVERDE",
    "Fecha nacimiento": "1991-02-09",
    "Telefono": "70254789",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "64629",
    "Nombres": "FABIOLA",
    "Apellidos": "LOPEZ DONOSO",
    "Fecha nacimiento": "1965-01-09",
    "Telefono": "72165003",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6296230",
    "Nombres": "FABIOLA",
    "Apellidos": "MAMANI MONTAÑO",
    "Fecha nacimiento": "1985-10-29",
    "Telefono": "69066011",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13486169",
    "Nombres": "FABRICIO DANIEL",
    "Apellidos": "DANIEL ARGANDOÑA",
    "Fecha nacimiento": "2008-12-06",
    "Telefono": "78446946",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9874561",
    "Nombres": "FACUNDO EZEQUIEL",
    "Apellidos": "EZEQUIEL CAMPOS",
    "Fecha nacimiento": "2008-05-08",
    "Telefono": "78495303",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5984552",
    "Nombres": "FAHED",
    "Apellidos": "NIÑO",
    "Fecha nacimiento": "1997-02-12",
    "Telefono": "63248422",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4706046",
    "Nombres": "FERNANDO",
    "Apellidos": "ARIAS HURTADO",
    "Fecha nacimiento": "1977-12-03",
    "Telefono": "72906791",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9623423",
    "Nombres": "FERNANDO",
    "Apellidos": "MENACHO CADIMA",
    "Fecha nacimiento": "1993-07-14",
    "Telefono": "70981826",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6533059",
    "Nombres": "FREDDY",
    "Apellidos": "MAMANI VILLCA",
    "Fecha nacimiento": "1985-07-15",
    "Telefono": "73668707",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "78945614",
    "Nombres": "GABRIEL",
    "Apellidos": "CRUZ SALVATIERRA",
    "Fecha nacimiento": "2004-07-27",
    "Telefono": "76336759",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6362570",
    "Nombres": "GABRIELA",
    "Apellidos": "ORTIZ",
    "Fecha nacimiento": "1990-01-02",
    "Telefono": "71054520",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4600323",
    "Nombres": "GABRIELA",
    "Apellidos": "MONTERO VALENCIA",
    "Fecha nacimiento": "1977-08-02",
    "Telefono": "77390948",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6278962",
    "Nombres": "GABRIELA ROXANA",
    "Apellidos": "ROXANA ALVIS",
    "Fecha nacimiento": "1984-07-09",
    "Telefono": "72029408",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5046185",
    "Nombres": "GABRIELA ROXANA",
    "Apellidos": "ROXANA GARCIA",
    "Fecha nacimiento": "1982-09-22",
    "Telefono": "78002749",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12355677",
    "Nombres": "GABRIELLA BELL",
    "Apellidos": "BELL AGUILAR",
    "Fecha nacimiento": "2006-05-03",
    "Telefono": "63475108",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "0",
    "Nombres": "GANDANFARA",
    "Apellidos": "GUASINAVE",
    "Fecha nacimiento": "1986-09-24",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8624912",
    "Nombres": "GAUDENCIA",
    "Apellidos": "PIEDRA ORKO",
    "Fecha nacimiento": "2001-10-25",
    "Telefono": "67950937",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9597923",
    "Nombres": "GEOGINA FERNANDA",
    "Apellidos": "FERNANDA PINTO",
    "Fecha nacimiento": "2003-08-12",
    "Telefono": "77083728",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5864982",
    "Nombres": "GIOVANNA",
    "Apellidos": "CABRERA",
    "Fecha nacimiento": "1987-02-15",
    "Telefono": "77666573",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "172298433-1",
    "Nombres": "GRACE",
    "Apellidos": "ARCOS BRICEÑO",
    "Fecha nacimiento": "1987-10-09",
    "Telefono": "69039963",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14774278",
    "Nombres": "GRECIA NATALIA",
    "Apellidos": "NATALIA PEDRIEL",
    "Fecha nacimiento": "2000-10-23",
    "Telefono": "62160812",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6279763",
    "Nombres": "GRENIA",
    "Apellidos": "CARDONA DOMINGUEZ",
    "Fecha nacimiento": "1990-01-24",
    "Telefono": "76663136",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3270794",
    "Nombres": "GUIDO ARTURO JESUS",
    "Apellidos": "ARACENA AGREDA",
    "Fecha nacimiento": "1985-11-18",
    "Telefono": "73681201",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2349111",
    "Nombres": "GUSTAVO",
    "Apellidos": "PEDRIEL",
    "Fecha nacimiento": "1973-03-29",
    "Telefono": "76078630",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6239904",
    "Nombres": "GUSTAVO",
    "Apellidos": "ECHART LORINI",
    "Fecha nacimiento": "1999-02-08",
    "Telefono": "69027929",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12856135",
    "Nombres": "HEIDI YULIANA",
    "Apellidos": "YULIANA SAUCEDO",
    "Fecha nacimiento": "2008-06-29",
    "Telefono": "77389558",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5401685",
    "Nombres": "HELGA",
    "Apellidos": "NIESSENER",
    "Fecha nacimiento": "1983-11-29",
    "Telefono": "77019557",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8877470",
    "Nombres": "HERNAN GABRIEL",
    "Apellidos": "GABRIEL GUTIERREZ",
    "Fecha nacimiento": "1997-01-15",
    "Telefono": "71062442",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "E- 11263811",
    "Nombres": "HOUSTON JUAN",
    "Apellidos": "ESPINOSA DE LA CRUZ",
    "Fecha nacimiento": "1984-05-07",
    "Telefono": "76631144",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "45205",
    "Nombres": "HUMBERTO",
    "Apellidos": "LILLO GONZALEZ",
    "Fecha nacimiento": "1962-01-03",
    "Telefono": "72168011",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13306706",
    "Nombres": "HUMBERTO",
    "Apellidos": "PINTO GUTIERREZ",
    "Fecha nacimiento": "2010-06-22",
    "Telefono": "70812412",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6889451",
    "Nombres": "IAN",
    "Apellidos": "WIDHIN",
    "Fecha nacimiento": "2009-11-26",
    "Telefono": "75555375",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2045926",
    "Nombres": "INGRID MABEL",
    "Apellidos": "MABEL VARGAS",
    "Fecha nacimiento": "1953-10-14",
    "Telefono": "61005500",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6340899",
    "Nombres": "IRIS",
    "Apellidos": "BELEN GONZALES",
    "Fecha nacimiento": "2002-10-09",
    "Telefono": "61151077",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6376674",
    "Nombres": "IRIS GUADALUPE",
    "Apellidos": "GUADALUPE HOYOS",
    "Fecha nacimiento": "1997-02-01",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3425257",
    "Nombres": "IVETTHE ANTHONETTE",
    "Apellidos": "ANTHONETTE EID",
    "Fecha nacimiento": "1983-03-26",
    "Telefono": "76767676",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4036590",
    "Nombres": "JACQUELINE",
    "Apellidos": "BALDERRAMA FLORES",
    "Fecha nacimiento": "1980-09-12",
    "Telefono": "72251638",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "58875987",
    "Nombres": "JAVIER",
    "Apellidos": "URRUTIA",
    "Fecha nacimiento": "1989-12-10",
    "Telefono": "77601910",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10369843",
    "Nombres": "JAVIER ANDRES",
    "Apellidos": "ANDRES BARRIOS",
    "Fecha nacimiento": "2005-07-21",
    "Telefono": "63583114",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "15953",
    "Nombres": "JAVIER SANTIAGO",
    "Apellidos": "SANTIAGO BALVIDIA",
    "Fecha nacimiento": "1976-02-20",
    "Telefono": "69051040",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7714397",
    "Nombres": "JEFERSON ABRAHAN",
    "Apellidos": "ABRAHAN DELGADILLO",
    "Fecha nacimiento": "2000-12-15",
    "Telefono": "62187457",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8181924",
    "Nombres": "JESSICA",
    "Apellidos": "RAMOS CALDERON",
    "Fecha nacimiento": "1993-03-06",
    "Telefono": "60041141",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8233645",
    "Nombres": "JESSICA",
    "Apellidos": "ROJAS CORTEZ",
    "Fecha nacimiento": "1991-07-22",
    "Telefono": "76858444",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "64241611",
    "Nombres": "JIMENA",
    "Apellidos": "SALDIAS BARBA",
    "Fecha nacimiento": "1999-01-18",
    "Telefono": "70987039",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14594303",
    "Nombres": "JOSE",
    "Apellidos": "MANUEL MARTINEZ",
    "Fecha nacimiento": "2011-12-28",
    "Telefono": "70874678",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11651445",
    "Nombres": "JOSE ELIAS",
    "Apellidos": "ELIAS MEJIA",
    "Fecha nacimiento": "1979-09-02",
    "Telefono": "75343309",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13332892",
    "Nombres": "JOSE HENRY",
    "Apellidos": "HENRY CALDERON",
    "Fecha nacimiento": "2000-12-28",
    "Telefono": "79483595",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13429640",
    "Nombres": "JOSE LUIS",
    "Apellidos": "LUIS CEDEÑO",
    "Fecha nacimiento": "1998-09-10",
    "Telefono": "75644150",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6280469",
    "Nombres": "JOSE LUIS",
    "Apellidos": "LUIS DORADO",
    "Fecha nacimiento": "1986-08-10",
    "Telefono": "70071072",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6222473",
    "Nombres": "JOSE NAIM",
    "Apellidos": "JIMNEZ EL WISIEH",
    "Fecha nacimiento": "1984-10-28",
    "Telefono": "69219662",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13700077",
    "Nombres": "JUAN",
    "Apellidos": "PEREZ",
    "Fecha nacimiento": "1999-08-26",
    "Telefono": "74699137",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13543414",
    "Nombres": "JUAN",
    "Apellidos": "PABLO BROCKMANN",
    "Fecha nacimiento": "2007-10-05",
    "Telefono": "69238810",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9753940",
    "Nombres": "JUAN ENRIQUE",
    "Apellidos": "ENRIQUE PRADO",
    "Fecha nacimiento": "2009-04-24",
    "Telefono": "75532286",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "102030",
    "Nombres": "JUAN PABLO",
    "Apellidos": "VACA PEREIRA JUSTINIANO",
    "Fecha nacimiento": "1985-05-25",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6391353",
    "Nombres": "JUANA ROXANA",
    "Apellidos": "ROXANA GUZMAN",
    "Fecha nacimiento": "1989-02-02",
    "Telefono": "60810656",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6220172",
    "Nombres": "JUANITA",
    "Apellidos": "GARCIA ALBA",
    "Fecha nacimiento": "1984-03-12",
    "Telefono": "72637938",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13305689",
    "Nombres": "JULIANA",
    "Apellidos": "PINTO GARRIDO",
    "Fecha nacimiento": "2002-10-27",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10913285",
    "Nombres": "JULIO CESAR",
    "Apellidos": "CESAR REGUERIN",
    "Fecha nacimiento": "2006-12-18",
    "Telefono": "72508275",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1719473",
    "Nombres": "KAREN LISSETTE",
    "Apellidos": "LISSETTE DELLIEN",
    "Fecha nacimiento": "1976-05-05",
    "Telefono": "78290732",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4601415",
    "Nombres": "KARIN DE LA CRUZ",
    "Apellidos": "MELGAR CLAVEL",
    "Fecha nacimiento": "1977-01-25",
    "Telefono": "77636217",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "58888793",
    "Nombres": "KARLA",
    "Apellidos": "JUSTINIANO AVILES",
    "Fecha nacimiento": "1989-10-22",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6213647",
    "Nombres": "KATHERIN",
    "Apellidos": "URGEL MORENO",
    "Fecha nacimiento": "1999-01-15",
    "Telefono": "75349364",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13429376",
    "Nombres": "KENIA",
    "Apellidos": "GIL MELGAR",
    "Fecha nacimiento": "1998-04-17",
    "Telefono": "77036306",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7894614",
    "Nombres": "KEVIN",
    "Apellidos": "SALDIAS",
    "Fecha nacimiento": "2000-04-02",
    "Telefono": "78019325",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6243813",
    "Nombres": "LAURA SOFIA",
    "Apellidos": "SOFIA PAZ",
    "Fecha nacimiento": "1996-12-03",
    "Telefono": "75068104",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4700449",
    "Nombres": "LEDA MARIA",
    "Apellidos": "MARIA PEREIRA",
    "Fecha nacimiento": "1981-09-08",
    "Telefono": "70040001",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5853777",
    "Nombres": "LEIDY LAURA",
    "Apellidos": "LAURA ZAMBRANA",
    "Fecha nacimiento": "1982-12-21",
    "Telefono": "72180288",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8991129",
    "Nombres": "LEIDY YOSSELIN",
    "Apellidos": "YOSSELIN  ZABALA",
    "Fecha nacimiento": "1992-05-26",
    "Telefono": "75354309",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "Y0845479J",
    "Nombres": "LEILA",
    "Apellidos": "SARMIENTO ARREDONDO",
    "Fecha nacimiento": "1986-06-10",
    "Telefono": "71606703",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14808708",
    "Nombres": "LEONARDO",
    "Apellidos": "DEHEZA",
    "Fecha nacimiento": "2016-04-26",
    "Telefono": "71639110",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2233317",
    "Nombres": "LEONARDO",
    "Apellidos": "BARBERY SANTISTEVAN",
    "Fecha nacimiento": "1986-09-24",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4804426",
    "Nombres": "LEONARDO",
    "Apellidos": "DEHEZA ANTELO",
    "Fecha nacimiento": "1986-09-24",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3852086",
    "Nombres": "LESLIE BARBERY",
    "Apellidos": "BARBERY DE",
    "Fecha nacimiento": "1971-10-18",
    "Telefono": "70050000",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7790623",
    "Nombres": "LILIAM",
    "Apellidos": "OSORIO ARTEAGA",
    "Fecha nacimiento": "1991-07-15",
    "Telefono": "78564482",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "789562",
    "Nombres": "LILIAN MARIA",
    "Apellidos": "MARIA PADILLA",
    "Fecha nacimiento": "1987-04-06",
    "Telefono": "79658236",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5350487",
    "Nombres": "LILIANA",
    "Apellidos": "PRUEBA",
    "Fecha nacimiento": "1994-07-12",
    "Telefono": "78420188",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7747557",
    "Nombres": "LINDA",
    "Apellidos": "PANIAGUA ROBLES",
    "Fecha nacimiento": "1985-07-03",
    "Telefono": "66042019",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "665415",
    "Nombres": "LISSETH",
    "Apellidos": "NIÑA",
    "Fecha nacimiento": "2002-04-20",
    "Telefono": "69548214",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6308217",
    "Nombres": "LISSETH VERONICA",
    "Apellidos": "VERONICA WARNES",
    "Fecha nacimiento": "1991-03-01",
    "Telefono": "70044381",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "47013166",
    "Nombres": "LIZEDT",
    "Apellidos": "CASTEDO OROZ",
    "Fecha nacimiento": "1982-02-28",
    "Telefono": "72104405",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9818154",
    "Nombres": "LUANA",
    "Apellidos": "RIVERO ROMAN",
    "Fecha nacimiento": "2009-12-02",
    "Telefono": "70009010",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8975545",
    "Nombres": "LUCAS LEON",
    "Apellidos": "LEON DAENS",
    "Fecha nacimiento": "2011-05-18",
    "Telefono": "60029916",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13373860",
    "Nombres": "LUCIANA VALENTINA",
    "Apellidos": "VALENTINA CAZON",
    "Fecha nacimiento": "2003-03-18",
    "Telefono": "75012517",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2965672",
    "Nombres": "LUCY",
    "Apellidos": "CASTRO",
    "Fecha nacimiento": "1961-12-13",
    "Telefono": "77310955",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4692930",
    "Nombres": "LUIS",
    "Apellidos": "PERROGON",
    "Fecha nacimiento": "1995-09-08",
    "Telefono": "77905655",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5400322",
    "Nombres": "LUIS ENRIQUE",
    "Apellidos": "ENRIQUE URIONA",
    "Fecha nacimiento": "1981-07-02",
    "Telefono": "73125487",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5892875",
    "Nombres": "LUIS FEDERICO",
    "Apellidos": "FEDERICO FERRUFINO",
    "Fecha nacimiento": "1986-02-13",
    "Telefono": "78023225",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3234568",
    "Nombres": "LUIS FERNANDO",
    "Apellidos": "FERNANDO FRAIJA",
    "Fecha nacimiento": "1968-10-12",
    "Telefono": "77881888",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3836909",
    "Nombres": "LUZ MELINA",
    "Apellidos": "MELINA ORDOÑEZ",
    "Fecha nacimiento": "1979-04-11",
    "Telefono": "63533962",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8125476",
    "Nombres": "MAITE",
    "Apellidos": "ASQUITH VARGAS",
    "Fecha nacimiento": "2004-05-26",
    "Telefono": "68923472",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4733895",
    "Nombres": "MALKA",
    "Apellidos": "KAVLIN ELBIRT",
    "Fecha nacimiento": "1974-10-06",
    "Telefono": "69087974",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3279418",
    "Nombres": "MANFREDO",
    "Apellidos": "AÑEZ JUSTINIANO",
    "Fecha nacimiento": "1969-02-02",
    "Telefono": "78511321",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "E- 11651665",
    "Nombres": "MARCELA",
    "Apellidos": "FEDRALEYLA TABAREZ",
    "Fecha nacimiento": "1974-06-30",
    "Telefono": "62244575",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8195412",
    "Nombres": "MARCELO ANDRES",
    "Apellidos": "ANDRES SAAVEDRA",
    "Fecha nacimiento": "1995-04-06",
    "Telefono": "61550774",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5391414",
    "Nombres": "MARI",
    "Apellidos": "SAUCEDO CUELLAR",
    "Fecha nacimiento": "1978-10-09",
    "Telefono": "72663735",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "102040",
    "Nombres": "MARIA",
    "Apellidos": "",
    "Fecha nacimiento": "1982-10-02",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7836649",
    "Nombres": "MARIA",
    "Apellidos": "ALEJANDRA MORALES",
    "Fecha nacimiento": "1987-12-05",
    "Telefono": "60983301",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4560821",
    "Nombres": "MARIA",
    "Apellidos": "BELEN ARIAS",
    "Fecha nacimiento": "1985-04-12",
    "Telefono": "75022588",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "45608212",
    "Nombres": "MARIA",
    "Apellidos": "JOSE CABRERA",
    "Fecha nacimiento": "1985-04-12",
    "Telefono": "75022588",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7724661",
    "Nombres": "MARIA",
    "Apellidos": "LAURA QUINTANILLA",
    "Fecha nacimiento": "1995-07-28",
    "Telefono": "76040267",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3919415",
    "Nombres": "MARIA",
    "Apellidos": "PAOLA RIVERA",
    "Fecha nacimiento": "1990-11-30",
    "Telefono": "77358179",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4648267",
    "Nombres": "MARIA",
    "Apellidos": "ROXANA SALAZAR",
    "Fecha nacimiento": "1982-08-31",
    "Telefono": "79460005",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7117788",
    "Nombres": "MARÍA",
    "Apellidos": "BELÉN ARIAS",
    "Fecha nacimiento": "2002-11-04",
    "Telefono": "63405103",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13699694",
    "Nombres": "MARIA CLAUDIA",
    "Apellidos": "CLAUDIA GUZMAN",
    "Fecha nacimiento": "2007-04-27",
    "Telefono": "70849206",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9876543",
    "Nombres": "MARÍA CLAUDIA",
    "Apellidos": "CLAUDIA SABA",
    "Fecha nacimiento": "1995-04-07",
    "Telefono": "70545001",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7790783",
    "Nombres": "MARIA EUGENIA",
    "Apellidos": "EUGENIA MERCADO",
    "Fecha nacimiento": "1989-12-10",
    "Telefono": "70983620",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3137264",
    "Nombres": "MARIA JOSE",
    "Apellidos": "JOSE AMATLLER",
    "Fecha nacimiento": "1974-12-06",
    "Telefono": "71795867",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4683099",
    "Nombres": "MARIA LORENA",
    "Apellidos": "LORENA SANDOVAL",
    "Fecha nacimiento": "1977-09-11",
    "Telefono": "77682691",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4385960",
    "Nombres": "MARIA RENE",
    "Apellidos": "RENE PIOTTI",
    "Fecha nacimiento": "1979-05-01",
    "Telefono": "75008247",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9586452",
    "Nombres": "MARIA SOL",
    "Apellidos": "SOL MORENO",
    "Fecha nacimiento": "2002-11-19",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9268455",
    "Nombres": "MARIA SOLANGE",
    "Apellidos": "SOLANGE RIVERO",
    "Fecha nacimiento": "1996-12-14",
    "Telefono": "73030555",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8212552",
    "Nombres": "MARIANA",
    "Apellidos": "PARADA PAZ",
    "Fecha nacimiento": "2005-04-06",
    "Telefono": "75635365",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5630063",
    "Nombres": "MARIANA",
    "Apellidos": "ZARATE GUTIERREZ",
    "Fecha nacimiento": "1986-05-24",
    "Telefono": "71683738",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1492511",
    "Nombres": "MARIANO",
    "Apellidos": "ROJAS",
    "Fecha nacimiento": "2012-05-21",
    "Telefono": "77307171",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5279069",
    "Nombres": "MARIBEL",
    "Apellidos": "JIMENEZ SIERRA",
    "Fecha nacimiento": "1979-01-30",
    "Telefono": "72178483",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10311792",
    "Nombres": "MARIELA",
    "Apellidos": "ARAMAYO MERCADO",
    "Fecha nacimiento": "2000-10-24",
    "Telefono": "74686561",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "123459",
    "Nombres": "MARIO",
    "Apellidos": "ZAMBRANA",
    "Fecha nacimiento": "2008-06-02",
    "Telefono": "62102233",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4716609",
    "Nombres": "MARIO BRUNO",
    "Apellidos": "BRUNO M.",
    "Fecha nacimiento": "1985-10-08",
    "Telefono": "78048048",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3830598",
    "Nombres": "MARIOLI",
    "Apellidos": "GOMEZ VACA",
    "Fecha nacimiento": "1977-03-24",
    "Telefono": "77067484",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4578730",
    "Nombres": "MARIOLY",
    "Apellidos": "RIBERA",
    "Fecha nacimiento": "1978-02-25",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7726635",
    "Nombres": "MARLEN",
    "Apellidos": "RIBERA CHUVIRU",
    "Fecha nacimiento": "1993-01-15",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4291331",
    "Nombres": "MARLENE",
    "Apellidos": "VALDEZ",
    "Fecha nacimiento": "1973-01-20",
    "Telefono": "70887023",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "17011484",
    "Nombres": "MATEO",
    "Apellidos": "ZAMBRANA",
    "Fecha nacimiento": "2010-04-06",
    "Telefono": "78048048",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12506453",
    "Nombres": "MATIAS",
    "Apellidos": "ROJAS",
    "Fecha nacimiento": "2012-02-24",
    "Telefono": "77390214",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "76858769",
    "Nombres": "MATIAS",
    "Apellidos": "URRUTIA",
    "Fecha nacimiento": "2011-12-03",
    "Telefono": "71163616",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12729340",
    "Nombres": "MATIAS",
    "Apellidos": "APONTE BILDT",
    "Fecha nacimiento": "2010-02-11",
    "Telefono": "76003063",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9796243",
    "Nombres": "MATIAS",
    "Apellidos": "ARNOLD PRUDENCIO",
    "Fecha nacimiento": "2006-11-29",
    "Telefono": "75013850",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "15337143",
    "Nombres": "MATIAS",
    "Apellidos": "PADILLA PARADA",
    "Fecha nacimiento": "2001-12-07",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "64960",
    "Nombres": "MATILDA",
    "Apellidos": "LILLO LOPEZ",
    "Fecha nacimiento": "2005-01-14",
    "Telefono": "72195607",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2474995",
    "Nombres": "MAURICIO",
    "Apellidos": "ROJAS",
    "Fecha nacimiento": "1977-08-21",
    "Telefono": "77311949",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5391979",
    "Nombres": "MAURICIO",
    "Apellidos": "CABRERA GARCIA",
    "Fecha nacimiento": "1984-09-29",
    "Telefono": "77307171",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "E-11605606",
    "Nombres": "MAXIMILIANO CARLOS",
    "Apellidos": "CARLOS MANUEL",
    "Fecha nacimiento": "1975-06-05",
    "Telefono": "61501179",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "845952",
    "Nombres": "MAYERLI",
    "Apellidos": "AYMA ROJAS",
    "Fecha nacimiento": "2005-09-28",
    "Telefono": "63554862",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "E-0064788",
    "Nombres": "MD",
    "Apellidos": "NAZIB HASAN",
    "Fecha nacimiento": "1986-01-15",
    "Telefono": "77655948",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "15041378",
    "Nombres": "MELISA",
    "Apellidos": "PATIÑO ROBLES",
    "Fecha nacimiento": "2016-11-04",
    "Telefono": "63533962",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9702024",
    "Nombres": "MELISSA",
    "Apellidos": "PEREIRA",
    "Fecha nacimiento": "2002-04-01",
    "Telefono": "70885360",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9754000",
    "Nombres": "MICAELA",
    "Apellidos": "PERROGON RIBERA",
    "Fecha nacimiento": "2006-10-18",
    "Telefono": "78033745",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7852240",
    "Nombres": "MICAELA",
    "Apellidos": "RUEGG VALDEZ",
    "Fecha nacimiento": "2004-08-17",
    "Telefono": "70887023",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6395205",
    "Nombres": "MICHELLE ANDREA",
    "Apellidos": "ANDREA WAYAR",
    "Fecha nacimiento": "1999-07-20",
    "Telefono": "75025113",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9736179",
    "Nombres": "MIGUEL ANGEL",
    "Apellidos": "ANGEL MONTOYA",
    "Fecha nacimiento": "2003-02-25",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5332135",
    "Nombres": "MIGUEL DE",
    "Apellidos": "DE LA",
    "Fecha nacimiento": "1979-08-17",
    "Telefono": "72114772",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1465278",
    "Nombres": "MILAN DANIEL",
    "Apellidos": "DANIEL CRONENBOLD",
    "Fecha nacimiento": "2016-03-21",
    "Telefono": "74160086",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3988678",
    "Nombres": "MILENA",
    "Apellidos": "GALLO MORALES",
    "Fecha nacimiento": "1984-06-25",
    "Telefono": "75669359",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4878813",
    "Nombres": "MILENCA",
    "Apellidos": "RODAS",
    "Fecha nacimiento": "1980-08-25",
    "Telefono": "67707061",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6372963",
    "Nombres": "MIRIAM",
    "Apellidos": "SUAREZ SOTELO",
    "Fecha nacimiento": "1985-09-29",
    "Telefono": "70959304",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3262553",
    "Nombres": "MIRIAN CONCEPCION",
    "Apellidos": "CONCEPCION GUILLEN",
    "Fecha nacimiento": "1954-12-15",
    "Telefono": "76633000",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8193335",
    "Nombres": "NAHUN FARID",
    "Apellidos": "FARID ZAMORANO",
    "Fecha nacimiento": "2000-11-19",
    "Telefono": "60098994",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9760163",
    "Nombres": "NATALIA",
    "Apellidos": "JUSTINIANO",
    "Fecha nacimiento": "2009-04-02",
    "Telefono": "62133303",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8127051",
    "Nombres": "NATALIA",
    "Apellidos": "AGUILERA MATAMOROS",
    "Fecha nacimiento": "2004-01-21",
    "Telefono": "75053053",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7522493",
    "Nombres": "NATALIA",
    "Apellidos": "ARACENA SUÁREZ",
    "Fecha nacimiento": "2000-12-01",
    "Telefono": "75526839",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5888790",
    "Nombres": "NATALIA ANDREA",
    "Apellidos": "BAYA DE COSTA",
    "Fecha nacimiento": "1988-08-12",
    "Telefono": "77057652",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "58555940",
    "Nombres": "NATALY",
    "Apellidos": "BARRIOS ROCHA",
    "Fecha nacimiento": "1997-02-23",
    "Telefono": "75546658",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4942886",
    "Nombres": "NATHALIA",
    "Apellidos": "REYES",
    "Fecha nacimiento": "1993-06-22",
    "Telefono": "77311971",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10832950",
    "Nombres": "NAYA ALETSE",
    "Apellidos": "ALETSE VACA",
    "Fecha nacimiento": "1999-10-31",
    "Telefono": "75080106",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3821977",
    "Nombres": "NEILL",
    "Apellidos": "AÑEZ CASTILLO",
    "Fecha nacimiento": "1973-11-17",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12504709",
    "Nombres": "NICOLAS",
    "Apellidos": "CANDIA INCHAUSTI",
    "Fecha nacimiento": "2006-06-26",
    "Telefono": "75852856",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11497196",
    "Nombres": "NICOLAS",
    "Apellidos": "RIBERA MENESES",
    "Fecha nacimiento": "1983-09-19",
    "Telefono": "78564649",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3216549",
    "Nombres": "NICOLÁS",
    "Apellidos": "CARABACA",
    "Fecha nacimiento": "2007-03-26",
    "Telefono": "70096390",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8167659",
    "Nombres": "NOELIA",
    "Apellidos": "BALDERRAMA ARNEZ",
    "Fecha nacimiento": "1992-11-11",
    "Telefono": "76358253",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9850420",
    "Nombres": "NOHELIA",
    "Apellidos": "GUZMAN",
    "Fecha nacimiento": "1993-01-04",
    "Telefono": "69179494",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5898360",
    "Nombres": "NURIA",
    "Apellidos": "ROJAS CORTEZ",
    "Fecha nacimiento": "2004-07-17",
    "Telefono": "78480812",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2094878",
    "Nombres": "OLGA PATRICIA",
    "Apellidos": "PATRICIA RUBIO",
    "Fecha nacimiento": "1969-06-16",
    "Telefono": "69902995",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6291654",
    "Nombres": "ORIELIS EDEN",
    "Apellidos": "EDEN ARREDONDO",
    "Fecha nacimiento": "2001-02-08",
    "Telefono": "76854244",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9654784",
    "Nombres": "OSCAR ALVARO",
    "Apellidos": "ALVARO RAMIREZ",
    "Fecha nacimiento": "2007-03-30",
    "Telefono": "78505902",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6231302",
    "Nombres": "PABLO",
    "Apellidos": "HERNANDEZ",
    "Fecha nacimiento": "1993-01-05",
    "Telefono": "76019592",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4570149",
    "Nombres": "PAOLA",
    "Apellidos": "NAVARRO",
    "Fecha nacimiento": "1976-12-23",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6359910",
    "Nombres": "PAOLA",
    "Apellidos": "BUSTAMANTE ROSALE",
    "Fecha nacimiento": "1988-05-05",
    "Telefono": "71353203",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5377532",
    "Nombres": "PAOLA ALEEIVA",
    "Apellidos": "ALEEIVA GUTIERREZ",
    "Fecha nacimiento": "1986-08-11",
    "Telefono": "77011787",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5358229",
    "Nombres": "PAOLA ANDREA",
    "Apellidos": "ANDREA ROCHA",
    "Fecha nacimiento": "1980-08-18",
    "Telefono": "70833662",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8185201",
    "Nombres": "PAOLA ROYLLY",
    "Apellidos": "ROYLLY MARTINEZ",
    "Fecha nacimiento": "1990-06-15",
    "Telefono": "78001985",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7116134",
    "Nombres": "PATRICIA",
    "Apellidos": "ABAN",
    "Fecha nacimiento": "1990-05-04",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3595945",
    "Nombres": "PATRICIA",
    "Apellidos": "PINEDO CUELLAR",
    "Fecha nacimiento": "1976-12-16",
    "Telefono": "60038726",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3160568",
    "Nombres": "PATRICIA",
    "Apellidos": "RICHTER PATIÑO",
    "Fecha nacimiento": "1966-04-17",
    "Telefono": "70441444",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13345680",
    "Nombres": "RAFAEL",
    "Apellidos": "PEROU FERNANDEZ",
    "Fecha nacimiento": "2012-11-04",
    "Telefono": "76913348",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14766479",
    "Nombres": "RAFAEL ANDRES",
    "Apellidos": "LUNA PIZZARRO ECHAZU",
    "Fecha nacimiento": "2016-11-01",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5895974",
    "Nombres": "RANDY RAMBERT",
    "Apellidos": "RAMBERT ROJAS",
    "Fecha nacimiento": "1994-11-24",
    "Telefono": "75634362",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "E-0037973",
    "Nombres": "RAUL FERNANDO",
    "Apellidos": "FERNANDO MEDINA",
    "Fecha nacimiento": "1987-06-06",
    "Telefono": "75002448",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7635708",
    "Nombres": "RAUL ROBERTO",
    "Apellidos": "ROBERTO APONTE",
    "Fecha nacimiento": "1989-10-09",
    "Telefono": "65045343",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10763281",
    "Nombres": "REBECA",
    "Apellidos": "NUÑES PATRICIO",
    "Fecha nacimiento": "1997-06-13",
    "Telefono": "77605321",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9752288",
    "Nombres": "REINA DIYA",
    "Apellidos": "DIYA TORREEZ",
    "Fecha nacimiento": "2005-03-20",
    "Telefono": "75069500",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7750788",
    "Nombres": "RICARDO",
    "Apellidos": "LLANOS BARBA",
    "Fecha nacimiento": "2001-07-08",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14396101",
    "Nombres": "ROBERT  EDWARD",
    "Apellidos": "EDWARD CALLE",
    "Fecha nacimiento": "2009-03-23",
    "Telefono": "70805166",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12667408",
    "Nombres": "ROBERTO",
    "Apellidos": "POLO ALVIS",
    "Fecha nacimiento": "1998-01-02",
    "Telefono": "70034720",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3814781",
    "Nombres": "ROCIO",
    "Apellidos": "REVOLLO BARRIGA",
    "Fecha nacimiento": "1974-07-02",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2444594",
    "Nombres": "RODOLFO ALI",
    "Apellidos": "ALI NALLAR",
    "Fecha nacimiento": "1974-08-26",
    "Telefono": "75886598",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10701156",
    "Nombres": "RODRIGO",
    "Apellidos": "IBARRA",
    "Fecha nacimiento": "1990-05-07",
    "Telefono": "62093722",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3274597",
    "Nombres": "RODRIGO",
    "Apellidos": "MERCADO HURTADO",
    "Fecha nacimiento": "1972-02-27",
    "Telefono": "75669360",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7894561",
    "Nombres": "RONALD",
    "Apellidos": "RIBERA MENESES",
    "Fecha nacimiento": "2005-12-02",
    "Telefono": "70096390",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13083241",
    "Nombres": "ROSABEL",
    "Apellidos": "CACERES ARANCIBIA",
    "Fecha nacimiento": "2001-09-09",
    "Telefono": "78191875",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9829907",
    "Nombres": "ROSANDY",
    "Apellidos": "DURAN CORTEZ",
    "Fecha nacimiento": "1993-04-21",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5338018",
    "Nombres": "ROSSE MARY",
    "Apellidos": "MARY IBAÑEZ",
    "Fecha nacimiento": "1989-06-14",
    "Telefono": "77807890",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2982538",
    "Nombres": "ROSSMERY",
    "Apellidos": "BALDOMAR GUILLEN",
    "Fecha nacimiento": "1980-07-31",
    "Telefono": "75008301",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3936166",
    "Nombres": "ROSSY HEIDI",
    "Apellidos": "HEIDI SEVERICH",
    "Fecha nacimiento": "1978-09-09",
    "Telefono": "75003494",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8952056",
    "Nombres": "ROXABA",
    "Apellidos": "VILLAGOMEZ MORALES",
    "Fecha nacimiento": "1990-10-05",
    "Telefono": "67747346",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8954056",
    "Nombres": "ROXANA",
    "Apellidos": "VILLAGOMEZ MORALES",
    "Fecha nacimiento": "1990-10-05",
    "Telefono": "67747346",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13700288",
    "Nombres": "RUBEN DARIO",
    "Apellidos": "DARIO VARGAS",
    "Fecha nacimiento": "2009-10-04",
    "Telefono": "78100196",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9791311",
    "Nombres": "RUBEN SMISER",
    "Apellidos": "SMISER ANAGUA",
    "Fecha nacimiento": "2005-05-02",
    "Telefono": "71377420",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3732142",
    "Nombres": "RUDY LUIS",
    "Apellidos": "LUIS RIVERA",
    "Fecha nacimiento": "1980-10-01",
    "Telefono": "72163186",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3855974",
    "Nombres": "SANDRA",
    "Apellidos": "CABRERA",
    "Fecha nacimiento": "1995-04-01",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13012935",
    "Nombres": "SANTIAGO",
    "Apellidos": "ROJAS",
    "Fecha nacimiento": "2011-08-27",
    "Telefono": "70874678",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8324644",
    "Nombres": "SANTIAGO",
    "Apellidos": "RIVERO ROMAN",
    "Fecha nacimiento": "2005-08-17",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9587726",
    "Nombres": "SANTIAGO NAOKI",
    "Apellidos": "NAOKI MEJIA",
    "Fecha nacimiento": "2007-04-12",
    "Telefono": "78127702",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11308307",
    "Nombres": "SANTIAGO NICOLAS",
    "Apellidos": "NICOLAS CHAVEZ",
    "Fecha nacimiento": "2010-08-24",
    "Telefono": "77307171",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3886086",
    "Nombres": "SAYUEY",
    "Apellidos": "PERDRIEL SENZANO",
    "Fecha nacimiento": "1975-09-09",
    "Telefono": "70090108",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14497182",
    "Nombres": "SEBASTIAN",
    "Apellidos": "SAVEEDRA",
    "Fecha nacimiento": "2012-01-21",
    "Telefono": "60983301",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "0",
    "Nombres": "SEBASTIAN",
    "Apellidos": "MACHUCA MORALES",
    "Fecha nacimiento": "2005-01-15",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9636933",
    "Nombres": "SEBASTIAN",
    "Apellidos": "ROCA ARDAYA",
    "Fecha nacimiento": "2007-12-04",
    "Telefono": "70958198",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2587416",
    "Nombres": "SEBASTIAN GAEL",
    "Apellidos": "GAEL MACHUCA",
    "Fecha nacimiento": "2007-06-21",
    "Telefono": "76684409",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5402158",
    "Nombres": "SILVANA",
    "Apellidos": "RIVERA RODAS",
    "Fecha nacimiento": "1980-06-21",
    "Telefono": "76097499",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6212850",
    "Nombres": "SILVANA JENNY",
    "Apellidos": "JENNY LORA",
    "Fecha nacimiento": "1982-10-25",
    "Telefono": "76676479",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8978227",
    "Nombres": "SIVIA BONIFACIA",
    "Apellidos": "BONIFACIA ESPINOZA",
    "Fecha nacimiento": "1993-04-14",
    "Telefono": "76369357",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2534778",
    "Nombres": "SOFIA",
    "Apellidos": "URRITIA",
    "Fecha nacimiento": "1980-01-03",
    "Telefono": "77390214",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "15337144",
    "Nombres": "SOFIA MALIN VALERIA",
    "Apellidos": "BILDT BARRAGAN",
    "Fecha nacimiento": "2006-11-14",
    "Telefono": "69087974",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "5602321",
    "Nombres": "SORAYA",
    "Apellidos": "AMOS MOLINA",
    "Fecha nacimiento": "2000-02-22",
    "Telefono": "77821940",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14994615",
    "Nombres": "SUSANA",
    "Apellidos": "VARGAS ROMERO",
    "Fecha nacimiento": "2001-08-23",
    "Telefono": "76022555",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6304863",
    "Nombres": "TAMARA",
    "Apellidos": "NAVARRO CARDONA",
    "Fecha nacimiento": "1987-09-29",
    "Telefono": "79002408",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6324014",
    "Nombres": "TATIANA",
    "Apellidos": "BALCAZAR ARIAS",
    "Fecha nacimiento": "1987-01-06",
    "Telefono": "76079275",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6231315",
    "Nombres": "TERESA",
    "Apellidos": "MARIA MAMANI",
    "Fecha nacimiento": "1987-08-22",
    "Telefono": "76640572",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14504233",
    "Nombres": "THIAGO",
    "Apellidos": "FUENTE WARNES",
    "Fecha nacimiento": "2013-06-01",
    "Telefono": "71626345",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "14467925",
    "Nombres": "TOBIAS HIROKI",
    "Apellidos": "HIROKI MEDIA",
    "Fecha nacimiento": "2015-02-05",
    "Telefono": "70874678",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4871457",
    "Nombres": "VALENTINA",
    "Apellidos": "SAAVEDRA",
    "Fecha nacimiento": "2009-02-19",
    "Telefono": "77020188",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12535282",
    "Nombres": "VALENTINA",
    "Apellidos": "SEVERICH NAVIA",
    "Fecha nacimiento": "2007-01-07",
    "Telefono": "70975069",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9787549",
    "Nombres": "VALENTINA",
    "Apellidos": "SUAREZ ARZE",
    "Fecha nacimiento": "2002-09-17",
    "Telefono": "76361763",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7281252",
    "Nombres": "VALERIO",
    "Apellidos": "JUANIQUINA INCA",
    "Fecha nacimiento": "2001-08-09",
    "Telefono": "67208454",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "2988179",
    "Nombres": "VERONICA",
    "Apellidos": "MATAMOROS",
    "Fecha nacimiento": "1980-05-01",
    "Telefono": "",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "3932372",
    "Nombres": "VIVIAN",
    "Apellidos": "ROJAS",
    "Fecha nacimiento": "1977-01-02",
    "Telefono": "72658358",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8248357",
    "Nombres": "VIVIANA",
    "Apellidos": "HUGUENET LEYTHON",
    "Fecha nacimiento": "1987-06-06",
    "Telefono": "78572353",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9875784",
    "Nombres": "WARA",
    "Apellidos": "BARREDA CALVIMONTES",
    "Fecha nacimiento": "2003-08-12",
    "Telefono": "77591400",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "1141937",
    "Nombres": "WENDY",
    "Apellidos": "PEREZ VARGAS",
    "Fecha nacimiento": "1978-11-12",
    "Telefono": "74660492",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6296693",
    "Nombres": "WILL",
    "Apellidos": "MORENO",
    "Fecha nacimiento": "1978-03-31",
    "Telefono": "77379000",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "11335922",
    "Nombres": "WILSON ALEJANDRO",
    "Apellidos": "ALEJANDRO VACA",
    "Fecha nacimiento": "2010-09-01",
    "Telefono": "72680043",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "8180978",
    "Nombres": "YAMID YOSELIN",
    "Apellidos": "YOSELIN BARRIOS",
    "Fecha nacimiento": "1999-05-19",
    "Telefono": "69098587",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9760177",
    "Nombres": "YANAIRA",
    "Apellidos": "ORDOÑEZ ZAMBRANA",
    "Fecha nacimiento": "1997-11-01",
    "Telefono": "78103545",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "4610358",
    "Nombres": "YANET",
    "Apellidos": "FLORES TORDOYA",
    "Fecha nacimiento": "1976-01-18",
    "Telefono": "77008022",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "12790710",
    "Nombres": "YENIFER",
    "Apellidos": "ESPINOZA LAIME",
    "Fecha nacimiento": "1995-06-25",
    "Telefono": "68829457",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7818488",
    "Nombres": "YERIELI YOELA",
    "Apellidos": "YOELA LINO",
    "Fecha nacimiento": "2000-04-06",
    "Telefono": "77616176",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "9585587",
    "Nombres": "YESSENIA",
    "Apellidos": "AVILA ARTEAGA",
    "Fecha nacimiento": "1991-06-10",
    "Telefono": "75619374",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "7856407",
    "Nombres": "YESSICA",
    "Apellidos": "AREVALO CASTRO",
    "Fecha nacimiento": "1992-02-03",
    "Telefono": "76696176",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6358215",
    "Nombres": "YOHANA",
    "Apellidos": "GTARCIA CORTEZ",
    "Fecha nacimiento": "1986-12-31",
    "Telefono": "75617076",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "13731374",
    "Nombres": "YULIANA",
    "Apellidos": "NAVARRO HUERTA",
    "Fecha nacimiento": "2012-02-03",
    "Telefono": "76033379",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "10650843",
    "Nombres": "YULISA",
    "Apellidos": "RIVERA PORCO",
    "Fecha nacimiento": "2001-06-12",
    "Telefono": "69373854",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  },
  {
    "CI": "6230173",
    "Nombres": "ZUELYN",
    "Apellidos": "MANRRIQUE GARCIA",
    "Fecha nacimiento": "2013-01-02",
    "Telefono": "72637938",
    "Correo": "sin_correo@gmail.com",
    "Password": "123",
    "estado": "1",
    "Peso": "1"
  }
];
class testAlvaro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_ricky_paquetes_vendidos",
      parametros: {
        "inicio": "2022-01-01",
        "fin": "2026-02-01",
        // "inicio": new SDate().addMonth(-2).setDay(1).toString("yyyy-MM-dd"),
        // "fin": new SDate().addMonth(1).setDay(1).addDay(-1).toString("yyyy-MM-dd"),
      },
      ...this.state,
    };
  }

  componentDidMount() {

    Model.usuario.Action.getAll({ force: true });
  }

  renderExcelImport() {
    if (this.state.data) return;
    return <SView col={"xs-12"} height padding={8}>
      <SText>Para subir los usuarios clientes, requerimos una hoja de cálculo de Excel que cumpla con ciertas especificaciones. En la línea #1 de la hoja de Excel, deben estar presentes las siguientes cabeceras, comenzando desde la columna 'A':</SText>
      <SHr />
      <SText>{`|  ci   |  Nombres   |   Apellidos    |   Telefono   |     Fecha nacimiento   |   Correo   | Password |`}</SText>
      <SHr />

      <SText>Es importante que las cabeceras se escriban exactamente como se indica, respetando mayúsculas y minúsculas, y sin incluir espacios adicionales.

        Aquí se detalla el significado de cada cabecera:

      </SText>

      <SHr />
      <SHr />
      <SHr />
      <SText>{`[A1] ci: Número de carnet de identidad del cliente.`}</SText>
      <SText>{`[B1] Nombres: Nombres del cliente.`}</SText>
      <SText>{`[C1] Apellidos: Apellidos del cliente.`}</SText>
      <SText>{`[D1] Telefono: Número de teléfono del cliente.`}</SText>
      <SText>{`[E1] Fecha de nacimiento: Fecha de nacimiento del cliente en el formato 'yyyy-MM-dd'.`}</SText>
      <SText>{`[F1] Correo: Correo electrónico del cliente.`}</SText>
      <SText>{`[G1] Contraseña: Contraseña del cliente.`}</SText>

      <SHr />
      <SHr />
      <SHr />
      <SText>WARNING   Tenga en cuenta que las cabeceras deven respetar las MAYUSCULAS y minusculas, y no deben contener espacios.</SText>
      <SText>WARNING   Tenga en cuenta que las fechas deben respetar el formato.</SText>
      <SHr />
      <SText>Preciona en el icono de excel de la parte inferior derecha, para descargar el modelo de excel</SText>
      <SHr />
      <SView col={"xs-12"} center>
        <SExcelReader onSubmit={(data, callback) => {
          console.log(data);
          this.setState({ data: data })
          callback();
        }} >
          <SView card padding={30} width={180} center><SText center>SUBIR EXCEL DE DATOS</SText></SView>
        </SExcelReader>
      </SView>
      <SHr />
      <SView flex card>
        <STable2
          header={[
            { key: "Key", width: 80 },
            { key: "CI", center: true, width: 70 },
            { key: "Nombres", center: true, width: 80 },
            { key: "Apellidos", center: true, width: 80 },
            { key: "Telefono", center: true, width: 80 },
            { key: "Fecha nacimiento", center: true, width: 120, },
            { key: "Correo", center: true, width: 120 },
            { key: "Password", center: true, width: 80 },
          ]}
          data={{}} />
      </SView>
    </SView>
  }


  renderTable() {
    if (!this.state.data) return;
    return <STable2
      header={[
        // { key: "index", label: "#", width: 40 },
        { key: "user/key", label: "key", width: 150 },
        { key: "CI", center: true, width: 70 },
        { key: "Nombres", center: true, width: 80 },
        { key: "Apellidos", center: true, width: 80 },
        { key: "Telefono", center: true, width: 80 },
        { key: "Fecha nacimiento", center: true, width: 120, },
        { key: "Correo", center: true, width: 120 },
        { key: "Password", center: true, width: 80 },
        {
          key: "-", label: "Usuario", center: true, width: 80, component: (obj) => {
            if (obj.user) return <SText>Usuairo regitrad</SText>;
            return <SView width={28}
              onPress={() => {
                Model.usuario.Action.registro({
                  data: {
                    "CI": obj.CI,
                    "Nombres": obj.Nombres,
                    "Apellidos": obj.Apellidos,
                    "Fecha nacimiento": new SDate(obj["Fecha nacimiento"]).toString("yyyy-MM-dd"),
                    "Telefono": obj.Telefono,
                    "Correo": obj.CI + "@calistenia.com",
                    "Password": obj.Password
                  },
                  key_usuario: Model.usuario.Action.getKey(),

                }).then((resp) => {

                  var key_usurio = resp.data.key;
                  // var key_rol = "d16d800e-5b8d-48ae-8fcb-99392abdf61f";
                  // console.log("CUANDO ES EXITO puto", key_usurio)

                  Model.usuarioRol.Action.registro({
                    data: {
                      key_usuario: key_usurio,
                      key_rol: "d16d800e-5b8d-48ae-8fcb-99392abdf61f"
                    },
                    key_usuario: Model.usuario.Action.getKey()
                  }).then((resp) => {
                    console.log("CUANDO ES EXITO rol insertado", resp)
                  }).catch(e => {
                    console.log("CUANDO ES ERROR del rol", e)
                  })
                  console.log("CUANDO ES EXITO", resp)
                }).catch(e => {
                  console.log("CUANDO ES ERROR", e)
                })
              }}>
              <SIcon name="Add" /></SView >
          }
        },

        {
          key: "-54", label: "Verificar", center: true, width: 80, component: (obj) => {
            if (obj.user) return <SText>Usuairo regitrad</SText>;
            return <SView width={28}
              onPress={() => {
                // SNavigation.navigate(route)
              }}>
              <SIcon name="Eye" /></SView >
          }
        }

      ]}
      data={this.state.data} />
  }


  // https://csvjson.com/csv2json

  recorrer() {
    let data_usuarios = Model.usuario.Action.getAll();

    if (!data_usuarios) return <SLoad />;

    Object.values(data_usuarios).map((obj) => {
      let user = arr_usuarios_palmas.find(a => a.CI == obj.CI)
      if (user) {
        user.user = obj
      }
    })

    return <STable2
      header={[
        { key: "index", label: "#", width: 40 },
        { key: "CI", label: "CI", width: 80 },
        { key: "Nombres", label: "Nombres", width: 150, },
        { key: "Apellidos", label: "Apellidos", width: 150, },
        { key: "Telefono", label: "Telefono", order: "asc", width: 150, },
        {
          key: "user/key", label: "key", width: 150
        },
        {
          key: "-", label: "Usuario", width: 150, component: (obj) => {
            if (obj.user) return <SText>Usuairo regitrad</SText>;
            return <SView width={20}
              onPress={() => {
                Model.usuario.Action.registro({
                  data: {
                    "CI": obj.CI,
                    "Nombres": obj.Nombres,
                    "Apellidos": obj.Apellidos,
                    "Fecha nacimiento": obj["Fecha nacimiento"],
                    "Telefono": obj.Telefono,
                    "Correo": obj.CI + "@calistenia.com",
                    "Password": obj.Password
                  },
                  key_usuario: Model.usuario.Action.getKey(),

                }).then((resp) => {

                  var key_usurio = resp.data.key;
                  // var key_rol = "d16d800e-5b8d-48ae-8fcb-99392abdf61f";
                  // console.log("CUANDO ES EXITO puto", key_usurio)

                  Model.usuarioRol.Action.registro({
                    data: {
                      key_usuario: key_usurio,
                      key_rol: "d16d800e-5b8d-48ae-8fcb-99392abdf61f"
                    },
                    key_usuario: Model.usuario.Action.getKey()
                  }).then((resp) => {
                    console.log("CUANDO ES EXITO rol insertado", resp)
                  }).catch(e => {
                    console.log("CUANDO ES ERROR del rol", e)
                  })
                  console.log("CUANDO ES EXITO", resp)
                }).catch(e => {
                  console.log("CUANDO ES ERROR", e)
                })
              }}>
              <SIcon name="Add" /></SView >
          }
        },
      ]}
      filter={(item) => {
        // if (item.user) return false;

        // if (item.Telefono == null) return false;
        return true;

      }}
      limit={100}
      data={arr_usuarios_palmas}
    />


  }

  render() {
    return (
      <SPage title={"Migrador cliente"} disableScroll center >

        {/* {this.recorrer()} */}


        {this.renderExcelImport()}
        {this.renderTable()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(testAlvaro);
