import { Component } from 'react';
import { Text, View } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SForm, SLoad, SNavigation, SPage, STheme, SView } from 'servisofts-component';
import Usuario from '../..';
import BarraSuperior from '../../../../Components/BarraSuperior';
import FloatButtomMolinete from '../../../../Components/FloatButtomMolinete';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
import Sucursal from '../../../Sucursal';
import sucursal_usuario from '../../../sucursal_usuario';
var objFinal = {};




class entrenador_horarios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        curPage: 1,
      }
    };
    this.key_entrenador = SNavigation.getParam("key_entrenador");
    this.key_horario = SNavigation.getParam("key_horario");

  }

  componentDidMount() {
    Model.usuario.Action.getAll({force:true});
    // var object = {
    //   service: "usuario",
    //   component: "usuario",
    //   version: "2.0",
    //   type: "getAll",
    //   estado: "cargando",
    //   cabecera: "registro_administrador",
    //   key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
    // }
    // SSocket.send(object);

  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  }
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />
    }
    if (data.estado != 0) {
      return <View />
    }
    return <SButtom
      props={{
        type: "danger",
        variant: "confirm"
      }}
      onPress={() => {
        Usuario.Actions.editar({
          ...data,
          estado: 1,
        }, this.props)
      }} >Recuperar</SButtom>
  }
  getExportar() {




    if (!SSRolesPermisosValidate({ page: "EntrenadorPage", permiso: "exportar_excel" })) {
      return;
    }

    return <SView col={"xs-12"} center>
      <ExportExcel
        header={[
          { key: "indice", label: "n", width: 40 },
          { key: "key_usuario", label: "key", width: 250 },
          { key: "nombres", label: "nombres", width: 200 },
        ]}
        getDataProcesada={() => {
          var daFinal = {};
          Object.values(objFinal).map((obj, i) => {
            var toInsert = {
              indice: i + 1,
              key_usuario: obj?.key,
              nombres: obj?.Nombres + " " + obj?.Apellidos,
            }
            daFinal[i] = toInsert
          })
          return daFinal
        }}
      />
    </SView>
  }
  getBotonCrear() {




    if (!SSRolesPermisosValidate({ page: "EntrenadorPage", permiso: "crear" })) {
      return;
    }

    return <FloatButtomMolinete label={"+"} onPress={() => {
      this.props.navigation.navigate("dispositivo");
    }} />
  }




  sucursal_descripcion(_keys) {
    const arr = Sucursal.Actions.getAll(this.props);
    const arr_sucursal_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(_keys, this.props);

    if (!arr || !arr_sucursal_usuario) return <SLoad />;

    const sucursalesActivas = Object.values(arr).filter(obj => {
      const objFind = arr_sucursal_usuario.find((o) => o.key_sucursal === obj.key && o.estado !== 0);
      return objFind !== undefined;
    });

    return <SView border={"transparent"} flex>{sucursalesActivas.map(obj => obj.descripcion)}</SView>

    // <SText fontSize={13} font={"Roboto"}>Usuario</SText>
    // <SText fontSize={13}>Verde: `&gt;`60.000</SText>
    // <SText fontSize={13}>Alvaro</SText>
    // <SText fontSize={13} font={"Roboto"}>Usuario:Alvaro</SText>
    // <SText fontSize={13}>Fecha: 2023-05-02</SText>
    // <SText fontSize={13}>Horas: 18:30 pm</SText>
    // <SText fontSize={13}>Rojo: {'>='} 30.000</SText>

  }

  veces(_keys) {
    const arr = Sucursal.Actions.getAll(this.props);
    const arr_sucursal_usuario = sucursal_usuario.Actions.getAllByKeyUsuario(_keys, this.props);
    if (!arr || !arr_sucursal_usuario) return <SLoad />;
    const sucursalesActivas = Object.values(arr).filter(obj => {
      const objFind = arr_sucursal_usuario.find((o) => o.key_sucursal === obj.key && o.estado !== 0);
      return objFind !== undefined;
    });

    return <>
      <Text center style={{
        color: STheme.color.text, position: "absolute", right: 0,
      }}>{"veces(" + sucursalesActivas.length + ")"}</Text>

    </>
  }

  horarios(_keys) {

    return <>
      <SView width={"100%"} border={"red"}
        onPress={() => { SNavigation.navigate("entrenador_horario", { key: _keys }) }}>
        <Text center style={{
          color: STheme.color.text, position: "absolute", right: 0,
        }}>Horarios</Text>
      </SView>

    </>
  }




  getForm() {

    var objSucursal = Sucursal.Actions.getAll(this.props);
    if (!objSucursal) return <SLoad />

    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />

    const data_sucursales = Object.values(objSucursal).map((obj) => ({
      key: obj.key,
      content: obj.descripcion
    }));

    let dias_semana = [
      { key: "", content: "--" },
      { key: "1", content: "Lunes" },
      { key: "2", content: "Martes" },
      { key: "3", content: "Miercoles" },
      { key: "4", content: "jueves" },
      { key: "5", content: "Viernes" },
      { key: "6", content: "Sabado" },
      { key: "7", content: "Domingo" },
    ]

    const horas_dia = [
      { key: "", content: "--" },
      { key: "06:00", content: "06:00" },
      { key: "07:00", content: "07:00" },
      { key: "08:00", content: "08:00" },
      { key: "09:00", content: "09:00" },
      { key: "10:00", content: "10:00" },
      { key: "11:00", content: "11:00" },
      { key: "12:00", content: "12:00" },
      { key: "13:00", content: "13:00" },
      { key: "14:00", content: "14:00" },
      { key: "15:00", content: "15:00" },
      { key: "16:00", content: "16:00" },
      { key: "17:00", content: "17:00" },
      { key: "18:00", content: "18:00" },
      { key: "19:00", content: "19:00" },
      { key: "20:00", content: "20:00" },
      { key: "21:00", content: "21:00" },
    ]



    if (this.key_horario) {
      this.data = Model.entrenador_horario.Action.getByKey(this.key_horario);
      if (!this.data) return <SLoad />
    }
    console.log("ja ", this.data)

    return <>



      {/* <InputDay label={"Dia"} defaultValue={""} ref={ref => this.dia = ref} required /> */}
      {/* <InputHours label={"horas"} defaultValue={""} ref={ref => this.hora = ref} required /> */}
      {/* <InputSucursales label={"Sucu"} defaultValue={""} ref={ref => objSucursal.descripcion = ref} required /> */}

      <SForm
        ref={(ref) => { this.form = ref; }}
        row
        style={{
          justifyContent: "space-between",
        }}
        inputProps={{ col: "xs-12", customStyle: "calistenia" }}
        inputs={{

          key_entrenador: {
            label: "Entrenador", isRequired: false,
            defaultValue: this.data?.key_entrenador || this?.key_entrenador,
            // render: (keys) => { return usuarios[keys]?.Nombres + " " + usuarios[keys].Apellidos }

            // defaultValue: usuarios[this?.key_entrenador]?.Nombres + " " + usuarios[this?.key_entrenador].Apellidos
          },
          key_sucursal: {
            label: "Sucursal", type: "select", isRequired: false, defaultValue: this.data?.key_sucursal || "",
            options: [{ key: "", content: "--" }, ...data_sucursales]
          },

          dia: { label: "Dia", isRequired: false, defaultValue: this.data?.dia || "", type: "select", options: dias_semana },
          hora_inicio: { label: "hora_inicio", isRequired: false, defaultValue: this.data?.hora_inicio || "", type: "select", options: horas_dia, col: "xs-5.5" },
          hora_fin: { label: "hora_fin", isRequired: false, defaultValue: this.data?.hora_fin || "", type: "select", options: horas_dia, col: "xs-5.5" },

        }
        }

        onSubmitName={"Registrar"}


        onSubmit={(values) => {
          // this.state.onLoad = true;
          // alert("chaval", ...values)

          // console.log("canal ", values, this.state.dia, this.state.hora)
          if (this.key_horario) {

            let respuesta = Model.entrenador_horario.Action.editar({

              key_usuario: Model.usuario.Action.getUsuarioLog().key,

              data: {
                ...this.data,
                ...values
              },


            });

            // if (this.key_horario) {

            // Usuario.Actions.editar({
            //   ...this.usr,
            //   ...values
            // }, this.props);

            // Model.entrenador_horario.editar
            // console.log("Actualizacion ", values)

          } else {

            // console.log("registro ", values)
            let respuesta = Model.entrenador_horario.Action.registro({
              data: {
                key_usuario: Model.usuario.Action.getUsuarioLog().key,
                ...values
              }

            });
            // console.log("registro info ", respuesta)
          }

          SNavigation.goBack()

        }}
      />
    </>

  }

  render() {
    return (
      <>
        <SPage hidden disableScroll>
          <BarraSuperior title={"Horarios entrenador"} navigation={this.props.navigation} goBack={() => { SNavigation.goBack(); }} />

          {this.getForm()}
          {/* <SText color={"green"}>{this?.key_entrenador}</SText> */}
        </SPage>
      </>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(entrenador_horarios);