import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SForm, SHr, SIcon, SInput, SList2, SLoad, SNavigation, SPage, SScrollView2, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Model from "../../../../Model";
import Asistencia from "../../../Asistencia";
import Entrenamiento from "../..";
import Sucursal from "../../../Sucursal";

// clientes.
// sucursal,
// mes
// hora de ingreso
// horarios bajos
// entrenadores
// en cada sucursal
// pero para entrenador debemos
// registrarlos
// Excel
// hacerlo por fecha


class reporte_asistencias_detalle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fecha_capturada = SNavigation.getParam("fecha", new SDate().toString("yyyy-MM-dd"));
    this.key_entrenamiento = SNavigation.getParam("key_entrenamiento");

  }
  componentDidMount() {
    this.getData();
  }

  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_ricky_entrenamientos",
      params: ["'" + this.fecha_capturada + "'"],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getSucursal = (key) => {
    var sucursal = Sucursal.Actions.getByKey(key, this.props);
    if (!sucursal) return <SLoad />
    // if (!sucursal) return <SLoad />
    return sucursal.descripcion;
  }

  getATC = (key) => {

    var usuario = Model.usuario.Action.getByKey(key);
    if (!usuario) return;
    return `${usuario.Nombres} ${usuario.Apellidos}`;
  }

  showLista() {

    var asistencia = Asistencia.Actions.getByKeyEntrenamiento({ key_entrenamiento: this.key_entrenamiento }, this.props);
    if (!asistencia) return <SLoad />

    const dataAsistencia = Object.values(asistencia).flat();



    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />

    var entrenamiento = Entrenamiento.Actions.getByKey(this.key_entrenamiento, this.props);
    if (!entrenamiento) return <SLoad />


    let suc = this.getSucursal(entrenamiento.key_sucursal);
    let atc = this.getATC(entrenamiento?.key_usuario);

    console.log("mira bobo ", dataAsistencia)
    return <STable2
      limit={18}
      data={dataAsistencia}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        { key: "descripcion", label: "descripcion", width: 80, render: key => !key ? "Manual" : "Molinete" },
        { key: "estado", label: "estado", center: true, width: 60 },
        { key: "fecha_on", label: "fecha", center: true, width: 100, render: (item) => { return new SDate(item).toString("yyyy-MM-dd") }, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "-cap_hora", label: "Hora", center: true, width: 100, render: (item) => { return new SDate(item.fecha_on).toString("hh:mm") }, component: (e) => <SText color={"orange"} >{`${e}`}</SText> },
        // { key: "-suc", label: "Sucursal", width: 80, render: key => suc, component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView> },
        {
          key: "key_usuario", label: "Usuario", width: 300, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },

        // {
        //   key: "-atcc", label: "ATC", width: 300, render: key => atc,
        //   component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        // },
        // {
        //   key: "-key_usuariasao", label: "ATC", width: 300, render: key =>  atc,
        //   component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        // },


      ]}
    />
  }
  render() {
    return (<>
      <SPage title={"Detalle Entrenamientos " + this.fecha_capturada}>
        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(reporte_asistencias_detalle);

