import { TouchableOpacity, View } from "react-native";
import { SIcon } from 'servisofts-component';
const FloatButtomMolinete = (props) => {
  if (props.esconder) {
    return <View />
  }
  return (
    <TouchableOpacity onPress={() => {
      props.onPress();
    }}
      style={{
        position: "absolute",
        // left: 10,
        // right: 50,
                        right: 10,

        // top: 100,
        bottom: 100,
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        ...props.style
      }}>
      <SIcon name={"Molinete"} />
      {/* <Svg name={"Add"} style={{
                width: "100%",
                height: "100%",
                // fill:"#C31"
            }} /> */}
    </TouchableOpacity >
  )
}
export default FloatButtomMolinete;