import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
  info: {
    component: "prestamo_pago",
  },
  Columns: {
    key: { type: "text", pk: true },
    key_usuario: { type: "text", fk: "usuario" },
    fecha_on: { type: "timestamp", label: "Fecha de registro" },
    estado: { type: "integer" },

    key_prestamo: { type: "text" },
    monto_prestado: { type: "double" },
    fecha_ultimo_pago: { type: "timestamp" },
    porcentaje: { type: "integer" },
    interes_porperiocidad: { type: "double" },
    interes_pordia: { type: "double" },
    interes_pagado: { type: "double" },
    estado_pago: { type: "integer" },
  },
  Action,
  Reducer,
});
