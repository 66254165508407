import { Component } from "react";
import { connect } from "react-redux";
import {
  SDate,
  SForm,
  SHr,
  SIcon,
  SInput,
  SList2,
  SLoad,
  SNavigation,
  SPage,
  SScrollView2,
  SText,
  STheme,
  SView,
} from "servisofts-component";
import SSocket from "servisofts-socket";

class Lista2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sucursal: null,
      fecha: new SDate().toString("yyyy-MM-dd"),

      // fecha: SNavigation.getParam("fecha", new SDate("2023-07-13").toString("yyyy-MM-dd")),
      func: "_ricky_entrenamientos",
      ...this.state,
    };
    this.key_sucursal = SNavigation.getParam("key_sucursal");
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      // params: ["'" + "2023-07-17" + "'"],
      params: ["'" + this.state.fecha + "'"],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }


  setColor(grado) {
    switch (grado) {
      case "Alto":
        return "green";
      case "Bajo":
        return "#ff5f5f";
      default:
        return "transparent";
    }
  }
  validarGrado(cantidad) {
    if (cantidad > 50) {
      return "Alto";
    }
    if (cantidad < 10) {
      return "Bajo";
    }
    else {
      return "";
    }
  }

  setColorPorAsistenciaManual(grado) {
    if (grado > 20) {
      return "green";
    } else if (grado > 10) {
      //  return "#ffaa00";
      return "#cc8f00";
    } else if (grado > 0) {
      // return "#E74C3C";
      // return "#A93226";
      return "#7f0000";
    } else {
      return "transparent";
    }
  }

  ItemDetalle(horarios) {
    // var usuarios = Model.usuario.Action.getAll();
    // if (!usuarios) return <SLoad />;

    // const horarios = [];
    // for (let i = 0; i < 16; i++) {
    //   const horario = {
    //     key_horario: i + 1,
    //     horario: `${6 + i}:00 AM`,
    //     entrenador:
    //       i % 2 === 0 ? "Juan Perez dominguez" : "Carlistos Perez dominguez",
    //     manual: Math.floor(Math.random() * 100),
    //     molinete: Math.floor(Math.random() * 30),
    //   };
    //   horarios.push(horario);
    // }
    return horarios.map((obj) => {
      return (
        <SView col={"xs-12"} backgroundColor={"transparent"}>
          <SView col={"xs-12"} backgroundColor={"transparent"} row
            style={{
              borderColor: "transparent",
              // borderColor: this.setColorPorAsistenciaManual(obj.manual),
              borderWidth: 1,
            }}
          >
            <SView col={"xs-2"} backgroundColor={"transparent"} row>
              <SText col={"xs-12"} fontSize={10}>
                {obj?.horario}
              </SText>
            </SView>

            <SView col={"xs-5"} row border={"transparent"}>
              <SText fontSize={10} col={"xs-12"}>
                {/* {obj?.key_entrenamiento}{" "} */}
                {/* {obj?.entrenador}{" "} */}
              </SText>
            </SView>

            <SView col={"xs-1.7"} center backgroundColor={this.setColorPorAsistenciaManual(obj.manual)} >
              <SText fontSize={10}> {obj.manual} </SText>
            </SView>

            <SView col={"xs-1.7"} center border={this.setColorPorAsistenciaManual(obj.molinete)}>
              <SText fontSize={10}> {obj.molinete} </SText>
            </SView>


            {obj?.manual || obj?.molinete ?
              <SView flex row center border={"transparent"} onPress={() => { SNavigation.navigate("entrenamiento_perfil", { key_entrenamiento: obj?.key_entrenamiento }) }} >
                <SIcon name={"Usuarios"} width={18} fill={"blue"} />
              </SView>
              : ""}
          </SView>
          <SHr height={2} color={STheme.color.card} />
        </SView >
      );
    });
  }

  Item(obj) {

    if (obj.key === "788ef65e-12a6-4fb5-b626-bf9b6dc1010d" ||
      obj.key === "1d897377-6ddb-46f8-895b-0ef369da64fe" ||
      obj.key === "489fdb5f-65aa-44c0-ac79-f5a084680325" ||
      obj.key === "7164d756-ebe5-48be-b471-5f413ea72b20" ||
      obj.key === "77a2f26f-7a65-40d5-8e2c-f8292b6def43" ||
      obj.key === "7bd13b8b-5811-4096-ab32-17129bd66423" ||
      obj.key === "24e3e37e-195f-431f-ae46-04d4842cb93b" ||
      obj.key === "a3d7655f-5e2d-4a72-9a5d-82a70aebb143" ||
      obj.key === "a4629322-fda0-4c00-8431-5267491c2432" ||
      obj.key === "df9b2831-b508-41bc-b5b6-c1a69d18818e" ||
      obj.key === "0a9b2cdd-6173-4e8e-b776-05a0f642090d" ||
      obj.key === "ffd2d5ce-a61d-46ad-97c4-b7a1947ed180" ||
      obj.key === "2b9cb90e-ea14-4ea7-9a1e-db103f7203cb") {
      return null;
    }

    return (<>

      <SView
        col={"xs-12 md-6 lg-4 xl-3"}
        // col={"xs-12 md-6 lg-4 xl-3"}
        // border={"red"}
        height={400}
        style={{ padding: 8 }}
        center
      >
        <SView col={"xs-12"}
          height
          card
          style={{ borderWidth: 1.5, borderRadius: 4, padding: 4, borderColor: STheme.color.card }}
        >
          <SView col={"xs-12"}
            height={60} center border={"transparent"}
            style={{ borderWidth: 1.5, borderRadius: 4 }}
          >
            <SText center fontSize={14} bold>{obj?.descripcion}</SText>
          </SView>

          <SView center col={"xs-12"} row backgroundColor={"transparent"}>
            <SView col={"xs-12"} height={80} card row center>


              <SView col={"xs-3"} height center>
                <SView center border={STheme.color.card} style={{ width: 70, height: 30, borderRadius: 4 }}>
                  <SText fontSize={14} bold>  {obj?.total_horarios} </SText>
                </SView>
                <SHr height={2} />
                <SText center fontSize={10}>  Horarios </SText>
              </SView>

              <SView col={"xs-3"} height center>
                <SView center border={STheme.color.card} style={{ width: 70, height: 30, borderRadius: 4 }}>
                  <SText fontSize={14} bold>  {obj?.total_horarios_activos} </SText>
                </SView>
                <SHr height={2} />
                <SText center fontSize={10}> Horas Activas  </SText>
              </SView>


              <SView col={"xs-3"} height center>
                <SView center border={STheme.color.card} style={{ width: 70, height: 30, borderRadius: 4 }}>
                  <SText fontSize={14} bold>
                    {obj.total_manual}
                  </SText>
                </SView>
                <SHr height={2} />
                <SText center fontSize={10}>Asis. Manual</SText>
              </SView>

              <SView col={"xs-3"} height center>
                <SView center border={STheme.color.card} style={{ width: 70, height: 30, borderRadius: 4 }}>
                  <SText fontSize={14} bold>
                    {obj.total_molinete}
                  </SText>
                </SView>
                <SHr height={2} />
                <SText center fontSize={10}> Asis. Molinete  </SText>
              </SView>

              {/* <SView col={"xs-3"} height center>
                <SView center border={STheme.color.card} style={{ width: 70, height: 30, borderRadius: 4 }}>
                  <SText fontSize={14} bold>
                    {obj.total_asistencias}
                  </SText>
                </SView>
                <SHr height={2} />
                <SText col={"xs-12"} row center fontSize={10}> Total Asist.</SText>
              </SView> */}
            </SView>
          </SView>
          <SHr height={16} />
          <SView col={"xs-11.8"} backgroundColor={"transparent"}>
            <SView col={"xs-12"} row center>
              <SView col={"xs-6"} row>
                <SText fontSize={10}>Detalle</SText>
              </SView>
              <SView flex style={{ justifyContent: "flex-end" }} row><SText fontSize={10}>{obj.total_horarios}</SText>
              </SView>
            </SView>
            <SHr height={4} color={STheme.color.card} />
            <SView col={"xs-12"} card row>
              <SView col={"xs-2"} row center>
                <SText fontSize={10}>Horarios</SText>
              </SView>
              <SView col={"xs-5"} row center>
                <SText fontSize={10}>Entrenador</SText>
              </SView>
              <SView col={"xs-1.7"} row center>
                <SText fontSize={10}> Manual</SText>
              </SView>
              <SView col={"xs-1.7"} row center>
                <SText fontSize={10}>Molinete</SText>
              </SView>
              <SView flex row center>
                <SText fontSize={10}>Usuarios</SText>
              </SView>
            </SView>
          </SView>
          <SHr height={8} />
          <SView col={"xs-11.8"} flex backgroundColor={"transparent"}>
            <SScrollView2 style={{ width: "100%" }} disableHorizontal>
              {this.ItemDetalle(obj.lista_horarios)}
            </SScrollView2>
          </SView>

          <SHr height={8} />

        </SView>

      </SView>

    </>

    );
  }

  getLista() {
    if (!this.state.data) return <SLoad />;
    console.log("mira bobo ", this.state.data)

    // return <SList2 data={this.state.data} horizontal space={0} render={obj_fondo =>
    //   this.Item(obj_fondo)
    // } />

    // var data = data_test.map((objeto) => Object.values(objeto)[0]);

    // const data = Array(12).fill(null);
    const data = this.state.data;
    // console.log("mira bobo ", data);
    let aux;
    return (
      <SList2
        data={data}
        horizontal
        space={0}
        render={(obj) =>
          // this.Item(obj_fondo)
          // aux = indice++;
          this.Item(obj)
        }
      />
    );
  }

  getParametros() {
    return (
      <SView col={"xs-12"} center row border={"transparent"}>
        <SView col={"xs-6 md-3"} height={40} center row border={"transparent"}>
          <SInput
            flex
            type={"date"}
            iconR={
              <SIcon
                name={"Evento"}
                width={20}
                fill={STheme.color.card}
                stroke={STheme.color.secondary}
                style={{ paddingRight: 10 }}
              />
            }
            customStyle={"calistenia"}
            defaultValue={this.state.fecha.toString("dd-MM-yyyy")}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 4,
              borderColor: "#666",
            }}
            onChangeText={(val) => {
              // this.setState({ fecha: val });

              if (this.state.fecha != val) {
                this.state.fecha = val;
                this.getData();
              }

              // if (this.state.parametros.inicio != val) {
              //   this.state.parametros.inicio = val;
              //   this.getData();
              //  }
            }}
          />
        </SView>
      </SView>
    );
  }

  getForm() {

    return <SForm
      ref={(ref) => { this.form = ref; }}
      row
      style={{
        justifyContent: "space-between",
      }}
      inputProps={{
        col: "xs-12",
        customStyle: "calistenia",

      }}
      inputs={{
        Nombres: { label: "Entrenador", isRequired: true, defaultValue: this.usr.Nombres, },
        Apellidos: { label: "Sucursal", isRequired: true, defaultValue: this.usr.Apellidos, },
        Apellidos: { label: "Dia", isRequired: true, defaultValue: this.usr.Apellidos, },

        CI: { label: "hora", isRequired: true, defaultValue: this.usr.CI, col: "xs-5.5" },
        CI: { label: "hora", isRequired: true, defaultValue: this.usr.CI, col: "xs-5.5" }


      }}
      onSubmit={(values) => {
        this.state.onLoad = true;
        // if (this.key) {
        //   Usuario.Actions.editar({
        //     ...this.usr,
        //     ...values
        //   }, this.props);

        // } else {
        //   Usuario.Actions.registro(values, this.key_rol, this.props);
        // }

      }}
    />
  }

  reporte() {
    return <>
      <SView flex border={"yellow"} backgroundColor={STheme.color.primary} style={{ padding: 4, position: "absolute", top: 35, left: 50, }}>
        <SText fontSize={10}>Asist. Manual {'>'} 1  =  <SView width={8} height={8} backgroundColor={"red"} ></SView></SText>
        <SText fontSize={10}>Asist. Manual {'>'} 10 =  <SView width={8} height={8} backgroundColor={"yellow"} ></SView></SText>
        <SText fontSize={10}>Asist. Manual {'>'} 20 =  <SView width={8} height={8} backgroundColor={"green"} ></SView></SText>
        <SText fontSize={8}> </SText>
        <SText fontSize={10}>Nota: Las sucursales del dashboard están ordenado</SText>
        <SText fontSize={10}>por el total de <SText fontSize={10} color="green"> Asist. molinete y Asist. manuales </SText></SText>
      </SView>
    </>
  }

  render() {
    return (<>
      <SPage title={"Entrenamientos historicos"}>
        {this.getParametros()}
        <SHr height={24} color={"transparent"}></SHr>
        <SView
          col={"xs-12"}
          center
          row
          style={{
            // paddingStart: 10,
            // paddingEnd: 10,
            // paddingTop: 50,
            // paddingBottom: 0,
            flex: 1,
            width: "100%",
          }}
        >
          {this.getLista()}

        </SView>

        {/* <SHr height={50} color={"red"}></SHr> */}
      </SPage>
      {this.reporte()}
    </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(Lista2);
