import { Component } from 'react';
import { View, TouchableOpacity, Animated } from 'react-native';
import { connect } from 'react-redux';
import { SHr, SNavigation, SPage, SScrollView2, SStorage, SText, STheme, SView } from 'servisofts-component';
import BarraSuperior from '../../../../Components/BarraSuperior';
import FloatButtom from '../../../../Components/FloatButtom/index';
import CajasActivas from './CajasActivas';
import EstadoCaja from './EstadoCaja';
import Menu from './Menu/index';
import Movimientos from './Movimientos';
import Sucursal from './Sucursal';
import Model from '../../../../Model';
import FloatButtomMolinete from '../../../../Components/FloatButtomMolinete';


var _sucursal;
class CajaPage extends Component {
  static navigationOptions = {
    headerShown: false,
  }
  constructor(props) {
    super(props);
    this.state = {
      sucursal: _sucursal,
      activa: false,
      montoEnCaja: 0,
    };
    this.animatedSelec = new Animated.Value(0);

  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true })
    SStorage.getItem("sucursal", (vl) => {
      this.setState({ key_sucursal: vl });
    })

  }

  startAnimated() {
    Animated.loop(
      Animated.timing(this.animatedSelec, {
        toValue: 1,
        delay: 0,
        duration: 1000,
      }),
      { iterations: 1000 },
    ).start();
  }

  getFB() {
    if (!this.state.activa) return <View />
    return <>
      <FloatButtomMolinete
        label={"+"}
        onPress={() => {
          this.props.navigation.navigate("dispositivo");
        }}
      />
      <SHr height={1}></SHr>
      <FloatButtom label={"+"} onPress={() => {
        if (!this.state.activa.key) {
          return null;
        }
        this.props.navigation.navigate("CajaMovimientoRegistroPage", { key_caja: this.state.activa.key });
      }} />
    </>
  }

  getBtnAdd2 = () => {
    if (!this.state.activa) return <SView />
    return <SView col={"xs-12 md-4"} center height={50} style={{ width: "100%", borderColor: "yellow", borderWidth: 1, borderRadius: 8, bottom: 4 }} onPress={() => {
      SNavigation.navigate("PaquetePage", {
        type: "select",
        onSelect: (obj) => {
          SNavigation.goBack();
          SNavigation.navigate("ClientePaqueteRegistroPage2", {
            // key_usuario: this.props.key_usuario,
            key_paquete: obj.key,
            key_caja: this.state.activa.key
          });
        }
      });
    }}>   <SText color='yellow' >Vender paquetes</SText>
    </SView>
  }


  getHistorialCaja_CajaActiva() {
    if (this.state.activa) {
      this.startAnimated();
      return <>
        <SView col="xs-11 md-6 lg-4" center row border={"cyan"} animated
          style={{
            height: 50, borderRadius: 8,
            backgroundColor: this.animatedSelec.interpolate({
              inputRange: [0, 1],
              outputRange: ["#00ffff33", "#00000000"],
            })
          }} props={{
            animated: true,
          }}
          onPress={() => { SNavigation.navigate("CajasPage2", { key_sucursal: this.state.key_sucursal }); }}>
          <SText center style={{ color: "cyan", fontSize: 14 }}>HISTORIAL DE CIERRES DE CAJA</SText>

        </SView>
        <SHr height={16} />
      </>
    } else {
      return;
    }
  }






  render() {
    _sucursal = this.state.sucursal;
    if (this.props.state.cajaReducer.usuario) {
      this.state.activa = this.props.state.cajaReducer.usuario[this.props.state.usuarioReducer.usuarioLog.key];
    }


    return (<SPage title={"Cajasss"} hidden disableScroll>
      <BarraSuperior title={"Módulo Caja"} goBack={() => {
        // SNavigation.goBack();
        SNavigation.reset();
      }} />
      <SView col={"xs-12"} style={{
        flex: 1,
        width: "100%",
      }}>
        <SScrollView2 disableHorizontal style={{
          width: "100%"
        }}>
          <SView col={"xs-12"} center>
            <SHr height={24} />
            {this.getHistorialCaja_CajaActiva()}



            {/* aqui vemos la Sucursal aperturada*/}
            <Sucursal
              navigation={this.props.navigation}
              key_sucursal={this.state.key_sucursal}
              sucursal={this.state.sucursal}
              key_caja={this.state.key_caja}
              setSucursal={(suc) => {
                if (!suc) return null
                SStorage.setItem("sucursal", suc.key)
                this.setState({ sucursal: suc, key_sucursal: suc.key });
              }}
            />

            {/* aqui vemos el ATC/Cajero Activo */}
            <CajasActivas sucursal={this.state.sucursal} key_caja={this.state.key_caja} />
            <SHr />

            {/* aqui vemos el monto en caja */}
            <EstadoCaja
              montoEnCaja={this.state.montoEnCaja}
              navigation={this.props.navigation}
              sucursal={this.state.sucursal}
              setKeySucursal={(suc, key_caja) => {
                if (!this.state.key_sucursal) {
                  this.setState({ key_sucursal: suc, key_caja: key_caja });
                }
              }} setActiva={(caja) => {
                this.setState({ activa: caja })
              }} />
            <Menu />


            {/* {this.getBtnAdd2()} */}



            <Movimientos setActiva={(caja) => {
              if (!this.state.activa) {
                this.setState({ activa: caja })
              }
            }} />
          </SView>


          <SView style={{
            height: 20,
          }}></SView>

        </SScrollView2>

        {this.getFB()}
      </SView>
    </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(CajaPage);