import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SPage, SText } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos'
import { Parent } from '.';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <SPage title={'Migrador'}>
                <SHr h={20} />
                <MenuPages path={Parent.path}>
                    <MenuButtom label='Usuarios' url='/migrador/usuarios' />
                    <MenuButtom label='Ventas' url='/migrador/ventas' />
                </MenuPages>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);