import React, { Component } from 'react';
import { Text, TouchableOpacity, View, TextInput, Dimensions, Image, ScrollView, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { SImage, SLoad, STheme } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Servicio from '../../Servicio';
import Model from '../../../Model';

const ServicioDePaquete = (props) => {
  const [select, setSelect] = React.useState({});

  // componentDidMount() {

  //   Model.usuario.Action.getAll({ force: true });
  // }

  let data = Servicio.Actions.getAll(props);
  let paqueteservicio = Model.paqueteServicio.Action.getAll({
    key_paquete: props.keyPaquete,
    key_usuario: Model.usuario.Action.getKey()
  })
  if (!props.keyPaquete) {
    paqueteservicio = {}
  }
  if (!data) return <SLoad />
  if (!paqueteservicio) return <SLoad />
  console.log(props.keyPaquete)
  console.log(paqueteservicio)
  const getLista = () => {
    let data_p = {};
    // if (props.keyPaquete) {
    //   data_p = paqueteservicio
    //   if (!select) {
    //     data_p = Object.values(data_p).find(x => x.key_paquete == props.keyPaquete);
    //     setSelect(data_p);
    //     props.onChange(select);
    //   }

    // }

    return Object.keys(data).map((key) => {
      var obj = data[key];
      var isActivo = false;
      var ps = Object.values(paqueteservicio).find(a => a.key_servicio == obj.key && a.estado != 0);
      if (ps) {
        isActivo = ps;
      }

      if (select[key]) {
        isActivo = true;
      }

      return <TouchableOpacity style={{
        width: 160,
        height: 140,
        margin: 8,
        borderRadius: 4,
        borderWidth: 1,


        borderWidth: isActivo ? 1 : 0.5,
        borderColor: isActivo ? "green" : STheme.color.card,

        // borderWidth:   0.5,
        // borderColor:   "green" ,

        backgroundColor: STheme.color.card,
        // padding:4,

      }}
        onPress={() => {
          if (props.keyPaquete) {
            if (!isActivo) {
              var object = {
                component: "paqueteServicio",
                type: "registro",
                key_usuario: props.state.usuarioReducer.usuarioLog.key,
                estado: "cargando",
                data: {
                  key_paquete: props.keyPaquete,
                  key_servicio: key,
                }
              }
              SSocket.send(object);
            } else {
              var object = {
                component: "paqueteServicio",
                type: "editar",
                key_usuario: props.state.usuarioReducer.usuarioLog.key,
                estado: "cargando",
                data: { ...isActivo, estado: 0 }
              }
              SSocket.send(object);
            }
          } else {
            if (select[key]) {
              delete select[key];
            } else {
              select[key] = obj;
            }
            setSelect({ ...select });
            props.onChange(select);
          }


        }}>
        <View style={{
          flex: 1
        }}>
          <View style={{
            padding: 8,

            height: 110,
            borderRadius: 8,
            overflow: "hidden"
          }}>
            <SImage src={SSocket.api.root + "servicio/" + obj.key} />
          </View>
          <View style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Text style={{
              fontSize: 18,
              fontWeight: "bold",
              // color: 'ree'
              color: isActivo ? STheme.color.secondary : STheme.color.card,

              // color: STheme.color.
            }}>{obj.descripcion}</Text>
          </View>
        </View>
        {(isActivo ? <View /> : <View style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          borderRadius: 8,
          backgroundColor: "#000000dd",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Text style={{
            fontSize: 20,
            color: STheme.color.text,
            fontWeight: "bold"
          }}>Activar</Text>
        </View>)}
      </TouchableOpacity>
    })
  }

  return <View style={{
    marginTop: 16,
    width: "100%",
    borderRadius: 8,
    // padding: 8,
    minHeight: 220,
    marginBottom: 32,

  }}>
    <Text style={{
      // padding: 8,
      // fontSize: 12,
      // color: "#999",
      // width: "100%",
      // textAlign: "center"

      padding: 8,
      fontSize: 14,
      color: STheme.color.secondary,
      width: "100%",
      textAlign: "center"


    }}>SERVICIOS ASIGNADAS PARA LA APLICACIÓN:</Text>
    {/* }}>Servicios que incluye el paquete:</Text> */}
    <View style={{
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    }}>
      {getLista()}
    </View>
  </View>
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ServicioDePaquete);