import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import Container from '../../Components/Container'
export default class root extends Component {
    state = {}
    componentDidMount() {
        SSocket.sendPromise({
            component: "facturacion",
            type: "getParametricas"
        }).then(e => {
            this.setState({ data: e.data })
        }).catch(e => {
            console.error(e)
        })

    }


    _label({ label, value, onPress }) {
        return <SView row center onPress={onPress}>
            <SText color={STheme.color.gray} style={{
                textAlign: 'right',
            }}>{label} :</SText> <SText fontSize={18}>{value}</SText>
        </SView>
    }

    renderParametrica(type) {
        const parametricas = this.state?.data?.empresa?.parametricas;
        return this._label({
            label: type,
            value: (parametricas[type] ?? []).length,
            onPress: () => { SNavigation.navigate("/facturacion/parametricas/table", { type: type }) }
        })
    }
    renderContent() {
        if (!this.state.data) return <SLoad />
        const {
            RAZON_SOCIAL,
            NOMBRE_REP_LEGAL,
            NOMBRE_DOMINIO,
            NIT,
            MAIL_REP_LEGAL,
            KEY_WEB_SERVICE,
            CI_REP_LEGAL,
            sucursales,
            regionales,
            parametricas
        } = this.state?.data?.empresa;
        return <SView col={"xs-12"} center>
            <SHr />
            {this._label({ label: "NIT", value: NIT })}
            {this._label({ label: "RAZON SOCIAL", value: RAZON_SOCIAL })}
            <SHr />
            {this._label({ label: "CI", value: CI_REP_LEGAL })}
            {this._label({ label: "Nombre", value: NOMBRE_REP_LEGAL })}
            {this._label({ label: "Email", value: MAIL_REP_LEGAL })}
            {/* <SHr /> */}
            {/* {this._label({ label: "Domain", value: NOMBRE_DOMINIO })} */}
            {/* {this._label({ label: "Key", value: KEY_WEB_SERVICE })} */}
            <SHr h={32} />
            {this._label({ label: "Sucursales", value: (sucursales ?? []).length })}
            {this._label({ label: "Regionales", value: Object.keys(regionales ?? {}).length })}
            <SHr h={32} />

            {/* {this._label({ label: "Metodos de pago", value: (parametricas?.metodos_pago ?? []).length })}
            {this._label({ label: "Motivos de anulacion", value: (parametricas?.motivos_anulacion ?? []).length })}
            {this._label({ label: "Productos y servicios", value: (parametricas?.productos_servicios ?? []).length })}
            {this._label({ label: "Tipos de documentos de identidad", value: (parametricas?.tipos_documento_identidad ?? []).length })}
            {this._label({ label: "Unidades de medida", value: (parametricas?.unidades_medida ?? []).length })} */}
            {this.renderParametrica("leyendas_factura")}
            {this.renderParametrica("metodos_pago")}
            {this.renderParametrica("motivos_anulacion")}
            {this.renderParametrica("productos_servicios")}
            {this.renderParametrica("tipos_documento_identidad")}
            {this.renderParametrica("unidades_medida")}
        </SView>
    }
    render() {
        return <SPage>
            <Container>
                <SText fontSize={18} bold center>Facturacion</SText>
                {this.renderContent()}
            </Container>

        </SPage>
    }
}