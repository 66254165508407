import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SImage, SLoad, SNavigation, SPage, SStorage, SText, SView } from 'servisofts-component';
import Model from '../../../Model';
import Container from '../../../Components/Container';
import FotoPerfilUsuario from '../../../Components/FotoPerfilUsuario';
import HuellasDeUsuario from '../../../Services/zkteco/Components/usuario_huella/Components/HuellasDeUsuario';
// import SincronizarUsuario from '../../../Services/zkteco/Components/usuario_huella/Components/SincronizarUsuario';
import SSocket from 'servisofts-socket';
// import { View } from 'servisofts-rn-spdf';
import BtnSincronizar from '../../../Services/zkteco/Components/dispositivo/Components/BtnSincronizar';
import Sucursal from '../../Sucursal';
import dispositivo from '../../../Services/zkteco/Components/dispositivo';
import punto_venta from '../../../Services/zkteco/Components/punto_venta';
import SincronizarUsuario from '../../../Services/zkteco/Components/usuario_huella/Components/SincronizarUsuario';

class Huellas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // sucursal: _sucurssal,
            // activa: false,
            // montoEnCaja: 0,
        };
        this.keyUsuario = SNavigation.getParam("key");
    }

    componentDidMount() {
        this.getActiva();
    }
    getActiva() {
        if (!this.props.state.usuarioReducer.usuarioLog) {
            return;
        }
        var reducer = this.props.state.cajaReducer;
        var data = reducer.usuario[this.props.state.usuarioReducer.usuarioLog.key];
        if (this.getCajaActiva()) return;
        if (reducer.estado == "cargando") return;
        if (reducer.lastGetActiva) return;
        var obj = {
            component: "caja",
            type: "getActiva",
            estado: "cargando",
            key_usuario: this.props.state.usuarioReducer.usuarioLog.key
        }
        SSocket.send(obj);
    }

    getCajaActiva() {
        var reducer = this.props.state.cajaReducer;
        var data = reducer.usuario[this.props.state.usuarioReducer.usuarioLog.key];
        return data;
    }

    renderProfile() {
        const usuario = Model.usuario.Action.getByKey(this.keyUsuario) ?? {};
        return <SView col={"xs-12"} card padding={4} center>
            <SView style={{
                width: 180,
                height: 180,
            }} center>
                <FotoPerfilUsuario usuario={usuario} />
            </SView>
            <SHr />
            <SText fontSize={18} bold>{usuario["Nombres"]} {usuario["Apellidos"]}</SText>
            <SHr />
            <SText >CI: {usuario["CI"]}</SText>
            <SText >Tel.: {usuario["Telefono"]}</SText>
            <SText >Correo: {usuario["Correo"]}</SText>
            <SHr />
            <SHr />
        </SView>
    }

    renderSincronizarUsuariosMolinete() {
        Sucursal.Actions.getAll(this.props);
        punto_venta.Actions.getAll(this.props);
        var data = dispositivo.Actions.getAll(this.props);
        if (!data) return <SLoad />;

        var cajaActiva = this.getCajaActiva();
        if (!cajaActiva) {
            this.getActiva()
            return <SText>debe abrir caja</SText>
            // return null;
        }
        console.log("aqui ", data)
        return <>
            <SHr height={40} />
            {/* <BtnSincronizar  key_sucursal={cajaActiva.key_sucursal} /> */}
            <BtnSincronizar dispositivo={data} key_sucursal={cajaActiva.key_sucursal} />
        </>
    }

    render() {
        return (
            <SPage title={'Huellas'}>
                <Container>
                    {/* <SView col={"xs-12"} center row> */}
                    {this.renderProfile()}
                    <SHr height={40} />
                    <HuellasDeUsuario key_usuario={this.keyUsuario} />
                    <SHr height={40} />
                    <SincronizarUsuario key_usuario={this.keyUsuario} />
                    <SHr height={40} />
                    {/* </SView> */}
                </Container>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Huellas);