import { Component } from 'react';
import { connect } from 'react-redux';
import { SLoad, SPage, STable2 } from 'servisofts-component';
// import FloatButtom from '../../../../../Components/FloatButtom';
// import Model from '../../../../../Model';
import SSocket from 'servisofts-socket';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Reporte Name",
    };

  }

  componentDidMount() {
    this.getLista();
    // this.getData();

  }




  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      // params: this.state.params,
      // params: ["'" + 123123 + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }




  getLista() {
    var data = this.getData();

    if (!data) return <SLoad />

    console.log("data ", data);


    return <STable2
      header={[
        { key: "index", label: "#", width: 50, },
        { key: "sucursal", label: "sucursal", width: 160, order: "asc", },
        { key: "key_prestamista", label: "Prestamista", width: 160 },

      ]}
      // filter={(data) => {
      //   if (data.estado != 1) return false;
      //   return true;
      // }}
      data={data}
    />
  }








  render() {
    return (<><SPage title={'Documentos'} onRefresh={(end) => {
      // Model.prestamo.Action.CLEAR();
      // end()
    }} disableScroll>

      {this.getLista()}



    </SPage >
    </>
    )
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(index);
