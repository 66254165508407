import { SPage } from "servisofts-component";

import root from "./root";

import calendario from "./calendario";
import calendarioDetalle from "./calendarioDetalle";
import calendarioDetalleGlobal from "./calendarioDetalleGlobal";
import comparativos from "./comparativos";
import cumpleaños from "./cumpleaños";
import { default as historial } from "./historial";
import inactivos from "./inactivos";
import nuevos from "./nuevos";
import renovacion from "./renovacion";
import resumen_anual from "./resumen_anual";
export const Parent = {
  title: "fidelizacion",
  name: "fidelizacion",
  path: "/fidelizacion",
};
export default SPage.combinePages(Parent.name, {
  "": root,
  comparativos: comparativos,
  cumpleaños: cumpleaños,
  historial: historial,
  nuevos: nuevos,
  renovacion: renovacion,
  calendario: calendario,
  calendarioDetalle: calendarioDetalle,
  calendarioDetalleGlobal: calendarioDetalleGlobal,
  inactivos: inactivos,
  resumen_anual: resumen_anual,
});
