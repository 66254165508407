import { Component } from "react";
import { connect } from "react-redux";
import { SButtom, SHr, SInput, SLoad, SNavigation, SPage, SText, STheme, SView, Upload } from "servisofts-component";
import SSocket from 'servisofts-socket';
import FotoPerfilUsuario from "../Components/FotoPerfilUsuario";
import Model from "../Model";

class documentos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.key_usuario = SNavigation.getParam("key_usuario", "fad45bc1-015a-48e9-9e7a-1162daaad707");
  }




  valido_NombreCompleto(nombre, apellidos) {
    return <SText style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{"Nombre Completo: " + (nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_CI(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 7 ? STheme.color.text : "cyan"} >{"CI: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText backgroundColor='red' color="white" bold > error: el CI tiene menos de 7 dígitos </SText> : ""}
    </SView>
  }

  valido_Telefono(telefono) {
    return <SView col={"xs-12"} row >
      <SText color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}   </SText>
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText backgroundColor='red' color="white" bold > error: campo Telefono </SText> : ""}
    </SView>
  }

  valido_Correo(correo) {
    return <SText style={{ color: (correo ?? "").length < 12 ? "red" : "cyan" }}>{"Correo: " + (correo ?? "")} </SText>;
  }


  valido_Cumpleaños(Cumpleaños) {
    return <SView col={"xs-12"} row >
      <SText color={STheme.color.text} >{"F.Nacimiento: " + (Cumpleaños ?? "")}   </SText>
      {(Cumpleaños ?? "").length < 9 ? <SText backgroundColor='red' color="white" bold > error: campo Fecha Nacimiento </SText> : ""}
    </SView>
  }

  valido_RazonSocial(nombre) {
    return <SView col={"xs-12"} row >
      <SText color={(nombre ?? "").length < 5 ? STheme.color.text : "cyan"} >{"Razón Social: " + (nombre ?? "")}   </SText>
      {(nombre ?? "").length < 5 ? <SText backgroundColor='red' color="white" bold > error: La razón social tiene poco caracteres </SText> : ""}
    </SView>
  }

  valido_Nit(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 5 ? STheme.color.text : "cyan"} >{"Nit: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 5 ? <SText backgroundColor='red' color="white" bold > error: el CI/NIT tiene menos de 5 dígitos </SText> : ""}
    </SView>
  }
  getPerfil() {
    this.data = Model.usuario.Action.getByKey(this.key_usuario);
    if (!this.data) return <SLoad />
    return <SView center col={"xs-11 md-11 lg-7"} style={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 8 }} row border>
      <SView col={"xs-12"} row height center  >
        <SHr height={24} />

        <SView col={"xs-12 md-4"} border={"transparent"} center >
          <SView border="transparent" style={{ width: 180, height: 180, }} center>
            <FotoPerfilUsuario usuario={this.data} />
          </SView>
        </SView>
        <SView col={"xs-12 md-8"} border={"transparent"} center>
          <SView col={"xs-12"}>
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos de usuario</SText>
            <SHr height={4} />
            {this.valido_NombreCompleto(this.data?.Nombres, this.data?.Apellidos)}
            {this.valido_CI(this.data?.CI)}
            {this.valido_Telefono(this.data?.Telefono)}
            {this.valido_Cumpleaños(this.data["Fecha nacimiento"])}
            {this.valido_Correo(this.data?.Correo)}
            <SHr />
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos para la facturación</SText>
            <SHr height={4} />
            {this.valido_Nit(this.data?.Nit)}
            <SHr height={4} />
            {this.valido_RazonSocial(this.data?.RazonSocial)}
          </SView>
        </SView>


        <SHr height={24} />
      </SView>
    </SView >
  }



  getLista() {



    return <>
      <SView col={"xs-12"} row center border={"transparent"} >
        <SView col={"xs-6"} center border={"transparent"} >
          <SView border={STheme.color.secondary + "22"}
            style={{ width: 450, height: 250, padding: 16, backgroundColor: STheme.color.card, borderRadius: 8 }}  >
            <SText fontSize={16} bold >Documento Frontal</SText>
            <SView col={"xs-12"} height center border={"transparent"}>
              <SInput ref={inst => this.inpimgFront = inst} center type="image" label={"Imagen Frontal"} defaultValue={`${SSocket.api.root}documento/${this.key_usuario}/frontal`} />
              <SButtom onPress={() => {
                let value = this.inpimgFront.getValue();
                Upload.sendPromise({ file: value[0].file }, SSocket.api.root + "upload/documento/" + this.key_usuario + "/frontal");
                console.log(value);
              }} type={"danger"}>SUBIR</SButtom>

            </SView>
          </SView >
        </SView >
        <SView col={"xs-6"} center >
          <SView border={STheme.color.secondary + "22"}
            style={{ width: 450, height: 250, padding: 16, backgroundColor: STheme.color.card, borderRadius: 8 }}  >
            <SText fontSize={16} bold >Documento Reverso</SText>
            <SView col={"xs-12"} height center border={"transparent"}>
              <SInput ref={inst => this.inpimgBack = inst} type="image" label={"Imagen Reverso"} defaultValue={`${SSocket.api.root}documento/${this.key_usuario}/reverso`} />
              <SButtom onPress={() => {
                let value = this.inpimgBack.getValue();
                Upload.sendPromise({ file: value[0].file }, SSocket.api.root + "upload/documento/" + this.key_usuario + "/reverso");
                console.log(value);
              }} type={"danger"}>SUBIR</SButtom>
            </SView>
          </SView >
        </SView >
      </SView >

    </>
  }





  render() {
    return (
      <SPage title={'Registrar Documentos'} disableScroll center>
        <SView col={"xs-9"} center row border="transparent">
          {this.getPerfil()}
          <SHr height={32} />
          {this.getLista()}
        </SView>
        <SLoad type='window' hidden={!this.state.loading} />
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(documentos);
