import { SDate } from "servisofts-component";
import { FacturaType } from "./type";
import XLSX, { WorkBook } from 'xlsx-color';

export const crear_excel = (arr: FacturaType[], empresa: any) => {
    var book: WorkBook = XLSX.utils.book_new();
    arr = arr.filter(a => {
        // anulada, emitida, enviada, procesando
        if (a.CABECERA.CODIGO_AMBIENTE != 1) return false;

        return true;
    })
    crear_resumen(arr, book);
    crear_detalle(arr.filter(a => a.CABECERA.ESTADO != "anulada"), book, "Detalle", empresa);
    crear_detalle(arr.filter(a => a.CABECERA.ESTADO == "anulada"), book, "Anuladas", empresa);
    XLSX.writeFile(book, "reporte_de_facturas.xlsx");
}

let alignment = {
    vertical: "center", // Centra verticalmente
    horizontal: "center", // Centra horizontalmente
    wrapText: true // Activa el ajuste de texto
}
let style = {
    fill: {
        fgColor: { rgb: "DCDFE5" } // Amarillo, como ejemplo
    },
    alignment: alignment,
    border: {
        bottom: { style: 'thin', color: { rgb: "272546" } },
    },
}
let style2 = {

    fill: {
        fgColor: { rgb: "FFFFFF" } // Amarillo, como ejemplo
    },
    alignment: alignment
}
let style3 = {
    fill: {
        fgColor: { rgb: "272546" } // Amarillo, como ejemplo
    },
    alignment: alignment
}
let styleTitle = {

    fill: {
        fgColor: { rgb: "DCDEE6" } // Amarillo, como ejemplo
    },
    alignment: alignment
}

const crear_resumen = (arr: FacturaType[], book: WorkBook) => {

    let val = {
        cantidad_factura: 0,
        monto_factura: 0,
        cantidad_anuladas: 0,
        monto_anuladas: 0,
    }
    arr.map(a => {
        if (a.CABECERA.ESTADO == "anulada") {
            val.cantidad_anuladas += 1;
            val.monto_anuladas += a.CABECERA.MONTOTOTAL;
        } else {
            val.cantidad_factura += 1;
            val.monto_factura += a.CABECERA.MONTOTOTAL;
        }

    })

    var worksheet = XLSX.utils.aoa_to_sheet([
        ["Resumen de Ventas Calistenia Bolivia S.R.L."],
        [],
        ["Tipo de documento de venta", "# Documentos de venta emitidos", "Valos documentos de venta emitidos (en Bs.) -> No incluye anulados", "# Documentos de venta anulados", "Valor documentos de venta anulados"],
        ["Facturas (Incluye prefacturas transformadas en facturas)", val.cantidad_factura, val.monto_factura, val.cantidad_anuladas, val.monto_anuladas],
        ["Prefacturas (nomre prefactura)", 0, "-", 0, "-"],
        ["TOTAL", val.cantidad_factura, val.monto_factura, val.cantidad_anuladas, val.monto_anuladas],
        [],
        [],
        [],
        ["% Ventas con factura/ ventas totales", "100%"],
        ["% Ventas con prefactura/ ventas totales", "-"],
        ["Prefacturas convertidas en factura", "0"],
        ["Valor prefacturas convertidas en factura", "-"],
    ]);

    worksheet["!cols"] = [
        { wpx: 300 }, // Ancho de la primera columna
        { wpx: 120 }, // Ancho de la segunda columna
        { wpx: 120 }, // Ancho de la tercera columna
        { wpx: 120 }, // Ancho de la cuarta columna
        { wpx: 120 }, // Ancho de la quinta columna
    ];
    worksheet["!rows"] = [
        {},
        {},
        { hpx: 50 },
        { hpx: 50 },
    ]


    worksheet["A1"].s = styleTitle
    worksheet["A3"].s = styleTitle
    worksheet["B3"].s = styleTitle
    worksheet["C3"].s = styleTitle
    worksheet["D3"].s = styleTitle
    worksheet["E3"].s = styleTitle

    worksheet["A4"].s = style2
    worksheet["B4"].s = style2
    worksheet["C4"].s = style2
    worksheet["D4"].s = style2
    worksheet["E4"].s = style2

    worksheet["A5"].s = style
    worksheet["B5"].s = style
    worksheet["C5"].s = style
    worksheet["D5"].s = style
    worksheet["E5"].s = style

    worksheet["A6"].s = style3
    worksheet["B6"].s = style2
    worksheet["C6"].s = style2
    worksheet["D6"].s = style2
    worksheet["E6"].s = style2


    worksheet["A10"].s = styleTitle
    worksheet["B10"].s = style
    worksheet["A11"].s = styleTitle
    worksheet["B11"].s = style
    worksheet["A12"].s = styleTitle
    worksheet["B12"].s = style
    worksheet["A13"].s = styleTitle
    worksheet["B13"].s = style
    XLSX.utils.book_append_sheet(book, worksheet, "Resumen");
}
const crear_detalle = (arr: FacturaType[], book: WorkBook, name: string, empresa: any) => {

    let data: any = [];

    arr.map(a => {
        let metodo_pago = empresa.parametricas.metodos_pago.find((b: any) => b.CODIGO == a.CABECERA.CODIGOMETODOPAGO);
        data.push([
            new SDate(a.CABECERA.FECHAEMISION, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd"),
            new SDate(a.CABECERA.FECHAEMISION, "yyyy-MM-ddThh:mm:ss").toString("hh:mm"),
            "Factura",
            a.CABECERA.CUF,
            a.CABECERA.NUMEROFACTURA,
            0,
            // a.CABECERA.USUARIO,
            "",
            a.CABECERA.NUMERODOCUMENTO,
            a.CABECERA.NOMBRERAZONSOCIAL,
            "",
            a.DETALLE.length,
            0,
            a.CABECERA.MONTOTOTAL,
            "",
            0,
            metodo_pago?.DESCRIPCION,
            a.CABECERA.ESTADO,
            "No",
            a.CABECERA.CODIGOSUCURSAL,
            "",
            "",
            "Bs-BOLIVIANO",
            ""
        ])
    })



    var worksheet = XLSX.utils.aoa_to_sheet([
        ["Fecha", "Hora", "Tipo de venta", "Autorización", "Número", "Numero Pre-Factura", "Vendedor/Punto de venta", "Nit", "Razón Social", "Detalle", "Cantidad total de items", "Descuentos", "Monto Total", "Pagos divididos", "Giftcards", "Medio de pago", "Estado documento de venta", "Viene de una prefactura", "Sucursal", "Nota interna", "Actividad Económica", "Moneda", "Usuario Anulación"],
        ...data
    ])

    worksheet["!cols"] = [
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 220 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 220 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 80 },
    ];
    worksheet["A1"].s = styleTitle
    worksheet["B1"].s = styleTitle
    worksheet["C1"].s = styleTitle
    worksheet["D1"].s = styleTitle
    worksheet["E1"].s = styleTitle
    worksheet["F1"].s = styleTitle
    worksheet["G1"].s = styleTitle
    worksheet["H1"].s = styleTitle
    worksheet["I1"].s = styleTitle
    worksheet["J1"].s = styleTitle
    worksheet["K1"].s = styleTitle
    worksheet["L1"].s = styleTitle
    worksheet["M1"].s = styleTitle
    worksheet["N1"].s = styleTitle
    worksheet["O1"].s = styleTitle
    worksheet["P1"].s = styleTitle
    worksheet["Q1"].s = styleTitle
    worksheet["R1"].s = styleTitle
    worksheet["S1"].s = styleTitle
    worksheet["T1"].s = styleTitle
    worksheet["U1"].s = styleTitle
    worksheet["V1"].s = styleTitle
    worksheet["W1"].s = styleTitle
    XLSX.utils.book_append_sheet(book, worksheet, name);
}