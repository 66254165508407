import { Component } from 'react';
import { Text } from 'react-native';
import { connect } from 'react-redux';
import { SHr, SInput, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import BarraSuperior from '../../../../Components/BarraSuperior';
import FotoPerfilUsuario from '../../../../Components/FotoPerfilUsuario';
import Model from '../../../../Model';
import Sucursal from '../../../Sucursal';


const arr = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"];

let arr_dias_semana = [
  { key: "1", content: "Lunes" },
  { key: "2", content: "Martes" },
  { key: "3", content: "Miercoles" },
  { key: "4", content: "jueves" },
  { key: "5", content: "Viernes" },
  { key: "6", content: "Sabado" },
  { key: "7", content: "Domingo" },
];



class horarios extends Component {
  static navigationOptions = ({ navigation }) => {
    return { headerShown: false, }
  }
  constructor(props) {
    super(props);
    this.key_entrenador = SNavigation.getParam("key", false);
    this.bandera = false;
    this.state = {
      dias: {
        "0": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" }
        },
        "1": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" }
        },
        "2": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" },
        },
        "3": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" },
        },
        "4": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" },
        },
        "5": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" },
        },
        "6": {
          "06:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "07:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "08:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "09:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "10:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "11:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "12:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "13:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "14:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "15:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "16:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "17:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "18:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "19:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "20:00": { key_sucursal: "", precio: 0, key_horario: "" },
          "21:00": { key_sucursal: "", precio: 0, key_horario: "" },
        },
      },

      _totalHoras: 0,
      _lunes: {},
      _totalPagar: 0

    };
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });

  }

  entrenador_seleciona: SInput;
  dia_seleciona: SInput;
  getPerfil() {

    this.data = Model.usuario.Action.getByKey(this.key_entrenador);
    if (!this.data) return <SLoad />
    return <SView center style={{
      width: "100%", justifyContent: "center", alignItems: "center",
      backgroundColor: STheme.color.card
      // backgroundColor: "blue"
    }}>

      <SView style={{ width: 40, height: 40 }} center>
        <FotoPerfilUsuario usuario={this.data} />
      </SView>
      <Text style={{ color: STheme.color.text, fontSize: 18, textTransform: "capitalize", fontWeight: "bold" }}>{this.data["Nombres"] + " " + this.data["Apellidos"]}</Text>
      <SHr />
    </SView >
  }



  template(dia, hora) {
    return <>
      <SView col={"xs-12"} height border={this.state.dias[dia][hora].key_sucursal == "-1" ? "transparent" : "green"}
        style={{ position: "absolute" }}>

      </SView >
    </>
  }
  // template(dia, hora) {

  //   // let contador = 0;
  //   // if (this.state.dias[dia][hora].key_sucursal !== "-1") {
  //   //   contador++;
  //   // }
  //   // if (this.state.dias[dia][hora].key_sucursal === "-1") {
  //   //   contador--;
  //   // }

  //   // this.state._totalHoras = contador;
  //   // this.setState({ ...this.state });

  //   // console.log("contador ", contador)

  //   return <>
  //     <SView col={"xs-12"} height border={this.state.dias[dia][hora].key_sucursal == "-1" ? "transparent" : "green"}

  //       style={{ position: "absolute" }}>

  //       <SView width={45} backgroundColor='red' border={"transparent"} style={{ borderRadius: 4, padding: 1, paddingTop: 0, paddingBottom: 0, position: "absolute" }}
  //         onPress={() => {
  //           const datos = [];
  //           for (let i = 0; i < 7; i++) {
  //             datos.push({
  //               dia: i,
  //               key_sucursal: this.state.dias[i][hora]?.key_sucursal,
  //               precio: this.state.dias[i][hora]?.precio,
  //               estado: dia === i ? 0 : 1,
  //             });
  //           }
  //           const datosFiltrados = datos.filter(item => (item.dia !== dia) && (item.key_sucursal || item.precio));
  //           let miraa = Model.entrenador_horario.Action.registroAll({
  //             hora_inicio: hora,
  //             key_entrenador: this.key_entrenador,
  //             key_usuario: Model.usuario.Action.getUsuarioLog().key,
  //             data: datosFiltrados
  //           }).then(e => {
  //             alert("Update succefull");
  //           }).catch(e => {
  //             console.log("aqui error ", e)
  //           })
  //         }}
  //       >
  //         <SText fontSize={10} font={"Roboto"} color={"white"} >Eliminar</SText>
  //       </SView >
  //       <SIcon name={"Cerrar"} fill={"red"} style={{ position: "absolute", width: 12, height: 12, top: 0, right: 0 }}
  //       />
  //     </SView >
  //   </>
  // }

  tablaHorarios() {

    var objUsuario = Model.usuario.Action.getAll();
    if (!objUsuario) return <SLoad />;


    var objSucursal = Sucursal.Actions.getAll(this.props);
    if (!objSucursal) return <SLoad />

    const objHorarios = Model.entrenador_horario.Action.getAll();
    if (!objHorarios) return <SLoad />


    const data_sucursales = Object.values(objSucursal).map((obj) => ({
      key: obj.key,
      content: obj.descripcion
    }));

    Object.values(objHorarios)
      .filter(item => item.key_entrenador === this.key_entrenador)
      .map(obj => {


        if (!this.state.dias[obj.dia][obj.hora_inicio].key_sucursal) {
          this.state.dias[obj.dia][obj.hora_inicio].key_sucursal = obj.key_sucursal;
          this.state.dias[obj.dia][obj.hora_inicio].precio = obj.precio;
          this.state.dias[obj.dia][obj.hora_inicio].key_horario = obj.key;
        }

      });

    return (<>
      <STable2
        cellStyle={{
          fontSize: 12,
          height: 100,
        }}
        center
        data={arr}

        header={[
          { key: "-a", label: "Hora", width: 45, center: true },
          {
            key: "-lu", label: "Lun", width: 83, border: "transparent", center: true, component: (hora) => {
              // key: "-lu", label: "Lun - Mar - Mie - jue - Vie", width: 180, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["0"][hora]?.key_sucursal ? null : this.template(0, hora)}

                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32} defaultValue={this.state.dias["0"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {
                      this.state.dias["0"][hora].key_sucursal = val;
                      this.state.dias["1"][hora].key_sucursal = val;
                      this.state.dias["2"][hora].key_sucursal = val;
                      this.state.dias["3"][hora].key_sucursal = val;
                      this.state.dias["4"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                      // console.log("cambio suc", val)
                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    defaultValue={this.state.dias["0"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["0"][hora].precio = val;
                      this.state.dias["1"][hora].precio = val;
                      this.state.dias["2"][hora].precio = val;
                      this.state.dias["3"][hora].precio = val;
                      this.state.dias["4"][hora].precio = val;
                      this.setState({ ...this.state })
                      // console.log("cambio precio", val)
                    }}
                    required={true}
                  />
                </SView>

              </>
            }
          },
          {
            key: "-mar", label: "Mar", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["1"][hora]?.key_sucursal ? null : this.template(1, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32}
                    value={this.state.dias["0"][hora]?.key_sucursal || "-1"} disabled={true}
                    // defaultValue={this.state.dias["1"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {
                      this.state.dias["1"][hora].key_sucursal = val;
                      this.setState({ ...this.state })
                      console.log("cambio suc", val)

                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    value={this.state.dias["0"][hora]?.precio || ""} editable={false}
                    // defaultValue={this.state.dias["1"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["1"][hora].precio = val;
                      this.setState({ ...this.state })
                      console.log("cambio PRECIO", val)

                    }}
                    required={true}
                  />
                </SView>
              </>

            }
          },
          {
            key: "-mie", label: "Mie", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["2"][hora]?.key_sucursal ? null : this.template(2, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32}
                    value={this.state.dias["0"][hora]?.key_sucursal || "-1"} disabled={true}
                    // defaultValue={this.state.dias["2"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {
                      this.state.dias["2"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                      console.log("cambio suc", val)

                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    value={this.state.dias["0"][hora]?.precio || ""} editable={false}
                    // defaultValue={this.state.dias["2"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["2"][hora].precio = val;
                      this.setState({ ...this.state })
                      console.log("cambio precio", val)

                    }}
                    required={true}
                  />
                </SView>
              </>
            }
          },
          {
            key: "-jue", label: "Jue", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["3"][hora]?.key_sucursal ? null : this.template(3, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32}
                    value={this.state.dias["0"][hora]?.key_sucursal || "-1"} disabled={true}
                    // defaultValue={this.state.dias["3"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {
                      this.state.dias["3"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                      console.log("cambio suc", val)

                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    value={this.state.dias["0"][hora]?.precio || ""} editable={false}
                    // defaultValue={this.state.dias["3"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["3"][hora].precio = val;
                      this.setState({ ...this.state })
                      console.log("cambio precio", val)

                    }}
                    required={true}
                  />
                </SView>
              </>
            }
          },
          {
            key: "-vie", label: "Vie", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["4"][hora]?.key_sucursal ? null : this.template(4, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32}
                    value={this.state.dias["0"][hora]?.key_sucursal || "-1"} disabled={true}
                    // defaultValue={this.state.dias["4"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {
                      this.state.dias["4"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                      console.log("cambio suc", val)

                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    value={this.state.dias["0"][hora]?.precio || ""} editable={false}
                    // defaultValue={this.state.dias["4"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["4"][hora].precio = val;
                      this.setState({ ...this.state })
                      console.log("cambio precio", val)

                    }}
                    required={true}
                  />
                </SView>
              </>
            }
          },
          {
            key: "-sab", label: "Sab", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["5"][hora]?.key_sucursal ? null : this.template(5, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32} defaultValue={this.state.dias["5"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {

                      this.state.dias["5"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    defaultValue={this.state.dias["5"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["5"][hora].precio = val;
                      this.setState({ ...this.state })
                    }}
                    required={true}
                  />
                </SView>
              </>
            }
          },
          {
            key: "-dom", label: "Dom", width: 83, border: "transparent", center: true, component: (hora) => {
              return <>
                {!this.state.dias["6"][hora]?.key_sucursal ? null : this.template(6, hora)}
                <SView col={"xs-12"} center height  >
                  <SInput type={"select"} height={32} defaultValue={this.state.dias["6"][hora]?.key_sucursal || "-1"}
                    options={[{ key: "-1", content: "--" }, ...data_sucursales]} required={true}
                    onChangeText={(val) => {

                      this.state.dias["6"][hora].key_sucursal = val;
                      this.setState({ ...this.state });
                    }}
                  />
                  <SHr height={4}></SHr>
                  <SInput
                    type={"number"} placeholder={"0.00"} height={32}
                    defaultValue={this.state.dias["6"][hora]?.precio || ""}
                    onChangeText={(val) => {
                      this.state.dias["6"][hora].precio = val;
                      this.setState({ ...this.state })
                    }}
                    required={true}
                  />
                </SView>
              </>
            }
          },
          {
            key: "-key", label: "confirmar", center: true, width: 90, component: (keys) => {
              return <SView col={"xs-12"} height center >

                <SView col={"xs-12"} height={40} backgroundColor={"blue"} center onPress={() => {

                  // console.log("pintar ", this.state.dias["0"])
                  const datos = [
                    {
                      dia: 0,
                      key_sucursal: this.state.dias[0][keys]?.key_sucursal,
                      precio: this.state.dias[0][keys]?.precio
                    },
                    {
                      dia: 1,
                      key_sucursal: this.state.dias[1][keys]?.key_sucursal,
                      precio: this.state.dias[1][keys]?.precio
                    },
                    {
                      dia: 2,
                      key_sucursal: this.state.dias[2][keys]?.key_sucursal,
                      precio: this.state.dias[2][keys]?.precio
                    },
                    {
                      dia: 3,
                      key_sucursal: this.state.dias[3][keys]?.key_sucursal,
                      precio: this.state.dias[3][keys]?.precio
                    },
                    {
                      dia: 4,
                      key_sucursal: this.state.dias[4][keys]?.key_sucursal,
                      precio: this.state.dias[4][keys]?.precio
                    },
                    {
                      dia: 5,
                      key_sucursal: this.state.dias[5][keys]?.key_sucursal,
                      precio: this.state.dias[5][keys]?.precio
                    },
                    {
                      dia: 6,
                      key_sucursal: this.state.dias[6][keys]?.key_sucursal,
                      precio: this.state.dias[6][keys]?.precio
                    }
                  ]
                  const datosFiltrados = datos.filter(item => item.key_sucursal || item.precio);



                  let miraa = Model.entrenador_horario.Action.registroAll({
                    hora_inicio: keys,
                    key_entrenador: this.key_entrenador,
                    key_usuario: Model.usuario.Action.getUsuarioLog().key,
                    data: datosFiltrados
                  }).then(e => {
                    alert("registro existoso");
                  }).catch(e => {
                    console.log("aqui error ", e)
                  })
                  console.log("ruddy ", miraa)
                  // console.log("felicidades ", datosFiltrados)
                }}>
                  <SText>Guardar</SText>
                </SView >
              </SView >

            }
          }
        ]}
      />
    </>
    );

  }
  render() {
    return (<>
      <SPage hidden disableScroll center >
        <BarraSuperior duration={500} title={"Entrenador horariosaaa"} navigation={this.props.navigation} goBack={() => { SNavigation.goBack() }} {...this.props} />
        <SView col={"xs-12"} center>
          {this.getPerfil()}
          <SText color={"blue"}> Total horas {this.state._totalHoras} - pago {this.state._totalPagar} </SText>

        </SView>
        <SView col={"xs-12 lg-9 xl-7"} flex>
          {this.tablaHorarios()}
        </SView>
      </SPage >
    </>);
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(horarios);