import { SButtom, SDate, SIcon, SLoad, SNavigation, SPopup, SText, STheme, SView } from 'servisofts-component';
import DPA, { connect } from 'servisofts-page';
import { Parent } from ".";
import Model from '../../Model';
import Sucursal from '../Sucursal';

class index extends DPA.list {
  constructor(props) {
    super(props, {
      Parent: Parent,
      params: ["key_paquete_venta_usuario"],
      excludes: ["key", "fecha_on", "key_usuario", "estado", "key_empresa", "key_cuenta_contable"],
      onRefresh: (resolve) => {
        Parent.model.Action.CLEAR();
        resolve();
      }
    });
  }
  $allowNew() {
    return true;
    return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
  }

  $menu() {
    let menu = super.$menu();
    menu.push({
      label: "Sincronizar molinete", onPress: () => { SNavigation.navigate("dispositivo"); }
    })
    return menu;
  }
  // $allowTable() {
  // return true;
  // return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
  // }
  $allowAccess() {
    return true;
    return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
  }
  $filter(data) {
    return data.estado != 0
  }

  onNew() {
    let data = this.data;
    // console.log(data);
    SNavigation.navigate("SucursalPage", {
      onSelect: (suc) => {
        if (data) {
          let select = Object.values(data).find((a) => a.key_sucursal == suc.key && a.estado > 0);
          if (select) {
            SPopup.alert("La sucursal ya exite");
            return;
          }

        }
        console.log(suc)
        Parent.model.Action.registro({
          data: {
            key_paquete_venta_usuario: this.$params.key_paquete_venta_usuario,
            key_sucursal: suc.key
          }
        })
      }
    })
  }

  $onSelect(obj) {
    SPopup.confirm({
      title: "Seguro que desea borrar?",
      onPress: () => {
        Parent.model.Action.editar({
          data: {
            ...obj,
            estado: 0
          }
        })
      }
    })
  }
  $item(obj) {
    const arr = Sucursal.Actions.getByKey(obj.key_sucursal, this.props);
    if (!arr) return <SLoad />;
    return <SView col="xs-12" card padding={8} onPress={() => {
      this.$onSelect(obj)
    }}
    >

      <SText>Suc. {arr.descripcion}</SText>
      <SText color="green">permiso {new SDate(obj.fecha_on).toString('yyyy-MM-dd hh:mm')}</SText>
      {/* <SText>{obj.key_paquete_venta_usuario}</SText> */}
      {/* <SText>{sucursal.descripcion}</SText> */}
      {/* <SText>{JSON.stringify(obj)}</SText> */}
    </SView>
  }
  template() {
    return <>
      <SButtom Card
        onPress={() => { SNavigation.navigate("grafico") }}
        style={{ backgroundColor: STheme.color.gray, position: "absolute", right: 40, bottom: 20, width: 50, height: 50, }}>
        <SIcon name={"DashboardBtn"} style={{ width: 45, height: 45, }} />
      </SButtom>
    </>
  }

  $getData() {
    var data = Parent.model.Action.getAll({ key_paquete_venta_usuario: this.$params.key_paquete_venta_usuario });

    // var data_sucursal = Model.sucursal.Action.getAll();
    // if (!data_sucursal) return;

    // console.log(data);
    // console.log(data_sucursal);
    // console.log(data);
    this.data = data;
    return data;
  }
}
export default connect(index);