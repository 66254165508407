import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SLoad, SNavigation, SOrdenador, SPage, SScrollView2, STable2, SText, STheme, SView } from 'servisofts-component';
import Paquete from '..';
import Sucursal from '..';
import BarraSuperior from '../../../Components/BarraSuperior';
import Buscador from '../../../Components/Buscador';
import FloatButtom from '../../../Components/FloatButtom';
import { SSRolesPermisosValidate } from '../../../SSRolesPermisos';
import sucursal_paquete from '../../sucursal_paquete';
import sucursal_usuario from '../../sucursal_usuario';
import PaqueteItem from './PaqueteItem';

class PaquetePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.onSelect = SNavigation.getParam("onSelect");
    }
    getLista() {

        var data = Paquete.Actions.getAll(this.props);
        var data_sucursal_paquete = sucursal_paquete.Actions.getAll(this.props);
        var sucursales_activas = sucursal_usuario.Actions.getActive(this.props);
        if (!data) return <SLoad />;
        if (!data_sucursal_paquete) return <SLoad />;
        if (!sucursales_activas) return <SLoad />;
        if (!this.state.buscador) return <SLoad />
        // return 
        var isAll = SSRolesPermisosValidate({ page: "SucursalPage", permiso: "admin_all" });
        return new SOrdenador([
            // { key: "Peso", order: "desc", peso: 4 },
            { key: "Descripcion", order: "asc", peso: 2 },
        ]).ordernarObject(
            this.state.buscador.buscar(data)
        ).map((key) => {
            var isActive = false;
            if (!isAll) {
                var arr_ps = Object.values(data_sucursal_paquete).filter(o => o.key_paquete == key && o.estado != 0).map((p_s_a) => {
                    if (!isActive) {
                        isActive = sucursal_usuario.Actions.isActive(p_s_a.key_sucursal, this.props);
                    }
                });
                if (!isActive) return null;
            }

            return <PaqueteItem key_paquete={key} onPress={(obj) => {
                if (this.onSelect) {
                    this.onSelect(obj);
                    return;
                }
                SNavigation.navigate("PaquetePage/perfil", {
                    key: key
                })
            }} />
        })
    }


    template() {
        return (<SView col={"xs-2.5 md-1.4"} border={"yellow"} center   >
            <Buscador placeholder={"Buscar..."} ref={(ref) => {
                if (!this.state.buscador) this.setState({ buscador: ref });
            }} repaint={() => { this.setState({ ...this.state }) }}
                eliminados
            />
            <SScrollView2 disableHorizontal contentContainerStyle={{ width: "100%" }}>
                <SView center>
                    {this.getLista()}
                </SView>
            </SScrollView2>
            <FloatButtom esconder={!SSRolesPermisosValidate({ page: "PaquetePage", permiso: "crear" })} onPress={() => {
                SNavigation.navigate("PaquetePage/registro")
            }} />
        </SView >);
    }

    render() {

        var data = Paquete.Actions.getAll(this.props);
        var data_sucursal_paquete = sucursal_paquete.Actions.getAll(this.props);
        var sucursales_activas = sucursal_usuario.Actions.getActive(this.props);
        if (!data) return <SLoad />;
        if (!data_sucursal_paquete) return <SLoad />;
        if (!sucursales_activas) return <SLoad />;
        //if (!this.state.buscador) return <SLoad />
        // return 
        // var isAll = SSRolesPermisosValidate({ page: "SucursalPage", permiso: "admin_all" });
        //if(data.estado_app != null ) return ;

        console.log('data ', data)
        //console.log('data_sucursal_paquete ', data_sucursal_paquete)
        //console.log('sucursales_activas ', sucursales_activas)


        return (<SPage title={'PaquetePage'} hidden disableScroll>
            <BarraSuperior title={"QR"} navigation={this.props.navigation} goBack={() => { SNavigation.goBack(); }} />
            <SText color='red'> Paquete Activos </SText>

            <STable2
                limit={50}
                data={data}
                cellStyle={{
                    fontSize: 12,
                    // height: 30,
                }}
                header={[
                    { key: "index", label: "#" },
                    { key: "descripcion", label: "Descripcion", width: 180 },
                    { key: "dias", label: "dias", width: 40 },
                    { key: "participantes", label: "participantes", width: 80 },
                    { key: "precio", label: "precio", order: "asc", width: 40 },
                    { key: "estado", label: "estado", width: 40 },
                    { key: "requiere_motivo", label: "motivo", width: 80 },
                    { key: "observacion", label: "observacion", width: 280 },



                    {
                        key: "estado_app", label: "APP", width: 80, center: true,
                        component: (item) => {
                            return <SView width={70} height={32} border={item == 1 ? "green" :STheme.color.card} center 
                            style={{  borderRadius: 8, padding: 5, fontSize: 2 }}>
                                
                                <SText color={item == 1 ? "green" :STheme.color.card} >{ item == 1 ? "activo" : "desactivo"}</SText>

                            </SView>  
                            
                        }
                    },

                    {
                        key: "-perfil2", label: "perfil", width: 80, center: true,
                        component: (item) => {

                            return <SView width={70} height={32} border={"yellow"} center 
                            style={{  borderRadius: 8, padding: 5, fontSize: 2 }}
                            onPress={() => {
                                SNavigation.navigate("PaquetePage/perfil", { key: item.key })
                            }}  >
                                
                                <SText color={"yellow"} >Perfil</SText>

                            </SView>  

                            // return <FloatButtom onPress={() => {
                            //     SNavigation.navigate("PaquetePage/perfil", { key: item.key })
                            // }} />
                        }
                    },
                ]}
                filter={(item) => {
                    if (item.estado == 0) return false;
                    // if (item.estado_app != null) return false;
                    return true;
                }}
            />


        </SPage>);
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(PaquetePage);