import { Component } from 'react';
import { connect } from 'react-redux';
import SChart from "servisofts-charts";
import { SDate, SHr, SLoad, SText, SView } from 'servisofts-component';
import Finanza from '../../../Finanza';
import Sucursal from '../../../Sucursal';
import Usuario from '../../../Usuario';
import sucursal_usuario from '../../../sucursal_usuario';
const papa = {
  ricky: 10,
  alvaro: 20,
  ruddy: 15,
  liceth: 18
}

const mama = {
  ricky: { lunes: 10, martes: 14, miercoles: 17, jueves: 21 },
  alvaro: { lunes: 8, martes: 11, miercoles: 14, jueves: 23 },
  ruddy: { lunes: 7, martes: 19, miercoles: 16, jueves: 20 },
  liceth: { lunes: 16, martes: 29, miercoles: 10, jueves: 19 }
}

var nota = [];
// var nota = ["4306ff38-b6ea-41a5-96cd-f0b92f944a23" , "9b12670e-c6ca-4948-a532-550faccdcb9d"];
class SucursalesDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
    };
  }

  getData() {
    var fecha_desde = this.props.fechaInicio.toString("yyyy-MM-dd");
    var fecha_hasta = this.props.fechaFin.toString("yyyy-MM-dd");
    var sucursales = Sucursal.Actions.getAll(this.props);
    var clientesActivos = Usuario.Actions.getAllClientesActivos(this.props);
    var paquetes = Finanza.Actions.getPaquetesVendidos({
      fecha_desde: fecha_desde, fecha_hasta: fecha_hasta
    }, this.props, false);

    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);

    if (!sucursales || !clientesActivos || !paquetes || !_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    let arr_paquetes_vendidos = Object.values(paquetes);
    var now = new SDate();

    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    nota = sucursalesActivas.map(sucursal => sucursal.key);

    Object.values(sucursales).map(sucursal => {
      let paquetes_vendidos_en_esta_sucursal = arr_paquetes_vendidos.filter((p) => p.key_sucursal == sucursal.key
      );
      let total = 0;
      paquetes_vendidos_en_esta_sucursal.map(o => total += parseFloat(o.total))
      sucursal.total_vendido = total;
      sucursal.paquetes_vendidos = paquetes_vendidos_en_esta_sucursal;
      sucursal.cantidad_clientes = 0;
      sucursal.cantidad_becados = 0;
    })
    Object.keys(clientesActivos).map((key_cli) => {
      var cliente = clientesActivos[key_cli];
      if (!cliente["caja"].key_sucursal) return;
      if (!sucursales[cliente["caja"].key_sucursal]) return;
      if (!sucursales[cliente["caja"].key_sucursal].cantidad_clientes) sucursales[cliente["caja"].key_sucursal].cantidad_clientes = 0;
      if (!sucursales[cliente["caja"].key_sucursal].cantidad_becados) sucursales[cliente["caja"].key_sucursal].cantidad_becados = 0;
      if (!(new SDate(cliente.fecha_inicio, "yyyy-MM-dd").isBefore(now) && new SDate(cliente.fecha_fin, "yyyy-MM-dd").isAfter(now))) {
        return;
      }
      if (cliente["paquete"].precio == 0) {
        sucursales[cliente["caja"].key_sucursal].cantidad_becados++;
      } else {
        sucursales[cliente["caja"].key_sucursal].cantidad_clientes++;
      }
    })

    return Object.values(sucursales).sort((a, b) => b.total_vendido - a.total_vendido)

  }

  graficoClientesYBecados() {
    let data = this.getData();
    if (!data) return <SLoad />


    let _dta = {}
    let _dta2 = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );

    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);


    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {


        _dta[item.descripcion] = item.total_vendido;
        _dta2[item.descripcion] = {
          "clientes": item.cantidad_clientes,
          "becado": item.cantidad_becados
        };
        color.push(item.color);
      }

    });


    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta2).length * 100}>
        <SChart
          //colors={["#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00"]}
          colors={color}
          strokeWidth={1}
          showControl
          showGuide
          showValue
          showLabel
          max_value={60000}
          space={0.1}
          textColor='#ffffff'
          type='Bar'
          data={_dta2} />
      </SView>
    </>

  }

  grafico_01() {
    let data = this.getData();
    if (!data) return <SLoad />
    let _dta = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {
        _dta[item.descripcion] = item.paquetes_vendidos.length;
        color.push(item.color);
      }
    });
    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 60}>
        <SChart
          //colors={["#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00"]}
          colors={color}
          strokeWidth={1}
          // showControl
          showGuide
          showValue
          showLabel
          max_value={60000}
          space={0.1}
          textColor='#ffffff'
          type='Column'
          data={_dta} />
      </SView>
    </>

  }

  graficoIngresos() {
    let data = this.getData();
    if (!data) return <SLoad />
    let _dta = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {
        _dta[item.descripcion] = item.total_vendido;
        color.push(item.color);
      }
    });
    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 100}>
        <SChart
          //colors={["#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00"]}
          colors={color}
          strokeWidth={1}
          showControl
          showGuide
          showValue
          showLabel
          max_value={60000}
          space={0.1}
          textColor='#ffffff'
          type='Donut_gauge'
          data={_dta} />
      </SView>
    </>

  }

  grafico_02() {
    let data = this.getData();
    if (!data) return <SLoad />
    let _dta = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {
        _dta[item.descripcion] = item.paquetes_vendidos.length;
        // _dta[item.descripcion] = item.total_vendido;
        color.push(item.color);
      }
    });

    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 60}>
        <SChart
          colors={color}
          strokeWidth={1}
          // showControl
          showGuide
          showValue
          center
          showLabel
          max_value={60000}
          space={0}
          textColor='#ffffff'
          fontSize="80"
          // type='Bar'
          // type='Bar'
          type='Donut_gauge'
          // type='Pie'

          style={{ fontSize: 80 }}
          data={_dta} />
      </SView>
    </>
  }
  grafico_03() {
    let data = this.getData();
    if (!data) return <SLoad />
    let _dta = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {
        _dta[item.descripcion] = item.paquetes_vendidos.length;
        color.push(item.color);
      }
    });

    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 60}>
        <SChart
          colors={color}
          strokeWidth={1}
          // showControl
          showGuide
          showValue
          center
          showLabel
          max_value={60000}
          space={0}
          textColor='#ffffff'
          fontSize="80"
          type='Bar'
          style={{ fontSize: 80 }}
          data={_dta} />
      </SView>
    </>
  }
  grafico_04() {
    let data = this.getData();
    if (!data) return <SLoad />
    let _dta = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );
    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);
    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {
        _dta[item.descripcion] = item.paquetes_vendidos.length;
        color.push(item.color);
      }
    });

    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 60}>
        <SChart
          colors={color}
          strokeWidth={1}
          // showControl
          showGuide
          showValue
          center
          showLabel
          max_value={60000}
          space={0}
          textColor='#ffffff'
          fontSize="80"
          type='Pie'
          style={{ fontSize: 80 }}
          data={_dta} />
      </SView>
    </>
  }

  graficoIngresos3() {
    let data = this.getData();
    if (!data) return <SLoad />


    let _dta = {}
    let _dta2 = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );

    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);


    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {

        console.log("carayu ", item)

        _dta[item.descripcion] = item.total_vendido;
        _dta2[item.descripcion] = {
          "clientes": item.cantidad_clientes,
          "becado": item.cantidad_becados
        };
        color.push(item.color);
      }

    });


    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 100}>
        <SChart
          //colors={["#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00"]}
          colors={color}
          strokeWidth={1}
          showControl
          showGuide
          showValue
          showLabel
          max_value={60000}
          space={0}
          textColor='#ffffff'
          type='Line'
          data={_dta} />
      </SView>
    </>

  }

  graficoIngresos4() {
    let data = this.getData();
    if (!data) return <SLoad />


    let _dta = {}
    let _dta2 = {}
    let color = []
    const _key_usuario = this.props.state.usuarioReducer.usuarioLog.key;
    const _getallSucursales = Sucursal.Actions.getAll(this.props);
    const _getAllSucursalesByKeyUsuario = sucursal_usuario.Actions.getAllByKeyUsuario(_key_usuario, this.props);
    if (!_getallSucursales || !_getAllSucursalesByKeyUsuario) return null;
    const sucursalesActivas = Object.values(_getallSucursales).filter((b) =>
      _getAllSucursalesByKeyUsuario.some(
        (a) => a.key_sucursal === b.key && a.estado !== 0
      )
    );

    const arr_sucursal_key = sucursalesActivas.map(sucursal => sucursal.key);


    data.forEach(item => {
      if (arr_sucursal_key.includes(item.key)) {

        console.log("carayu ", item)

        _dta[item.descripcion] = item.total_vendido;
        _dta2[item.descripcion] = {
          "clientes": item.cantidad_clientes,
          "becado": item.cantidad_becados
        };
        color.push(item.color);
      }

    });


    return <>
      <SView col={"xs-12"} center height={Object.keys(_dta).length * 100}>
        <SChart
          //colors={["#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00", "#f0ff0f", "#ffffff", "#ffffff", "#f00f00"]}
          colors={color}
          strokeWidth={1}
          showControl
          showGuide
          showValue
          showLabel
          max_value={60000}
          space={0}
          textColor='#ffffff'
          type='Table'
          data={_dta} />
      </SView>
    </>

  }

  getContent() {

    let _data = this.getData();
    return <SView col={"xs-12"} border={"transparent"} center row>
      <SHr height={80}></SHr>

      {/* <SView col={"xs-12 md-6 "} center row style={{
        justifyContent: "space-between"
      }} >
        {this.graficoClientesYBecados()}
      </SView> */}

      <SView col={"xs-12 md-5 "} border={"transparent"} center row style={{
        justifyContent: "space-between"
      }} >
        <SText fontSize={24}>{`Gráfico Inscripciones`}</SText>
        <SHr height={30}></SHr>
        {this.grafico_01()}
      </SView>


      <SView col={"xs-12 md-4 "} border={"transparent"} center row style={{
        justifyContent: "space-between"
      }} >
        <SText fontSize={24}>{`Gráfico 2 Inscripciones`}</SText>
        <SHr height={30}></SHr>
        {this.grafico_02()}
      </SView>

      <SView col={"xs-12 md-4 "} border={"transparent"} center row style={{
        justifyContent: "space-between"
      }} >
        <SText fontSize={24}>{`Gráfico 3 Inscripciones`}</SText>
        <SHr height={30}></SHr>
        {this.grafico_03()}
      </SView>

      <SView col={"xs-12 md-4 "} border={"transparent"} center row style={{
        justifyContent: "space-between"
      }} >
        <SText fontSize={24}>{`Gráfico 4 Inscripciones`}</SText>
        <SHr height={30}></SHr>
        {this.grafico_04()}
      </SView>



      {/* <SView col={"xs-12 md-6 "} center row style={{
        justifyContent: "space-between"
      }} >
        {this.graficoIngresos()}
      </SView>

      <SView col={"xs-12 md-6 "} center row style={{
        justifyContent: "space-between"
      }} >
        {this.graficoIngresos2()}
      </SView> */}

      {/* <SView col={"xs-12 md-12 "} center row style={{
        justifyContent: "space-between"
      }} >
        {this.graficoIngresos3()}
      </SView> */}

      {/* <SView col={"xs-12 md-12 "} center row style={{
        justifyContent: "space-between"
      }} >
        {this.graficoIngresos4()}
      </SView>
 */}


    </SView>
  }
  render() {
    return (
      <SView col={"xs-12"} style={{
        padding: 8,
      }}>
        <SView col={"xs-12"} height center row>
          {this.getContent()}
        </SView>
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(SucursalesDetalle);
