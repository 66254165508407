import { SAction } from "servisofts-model";
export default class Action extends SAction {

    getAll(extra?: any) {
        if (extra.key_paquete != this._getReducer().key_paquete) {
            this._getReducer().key_paquete = extra.key_paquete;
            this._getReducer().data = null;
        }
        return super.getAll(extra);
    }
}