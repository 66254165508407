//  COMPONENT CONFIG
const component = "prestamos"; // COMPONENT NAME
import dashboard from "./Pages/dashboard";
// ---------------------------------------

import lista from "./Pages/lista";
import listaPago from "./Pages/listaPago";
import registro from "./Pages/registro";
import registroPago from "./Pages/registroPago";

export default {
  component,
  Reducers: {
  },
  Pages: {
    [component]: lista,
    [component + "/lista"]: lista,
    [component + "/dashboard"]: dashboard,
    [component + "/listaPago"]: listaPago,
    [component + "/registro"]: registro,
    [component + "/registroPago"]: registroPago,
  },
}