import { Component } from "react";
import { connect } from "react-redux";
import { SPage } from "servisofts-component";

let arr = [];
const accumulator = {};
const stockSum = {};

// https://speeding-station-796200.postman.co/workspace/My-Workspace~dc2cd970-38e5-4828-8059-a4159022f4a0/request/create?requestId=9e5a8c32-4245-430b-90fa-5b2dd1d78279
// https://www.base64decode.org/
class testAlvaro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      texto: "",
      loading: true,
    };
  };


  componentDidMount() {
    this.getData();
  }

  getData() {
    const username = "admin@calisteniabolivia.com";
    const password = "123";
    const url = "https://mail.calisteniabolivia.com/admin/api/v1/boxes";

    let convertirBase64 = btoa(username + ":" + password);

    // console.log("hola primo ", convertirBase64)
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {
        Authorization: "Basic " + convertirBase64,
      },
    })
      .then((data) => {
        console.log("pinto ", data)
      })
      .catch((error) => console.error(error));
  }


  render() {
    return (
      <SPage title={"Base datos - Registro de ventas"} disableScroll center>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(testAlvaro);
