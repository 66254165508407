import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SLoad, SMath, SNavigation, SPage, STable2, SText, STheme, SView } from "servisofts-component";
import Model from '../../Model';

class calendarioDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.total = SNavigation.getParam("total", "");
    this.fecha = SNavigation.getParam("fecha", "");
    this.obj = SNavigation.getParam("obj", "");
  }

  imprimir() {
    let array = this.obj;
    return array.map((a) => {
      return <SText fontSize={18} style={{ color: STheme.color.white }}>{"Sucursal " + a.sucursal + " usuario_key " + a.key_usuario}</SText>
    });
  }

  imprimir2() {
    var usuarios = Model.usuario.Action.getAll();
    if (!this.obj || !usuarios) return <SLoad type='skeleton' col={"xs-12"} height />
    return <STable2
      limit={30}
      data={this.obj}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        { key: "sucursal", label: "Sucursal", width: 100 },
        { key: "paquete", label: "Paquete", width: 180 },
        { key: "precio", label: "Precio", width: 70, render: (obj) => { return SMath.formatMoney(obj ?? 0, 2, ".") } },
        { key: "-usuarios", label: "key_usuario", width: 270, render: (obj) => { return obj.key_usuario }, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "key_usuario", label: "Cliente", width: 220, render: (key) => { return `${usuarios[key]?.Nombres} ${usuarios[key]?.Apellidos}`; } },
        {
          key: "-usuario_key", label: "Historial", width: 60,
          render: (obj) => { return obj.key_usuario },
          component: (e) => <SView center style={{
            borderWidth: 1,
            borderColor: "yellow",
            borderRadius: 4,
            padding: 4
          }} onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>
            <SText center color={"yellow"} >ver</SText>  </ SView>
        },
        { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
        { key: "-telefonocliente", label: "Telefono", width: 100, render: (obj) => { return `${usuarios[obj.key_usuario]?.Telefono}`; } },
        { key: "-correocliente", label: "Correo", width: 200, render: (obj) => { return `${usuarios[obj.key_usuario]?.Correo}`; } },
        {
          key: "-nacimiento", label: "F.Nac", width: 80, render: (obj) => {
            const validacion = usuarios[obj.key_usuario]?.["Fecha nacimiento"];
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-edad", label: "Edad", width: 30, render: (obj) => {
            const validacion = new SDate().toString("yyyy") - new SDate(usuarios[obj.key_usuario]?.["Fecha nacimiento"]).toString("yyyy");
            return validacion ? validacion : " ";
          }
        },

        // { key: "-nacimiento", label: "F.Nac", width: 80, center: true, render: (obj) => { return `${usuarios[obj.key_usuario]?.["Fecha nacimiento"]}`??"2"; } },
        // {
        //   key: "-edad", label: "Edad", width: 30, render: (obj) => {
        //     let edad = new SDate().toString("yyyy") - new SDate(`${usuarios[obj.key_usuario]?.["Fecha nacimiento"]}`).toString("yyyy");
        //     return edad;
        //   },
        // },
        // {
        //   key: "-razonsocial", label: "Razon Social", width: 100, render: (obj) => {
        //     const validacion = usuarios[obj.usuario_key].razonSocial;
        //     return validacion ? validacion : " ";
        //   }
        // },
        // {
        //   key: "-nit", label: "Nit", width: 60, render: (obj) => {
        //     const validacion = usuarios[obj.usuario_key].Nit;
        //     return validacion ? validacion : " ";
        //   }
        // },
        {
          key: "-empresa", label: "Empresa", width: 120, render: (obj) => {
            const validacion = usuarios[obj.key_usuario].Empresa;
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-cargo", label: "Cargo", width: 100, render: (obj) => {
            const validacion = usuarios[obj.key_usuario].Cargo;
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-referencia", label: "Referencia", width: 120, render: (obj) => {
            const validacion = usuarios[obj.key_usuario].Referencia;
            return validacion ? validacion : " ";
          }
        }
      ]}
    />
  }


  render() {
    return (
      <SPage title={"DETALLES DEL CALENDARIO: FECHA " + new SDate(this.fecha).addDay(1).toString("dd/MM/yyyy")} disableScroll center>
        {/* <SView col={"xs-11 md-6"} center height={55}>
          <SText fontSize={18} bold style={{ color: STheme.color.white }}>{this.imprimir()}</SText>
        </SView> */}
        {this.imprimir2()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(calendarioDetalle);
