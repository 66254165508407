//  COMPONENT CONFIG
const component = "crowdfunding"; // COMPONENT NAME
// import db from "./Pages/no_db";
import calculadora from "./Pages/calculadora";
import estado_resultado from "./Pages/estado_resultado";
import fijo from "./Pages/fijo";
import variable from "./Pages/variable";

export default {
  component,
  Reducers: {
  },
  Pages: {
    // [component]: lista,
    [component + "/calculadora"]: calculadora,
    [component + "/variable"]: variable,
    [component + "/estado_resultado"]: estado_resultado,
    [component + "/fijo"]: fijo,
    // [component + "/db"]: db,
    // [component + "/estado_resultado"]: db,

    // "/:servicio/proyecto": { component: Lista }
  },
}