import { Component } from 'react';
import { Animated } from 'react-native';
import { connect } from 'react-redux';

import { SButtom, SForm, SHr, SImage, SInput, SLoad, SNavigation, SPage, SScrollView2, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Paquete from '../..';
import BarraSuperior from '../../../../Components/BarraSuperior';
import FotoPerfilComponent from '../../../../Components/FotoPerfilComponent';
import Model from '../../../../Model';
import Sucursal_paquete from '../../../sucursal_paquete';
import ServicioDePaquete from '../ServicioDePaquete';
class Registro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicios: {},
    };

    this.animatedSelec = new Animated.Value(0);

    this.key_paquete = SNavigation.getParam("key");
    props.state.paqueteReducer.estado = ""
  }

  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }


  startAnimated() {
    Animated.loop(
      Animated.timing(this.animatedSelec, {
        toValue: 1,
        delay: 0,
        duration: 1000,
      }),
      { iterations: 1000 },
    ).start();
  }


  getForm() {

    return <SForm
      ref={(ref) => { this.form = ref }}
      col={"xs-12"}
      row
      props={{
        col: "xs-12",
        dir: "row",
      }}
      style={{
        justifyContent: "space-between",
        // justifyContent: ""
      }}
      inputProps={{
        customStyle: "calistenia",
        col: "xs-12"
      }}
      // tarea2 ✅ ✅ ✅

      inputs={{
        descripcion: { label: 'Descripcion', type: 'text', isRequired: true, defaultValue: this.data.descripcion },
        observacion: { label: 'Observacion', type: 'textArea', defaultValue: this.data.observacion },
        precio: { label: 'Precio', type: 'money', isRequired: true, defaultValue: parseFloat(this.data.precio || 0).toFixed(2) || null, col: "xs-5.5" },
        dias: { label: 'Cantidad de dias', type: 'number', defaultValue: this.data.dias, col: "xs-5.5" },
        participantes: { label: 'Participantes', type: 'number', isRequired: true, defaultValue: this.data.participantes, col: "xs-12" },

        requiere_motivo: { label: <SText bold width={150} >Agregar Motivo</SText>, type: 'checkBox', isRequired: false, defaultValue: this.data.requiere_motivo },
        estado_caja: { label: <SText bold width={150} >Activar en Caja 🖥️🖨️</SText>, type: 'checkBox', isRequired: false, defaultValue: this.data.estado_caja ? 1 : 0 },
        estado_app: { label: <SText bold width={150} >Activar en App 📱</SText>, type: 'checkBox', isRequired: false, defaultValue: this.data.estado_app ? 1 : 0 },
        is_molinete: { label: <SText bold width={350} >Permite ingresar molinete</SText>, type: 'checkBox', isRequired: false, defaultValue: this.data.is_molinete },

        // fac_codigo_producto: { label: 'Fac. Codigo de producto', defaultValue: this.data?.fac_codigo_producto, col: "xs-5.5" },
        // fac_unidad_medida: {
        //   label: 'Fac. Unidad de medida', defaultValue: this.data?.fac_unidad_medida, col: "xs-5.5", onPress: () => {
        //     SNavigation.navigate("/facturacion")
        //   }
        // },
      }}
      onSubmit={(data) => {

        // const nuevoEstadoApp = data.estado_app ? 1 : 0;

        var serviciosSelec = Object.keys(this.state.servicios);

        data.fac_codigo_producto = this.fac_codigo_producto.getValue();
        data.fac_unidad_medida = this.fac_unidad_medida.getValue();
        if (this.key_paquete) {
          Paquete.Actions.editar({
            ...this.data,
            ...data
          }, serviciosSelec, this.props);

          console.log("hola ", data);
        } else {
          // if (serviciosSelec.length <= 0) {
          //     alert("Debe activar almenos 1 servicio");
          //     return;
          // }
          Paquete.Actions.registro(data, serviciosSelec, this.props);
        }
      }}
    >

    </SForm>
  }
  getEliminar(data) {
    if (!this.key_paquete) return <SView />
    var serviciosSelec = Object.keys(this.state.servicios);
    return <> <SButtom props={{
      type: "danger",
      variant: "confirm"
    }}
      onPress={() => {
        Paquete.Actions.editar({
          ...data,
          estado: (data.estado == 1 ? 0 : 1),
        }, serviciosSelec, this.props);
      }}
    >{data.estado == 1 ? "Eliminar" : "Recuperar"}</SButtom>
      <SView col={"xs-1"} />
    </>
  }

  btnListaUsuarios() {
    this.startAnimated();
    return <>
      <SHr height={16} />
      <SView col="xs-12" center row border={"yellow"} animated
        style={{
          width: "100%", height: 50, borderRadius: 8,
          backgroundColor: this.animatedSelec.interpolate({ inputRange: [0, 1], outputRange: ["#00ffff33", "#00000000"] })
        }} props={{ animated: true }}
        onPress={() => {
          SNavigation.navigate("PaquetePage/promo_usuarios", { key_paquete: this.key_paquete })
        }}>
        <SText center style={{ color: "yellow", fontSize: 14 }}>CARGAR LISTA USUARIOS</SText>
      </SView>
      <SHr height={16} />
    </>
  }



  getPerfil() {
    var data = {}
    if (this.key_paquete) {
      data = Paquete.Actions.getByKey(this.key_paquete, this.props);
      if (!data) return <SLoad />
    }
    this.data = data;
    return (<><SView center col={"xs-10 md-8 lg-6 xl-4"}>
      {!this.key_paquete ? <SView /> : <SView style={{
        width: 110,
        height: 110,
      }}><FotoPerfilComponent data={data} component={"paquete"} />
      </SView>}
      {this.getForm()}
      {/* <SView height={32} /> */}
      <SHr h={1} color='#666' />
      <SHr h={32} />
      <SView col={"xs-12"}>
        <SView col={"xs-12"} row>
          <SText bold fontSize={16}>Factura</SText>
          <SView flex />
          <SText bold style={{
            fontSize: 16,
            color: "#00f",
            textDecoration: "underline"
          }} onPress={() => {
            this.fac_unidad_medida.setValue("")
            this.fac_codigo_producto.setValue("")
          }}>Limpiar</SText>
        </SView>
        <SText color={"#666"} fontSize={11}>Si el paquete no cuenta con un codigo de producto y una unidad de medida valida, no se facturara automaticamente.</SText>
      </SView>
      <SView col={"xs-12"} row style={{
        justifyContent: "space-between",
      }}>
        <SInput ref={ref => this.fac_codigo_producto = ref} defaultValue={this?.data?.fac_codigo_producto} label={"Codigo de producto"} placeholder={"--"} col="xs-5.5"
          onPress={() => {
            SNavigation.navigate("/facturacion/parametricas/table", {
              type: "productos_servicios", onSelect: (a) => {
                this.fac_codigo_producto.setValue(a["CODIGO"] + "")
              }
            })
          }} />
        <SInput ref={ref => this.fac_unidad_medida = ref} defaultValue={this?.data?.fac_unidad_medida} label={"Unidad de medida"} placeholder={"--"} col="xs-5.5"
          onPress={() => {
            SNavigation.navigate("/facturacion/parametricas/table", {
              type: "unidades_medida", onSelect: (a) => {
                this.fac_unidad_medida.setValue(a["CODIGO"] + "")
              }
            })
          }} />
      </SView>
      <SHr h={32} />
      <SHr h={1} color='#666' />
      <SHr h={32} />
      {/* <SButtom props={{ type: "outline" }} col={"xs-10 md-8 lg-6 xl-4"} style={{ height: 70, width: "100%", backgroundColor: STheme.color.card, borderRadius: 8 }} onPress={() => { SNavigation.navigate("PaquetePage/promo_usuarios", { key_paquete: this.key_paquete }) }}>USUARIOS CON PROMO</SButtom> */}
      {this.btnListaUsuarios()}

    </SView>

      <SHr h={36} />

      <SView col={"xs-11"} row center>
        {this.getEliminar(data)}
        <SButtom props={{
          type: "outline"
        }}
          onPress={() => {
            this.form.submit();
          }}
        >{(this.key_paquete ? "Editar" : "Crear")}</SButtom>
      </SView>
      <SHr h={36} />


      <ServicioDePaquete keyPaquete={data.key} onChange={(resp) => {
        console.log(resp);
        this.setState({ servicios: resp });
      }} />

      <SText style={{
        padding: 8,
        fontSize: 14,
        color: STheme.color.secondary,
        width: "100%",
        textAlign: "center"
      }}>SUCURSALES ASIGNADAS</SText>

      <Sucursal_paquete.Components.Select key_paquete={this.key_paquete} />

    </>)
  }

  render_comprar_paquete() {
    return <SView col={"xs-12"} center>
      <SView card padding={16} onPress={() => {
        console.log("Enviando")
        this.setState({ loading: true })
        SSocket.sendPromise({
          component: "paqueteVentaUsuario",
          type: "getQr",
          estado: "cargando",
          key_usuario: Model.usuario.Action.getKey(),
          key_paquete: this.key_paquete,
        }, 60 * 1000).then(e => {
          this.setState({ loading: false, dataqr: e.data })
        }).catch(e => {
          this.setState({ loading: false })
          console.error(e)
        })
      }}>
        <SText>GET QR</SText>
      </SView>

      <SLoad hidden={!this.state.loading} />
      {!this.state?.dataqr?.qrImage ? null : <SView col={"xs-12"} center height={100}>
        <SImage src={"data:image/png;base64, " + this.state.dataqr.qrImage} width={100} height={100} enablePreview />
      </SView>}
    </SView>

  }
  render() {
    if (this.props.state.paqueteReducer.estado == "exito" && this.props.state.paqueteReducer.type == "registro") {
      this.props.state.paqueteReducer.estado = ""
      SNavigation.goBack();
    }
    if (this.props.state.paqueteReducer.estado == "exito" && this.props.state.paqueteReducer.type == "editar") {
      this.props.state.paqueteReducer.estado = ""
      SNavigation.goBack();
    }
    return (
      <SPage hidden disableScroll>
        <BarraSuperior title={(this.key_paquete ? "Perfil de" : "Registro de") + " paquete"} goBack={() => {
          SNavigation.goBack();
        }} />
        <SScrollView2 disableHorizontal>

          {/* {this.render_comprar_paquete()} */}
          <SHr />
          <SView center col={"xs-12"}>
            {this.getPerfil()}

          </SView>
          <SView height={32} />
        </SScrollView2>

      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(Registro);