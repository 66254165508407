import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SLoad, SMath, SNavigation, SPage, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from 'servisofts-socket';
import Model from "../../Model";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {
        latitude: -17.7833276,
        longitude: -63.1821408,
      },
      func: "_get_fidelizacion_calendario2",
      parametros: {
        "inicio": new SDate().setDay(1).toString("yyyy-MM-dd"),
        "fin": new SDate().addMonth(1).toString("yyyy-MM-dd"),
      },
      ...this.state,
    };

    this.fecha_inicio = SNavigation.getParam("fecha_inicio", "");
    this.fecha_fin = SNavigation.getParam("fecha_fin", "");


    this.isRun = false;
  }

  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: ["'" + this.fecha_inicio + "'", "'" + this.fecha_fin + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }



  showLista() {
    if (!this.state.data) return <SLoad />;
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad type='skeleton' col={"xs-12"} height />

    // console.log(["'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'"])
    return <STable2
      limit={10}
      data={this.state.data}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}

      header={[
        { key: "index", label: "#" },
        { key: "fecha_fin", label: "Fecha fin", width: 100 },
        { key: "sucursal", label: "Sucursal", width: 100 },
        { key: "paquete", label: "Paquete", width: 180 },
        { key: "precio", label: "Precio", width: 70, render: (obj) => { return SMath.formatMoney(obj ?? 0, 2, ".") } },
        { key: "-usuarios", label: "key_usuario", width: 270, render: (obj) => { return obj.key_usuario }, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "key_usuario", label: "Cliente", width: 220, render: (key) => { return `${usuarios[key]?.Nombres} ${usuarios[key]?.Apellidos}`; } },
        {
          key: "-usuario_key", label: "Historial", width: 60,
          render: (obj) => { return obj.key_usuario },
          component: (e) => <SView center style={{
            borderWidth: 1,
            borderColor: "yellow",
            borderRadius: 4,
            padding: 4
          }} onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>
            <SText center color={"yellow"} >ver</SText>  </ SView>
        },
        { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
        { key: "-telefonocliente", label: "Telefono", width: 100, render: (obj) => { return `${usuarios[obj.key_usuario]?.Telefono}`; } },
        { key: "-correocliente", label: "Correo", width: 200, render: (obj) => { return `${usuarios[obj.key_usuario]?.Correo}`; } },
        {
          key: "-nacimiento", label: "F.Nac", width: 80, render: (obj) => {
            const validacion = usuarios[obj.key_usuario]?.["Fecha nacimiento"];
            return validacion ? validacion : " ";
          }
        },
        {
          key: "-edad", label: "Edad", width: 30, render: (obj) => {
            const validacion = new SDate().toString("yyyy") - new SDate(usuarios[obj.key_usuario]?.["Fecha nacimiento"]).toString("yyyy");
            return validacion ? validacion : " ";
          }
        }

      ]}
    />
  }

  render() {
    // this.state.parametros.inicio = SNavigation.getParam("fecha_inicio", new SDate().setDay(1).toString("yyyy-MM-dd"));
    // this.state.parametros.fin = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));
    return (
      // <SPage title={"DETALLES DEL CALENDARIO: FECHA " + new SDate(this.fecha).addDay(1).toString("dd/MM/yyyy")} disableScroll center>

      <SPage title={`DETALLE TOTAL DEL CALENDARIO (${this.fecha_inicio} / ${this.fecha_fin}) `} disableScroll center>
      {/* <SPage title={`DETALLE TOTAL DEL CALENDARIO (${this.state.parametros.inicio} / ${this.state.parametros.fin}) `} disableScroll center> */}
        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(index);
