import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SList2, SLoad, SMath, SPage, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../../../../Model';



class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Reporte Name",
      func: "get_ventas_por_sucursal_all",
      params: null,
    };

  }

  componentDidMount() {
    this.getLista();
    // this.getData();
    // this.getChaval();
  }

  ItemDetalle(key_prestamos) {




    var datax = Model.prestamo_pago.Action.getAll(this.props);


    if (!datax) return <SLoad />;

    console.log("pagos ", datax)

    return Object.keys(datax).map(keys => {
      const obj = datax[keys];
      if (obj.key_prestamo != key_prestamos) return;

      return <>
        <SView col={"xs-12"} backgroundColor={"cyan+66"} row>
          <SView col={"xs-2.5"} backgroundColor={"transparent"} row>
            <SText fontSize={10}> {new SDate(obj?.fecha_ultimo_pago).toString("dd/MM/yyyy")} </SText>
          </SView>
          <SView col={"xs-2"} row center style={{ justifyContent: "flex-start", }}>
            <SText fontSize={10}>{SMath.formatMoney(obj?.interes_pordia)} bs</SText>
          </SView>
          <SView col={"xs-2"} backgroundColor={"transparent"} row style={{ justifyContent: "flex-start", }}>
            <SText fontSize={10}> x {parseFloat(obj?.interes_pagado / obj?.interes_pordia).toFixed(0)}   dias</SText>
          </SView>
          <SView col={"xs-2.5"} backgroundColor={"transparent"} row style={{ justifyContent: "flex-end", }}>
            <SText fontSize={10}>{SMath.formatMoney(obj?.interes_pagado)} bs</SText>
          </SView>
        </SView>
        <SHr height={2} color={STheme.color.card} />
      </>
    })
  }

  Item(obj) {
    return <SView col={"xs-11 md-6 xl-3"} backgroundColor={"transparent"} height={350} style={{ padding: 4 }}>
      <SView center col={"xs-12"} height card style={{ borderWidth: 1.5, borderRadius: 4 }} >
        <SView center col={"xs-12"} height={65} center backgroundColor={"green+66"}>
          <SText center fontSize={14} bold>{obj?.key_prestamista}</SText>
          <SHr height={8} />
          <SText center fontSize={12}>Fecha Inicio:{new SDate(obj?.inicio_prestamo).toString("dd/MM/yyyy")} - fin:{new SDate(obj?.fin_prestamo).toString("dd/MM/yyyy")}  </SText>
        </SView>

        <SView center col={"xs-12"} row backgroundColor={"cyan+66"}>
          <SView col={"xs-12"} height={80} card row center>

            <SView col={"xs-3"} height center>
              <SView center style={{ width: 80, height: 30, borderRadius: 4, backgroundColor: "transparent" }}>
                <SText fontSize={14} bold>{SMath.formatMoney(obj?.monto_prestado)}</SText>
              </SView>
              <SHr height={2} />
              <SText center fontSize={10}>Monto/Prestado</SText>
            </SView>

            <SView col={"xs-1.5"} height center border={"transparent"}>
              <SView center style={{ width: 50, height: 30, borderRadius: 4, backgroundColor: "transparent" }}>
                <SText fontSize={14} bold>{obj?.porcentaje}</SText>
              </SView>
              <SHr height={2} />
              <SText center fontSize={10}>Interes(%)</SText>
            </SView>

            <SView col={"xs-2"} height center>
              <SView center style={{ width: 80, height: 30, borderRadius: 4, backgroundColor: "transparent" }}>
                <SText fontSize={14} bold>({obj?.periocidad}) dias</SText>
              </SView>
              <SHr height={2} />
              <SText center fontSize={10}>Periocidad</SText>
            </SView>

            <SView col={"xs-2.5"} height center border={"transparent"}>
              <SView center style={{ width: 80, height: 30, borderRadius: 4, backgroundColor: "transparent" }} border={STheme.color.card}>
                <SText fontSize={14} bold>5000 bs</SText>
                {/* <SText fontSize={14} bold>({obj?.periocidad})</SText> */}
              </SView>
              <SHr height={2} />
              <SText center fontSize={10}>Interes/percd</SText>
            </SView>

            <SView col={"xs-2"} height center>
              <SView center style={{ width: 80, height: 30, borderRadius: 4, backgroundColor: "transparent" }}>
                <SText fontSize={14} bold>55 bs</SText>
                {/* <SText fontSize={14} bold>({obj?.periocidad})</SText> */}
              </SView>
              <SHr height={2} />
              <SText center fontSize={10}>Interes/dias</SText>
            </SView>


          </SView>
        </SView>
        <SHr height={24} />

        <SView col={"xs-11.5"} backgroundColor={"cyan+66"}>
          <SView col={"xs-12"} row center>
            <SView col={"xs-6"} backgroundColor={"transparent"} row style={{ justifyContent: "flex-start", }}>
              <SText fontSize={10}>Detalle pagos</SText>
            </SView>
            <SView col={"xs-6"} backgroundColor={"transparent"} row style={{ justifyContent: "flex-end", }}>
              <SText fontSize={10}>#20</SText>
            </SView>
          </SView>

          <SHr height={2} color={STheme.color.card} />

          <SView col={"xs-12"} backgroundColor={"cyan+66"} row>
            <SView col={"xs-2.5"} backgroundColor={"transparent"} row>
              <SText fontSize={10}>Fecha</SText>
            </SView>
            <SView col={"xs-2"} backgroundColor={"transparent"} row style={{ justifyContent: "flex-start", }}>
              <SText fontSize={10}>Interes/dia</SText>
            </SView>
            <SView col={"xs-2"} center backgroundColor={"transparent"}>
              <SText fontSize={10}>Dias</SText>
            </SView>
            <SView col={"xs-2.5"} row backgroundColor={"transparent"} style={{ justifyContent: "flex-end", }}>
              <SText fontSize={10}>Monto pagado  </SText>
            </SView>
          </SView>
        </SView>
        <SHr height={12} />

        <SView col={"xs-11.5"} backgroundColor={"cyan+66"}>
          {this.ItemDetalle(obj?.key)}
        </SView>


      </SView>
    </SView >
  }
  getLista() {



    var dataPrestamos = Model.prestamo.Action.getAll(this.props);
    if (!dataPrestamos) return <SLoad />

    // const arr_data = Object.values(dataPrestamos);
    // if (!arr_data) return;

    // var nada = arr_data.map(obj => {
    //   console.log("coj ", obj);
    // });

    // Object.values(dataPrestamos)?.map(obj => console.log("coj ", obj));


    // if (!this.state.data) return <SLoad />
    // var data = data_test.map(objeto => Object.values(objeto)[0]);


    // var data1234 = data123.map(objeto => Object.values(objeto)[0]);

    return <SList2 data={dataPrestamos} horizontal space={0} render={obj_fondo =>
      this.Item(obj_fondo)
      // console.log("sakura ", obj_fondo)
    } />
  }





  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      // params: this.state.params,
    }).then(resp => {
      this.setState({ loading: false, data: resp.data });
    }).catch(e => {
      this.setState({ loading: false, error: e });
    })
  }


  getChaval() {
    var data123 = Model.prestamo.Action.getAll(this.props);
    if (!data123) return <SLoad />

    return data123.map(obj => {
      console.log("mpri ", obj.estado);
      // return obj; // Return the original object
    });

    console.log("chau ", datus);
  }


  render() {

    // if (!this.state.data) return <SLoad />
    // console.log("mucho ", this.state.data);

    return (
      <SPage title={'lista'} center>
        <SHr height={24} color={"transparent"}></SHr>
        <SView col={"xs-12 "} center row style={{
          paddingStart: 10,
          paddingEnd: 10,
          paddingTop: 0,
          paddingBottom: 0,
        }}>
          {this.getLista()}
          {/* {this.getChaval()} */}
        </SView>

        <SHr height={50} color={"transparent"}></SHr>
      </SPage >
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(dashboard);