import { Component } from "react";
import { TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import {
  ExportExcel,
  SButtom,
  SHr,
  SImage,
  SLoad,
  SNavigation,
  SOrdenador,
  SPage,
  SScrollView2,
  SText,
  STheme,
  SView
} from "servisofts-component";
import SSocket from "servisofts-socket";
import Usuario from "../..";
import BarraSuperior from "../../../../Components/BarraSuperior";
import Buscador from "../../../../Components/Buscador";
import FloatButtom from "../../../../Components/FloatButtom";
import FloatButtomSyncUsuario from "../../../../Components/FloatButtomSyncUsuario";
import Model from "../../../../Model";
import { SSRolesPermisosValidate } from "../../../../SSRolesPermisos";

var objFinal = {};
let tamaño_texto = 9;

class VentasPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { curPage: 1 }
    };
    this.onSelect = SNavigation.getParam("onSelect")
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
    const isMobile = window.innerWidth <= 768;
    tamaño_texto = isMobile ? 6.5 : 9;
  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50;
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  };
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />
    }
    if (data.estado != 0) {
      return <View />
    }
    return <SButtom
      style={{ height: 50, width: 55, borderRadius: 4, backgroundColor: "red", position: "absolute", right: 4 }}
      onPress={() => {
        Usuario.Actions.editar({
          ...data,
          estado: 1,
        }, this.props)
      }} ><SText center fontSize={9.3}>RECUPERAR</SText></SButtom>
  }

  // valido_Cumpleaños2(Cumpleaños) {
  //   var fecha = Cumpleaños;
  //   var fechaObj = new Date(fecha);
  //   var mes = fechaObj.getMonth() + 1;
  //   const fechaActual = new Date();
  //   const mesActual = fechaActual.getMonth() + 1;
  //   var mensaje = "";
  //   if (mes === mesActual) {
  //     mensaje = "Cumpleañero 🥳📆 " + (fecha ?? "");
  //   } else {
  //     mensaje = "Fecha nacimiento: " + (fecha ?? "");
  //   }
  //   const textColor = (Cumpleaños === null ? "red" : (mes === mesActual ? "green" : STheme.color.text));
  //   return <Text style={{ color: textColor }}>{mensaje}</Text>;
  // }


  template() {
    let isMobile = window.innerWidth <= 768; // Definir el tamaño_texto máximo para considerar como pantalla pequeña
    tamaño_texto = isMobile ? 6.5 : 9;
    if (isMobile) return null;

    return (
      <>
        <SView
          col={"xs-2.5 md-2.4"}
          border={"yellow"}
          style={{
            position: "absolute",
            top: 80,
            borderRadius: 8,
            padding: 5,
            fontSize: 2,
          }}
        >
          <SText bold color={"yellow"}>
            Nota:
          </SText>
          <SHr></SHr>
          <SText>
            Antes de proceder con el registro de una venta, es fundamental
            revisar los siguientes criterios de búsqueda:
          </SText>
          <SHr></SHr>
          <SText>1.- Documento (CI)</SText>
          <SText>2.- Nombres completo</SText>
          <SText>3.- Teléfono</SText>
          <SHr></SHr>
          <SText>
            Es bolivia de suma importancia asegurarse de que los datos sean precisos, ya
            que se utilizarán para verificar la autenticidad de la información
            al realizar
            <SText color={"yellow"}> sorteos </SText>o
            <SText color={"yellow"}>entregar permisos.</SText>
          </SText>
        </SView>
      </>
    );
  }

  exportar_excel() {
    // valido permiso exportar_excel
    if (!SSRolesPermisosValidate({ page: "ClientesPage", permiso: "exportar_excel" })) {
      return null;
    }

    return <SView col={"xs-12"} center row>
      <SText style={{ color: STheme.color.lightGray, fontSize: 12 }}>(Exportar)</SText>
      <ExportExcel
        header={[
          { key: "indice", label: "Nro", width: 40 },
          { key: "ci", label: "Documento", width: 60 },
          { key: "nombres", label: "Nombres", width: 200 },
          { key: "telefono", label: "Telefono", width: 90 },
          { key: "correo", label: "Correo", width: 170 },
          { key: "cumpleaños", label: "Fecha nacimiento", width: 75 },
          { key: "zona", label: "Zona", width: 150 },
          { key: "direccion", label: "Dirección", width: 170 },
          { key: "empresa", label: "Empresa", width: 150 },
          { key: "cargo", label: "Cargo", width: 150 },
          { key: "referencia", label: "Referencia", width: 100 },
          { key: "key_usuario", label: "key_usuario", width: 220 },
        ]}
        getDataProcesada={() => {
          var daFinal = {};
          Object.values(objFinal).map((obj, i) => {
            if (obj.estado == 0) return;
            var toInsert = {
              indice: i + 1,
              key_usuario: obj?.key,
              nombres: obj?.Nombres + " " + obj?.Apellidos,
              ci: obj?.CI,
              telefono: obj?.Telefono,
              correo: obj?.Correo,
              cumpleaños: obj["Fecha nacimiento"],
              zona: obj?.Zona,
              direccion: obj?.Direccion,
              empresa: obj?.Empresa,
              cargo: obj?.Cargo,
              referencia: obj?.Referencia,
            };
            daFinal[i] = toInsert;
          });
          return daFinal;
        }}
      />
    </SView>
  }

  valido_NombreCompleto(nombre, apellidos) {
    return <SText fontSize={tamaño_texto + 6} style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{(nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_CI(nit) {
    return <SText fontSize={tamaño_texto + 2} color={(nit ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"CI: " + (nit ?? "")}  {(nit ?? "").length < 5 ? <SText fontSize={tamaño_texto + 1} backgroundColor='red' color="white" bold  > error </SText> : ""}</SText >
  }

  valido_Cumpleaños(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"F.Nac: " + (Cumpleaños ?? "")}  {(Cumpleaños ?? "").length < 9 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}   </SText >
  }
  valido_sexo(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Género: " + (Cumpleaños ?? "")} {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}</SText >
  }

  valido_Festivo(Cumpleaños) {
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Festivo: " + (Cumpleaños ?? "")} {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold> error </SText> : ""}</SText>
  }

  valido_Telefono(telefono) {
    // if (!telefono) return null;
    return <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}  </SText>
  }

  valido_Correo(correo) {
    return <SText fontSize={tamaño_texto + 2} style={{ color: (correo ?? "").length < 12 ? "red" : STheme.color.text }}> {"Correo: " + (correo ?? "")} </SText >;
  }

  valido_Nit(nit) {
    return <SText fontSize={tamaño_texto} color={(nit ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"Nit: " + (nit ?? "")}  {(nit ?? "").length < 7 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}  </SText>
  }

  valido_RazonSocial(nombre) {
    return <>
      <SText fontSize={tamaño_texto} color={(nombre ?? "").length < 3 ? STheme.color.text : STheme.color.text} >{"Razón Social: " + (nombre ?? "")}
        {(nombre ?? "").length < 5 ? <SText fontSize={tamaño_texto} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}</SText>
    </>
  }
  render() {
    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      var dataRU = Model.usuarioRol.Action.getAllByKeyRol("d16d800e-5b8d-48ae-8fcb-99392abdf61f");

      if (!data) return <>
        <SText>Cargando server usuarios</SText>
        <SLoad />
      </>

      if (!dataRU) return <>
        <SText>Cargando rol clientes</SText>
        <SLoad />
      </>

      if (!this.state.buscador) {
        return <View />;
      }

      Object.keys(dataRU).map((key) => {
        var rol_user = dataRU[key];
        if (!rol_user.key_usuario) return;
        if (!rol_user.estado) return;
        if (!data[rol_user.key_usuario]) {
          return;
        }
        if (!data[rol_user.key_usuario].estado) return;
        objFinal[rol_user.key_usuario] = data[rol_user.key_usuario];
      });

      var isRecuperar = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "recuperar_eliminado" });

      return this.pagination(
        new SOrdenador([
          { key: "Peso", order: "desc", peso: 4 },
          { key: "Nombres", order: "asc", peso: 2 },
          { key: "Apellidos", order: "asc", peso: 1 },
        ]).ordernarObject(this.state.buscador.buscar(objFinal))
      ).map((key) => {
        var obj = data[key];
        return <TouchableOpacity
          key={key}
          style={{
            width: "90%",
            maxWidth: 600,
            height: 65,
            margin: 4,
            borderRadius: 10,
            backgroundColor: STheme.color.card,
          }}
          onPress={() => {

            if (this.onSelect) {
              this.onSelect(obj);
              return;
            }
            this.select = SNavigation.getParam("select");
            if (this.select) {
              this.select(obj);
              SNavigation.goBack();
              return;
            }
            SNavigation.navigate("ClientePerfilPage", { key: key });
          }} >
          <View style={{ flex: 1, justifyContent: "center" }} >
            <View
              style={{
                flexDirection: "row",
                height: "100%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <SView border={"yellow"}
                style={{ width: 40, height: 40, marginRight: 8, marginLeft: 8, justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 100, overflow: "hidden" }}
              >
                <SImage src={SSocket.api.root + "usuario/" + key + `?date=${new Date().getTime() / 500}`}
                />
              </SView>

              {/* texto */}
              <View style={{ flex: 1, justifyContent: "center" }}>
                <SView col={"xs-12"} row height  >
                  <SView col={"xs-8"}  >
                    {this.valido_NombreCompleto(obj?.Nombres, obj?.Apellidos)}
                    <SView col={"xs-12"} row >
                      <SView col={"xs-2.3"}>
                        {this.valido_CI(obj?.CI)}
                      </SView>
                      <SView col={"xs-3.4"}  >
                        {this.valido_Cumpleaños(obj?.["Fecha nacimiento"])}
                      </SView>
                      <SView col={"xs-3.2"}    >
                        {this.valido_sexo(obj?.Sexo)}
                      </SView>
                      <SView col={"xs-3"}  >
                        {this.valido_Festivo(obj?.Festivo)}
                      </SView>
                    </SView>
                    <SView col={"xs-12"} row >
                      <SView col={"xs-5"}   >
                        {this.valido_Telefono(obj?.Telefono)}
                      </SView>
                      <SView>
                        {this.valido_Correo(obj?.Correo)}
                      </SView>
                    </SView>
                  </SView>
                  <SView col={"xs-4"}  >
                    <SText fontSize={tamaño_texto + 3} color={"cyan"}>DATOS FACTURACIÓN</SText>
                    {this.valido_Nit(obj?.Nit)}
                    {this.valido_RazonSocial(obj?.RazonSocial)}
                  </SView>
                </SView>
              </View>
              {this.getRecuperar(obj, isRecuperar)}
            </View>
          </View>
        </TouchableOpacity>
      });
    };

    return (
      <>
        <SPage hidden disableScroll>
          <BarraSuperior
            title={"VENTAS - CLIENTES"}
            navigation={this.props.navigation}
            goBack={() => {
              SNavigation.goBack();
            }}
          />
          <Buscador
            placeholder={"Buscar usuario por CI, nombres, apellidos, teléfono."}
            ref={(ref) => {
              if (!this.state.buscador) this.setState({ buscador: ref });
            }}
            repaint={() => { this.setState({ ...this.state }) }}
            eliminados={SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "ver_eliminados" })}
          />
          <View style={{ flex: 1, width: "100%" }} >
            <SScrollView2
              disableHorizontal
              onScroll={(evt) => {
                var evn = evt.nativeEvent;
                var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
                var heigth = evn.contentSize.height;
                if (heigth - posy <= 0) {
                  this.state.pagination.curPage += 1;
                  this.setState({ ...this.state });
                }
              }}
            >
              {this.exportar_excel()}

              <SView col={"xs-12"} center>
                {getLista()}
              </SView>

            </SScrollView2>
            <FloatButtomSyncUsuario style={{ bottom: 90, }}
              onPress={() => {
                Model.usuario.Action.CLEAR_STORAGE()
              }}
            />
            <FloatButtom
              onPress={() => {
                SNavigation.navigate("registro");
              }}
            />
          </View>
        </SPage>
        {/* {this.template()} */}
      </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(VentasPage);
