import { Component } from "react";
// import { SectionList, Text } from "react-native";

import { SectionList } from 'react-native';
import SSocket from 'servisofts-socket';

import { connect } from "react-redux";
import { SHr, SIcon, SPage, SText, STheme, SView } from "servisofts-component";
import Botoncito from "../../Components/Botoncito";

class jose extends Component {

  constructor(props) {
    super(props);
    this.state = {
      servidor_estado: null,
      ...this.state,
    }
  }

  componentDidMount() {
    this.valida_server();
  }

  async valida_server() {
    const respuesta = await SSocket.sendPromise({
      component: "enviroments",
      type: "get",
      key: "qr_state",
      estado: "cargando",
    })
    this.setState({ servidor_estado: respuesta.data == "true" ? true : false })
    this.setState({ ...this.state });
  }

  botonServer() {
    if (this.state.servidor_estado == null) {
      this.valida_server();
    }
    return (<Botoncito height={80} width={180} onPress={() => {
      if (this.state.servidor_estado == true) {
        SSocket.send({
          component: "enviroments",
          type: "editar",
          estado: "cargando",
          data: {
            key: "qr_state",
            data: "false",
          }
        })
        alert("✅ Servidor QR activo: Todo está en orden.");

        this.setState({ servidor_estado: false });
        return;
      }
      else if (this.state.servidor_estado == false) {
        SSocket.send({
          component: "enviroments",
          type: "editar",
          estado: "cargando",
          data: {
            key: "qr_state",
            data: "true",
          }
        })
        alert("⚠️ Servidor QR inactivo.");

        this.setState({ servidor_estado: true });
        return;
      }
      this.setState({ ...this.state });
    }} >{"Actualizar"}</Botoncito>
    );
  };

  MyIconos() {
    if (this.state.servidor_estado == true) {
      return <SIcon name={"On"} width={24} height={24} />;
    }
    else {
      return <SIcon name={"Off2"} width={24} height={24} />;
    }
  };

  render() {
    return (
      <SPage title={"Activar el mensaje - No hay server qr jose "} disableScroll center>
        <SHr height={40}></SHr>
        <SText > APP Calistenia Bolivia </SText>
        <SHr height={80}></SHr>
        <SIcon name={"Logo"} width={80} height={80} />;
        <SView col={"xs-12"} height={150} backgroundColor={"transparent"} />
        {/* <SView col={"xs-12"} flex backgroundColor={"transparent"} /> */}
        <SText color="blue" fontSize={28} >Servidor QR  {this.state.servidor_estado ? "ACTIVADO" : "FUERA DE LINEA"} {this.MyIconos()}</SText>
        {this.botonServer()}
        <SHr height={80}></SHr>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(jose);
