import { Component } from 'react';
import { SButtom, SDate, SHr, SIcon, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import MonthBetween from '../../../Components/MonthBetween';
import CajasActivas from './dbItems/CajasActivas';
import ClientesActivos from './dbItems/ClientesActivos';
import EntrenamientosActivos from './dbItems/EntrenamientosActivos';
import SucursalesDetalle from './dbItems/SucursalesDetalle';
import SucursalesDetalleGrafico from './dbItems/SucursalesDetalleGrafico';

export default class index extends Component {
  constructor(props) {
    super(props);
    var fechaInicio = new SDate();
    fechaInicio.setDay(1);
    this.state = {
      fechaInicio: fechaInicio.toString("yyyy-MM-dd"),
      fechaFin: new SDate().toString("yyyy-MM-dd")
    };
  }

  /* componentDidMount
   hilo para hacerlo casa 30 min */

  mensaje() {
    return <>
      <SView flex border={STheme.color.card} style={{ position: "absolute", top: 0, left: '5%', padding: 12 }}>
        <SText fontSize={13} font={"Roboto"}>Codificación de colores</SText>
        <SText fontSize={13}>Verde:      {'>'}  60.000 bs</SText>
        <SText fontSize={13}>Amarillo: {'>'}  45.000 bs</SText>
        <SText fontSize={13}>Rojo:        {'>'}  30.000 bs</SText>
        {/* <SText fontSize={13}>Verde: `&gt;`60.000</SText> */}
        {/* <SText fontSize={13}>plomo: {'<'} 30.000</SText> */}
      </SView>
    </>
  }

  render() {
    return (<>
      <SPage title={"DASHBOARD"}>
        <SView col={"xs-12"} row center style={{ width: "100%", }}>
          {/* {this.mensaje()} */}

          <SHr height={32} />

          <MonthBetween fecha_inicio={this.state.fechaInicio} fecha_fin={this.state.fechaFin}
            onChange={(fechaInicio, fechaFin) => { this.setState({ fechaInicio, fechaFin }) }} />
          <SHr height={32} />

          <ClientesActivos />

          <EntrenamientosActivos fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />
          <CajasActivas fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />
          <SHr height={32} />
          <SucursalesDetalle fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />

          <SucursalesDetalleGrafico fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />

          {/* <GraficoPaquetesVendidos fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />
          <SHr height={36} />
          <GraficoIngresos fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />
          <SHr height={36} />
          <GraficoAsistencia fechaInicio={this.state.fechaInicio} fechaFin={this.state.fechaFin} />
          <SHr height={100} /> */}
        </SView>
      </SPage>
      <SButtom Card
        onPress={() => { SNavigation.navigate("grafico") }}
        style={{ backgroundColor: STheme.color.gray, position: "absolute", right: 50, bottom: 32, width: 50, height: 50, }}>
        <SIcon name={"DashboardBtn"} style={{ width: 45, height: 45, }} />
      </SButtom>
    </>
    );
  }
}
