import { SPageListProps } from 'servisofts-component'
import Actions from './Actions'

const Pages: SPageListProps = {

}

import asistenciaReducer from './Reducer/asistenciaReducer'
const Reducers = {
    asistenciaReducer
}


export default {
    Pages,
    Actions,
    Reducers
};