import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SDate, SIcon, SLoad, SNavigation, SPage, STable, STable2, SText, SThread, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import Model from '../../../../Model'
import { connect } from 'react-redux'
import sucursal from '../../../../Model/empresa/sucursal'
import Botoncito from '../../../../Components/Botoncito'
import Paquete from '../../../Paquete'

class test extends Component {

    constructor(props) {
        super(props)
        this.state = {
            date: ""
        }
        // 16082023
        this.state.date = SNavigation.getParam("date", "16082023");
    }
    componentDidMount() {
        Model.usuario.Action.getAll({ force: true })
        SSocket.sendPromise({
            service: "banco_ganadero",
            component: "bg_payment_order",
            type: "getAllTransactions",
            key_bg_profile: "8f686d25-1d0a-4cff-9b5b-ae030efa0e99",
            endDate: this.state.date,
            startDate: this.state.date,
            timeOut: 1000 * 60 * 10
        }, 1000 * 60 * 10).then(resp => {
            SSocket.sendPromise({
                component: "solicitud_compra_paquete",
                type: "getAll",
            }).then(resp2 => {
                SSocket.sendPromise({
                    service: "banco_ganadero",
                    component: "bg_payment_order",
                    type: "getAllSimple",
                    key_bg_profile: "8f686d25-1d0a-4cff-9b5b-ae030efa0e99",
                }).then(resp3 => {
                    const arr_banco_ganadero = resp.data;
                    const arr_solicitud_compra_calistena = Object.values(resp2.data);
                    const arr_ms_banco_ganadero = Object.values(resp3.data);

                    arr_banco_ganadero.orders.map((a) => {
                        const bg = arr_ms_banco_ganadero.find((b) => b.qrid == a.QRID)
                        if (bg) {
                            a.bg = bg;
                        }

                        const calis = arr_solicitud_compra_calistena.find((b) => b.qrid == a.QRID)
                        if (calis) {
                            a.calis = calis;
                        }

                    })
                    this.setState({ loading: false, data: resp.data.orders });
                })

            })
        }).catch(e => {
            this.setState({ loading: false, error: e });
        })
    }
    render() {
        // console.log("punbt")

        var usuarios = Model.usuario.Action.getAll();
        var sucursal = Model.sucursal.Action.getTodas();
        var paquetes = Paquete.Actions.getAll(this.props);
        if (!usuarios) return <>
            <SText>Cargando server Usuarios</SText>
            <SLoad />
        </>
        if (!sucursal) return <>
            <SText>Cargando server Sucursales</SText>
            <SLoad />
        </>
        if (!paquetes) return <>
            <SText>Cargando server paquetes</SText>
            <SLoad />
        </>

        // console.log("nuevo mundo")

        return <SPage disableScroll title={"Pagos Alvaro"}>

            {!this.state.data ?
                <>
                    <SText>Cargando server banco ganadero</SText>
                    <SLoad />
                </> : <STable2
                    data={this.state.data}
                    rowHeight={25}
                    limit={45}
                    header={[
                        { key: "index", label: "#", width: 30 },
                        // { key: "ORDERDATE", width: 100 },
                        { key: "AMOUNT", width: 80, sumar: true },
                        // { key: "CURRENCY", width: 50 },
                        // { key: "BANKCODE", width: 100 },
                        // { key: "ACCOUNTREFERENCE", width: 100 },
                        { key: "QRID", width: 170, order: "desc" },
                        { key: "ORDERSTATE", width: 50, filter_h: "2" },
                        { key: "TRANSACTIONID", width: 100, filter_notin: "^0" },
                        { key: "REFERENCE", width: 150 },
                        { key: "PAYDATE", width: 90 },
                        // { key: "bg/key_usuario", width: 100 },
                        { key: "calis/fecha_pago", width: 180, render: f => !f ? "" : new SDate(f).toString("yyyy-MM-dd hh:mm:ss") },
                        { key: "calis/key_sucursal", width: 100, render: (e) => sucursal[e]?.descripcion },
                        // { key: "-r", width: 100, component: e => !!e?.calis?.key_sucursal ? null : <Botoncito onPress={Reparar.bind(this, e, paquetes, sucursal)} height={20}>REPARAR</Botoncito> },
                        { key: "bg/fecha_pago", width: 180, render: f => !f ? "" : new SDate(f).toString("yyyy-MM-dd hh:mm:ss") },
                        { key: "calis/key_usuario", width: 200, component: (e) => <SView onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>{!e ? null : <SText color="yellow">{`${usuarios[e]?.Nombres} ${usuarios[e]?.Apellidos}`}</SText>}</ SView> },
                        // { key: "bg/key_usuario", width: 200, component: (e) => <SView onPress={() => { window.open("/ClientePerfilPage?key=" + e) }}>{!e ? null : <SText>{`${usuarios[e]?.Nombres} ${usuarios[e]?.Apellidos}`}</SText>}</ SView> },
                        { key: "bg/tipo", width: 80, center: true },
                        // { key: "bg/key_usuario-d", width: 200, render: e => `${usuarios[e]?.Nombres ?? ""} ${usuarios[e]?.Apellidos ?? ""}` },
                        { key: "calis/key_usuario-d", width: 200, render: e => `${usuarios[e]?.Nombres ?? ""} ${usuarios[e]?.Apellidos ?? ""}` },

                        {
                            key: "-54", label: "Verificar", center: true, width: 200, component: (obj) => {
                                if (obj) return <SText>Usuairo regitrad</SText>;
                                return <SView width={28}
                                    onPress={() => {
                                        // SNavigation.navigate(route)
                                    }}>
                                    <SIcon name="Eye" /></SView >
                            }
                        }
                    ]}
                />}
        </SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(test);


const Reparar = (data, paquetes, sucursales) => {
    const { ORDERDATE, AMOUNT, REFERENCE, bg, QRID } = data;
    const date = new SDate(ORDERDATE, "ddMMyyyy")

    let paquete = Object.values(paquetes).find(a => a.descripcion == REFERENCE)

    // if (!paquete) {
    // alert("Paquete no encontrado.")
    let key_paquete = prompt('Ingrese la key_paquete');
    paquete = paquetes[key_paquete];
    if (!paquete?.key) {
        alert("No se encontro el paquete");
        return;
    }
    // }
    if (!bg?.key_usuario) {
        alert("Usuario no encontrado.")
        return;
    }
    let key_sucursal = prompt('Ingrese la key_sucursal');
    const sucursal = sucursales[key_sucursal];
    if (!sucursal?.key) {
        alert("No se encontro la sucursal");
        return;
    }

    const solicitud_compra_paquete = {
        // key: "",
        key_usuario: bg.key_usuario,
        key_paquete: paquete.key,
        // fecha_on: "",
        estado: 1,
        fecha_inicio: date.toString("yyyy-MM-ddThh:mm:ss"),
        qrid: QRID,
        monto: parseFloat(AMOUNT),
        fecha_vencimiento: date.addDay(1).toString("yyyy-MM-ddThh:mm:ss"),
        key_sucursal: key_sucursal
    }
    const solicitud = {
        component: "solicitud_compra_paquete",
        type: "registro",
        estado: "cargando",
        data: solicitud_compra_paquete
    }
    SSocket.sendPromise(solicitud).then(e => {
        alert("exito")
        console.log(e);
    }).catch(e => {
        alert("Error")
        console.error(e);
    })
    // console.log(solicitud_compra_paquete);
}