import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SIcon, SLoad, SNavigation, SPage, STable2, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Model from "../Model";

class caja_en_cero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Caja en 0",
      func: "_caja_en_cero",
      parametros: {
        inicio: "2023-06-11",
        fin: "2023-08-29",
      },
      ...this.state,
    };
  }

  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }



  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: ["'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'"],
      // ...this.params
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type="skeleton" col={"xs-12"} height />;

    console.log("papi ", dase_datos)
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    // var aux = sucursal_usuario.Actions.getActive(this.props);
    // if (!aux) return <SLoad />;

    return (
      <STable2
        limit={15}
        data={dase_datos}
        cellStyle={{
          fontSize: 12,
          // height: 30,
        }}
        header={[
          { key: "index", label: "#" },
          // { key: "caja_key", label: "Caja", width: 250 },




          {
            key: "fecha", label: "Fecha", width: 80, render: (item) => {
              let edad = new SDate(item).toString("yyyy-MM-dd");
              return edad;
            },
          },
          { key: "sucursal", label: "Sucursal", width: 80 },
          { key: "monto_apertura", label: "Aperturó", width: 80 },
          { key: "cajero", label: "Cajero", width: 210, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; } },
          {
            key: "caja_key", label: "Caja", width: 50, center: true,
            component: (item) => {
              return <SView onPress={() => { SNavigation.navigate("DetalleCaja", { key: item }) }}>
                <SIcon name={"Ajustes"} width={35} />
              </SView>
            }
          },

        ]}
      />
    );
  }
  render() {
    return (
      <SPage
        title={"Caja mal cerrada 📊🛒"}
        disableScroll
        center
      >
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(caja_en_cero);
