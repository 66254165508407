import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import Container from '../../Components/Container'
export default class root extends Component {
    state = {
        type: SNavigation.getParam("type"),
        onSelect: SNavigation.getParam("onSelect"),
    }
    componentDidMount() {
        SSocket.sendPromise({
            component: "facturacion",
            type: "getParametricas"
        }).then(e => {
            const list = e?.data?.empresa?.parametricas[this.state.type];
            this.setState({ data: list })
        }).catch(e => {
            console.error(e)
        })

    }

    render() {
        if (!this.state.data) return <SLoad />
        return <SPage>
            <STable2
                header={[
                    { key: "index", width: 50 },
                    { key: "CODIGO", width: 80, },
                    { key: "DESCRIPCION", width: 350 },
                    ...(!this.state.onSelect ? [] : [
                        {
                            key: "-SELECT", width: 120, component: select => <SView flex height onPress={() => {
                                this.state.onSelect(select);
                                SNavigation.goBack();
                            }}>
                                <SText bold style={{
                                    fontSize: 16,
                                    color: "#00f",
                                    textDecoration: "underline"
                                }}>SELECCIONAR</SText>
                            </SView>
                        },
                    ])
                ]}
                data={this.state?.data}
            />
        </SPage>
    }
}