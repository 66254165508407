import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { SImage, SLoad, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';

const RolDeUsuario = (props) => {

  const [state, setState] = React.useState({});
  if (!props.data) return <View />;
  if (!props.data.key) return <View />;


  if (!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "ver_rol" })) {
    return <View />
  }
  var data = Model.rol.Action.getAll();
  if (!data) {
    return <SLoad />
  }


  var key_usuario = props.data.key;
  if (!key_usuario) {
    return <SLoad />
  }
  var usuarioRol = Model.usuarioRol.Action.getAllByKeyUsuario(key_usuario)
  if (!usuarioRol) {
    return <SLoad />
  }


  if (state.loading) return <SLoad />


  var arr_rol = Object.values(usuarioRol);
  const getRoles = () => {
    var isAddSuperUsuario = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "add_rol_super_usuario" })
    var Lista = Object.keys(data).map((key) => {
      var obj = data[key];
      var isActivo = false;
      if (key == "01726154-c439-4d63-99a1-0615d9e15f15") {
        if (!isAddSuperUsuario) {
          return <View />
        }
      }
      isActivo = arr_rol.find((a) => a.key_rol == key)
      if (!isActivo?.estado) {
        isActivo = false;
      }
      if (props.preventEdit && !isActivo) {
        return null;
      }
      return <TouchableOpacity style={{
        width: "40%",
        maxWidth: 170,
        height: 160,
        margin: 8,
        borderRadius: 4,
        // borderWidth: 1,
        borderWidth: isActivo.estado ? 1 : 0.5,
        borderColor: isActivo?.estado ? "green" : STheme.color.card,
        // isActivo
        // borderColor: STheme.color.card,
        backgroundColor: STheme.color.card,
        // padding:4,

      }}
        onPress={() => {



          if (state.loading) return false;
          if (props.preventEdit) return null;
          if (SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "editar_rol", isAlert: true })) {

            setState({ loading: true })
            if (!isActivo) {


              SSocket.sendPromise({
                service: "roles_permisos",
                component: "usuarioRol",
                type: "registro",
                key_usuario: props.state.usuarioReducer.usuarioLog.key,
                estado: "cargando",
                data: {
                  key_rol: key,
                  key_usuario: props.data.key,
                }
              }).then(resp => {
                if (resp.estado == "exito") {

                  props.dispatch(resp)
                  setState({ loading: false });
                }
              }).catch(e => {
                setState({ loading: false });
              })

            } else {


              SSocket.sendPromise({
                service: "roles_permisos",
                component: "usuarioRol",
                type: "editar",
                key_usuario: props.state.usuarioReducer.usuarioLog.key,
                estado: "cargando",
                data: { ...isActivo, estado: 0 }
              }).then(resp => {
                if (resp.estado == "exito") {

                  props.dispatch(resp)
                  setState({ loading: false });
                }
              }).catch(e => {
                setState({ loading: false });
              })
            }
          }
          // props.navigation.navigate("PermisoCrearPage", { key: objPermiso.key });
        }}>
        <View style={{
          flex: 1
        }}>
          <View style={{
            padding: 4,
            backgroundColor: STheme.color.card,



            height: 120,
            borderRadius: 8,
            overflow: "hidden"
          }}>
            <SImage src={SSocket.api.rp + "rol/" + obj.key} />
            {/* {props.state.imageReducer.getImage(AppParams.servicios["roles_permisos"] + "rol/" + obj.key, {})} */}
          </View>
          <View style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Text style={{
              fontSize: 16,
              textAlign: "center",
              fontWeight: "bold",
              color: STheme.color.secondary
            }}>{obj.descripcion}</Text>
          </View>
        </View>
        {(isActivo ? <View /> : <View style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          borderRadius: 8,
          backgroundColor: "#000000dd",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Text style={{
            fontSize: 20,
            fontWeight: "bold",
            color: STheme.color.text
          }}>Activar</Text>
        </View>)}
      </TouchableOpacity>
    })
    return <SView row center>
      {Lista}
    </SView>
  }
  // var pagina = props.state.usuarioPageReducer.data["UsuarioPage"];
  // if (!pagina) {
  //     return <View />;
  // }
  // if (!pagina.permisos["editar_roles"]) {
  //     return <View />;
  // }
  return <SView>
    <Text style={{
      padding: 8,
      fontSize: 14,
      color: STheme.color.secondary,
      width: "100%",
      textAlign: "center"
    }}>{props.title ? props.title : "ROLES USUARIOS"}</Text>
    {getRoles()}
  </SView>
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(RolDeUsuario);