import { SPageListProps } from "servisofts-component";
import Actions from "./Actions";

import EstadoFinanciero from "./Pages/EstadoFinanciero";
import EstadoFinancieroSelect from "./Pages/EstadoFinancieroSelect";
import FinanzaPage from "./Pages/FinanzaPage";
import IngresosEgresos from "./Pages/IngresosEgresos";
import modelo from "./Pages/Modelo";
import PaquetesVendidos from "./Pages/PaquetesVendidos";
import ReporteAsistencia from "./Pages/ReporteAsistencia";
import ReporteBancos from "./Pages/ReporteBancos";
import ReporteProrroga from "./Pages/ReporteProrroga";
import UsuariosTabla from "./Pages/UsuariosTabla";
import reporte_montos_actuales_todas_las_cajas from "./Pages/reporte_montos_actuales_todas_las_cajas";
import reporte_movimientos_banco from "./Pages/reporte_movimientos_banco";
import reporte_movimientos_caja from "./Pages/reporte_movimientos_caja";
import reporte_paquetes_anulados_todas_las_cajas from "./Pages/reporte_paquetes_anulados_todas_las_cajas";
import reporte_qr_banco_ganadero from "./Pages/reporte_qr_banco_ganadero";
import ReporteQR from "./Pages/ReporteQR";
import reporte_qr_pagado from "./Pages/reporte_qr_pagado"; 
import reparador from "./Pages/reparador"; 
const Pages: SPageListProps = {
  FinanzaPage,
  ReporteBancos,
  PaquetesVendidos,
  ReporteAsistencia,
  IngresosEgresos,
  EstadoFinanciero,
  EstadoFinancieroSelect,
  UsuariosTabla,
  ReporteProrroga,
  reporte_montos_actuales_todas_las_cajas,
  reporte_paquetes_anulados_todas_las_cajas,
  reporte_qr_banco_ganadero,
  reporte_movimientos_banco,
  reporte_movimientos_caja,
  modelo,
  ReporteQR,
  reparador,
  reporte_qr_pagado,
  ...qr
};

import reporteReducer from "./Reducer/reporteReducer";
import qr from "./Pages/qr";
const Reducers = {
  reporteReducer,
};

export default {
  Pages,
  Actions,
  Reducers,
};
