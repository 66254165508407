//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SDate, SHr, SInput, SList2, SLoad, SMath, SNavigation, SPage, SScrollView2, SScrollView3, SText, STheme, SView } from 'servisofts-component';
import Container from '../../Components/Container';
import SSocket from "servisofts-socket"
import { FacturaType } from './type';
import { crear_excel } from './reporte_excel_functions';
import SList from 'servisofts-component/Component/SList2';
import Model from '../../Model';
// create a component
const COLOR_1 = "#6460D5";
export default class index extends Component {
    state = {
        iva: 0.13,
        it: 0.03,
        ingresos: 0,
        gastos: 0,
        fecha: new SDate().toString("yyyy-MM-dd"),
        sucursal: {
            key: "todas"
        },
        sucursales: {},
    }


    componentDidMount() {
        SSocket.sendPromise({
            component: "sucursal",
            type: "getAll",
            key_usuario: Model.usuario.Action.getKey()
        }).then(e => {
            this.setState({ sucursales: e.data })
            this.loadData();
        }).catch(e => {

        })
    }

    loadData(props) {
        SSocket.sendPromise({
            component: "facturacion",
            type: "getFacturas",
            fecha_inicio: "2024-02-01",
            fecha_fin: "2024-02-29",
            CODIGO_AMBIENTE: 1,
            // CODIGOSUCURSAL: 2,
            ...props
        }).then(e => {
            const data: FacturaType[] = e?.data?.data;
            let monto_ventas = 0;
            data.map(a => {
                monto_ventas += a.CABECERA.MONTOTOTAL
            })
            this.setState({ ingresos: monto_ventas })

            // this.setState({ data: e?.data?.data })
        }).catch(e => {
            console.error(e)
        })

    }

    render() {
        return <SPage title={"Reporte de facturas"}>
            <SView col={"xs-12"} height={60} center>
                <SScrollView3 horizontal
                    showsHorizontalScrollIndicator
                >
                    <SList2
                        filter={e => !!e.codigo_sucursal_factura}
                        data={{
                            "todas": { key: "todas", descripcion: "Todas las sucursales", codigo_sucursal_factura: -1 },
                            ...this.state.sucursales
                        }}
                        order={[{ key: "codigo_sucursal_factura", order: "asc", type: "number" }]}
                        horizontal
                        render={e => {
                            let isSelect = false;
                            if (e.key == this.state.sucursal.key) {
                                isSelect = true;
                            }
                            let message = `Sucursal ${e.codigo_sucursal_factura} - `
                            return <SView padding={8} height={50} center onPress={() => {
                                this.loadData({ CODIGOSUCURSAL: e.codigo_sucursal_factura })
                                this.setState({ sucursal: e })
                            }}>
                                <SText color={isSelect ? STheme.color.text : STheme.color.gray} fontSize={18} bold>{e.codigo_sucursal_factura >= 0 ? message : ""}{e.descripcion}</SText>
                                {isSelect ? <SHr h={2} color={COLOR_1} /> : null}
                            </SView>
                        }}
                    />
                </SScrollView3>
                <SHr h={1} color={STheme.color.gray} />
            </SView>
            <SHr h={25} />
            <SView col={"xs-12"} row padding={8}>

                <SView col={"xs-12 sm-6 md-6 lg-6 xl-6"} height={400} padding={8}>
                    <SText color={STheme.color.gray}>Estadisticas por mes</SText>
                    <SHr />
                    <SView col={"xs-12"} card>
                        <SView row height={40}>
                            <SView flex height center>
                                <SText>Ene.2024</SText>
                            </SView>
                            <SView flex height center>
                                <SText color={COLOR_1}>Feb.2024</SText>
                            </SView>
                            <SView flex height center>
                                <SText>Mar.2024</SText>
                            </SView>
                        </SView>
                        <SHr h={1} color={STheme.color.lightGray} />
                        <SView row height={60}>
                            <SView flex height center>
                                <SText>Ventas: {SMath.formatMoney(this.state.ingresos)} Bs</SText>
                            </SView>
                            <SView flex height center>
                                <SText>Gastos: {SMath.formatMoney(this.state.gastos)} Bs</SText>
                            </SView>
                        </SView>
                        <SView col={"xs-12"} height={180} >

                        </SView>
                    </SView>
                </SView>
                <SView col={"xs-12 sm-6 md-6 lg-6 xl-6"} height={400} padding={8}>
                    <SText color={STheme.color.gray}>Impuestos por pagar</SText>
                    <SHr />
                    <SView col={"xs-12"} card>
                        <SView height={50} center>
                            <SText fontSize={18} bold>Ene. 2024</SText>
                        </SView>
                        {/* <SHr h={1} color={STheme.color.lightGray} /> */}
                        <SView row height={150}>
                            <SView flex height center>
                                <SText>IVA: {(this.state.iva * 100).toFixed(0)}%</SText>
                                <SHr />
                                <SText fontSize={20} bold color={COLOR_1}>{SMath.formatMoney((this.state.ingresos - this.state.gastos) * this.state.iva)} Bs</SText>
                            </SView>
                            <SView height width={2} backgroundColor={STheme.color.lightGray} />
                            <SView flex height center>
                                <SText>IT: {(this.state.it * 100).toFixed(0)}%</SText>
                                <SHr />
                                <SText fontSize={20} bold color={COLOR_1}>{SMath.formatMoney((this.state.ingresos) * this.state.it)} Bs</SText>
                            </SView>
                        </SView>
                    </SView>
                </SView>
            </SView>
        </SPage >
    }
}


