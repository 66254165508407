import { SPageListProps } from 'servisofts-component'
import Actions from './Actions'

import PaquetePage from './Page/PaquetePage'
import PaquetePage2 from './Page/PaquetePage2'
import PaqueteQR from './Page/PaqueteQR'
import Registro from './Page/Registro'
const Pages: SPageListProps = {
    PaquetePage,
    PaquetePage2,
    "PaquetePage/registro": Registro,
    "PaquetePage/perfil": Registro,
    "PaquetePage/QR": PaqueteQR,
    "PaquetePage/promo_usuarios": PaquetePromoUsuarios,
}


import paqueteReducer from './Reducer/paqueteReducer'
import paqueteServicioReducer from './Reducer/paqueteServicioReducer'
import PaquetePromoUsuarios from './Page/PaquetePromoUsuarios'
const Reducers = {
    paqueteReducer,
    paqueteServicioReducer
}


export default {
    Pages,
    Actions,
    Reducers
};