import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SExcelReader, SHr, SLoad, SNavigation, SPage, STable2, SText, SUuid, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos'
import { Parent } from '.';
import DataTest from "./test.json"
import Model from '../../Model';
import Paquete from '../Paquete';
import SSocket from 'servisofts-socket';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // data: DataTest
        };
    }


    componentDidMount() {
        SSocket.sendPromise({
            component: "clientesActivos",
            estado: "cargando",
            key_usuario: Model.usuario.Action.getKey(),
            type: "getAll"
        }).then(resp => {
            this.state.clientes_activos = Object.values(resp.data);
            this.setState({ ...this.state })
        })
        Model.usuario.Action.getAll({ force: true });
    }




    renderExcelImport() {
        if (this.state.data) return;
        return <SView col={"xs-12"} height padding={8}>
            <SText>Para migrar las ventas se nesecita tener una caja activa, las ventas se registraran en esta caja.</SText>
            <SHr />
            <SText>Nesecitamos subir una hoja de excel, que en la linea #1 contenga las siguientes cabeceras, iniciando desde la columna 'A'.</SText>
            <SHr />
            <SText>{`|  ci   |  fecha   |   fecha_inicio    |   fecha_fin   |   key_paquete |`}</SText>
            <SHr />
            <SText>{`[A1] ci  =  'Carnet de identidad del cliente.'`}</SText>
            <SText>{`[B1] fecha  =  'Fecha en la que se realizo la venta. (yyyy-MM-dd)'`}</SText>
            <SText>{`[C1] fecha_inicio  =  'Fecha en la que inicia la suscripción. (yyyy-MM-dd)'`}</SText>
            <SText>{`[D1] fecha_fin  =  'Fecha en la que termina la suscripción. (yyyy-MM-dd)'`}</SText>
            <SText>{`[E1] key_paquete  =  'Key del paquete que proporciona el sistema Calistenia Bolivia.'`}</SText>
            <SHr />
            <SText>WARNING   Tenga en cuenta que las cabeceras deven respetar las MAYUSCULAS y minusculas, y no deben contener espacios.</SText>
            <SText>WARNING   Tenga en cuenta que las fechas deben respetar el formato.</SText>
            <SHr />
            <SText>Preciona en el icono de excel de la parte inferior derecha, para descargar el modelo de excel</SText>
            <SHr />
            <SView col={"xs-12"} center>
                <SExcelReader onSubmit={(data, callback) => {
                    console.log(data);
                    this.setState({ data: data })
                    callback();
                }} >
                    <SView card padding={30} width={180} center><SText center>SUBIR EXCEL DE DATOS</SText></SView>
                </SExcelReader>
            </SView>
            <SHr />
            <SView flex card>
                <STable2
                    header={[
                        { key: "ci", width: 150 },
                        { key: "fecha", width: 150 },
                        { key: "fecha_inicio", width: 150 },
                        { key: "fecha_fin", width: 150 },
                        { key: "key_paquete", width: 150 },
                    ]}
                    data={{}} />
            </SView>
        </SView>
    }


    handleSubmit(obj) {
        obj.user.fecha_inicio = new SDate(obj.fecha_inicio).toString("yyyy-MM-dd");
        obj.user.fecha_fin = new SDate(obj.fecha_fin).toString("yyyy-MM-dd");
        obj.user.data = {}
        let toSend = {
            "component": "paqueteVenta",
            "type": "registro",
            "estado": "cargando",
            "key_usuario": Model.usuario.Action.getKey(),
            "data": {
                "descripcion": "",
                "key_paquete": obj.paquete.key,
                "monto": obj.paquete.monto ?? 0.00,
                "nombre_paquete": obj.paquete.descripcion,
                "key": SUuid(),
            },
            "clientes": [obj.user]
        }
        this.setState({ loading: true })
        SSocket.sendPromise(toSend).then(e => {
            e.clientes.map(cli => {
                this.state.clientes_activos.push({
                    ...cli,
                    paquete: obj.paquete
                })
            })

            this.setState({ loading: false })
        }).catch(e => {
            this.setState({ loading: false })
        })

    }
    renderTable() {
        if (!this.state.data) return;
        let usuarios = Model.usuario.Action.getAll();
        let paquetes = Paquete.Actions.getAll(this.props);
        if (!usuarios) return <SLoad />;
        if (!paquetes) return <SLoad />;
        if (!this.state.clientes_activos) return <SLoad />;
        let arr_usuarios = Object.values(usuarios);
        this.state.data.map((obj) => {
            let user = arr_usuarios.find(a => a.CI == obj.ci);
            obj.user = user;
            obj.paquete = paquetes[obj.key_paquete]
            obj.paquete_venta = this.state.clientes_activos.find(ca => ca.key_usuario == user.key && ca.fecha_inicio == new SDate(obj.fecha_inicio).toString("yyyy-MM-dd") && ca.paquete.key == obj.key_paquete)
        })
        return <STable2
            header={[
                { key: "fecha", width: 100, render: (d) => new SDate(d).toString("yyyy-MM-dd") },
                { key: "ci", width: 100 },
                { key: "user", width: 250, render: (a) => `${a.Nombres} ${a.Apellidos}` },
                { key: "fecha_inicio", width: 130, render: (d) => new SDate(d).toString("yyyy-MM-dd") },
                { key: "fecha_fin", width: 130, render: (d) => new SDate(d).toString("yyyy-MM-dd") },
                { key: "paquete/descripcion", width: 150, },
                { key: "paquete/precio", width: 100, },
                {
                    key: "-", width: 60, component: (obj) => {
                        if (obj.paquete_venta) {
                            return <SText onPress={() => {
                                // SNavigation.navigate("ClientePerfilPage", { key: obj.paquete_venta.key_usuario })
                                SNavigation.navigate("EsperandoVenta", { key: obj.paquete_venta.key_paquete_venta, key_paquete_venta_usuario: obj.paquete_venta.key })
                            }} center>Ver venta</SText>
                        }
                        return <SView width={50} height={30} card center onPress={this.handleSubmit.bind(this, obj)}>
                            <SText>SUBIR</SText>
                        </SView>
                    }
                },

            ]}
            data={this.state.data} />
    }
    render() {
        return (
            <SPage title={'Migrador de ventas'} disableScroll center>
                {this.renderExcelImport()}
                {this.renderTable()}
                <SLoad type='window' hidden={!this.state.loading} />
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);