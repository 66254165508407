import { SAction } from "servisofts-model";
export default class Action extends SAction {

    getAll(extra?: { key_paquete_venta_usuario: any }) {
        let reducer = this._getReducer();
        if (reducer.key_paquete_venta_usuario != extra?.key_paquete_venta_usuario) {
            reducer.data = null;
            reducer.key_paquete_venta_usuario = extra?.key_paquete_venta_usuario;
        }
        return super.getAll(extra);
    }
}