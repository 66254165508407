import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SForm, SHr, SIcon, SInput, SList2, SLoad, SNavigation, SPage, SScrollView2, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Model from "../../../../Model";
import Asistencia from "../../../Asistencia";
import Entrenamiento from "../..";
import Sucursal from "../../../Sucursal";

// clientes.
// sucursal,
// mes
// hora de ingreso
// horarios bajos
// entrenadores
// en cada sucursal
// pero para entrenador debemos
// registrarlos
// Excel
// hacerlo por fecha


class reporte_asistencias_global extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fecha_inicio = SNavigation.getParam("fecha_inicio", new SDate().toString("yyyy-MM-dd"));
    this.fecha_fin = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));

  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_ricky_entrenamientos_global",

      // params: ['2024-04-05', '2024-04-10'],
      params: ["'" + this.fecha_inicio + "'", "'" + this.fecha_fin + "'"],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }


  showLista() {


    var dase_datos = this.state.data;
    if (!dase_datos) return <>
      <SText>Descargando Base de datos</SText>;
      <SLoad />
    </>


    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <>
      <SText>Descargando Usuarios</SText>;
      <SLoad />
    </>

    var sucursal = Sucursal.Actions.getAll(this.props);
    if (!sucursal) return <>
      <SText>Descargando Sucursal</SText>;
      <SLoad />
    </>

    console.log("aqui ", dase_datos)
    return <STable2
      limit={18}
      data={dase_datos}
      cellStyle={{
        fontSize: 12,
        height: 30,
      }}
      header={[
        { key: "index", label: "#" },
        {
          key: "key_sucursal", label: "Sucursal	", width: 80, render: key => !key ? "" : sucursal[key]?.descripcion,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },
        {
          key: "key_cliente", label: "Cliente", width: 300, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },

        {
          key: "tipo_asistencia", label: "Tipo Ingreso", width: 100, center: true,
          component: (e) => <SView center style={{
            borderWidth: 1, borderColor: e == "Molinete" ? "cyan" : "yellow",
            borderRadius: 4, padding: 4
          }} >
            <SText center color={e == "Molinete" ? "cyan" : "yellow"} >{e}</SText></ SView>
        },


        // { key: "tipo_asistencia", label: "Tipo Ingreso", center: true, width: 100, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },

        { key: "fecha_ingreso", label: "Mes📆", center: true, width: 100, render: (item) => { return new SDate(item).toString("MONTH") } },
        { key: "horario", label: "Horario", center: true, width: 100, render: (item) => { return new SDate(item).toString("hh:mm") }, component: (e) => <SText color={"cyan"} >{`${e}`}</SText> },
        { key: "-fecha_ingreso1", label: "Fecha Ingreso", center: true, width: 100, render: (item) => { return new SDate(item.fecha_ingreso).toString("yyyy-MM-dd") }, component: (e) => <SText color={"yellow"} >{`${e}`}</SText> },
        { key: "-hora_ingreso1", label: "Hora Ingreso", center: true, width: 100, render: (item) => { return new SDate(item.fecha_ingreso).toString("hh:mm") } },
        { key: "Entrenador", label: "Entrenador", width: 80 },
        {
          key: "key_atc", label: "ATC", width: 200, render: key => !key ? "" : usuarios[key]?.Nombres + " " + usuarios[key]?.Apellidos,
          component: (e) => <SView ><SText color={STheme.color.text} >{`${e}`}</SText> </ SView>
        },
      ]}
    />
  }
  render() {
    return (<>
      <SPage title={"Global Entrenamientos " + this.fecha_inicio}>
        <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
          {this.showLista()}
        </SView>
      </SPage>
    </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(reporte_asistencias_global);

