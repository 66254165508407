
import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SDate, SExcelReader, SHr, SIcon, SInput, SNavigation, SPage, SPopup, STable2, SText, STheme, SView } from 'servisofts-component';
import SSocket from "servisofts-socket";
import Paquete from '..';
import Model from '../../../Model';

let captura_fecha_inicio = new SDate().setHours(0, 1, 1, 1).toString('yyyy-MM-dd hh:mm:ss');
let captura_fecha_fin = new SDate().addDay(2).setHours(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss');


class PaquetePromoUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.key_paquete = SNavigation.getParam("key_paquete");
    // this.key_paquete = SNavigation.getParam("key_paquete", '0b29859d-1c59-40f8-a447-c4786af0cef9');
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: "cargando", data_promo: null });
    SSocket.sendPromise({
      component: "paquete_promo_usuario",
      type: "getAll",
      key_paquete: this.key_paquete
    })
      .then((resp) => {
        this.setState({ loading: false, data_promo: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  estado_notificacion(_aux_fecha) {
    if (new SDate(_aux_fecha).toString('yyyy-MM-dd') < new SDate().toString('yyyy-MM-dd')) {
      return <SText >🕒Expirado</SText>
    } if (new SDate(_aux_fecha).toString('yyyy-MM-dd') > new SDate().toString('yyyy-MM-dd')) {
      return <SText backgroundColor={"#800080"} color='white'>📅Programado</SText>
    } else {
      if (new SDate(_aux_fecha).toString('hh:mm') < new SDate().toString('hh:mm')) {
        return <SText color='#00ff00'>🔔Notificado</SText>
      }
      if (new SDate(_aux_fecha).toString('hh:mm') > new SDate().toString('hh:mm')) {
        return <SText color='#ffa500'>⌛En espera</SText>
      }
    }
  }
  estado_color(_aux_fecha) {
    let _aux_mensaje = new SDate(_aux_fecha).toString('yyyy-MM-dd hh:mm');
    if (new SDate(_aux_fecha).toString('yyyy-MM-dd') < new SDate().toString('yyyy-MM-dd')) {
      return <SText>{_aux_mensaje}</SText>
    } if (new SDate(_aux_fecha).toString('yyyy-MM-dd') > new SDate().toString('yyyy-MM-dd')) {
      return <SText backgroundColor={"#800080"} color='white'>{_aux_mensaje}</SText>
    } else {
      if (new SDate(_aux_fecha).toString('hh:mm') < new SDate().toString('hh:mm')) {
        return <SText color='#00ff00'>{_aux_mensaje}</SText>
      }

      if (new SDate(_aux_fecha).toString('hh:mm') > new SDate().toString('hh:mm')) {
        return <SText color='#ffa500'>{_aux_mensaje}</SText>
      }
    }
  }
  renderExcelImport() {
    let paquetes = Paquete.Actions.getAll(this.props);
    var usuarios = Model.usuario.Action.getAll();
    // if (this.state.data) return <SText>carga data</SText>;
    // if (!this.state.data_promo) return <SText>carga promo</SText>;
    // if (!paquetes) return <SText>carga paquete</SText>;
    // if (!usuarios) return <SText>carga usuario</SText>;

    // if (this.state.data || !this.state.data_promo || !paquetes || !usuarios) return <SLoad/>
    if (this.state.data || !this.state.data_promo || !paquetes || !usuarios) return;

    let datos_todos = Object.values(this.state.data_promo).filter(obj => obj.key_paquete === this.key_paquete);
    return <SView col={"xs-12"} height center >
      <SView col={"xs-12"} row border={"transparent"}  >
        <SText>Datos del paquete</SText>
        <SText> Descripcion {paquetes[this.key_paquete]?.descripcion}</SText>
        <SText> Observacion {paquetes[this.key_paquete]?.motivo}</SText>
        <SHr />
        <SText>Para registrar a los usuarios en esta promoción de paquete, es necesario cargar un.</SText>
        <SText>archivo de Excel que incluya las siguientes encabezados.    <SText color='yellow'>{`key_usuario = Código del cliente`}</SText></SText>
        <SText>Haz clic en el ícono de Excel en la parte inferior derecha para descargar la plantilla de Excel 📊</SText>
        <SHr />
      </SView>
      <SView col={"xs-10"} center row border={"transparent"}  >
        <SView col={"xs-6"} center border={"transparent"}  >
          <SExcelReader onSubmit={(data, callback) => {
            this.setState({ data: data });
            callback();
          }} >
            <SView height={80} card width={180} center style={{ borderRadius: 4, }} ><SText center>CARGAR EXCEL</SText></SView>
          </SExcelReader>
        </SView>
        <SView col={"xs-6"} center    >
          <SView height={80} border={"red"} width={180} center backgroundColor='red' style={{ borderRadius: 4 }}
            onPress={() => {
              SPopup.confirm({
                key: "selectUser",
                title: "¿Estás seguro de borrar todos los registro?",
                message: "¿Deseas continuar?",
                onPress: () => {
                  SSocket.sendPromise({
                    component: "reporte",
                    type: "execute_function",
                    func: "_promo_usuario_borrar_todo",
                    params: ["'" + this.key_paquete + "'"],
                    ...this.params
                  }).then((resp) => {
                    this.setState({ loading: false, data_promo: null });
                    this.props.dispatch(resp);
                    SPopup.close("selectUser");
                  })
                    .catch((e) => {
                      this.setState({ loading: false, error: e });
                    });
                }
              })
            }}
          ><SText center>BORRAR USUARIOS</SText></SView>
        </SView>
      </SView>
      <SHr height={24} />
      <SView col={"xs-12"} flex card  >
        <STable2
          limit={15}
          data={datos_todos}
          cellStyle={{ fontSize: 12, height: 30 }}
          header={[
            { key: "index", label: "#" },
            {
              key: "key_usuario", label: "Cliente", width: 150, render: (item) => {
                return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
              }
            },
            {
              key: "key_paquete", label: "📦Paquete", width: 160, render: (item) => {
                return `${paquetes[item]?.descripcion}`;
              }
            },
            {
              key: "fecha_inicio", label: "🏁F.Fin promo", width: 150, center: true,
              render: (item) => { return new SDate(item).toString("dd-MM-yyyy hh:mm"); },
            },

            // {
            //   key: "-fecha_inicsio", label: "🎉F.Incio promo", width: 150, center: true,
            //   component: (e) => this.estado_color(e.fecha_inicio)
            // },
            {
              key: "fecha_fin", label: "🏁F.Fin promo", width: 150, center: true,
              render: (item) => { return new SDate(item).toString("dd-MM-yyyy hh:mm"); },
            },
            // {
            //   key: "-estado_fecha", label: "Estado", width: 150, center: true,
            //   render: (obj) => { return new SDate(obj.fecha_inicio).toString("yyyy-MM-dd hh:mm"); },
            //   component: (e) => this.estado_notificacion(e)

            // },
            {
              key: "estado", label: "Estado", width: 110, component: (e) => <SText center color={e != 1 ? "cyan" : "white"} >{e != 1 ? "📆Fecha Act." : "Enviado ✔"}</SText>
            },
            {
              key: "-btnEditarFecha", label: "Ed.Fecha", width: 70, center: true,
              component: (obj) => {
                return <SView onPress={() => {
                  alert("tranajandolo")
                  // SPopup.open({ content: this.popupEditarFecha(obj), key: "CodigoSeguridad" });
                }}>
                  <SIcon name={"Pencil"} fill='green' width={20} />
                </SView>
              }
            },
            {
              key: "-btnEliminar", label: "Elim.Usr", width: 70, center: true,
              component: (obj) => {
                return <SView onPress={() => {
                  alert("tranajandolo")

                  // SPopup.confirm({
                  //   key: "usuario_fila",
                  //   title: "¿Estás seguro de este registro?",
                  //   message: "¿Deseas continuar?",
                  //   onPress: () => {
                  //     SSocket.sendPromise({
                  //       component: "paquete_promo_usuario",
                  //       type: "editar",
                  //       estado: "cargando",
                  //       data: {
                  //         ...obj,
                  //         estado: 0,
                  //       }
                  //     }).then((resp) => {
                  //       delete this.state.data_promo[resp.data.key]
                  //       this.setState({ ...this.state });
                  //     })
                  //       .catch((e) => {
                  //         this.setState({ loading: false, error: e });
                  //       });
                  //   }
                  // })
                }}>
                  <SIcon name={"Delete"} width={35} />
                </SView>
              }
            },
            // {
            //   key: "key_usuario_autorizado", label: "Autorizado", width: 140, render: (item) => {
            //     return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
            //   },
            //   component: (e) => <SText color={"yellow"} >{e}</SText>
            // },
            {
              key: "fecha_on", label: "F.Reg.", width: 100, center: true, order: 'desc',
              render: (item) => { return new SDate(item).toString("dd-MM-yyyy hh:mm"); },
            },
          ]}
        />
      </SView>
    </SView>
  }

  popupEditarFecha(obj) {
    var dia_inicio = new SDate(obj.fecha_inicio).toString("yyyy-MM-dd");
    var dia_fin = new SDate(obj.fecha_fin).toString("yyyy-MM-dd");
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}>
        <SHr height={20} />
        <SText col={"xs-12"}> fecha inicio</SText>
        <SInput ref={ref => this._seleecion_fecha1 = ref} placeholder={"Ingresar f. inicio📅"} col={"xs-8"} type={"date"} defaultValue={dia_inicio} customStyle={"calistenia"} />
        <SHr height={20} />
        <SText col={"xs-12"} > fecha fin</SText>
        <SInput ref={ref => this._seleecion_fecha2 = ref} placeholder={"Ingresar fecha fin📅"} col={"xs-8"} type={"date"} defaultValue={dia_fin} customStyle={"calistenia"} />
        <SHr height={15} />
        <SText col={"xs-9"} color={STheme.color.text + 66} center>¿Está seguro de que desea cambiar la fecha de la promo?</SText>
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={() => {
          let datoss = {
            ...obj,
            fecha_inicio: new SDate(this._seleecion_fecha1.getValue()).addDay(1).setHours(0, 1, 1, 1).toString('yyyy-MM-dd hh:mm:ss'),
            fecha_fin: new SDate(this._seleecion_fecha2.getValue()).addDay(1).setHours(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss'),
            estado: 3,
            key_usuario_autorizado: this.props.state.usuarioReducer.usuarioLog.key
          }

          SSocket.sendPromise({
            component: "paquete_promo_usuario",
            type: "editar",
            estado: "cargando",
            data: datoss
          })
            .then((resp) => {
              this.state.data_promo[resp.data.key] = resp.data
              this.setState({ ...this.state })
              this.props.dispatch(resp);
              SPopup.close("CodigoSeguridad");
            })
            .catch((e) => {
              this.setState({ loading: false, error: e });
            });
        }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }

  insertarRegistro = async () => {
    let dia_por_defecto = new SDate().setHours(0, 1, 1, 1).toString('yyyy-MM-dd hh:mm:ss');
    let fin_por_defecto = new SDate().addDay(2).setHours(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss');
    console.log("total ", this.state.data.length)
    for (let i = 0; i < this.state.data.length; i++) {
      const data = this.state.data[i];


      let formatear_data = {
        key_usuario: data?.key_usuario,
        key_paquete: this.key_paquete,
        fecha_inicio: data.fecha_inicio ? data.fecha_inicio : dia_por_defecto,
        fecha_fin: data.fecha_fin ? data.fecha_fin : fin_por_defecto,
        estado: 2,
        key_usuario_autorizado: this.props.state.usuarioReducer.usuarioLog.key
      }
      const resp = await SSocket.sendPromise({
        component: "paquete_promo_usuario",
        type: "registro",
        data: formatear_data,
        estado: "cargando"
      })
      if (resp.estado == "exito") {
        data.estado = 2;
        this.setState({ ...this.state })
      }
    }
    alert("se envio todas las notificaciones")

  }

  renderTable() {
    let usuarios = Model.usuario.Action.getAll();
    let paquetes = Paquete.Actions.getAll(this.props);
    if (!this.state.data || !usuarios || !paquetes) return;
    // if (!this.state.data || !this.state.data_promo || !paquetes || !usuarios) return;

    let modifiedData = this.state.data.map((item, index) => {
      return {
        ...item,
        indice: index
      };
    });
    return <>
      {/* <SHr height={150} /> */}




      <SView col={"xs-12"} center row>
        <SView col={"xs-6"} center>
          <SView height={80} border={"green"} width={280} center backgroundColor='green' style={{ borderRadius: 4 }}
            onPress={() => {
              // alert("Estamos en la fase de pruebas del servidor para lograr respuestas rápidas, y se enviará una notificación a todos los usuarios en un tiempo breve")
              this.insertarRegistro()
            }}
          ><SText center>NOTIFICAR A TODOS USUARIOS</SText></SView>
        </SView>
      </SView>
      {/* <SView col={"xs-10"} center row border={"BLUE"}  >
        <SView col={"xs-6"} center    >
          <SView height={80} border={"red"} width={280} center backgroundColor='red' style={{ borderRadius: 4 }}
            onPress={() => {
              this.state.data = null;
              this.componentDidMount()
              this.setState({ ...this.state })
            }}
          ><SText center>cerrar</SText></SView>
        </SView>
      </SView> */}
      <SHr height={30} />
      <SView col={"xs-12"} flex height backgroundColor={STheme.color.card}   >
        {/* TODO: si presiona teclado delete, debe eliminar los check */}
        {/* por defecto todo tiene que estas check */}
        <STable2
          limit={20}
          cellStyle={{
            fontSize: 12,
            height: 30,
          }}
          data={this.state.data}
          // data={modifiedData}

          header={[
            { key: "indice", label: "nro" },
            // { key: "-check", label: "Selec.", width: 45, center: true, component: (obj) => { return <SInput type={"checkBox"} value='true' /> } },
            { key: "key_paquete", label: "📦Paquete", width: 120, render: (item) => { return `${paquetes[this.key_paquete]?.descripcion}`; } },
            { key: "-precio", label: "💲Precio", width: 40, render: (item) => { return `${paquetes[this.key_paquete]?.precio}`; } },
            { key: "key_usuario", label: "👤Usuario", width: 200, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; } },
            { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
            {
              key: "-nacimiento", label: "HB🎂", width: 45, render: (obj) => {
                const fechaNacimiento = usuarios[obj.key_usuario]?.["Fecha nacimiento"];
                const validacion = fechaNacimiento ? new SDate(fechaNacimiento).toString('dd/MM') : "";
                const esFechaDeHoy = validacion === new SDate().toString('dd/MM');
                const condicion = esFechaDeHoy ? `${validacion} 🎂` : validacion;
                return condicion;
              }
            },
            { key: "-correo", label: "Correo", width: 150, render: (obj) => { return `${usuarios[obj.key_usuario]?.Correo}`; } },
            {
              key: "fecha_inicio", label: "🎉F.Incio promo", width: 150, center: true, render: (item) => {
                if (!item) {
                  return new SDate(captura_fecha_inicio).toString("dd-MM-yyyy hh:mm:ss");
                }
                return new SDate(item).toString("dd-MM-yyyy hh:mm:ss");
              }
            },
            {
              key: "fecha_fin", label: "🏁F.Fin promo", width: 150, center: true, render: (item) => {
                if (!item) {
                  return new SDate(captura_fecha_fin).toString("dd-MM-yyyy hh:mm:ss");
                }
                return new SDate(item).toString("dd-MM-yyyy hh:mm:ss");
              }
            },
            // { key: "estado", label: "📊Estado", width: 70, component: (e) => <SText center color={e != 2 ? "white" : "cyan"} >{e != 2 ? "pendiente" : "Enviado✅"}</SText> },
            { key: "estado", label: "📊Estado", width: 70, component: (e) => <SText center color={"white"} >trabajando</SText> },
            {
              key: "-", label: "🔔Notificar", width: 90, center: true, component: (obj) => {
                return <SView center width={50} height={30} card onPress={async () => {
                  let formatear_data = {
                    key_usuario: obj.key_usuario,
                    key_paquete: this.key_paquete,
                    fecha_inicio: obj.fecha_inicio,
                    fecha_fin: obj.fecha_fin,
                    estado: 2,
                    key_usuario_autorizado: this.props.state.usuarioReducer.usuarioLog.key
                  }
                  const resp = await SSocket.sendPromise({
                    component: "paquete_promo_usuario",
                    type: "registro",
                    data: formatear_data,
                    estado: "cargando"
                  })
                  if (resp.estado == "exito") {
                    // aqui deberia registrar por indice
                    var objenc = this.state.data.find(a => a.key_usuario == obj.key_usuario)
                    if (objenc) {
                      objenc.estado = 2;
                      this.setState({ ...this.state })
                    }
                    alert("exito")
                  }
                }
                }>
                  <SText center>Enviar</SText>
                </SView>
              }
            },
            {
              key: "-d", label: "quitar", width: 60, center: true,
              component: (obj) => {
                return <SView onPress={() => {

                  alert("en proceso")
                  // delete this.state.data[obj.indice];
                  // this.setState({ data: modifiedData });
                  // this.setState({ ...this.state });

                }} >
                  <SIcon name={"Close"} fill='red' width={35} />
                </SView>
              }
            },
            // {
            //   key: "-d", label: "quitar", width: 60, center: true,
            //   component: (obj) => {
            //     return <SView onPress={() => {

            //    console.log("index ",index[obj])
            //       // delete this.state.data[obj.INDEX];
            //       // this.setState({ ...this.state });

            //       // const keyToDelete = obj.key_usuario;
            //       // const newData = this.state.data.filter(item => item.key_usuario !== keyToDelete);
            //       // this.setState({ data: newData });
            //     }} >
            //       <SIcon name={"Close"} fill='red' width={35} />
            //     </SView>
            //   }
            // },
          ]} />
      </SView>

    </>

  }
  render() {
    return (<>
      <SPage title={'Registros Notificación'} disableScroll center>
        {this.renderExcelImport()}
        {this.renderTable()}
        {/* <SLoad type='window' hidden={!this.state.loading} /> */}
      </SPage>
    </>
    );
  }

}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(PaquetePromoUsuarios);