import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SInput, SText, STheme, SView } from 'servisofts-component'
import Paquete from '../../../Paquete/Component/Paquete';

export default class Header extends Component {

    render_motivo(paquete) {
        if (paquete) return (
            <SInput customStyle={"calistenia"} label={"Motivo"} col={"xs-12"} ref={(ref) => { this.inputObservacion = ref }} isRequired={true} placeholder={"Escribir motivo del paquete"} />);
    }

    render() {
        const { paquete } = this.props;
        const { key, descripcion, observacion, precio, participantes, dias, requiere_motivo } = paquete;
        return <SView col={"xs-12"} center>
            <SText bold fontSize={24}>Datos de venta</SText>
            <SHr height={20} />
            <Paquete key_paquete={key} />
            {this.render_motivo(requiere_motivo)}
            <SHr height={20} />
            {/* <SText bold fontSize={18}>{descripcion}</SText> */}
            {/* <SText fontSize={16} color={STheme.color.lightGray} >{observacion}</SText> */}
            <SText bold fontSize={24}>Cliente</SText>
            <SHr color={STheme.color.text} col={"xs-12"} height={1} />
        </SView>
    }
}