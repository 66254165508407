import { SPage } from "servisofts-component";
import anulados from "./anulados";
import consolidados from "./consolidados";
import dashboard from "./dashboard";
import facturas from "./facturas";
import parametricas from "./parametricas";
import parametricas_table from "./parametricas_table";
import reporte_excel from "./reporte_excel";
import root from "./root";




export default SPage.combinePages("facturacion", {
  "": root,
  parametricas,
  "parametricas/table": parametricas_table,
  facturas,
  consolidados,
  anulados,
  reporte_excel,
  dashboard
})
