import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SHr, SIcon, SImage, SList, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import Container from '../Components/Container';
// import { Container } from '../Components';
import Model from '../Model';

class terminos extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <SPage
                // navBar={this.navBar()}
                // footer={this.footer()}
                title={"Términos y Condiciones"}
            >
                <Container>
                    <SHr height={40} />
                    <SText bold center fontSize={16}>TÉRMINOS Y CONDICIONES DE USO APP</SText>
                    <SHr height={25} />
                    <SText row> Última actualización: 08 de agosto de 2023</SText>
                    <SHr />
                    <SText> Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.</SText>
                    <SHr />
                    <SText> Utilizamos sus datos personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio, Usted acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad. Esta Política de Privacidad ha sido creada con la ayuda del Generador de Políticas de Privacidad.</SText>
                    <SHr height={25} />

                    <SText bold center fontSize={16}>Interpretación y definiciones</SText>


                    <SText>Interpretación</SText>
                    <SHr />

                    <SText>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</SText>
                    <SHr />
                    <SText>Definiciones</SText>
                    <SHr />
                    <SText>A los efectos de esta Política de privacidad:</SText>
                    <SHr />
                    <SText>Cuenta significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.
                        Aplicación se refiere a CALISTENIA APP, el programa de software proporcionado por la Compañía.
                        Compañía  (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a CALISTENIA BOLIVIA S.R.L., SANTA CRUZ DE LA SIERRA.
                        País se refiere a: Bolivia
                        Dispositivo significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</SText>
                    <SHr />
                    <SText>Los datos personales son cualquier información relacionada con un individuo identificado o identificable.
                        El Servicio se refiere a la Aplicación.
                        Proveedor de servicios significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a compañías o individuos de terceros empleados por la Compañía para facilitar el Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios relacionados con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio.</SText>
                    Servicio de redes sociales de terceros se refiere a cualquier sitio web o sitio web de red social a través del cual un Usuario puede iniciar sesión o crear una cuenta para usar el Servicio.
                    Los Datos de uso se refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).
                    Usted se refiere a la persona que accede o utiliza el Servicio, o la empresa, u otra entidad legal en nombre de la cual dicha persona está accediendo o utilizando el Servicio, según corresponda.

                    <SHr height={25} />

                    {/* <SText bold center fontSize={16}>Recopilación y uso de sus datos personales</SText> */}


                    <SHr height={40} />
                </Container>
            </SPage>
        );
    }

}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(terminos);