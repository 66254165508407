import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../../Model';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado", "key_empresa","key_cuenta_contable"],
        });
    }
    $allowNew() {
        return true;
    }
    // $allowTable() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    // }
    // $allowAccess() {
    //     return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    // }
    $filter(data) {
        return data.estado != 0
    }
    $getData() {
        var data = Parent.model.Action.getAll();
        console.log(data);
        return data;
    }
}
export default connect(index);