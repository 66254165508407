import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
  info: {
    component: "prestamo",
  },
  Columns: {
    key: { type: "text", pk: true },
    key_usuario: { type: "text", fk: "usuario" },
    fecha_on: { type: "timestamp", label: "Fecha de registro" },
    estado: { type: "integer" },
    key_prestamista: { type: "text" },
    monto_prestado: { type: "double" },
    porcentaje: { type: "integer" },
    inicio_prestamo: { type: "timestamp", label: "Fecha inicio" },
    fin_prestamo: { type: "timestamp", label: "Fecha fin" },
    periocidad: { type: "integer" },
    estado_prestamo: { type: "integer" },
  },
  Action,
  Reducer,
});
