import { Component } from 'react';
import { SHr, SIcon, SLoad, SText, SView } from 'servisofts-component';
import Parent from "..";
import SSocket from 'servisofts-socket'

class BtnSincronizar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getContent = () => {
    if (this.state.loading) return <SLoad />
    return <SView col={"xs-12"} height center row>
      <SIcon name={"Reload"} fill={"orange"} height={18} width={18} />
      {/* <SIcon name={"Reload"} fill={STheme.color.lightGray} height={18} width={18} /> */}
      <SView width={8} />
      <SText color={"orange"}>Enviar usuarios al molinete</SText>
      {/* <SText color={STheme.color.lightGray}>Sync users</SText> */}
    </SView>
  }

  render() {
    return (
      <SView col={"xs-12"} center
        onPress={() => {
          if (this.state.loading) return;
          this.setState({ loading: true });
          Parent.Actions.sincronizarMolinete(this.props.dispositivo?.key, this.props.key_sucursal, (5 * 60 * 1000)).then((resp) => {
            this.setState({ loading: false });
            if (resp.estado == "exito") {
              console.log("Exito al sincronizar molinete1");
            }
          }).catch((e) => {
            this.setState({ loading: false });
            console.log("Error al sincronizar molinete");
            console.log(e);
          })

          SSocket.sendPromise({
            component: "zkteco",
            type: "sincronizarLog",
            estado: "cargando",
            key_sucursal: this.props.key_sucursal,
        }, 10000)

        }} >
        <SHr />
        {this.getContent()}
        <SHr />
      </SView>
    );
  }
}

export default (BtnSincronizar);